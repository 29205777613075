import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { PayScheduleModel } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { loading, paydayDateUpdated, paydayDateUpdateFailure, paySchedulesFetched, paySchedulesFetchFailure } from "../redux/slices/payScheduleSlice";

const usePayScheduleActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getPaySchedules = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.paySchedules)
            .then(response => {
                dispatch(paySchedulesFetched(response.data as PayScheduleModel[]))
            })
            .catch(error => {
                console.log('getPaySchedules error:', error)
                dispatch(paySchedulesFetchFailure(error))
            })
    }

    const updatePaydayDate = (paydayId: string, date: string) => {
        dispatch(loading())
        const url = `${apiConfig.routes.paySchedules}paydays/${paydayId}` 

        return fetchClient.post(url, { paymentDate: date })
            .then(response => {
                dispatch(paydayDateUpdated(response.data as PayScheduleModel[]))
            })
            .catch(error => {
                console.log('updatePaydayDate error:', error)
                dispatch(paydayDateUpdateFailure(error))
            })
    }

    return {
        getPaySchedules,
        updatePaydayDate
    }
}

export default usePayScheduleActions