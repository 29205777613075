import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PayScheduleModel } from '../types'

interface PayScheduleState {
    loading: boolean
    paySchedules: PayScheduleModel[]
    error: Error | string | null
}

const initialState: PayScheduleState = {
    loading: false,
    paySchedules: [],
    error: null
}

export const payScheduleSlice = createSlice({
    name: 'paySchedule',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        paySchedulesFetched: (state, action: PayloadAction<PayScheduleModel[]>) => {
            state.loading = false
            state.paySchedules = action.payload
            state.error = null
        },
        paySchedulesFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        paydayDateUpdated: (state, action: PayloadAction<PayScheduleModel[]>) => {
            state.loading = false
            state.paySchedules = action.payload
            state.error = null
        },
        paydayDateUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        }, 
    }
})

export const { 
    loading, 
    paySchedulesFetched, 
    paySchedulesFetchFailure, 
    paydayDateUpdated, 
    paydayDateUpdateFailure 
} = payScheduleSlice.actions

export default payScheduleSlice.reducer