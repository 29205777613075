import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from './Modal'
import { EmployerPortalUser, PortalRole, EmployerPortalUserEditModel } from '../redux/types'
import InputField from './InputField'
import Switch from './Switch'
import { useTranslation } from 'react-i18next'

interface Props {
    initialValue?: EmployerPortalUser | null
    onSaveClick?: (user: EmployerPortalUserEditModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const EditPortalUserModal = ({
    initialValue,
    onSaveClick,
    onCancelClick,
    visible
} : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'portalUsers']);
    const [user, setUser] = useState<EmployerPortalUserEditModel>({portalUserId: null, email: '', roles: []})

    useEffect(() => {
        if (initialValue) {
            setUser({
                portalUserId: initialValue.portalUserId,
                email: initialValue.email,
                roles: [...initialValue.roles],
            })
        }           
    }, [initialValue])

    const handleOnSave = () => {
        user && onSaveClick && onSaveClick(user)
        setUser({portalUserId: null, email: '', roles: []})
    }

    const handleOnCancel = () => {
        onCancelClick && onCancelClick()
        setUser({portalUserId: null, email: '', roles: []})
    }

    const handleOnChange = (propName: string, value: any) => {
        if (user)
            setUser({...user, [propName]: value})
    }

    const handleRoleChange = (role: PortalRole, value: boolean) => {
        if (user) {
            if (value) 
                setUser({...user, roles: [...user.roles ?? [], role]})
            else
                setUser({...user, roles: user.roles?.filter(x => x !== role)})
        }  
    }

    return (
        <Modal
            title={initialValue != null ? t('editPortalUser', {ns: 'portalUsers'}) : t('addPortalUser', {ns: 'portalUsers'})}
            visible={visible}
            okButtonTitle={t('save', {ns: 'common'})}
            cancelButtonTitle={t('cancel', {ns: 'common'})}
            onHide={() => handleOnCancel()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnCancel()}
        >
            <div className={styles.row}>
                <InputField
                    disabled={initialValue != null}
                    mandatory={true}
                    id='email'
                    value={user?.email ?? ''}
                    onChange={value => handleOnChange('email', value)}
                    label={t('email', {ns: 'common'})}
                    placeholder=''
                />
            </div>
            <div className={styles.row}>
                <h5 style={{marginTop: 20, marginBottom: 10}}>{t('roles', {ns: 'portalUsers'})}</h5>
                <Switch 
                    disabled={true}
                    id='owner'
                    key='owner'
                    checked={user?.roles?.includes('Owner') ?? false} 
                    label={t('role_Owner', {ns: 'portalUsers'})}
                    onChange={() => handleRoleChange('Owner', !(user?.roles?.includes('Owner') ?? false))} 
                    tooltipText={t('ownerDescription', {ns: 'portalUsers'})}
                />
                <Switch 
                    id='admin'
                    key='admin'
                    checked={user?.roles?.includes('Admin') ?? false} 
                    label={t('role_Admin', {ns: 'portalUsers'})}
                    onChange={() => handleRoleChange('Admin', !(user?.roles?.includes('Admin') ?? false))} 
                    tooltipText={t('adminDescription', {ns: 'portalUsers'})}
                />
                <Switch 
                    id='payrollAdmin'
                    key='payrollAdmin'
                    checked={user?.roles?.includes('PayrollAdmin') ?? false} 
                    label={t('role_PayrollAdmin', {ns: 'portalUsers'})}
                    onChange={() => handleRoleChange('PayrollAdmin', !(user?.roles?.includes('PayrollAdmin') ?? false))} 
                    tooltipText={t('payrollAdminDescription', {ns: 'portalUsers'})}
                />
                <Switch 
                    id='activationAdmin'
                    key='activationAdmin'
                    checked={user?.roles?.includes('ActivationAdmin') ?? false} 
                    label={t('role_ActivationAdmin', {ns: 'portalUsers'})}
                    onChange={() => handleRoleChange('ActivationAdmin', !(user?.roles?.includes('ActivationAdmin') ?? false))} 
                    tooltipText={t('activationAdminDescription', {ns: 'portalUsers'})}
                />
                <Switch 
                    id='financeAdmin'
                    key='financeAdmin'
                    checked={user?.roles?.includes('FinanceAdmin') ?? false} 
                    label={t('role_FinanceAdmin', {ns: 'portalUsers'})}
                    onChange={() => handleRoleChange('FinanceAdmin', !(user?.roles?.includes('FinanceAdmin') ?? false))} 
                    tooltipText={t('financeAdminDescription', {ns: 'portalUsers'})}
                />
                 <Switch 
                    id='financeContact'
                    key='financeContact'
                    checked={user?.roles?.includes('FinanceContact') ?? false} 
                    label={t('role_FinanceContact', {ns: 'portalUsers'})}
                    onChange={() => handleRoleChange('FinanceContact', !(user?.roles?.includes('FinanceContact') ?? false))} 
                    tooltipText={t('financeContactDescription', {ns: 'portalUsers'})}
                />
                <Switch 
                    id='technicalContact'
                    key='technicalContact'
                    checked={user?.roles?.includes('TechnicalContact') ?? false} 
                    label={t('role_TechnicalContact', {ns: 'portalUsers'})}
                    onChange={() => handleRoleChange('TechnicalContact', !(user?.roles?.includes('TechnicalContact') ?? false))} 
                    tooltipText={t('technicalContactDescription', {ns: 'portalUsers'})}
                />
                <Switch 
                    id='payrollContact'
                    key='payrollContact'
                    checked={user?.roles?.includes('PayrollContact') ?? false} 
                    label={t('role_PayrollContact', {ns: 'portalUsers'})}
                    onChange={() => handleRoleChange('PayrollContact', !(user?.roles?.includes('PayrollContact') ?? false))} 
                    tooltipText={t('payrollContactDescription', {ns: 'portalUsers'})}
                />
            </div>
        </Modal>
    )
}

export default EditPortalUserModal

const useStyles = createUseStyles({
    row: {
        paddingBottom: 12
    }
})
