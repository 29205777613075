import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { PayDeliveryStatus, PayRun } from "../../redux/types"
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'
import { Colors } from '../../constants/colors'
import { Fonts } from '../../constants/fonts'

interface Props {
    rows: PayRun[]
    pageIndex: number
    pageSize: number
    onPageChange: (pageIndex: number) => void
}

const PayRunList = ({ rows, pageIndex, pageSize, onPageChange } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'payrolls']);

    const itemsOnCurrentPage = useMemo(() => {
        const firstPageIndex = (pageIndex - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return rows.slice(firstPageIndex, lastPageIndex);
    }, [rows, pageIndex, pageSize])

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.idContainer}>
                    <strong>{t('idNumber', {ns: 'common'})}</strong>
                </div> 
                <div className={styles.nameContainer}>
                    <strong>{t('name', {ns: 'common'})}</strong>
                </div>
                <div className={styles.amountContainer}>
                    <strong>{t('amount', {ns: 'payrolls'})}</strong> 
                </div>
                <div className={styles.badgeContainer}>
                    <strong>{t('payDeliveryStatus', {ns: 'payrolls'})}</strong> 
                </div>  
                <div className={styles.amountContainer}>
                    <strong>{t('issues', {ns: 'common'})}</strong> 
                </div>  
            </ListItemCard>
        )    
    }

    const renderListItems = () => {
        return itemsOnCurrentPage?.map((row, index) => {
            return (
                <ListItemCard key={`row${index}`} className={styles.listItemContainer} index={index}>
                    <div className={styles.idContainer}>
                        {row.identificationNumber}
                    </div> 
                    <div className={styles.nameContainer}>
                        {row.name}
                    </div>
                    <div className={styles.amountContainer}>
                        {row.originalPay.amount.toLocaleString('sv-SE', { minimumFractionDigits: 2 })} {row.originalPay.currencyCode.toUpperCase()}
                    </div>
                    <div className={styles.badgeContainer}>
                        <div className={styles.badge} style={{backgroundColor: getStatusColor(row.payDeliveryStatus)}}>
                            <span className={styles.badgeText} style={{color: getStatusTextColor(row.payDeliveryStatus)}}> {t(`payDeliveryStatus_${row.payDeliveryStatus}`, {ns: 'payrolls'})}</span>
                        </div>
                    </div>
                    <div className={styles.amountContainer}>
                        {row.needsPaymentMethodUpdate && t('payPayoutPausedDueToWrongSwishCredentials', {ns: 'payrolls'})}
                    </div>   
                </ListItemCard>
            ) 
         })
    }

    const getStatusColor = (status: PayDeliveryStatus) => {
        switch (status) {
            case 'pending': return Colors.yen_300
            case 'completed': return Colors.euro_300
        }
    }

    const getStatusTextColor = (status: PayDeliveryStatus) => {
        switch (status) {
            case 'pending': return Colors.gray0
            case 'completed': return Colors.dollar_900
        }
    }

    return (
        <>
           {renderHeader()}
           {renderListItems()}
           <div className={styles.pagination}>
            <Pagination
                pageIndex={pageIndex}
                itemCount={rows.length}
                pageSize={pageSize}
                siblingCount={1}
                onPageChange={onPageChange}
            />
            </div>
        </>
    )
}

export default PayRunList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    idContainer: {
        width: 150
    },
    nameContainer: {
        width: 300
    },
    amountContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: 200
    },
    badgeContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: 100
    },
    badge: {
        height: '20px',
        paddingLeft: 7,
        paddingRight: 7,
        borderRadius: 20,
        backgroundColor: Colors.euro_800,
        margin: 2
    },
    badgeText: {
        display: 'block',
        fontSize: 14,
        color: Colors.gray4,
        fontFamily: Fonts.regular, 
        lineHeight: '21px'   
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
  })