import { useState, useEffect } from 'react'
import { useAppSelector } from "../redux/hooks"
import useEmployeeActions from "../hooks/useEmployeeActions"
import AutocompleteField, { AutocompleteItem } from './AutocompleteField'
import { useTranslation } from 'react-i18next'

interface Props {
    onSelected: (item: AutocompleteItem | null) => void
    value: string
    externalIdAsValue: boolean
    includeTerminatedEmployees?: boolean
    includeUnconnectedEmployees?: boolean
    includeEmployeesWithoutDataCollectionConsent?: boolean
    disabled?: boolean
    valid?: boolean
    mandatory?: boolean
 }

 const MAX_HITS = 10

const EmployeeSelector = ({onSelected, value, externalIdAsValue, includeTerminatedEmployees, includeUnconnectedEmployees = true, includeEmployeesWithoutDataCollectionConsent = true, disabled, valid, mandatory}: Props) => {
    const { t } = useTranslation('common');
    const { getEmployees } = useEmployeeActions()
    const employees = useAppSelector(state => state.employee.employees)
    const [filteredEmployees, setFilteredEmployees] = useState<AutocompleteItem[]>()

    useEffect(() => {
        if (employees.length === 0) {
            getEmployees()
        }
    }, [])

    const handleTermChange = (searchTerm: string) => {
        if (!searchTerm || searchTerm.length < 1) {
            setFilteredEmployees([])
            return
        }

        const lowerCaseSearchTerm = searchTerm.toLowerCase().trimStart().trimEnd()
        var employeeFilterResult = employees
        .filter((employee) => 
            (includeTerminatedEmployees || employee.status !== 'terminated') && 
            (includeUnconnectedEmployees || employee.status !== 'registered') &&
            (includeEmployeesWithoutDataCollectionConsent || employee.dataCollectionConsentFromUser === true) &&
            (employee.givenName?.toLowerCase().includes(lowerCaseSearchTerm) ||
            employee.familyName?.toLowerCase().includes(lowerCaseSearchTerm) ||
            (employee.givenName?.toLowerCase() + ' ' + employee.familyName?.toLowerCase()).includes(lowerCaseSearchTerm) ||
            employee.externalId.toLowerCase().includes(lowerCaseSearchTerm) ||
            employee.identificationNumber.toLowerCase().includes(lowerCaseSearchTerm)))
        .slice(0, MAX_HITS)

        const items = employeeFilterResult.map((item) => {
            return {name: `${item.givenName} ${item.familyName} (${item.identificationNumber})`, value: externalIdAsValue ? item.externalId : item.id}
        })
        setFilteredEmployees(items)
    }
    
    const handleSelected = (selectedItem: AutocompleteItem | null) => {
        onSelected(selectedItem ?? null)
    }

    return (
        <>
            <AutocompleteField
                label={t('employee')}
                placeholder={t('search')}
                id='employeeSearch'
                onTermChange={handleTermChange}
                onSelect={handleSelected}
                value={value}
                disabled={disabled}
                valid={valid}
                searchResult={filteredEmployees ?? []}
                mandatory={mandatory}
            /> 
        </>
    )
}

export default EmployeeSelector