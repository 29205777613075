import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface FortnoxIntegrationState {
    loading: boolean
    activated: boolean | null
    activationUnhandled: boolean
    authenticationUrl: string | null
    error: Error | string | null
}

const initialState: FortnoxIntegrationState = {
    loading: false,
    activated: null,
    activationUnhandled: false,
    authenticationUrl: null,
    error: null
}

export const fortnoxIntegrationSlice = createSlice({
    name: 'fortnoxIntegration',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        activationStatusFetched: (state, action: PayloadAction<boolean>) => {
            state.loading = false
            state.activated = action.payload
            state.error = null
        },
        activationStatusFetchFailue: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        authenticationUrlFetched: (state, action: PayloadAction<string>) => {
            state.loading = false
            state.authenticationUrl = action.payload
            state.error = null
        },
        authenticationUrlFetchFailue: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        integrationActivated: state => {
            state.loading = false
            state.activated = true
            state.activationUnhandled = true
            state.error = null
        },
        integrationActivationFailue: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        activationHandled: state => {
            state.activationUnhandled = false
        },
    }
})

export const { 
    loading, 
    activationStatusFetched, 
    activationStatusFetchFailue,
    authenticationUrlFetched,
    authenticationUrlFetchFailue,
    integrationActivated,
    integrationActivationFailue,
    activationHandled
} = fortnoxIntegrationSlice.actions

export default fortnoxIntegrationSlice.reducer