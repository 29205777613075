import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../redux/hooks"
import Select, { SelectOption } from "./Select"

interface Props {
    selectedValue: string | undefined
    onSelect: (value: string | undefined) => void
    valid?: boolean
    disabled?: boolean
    noSelectionItemName?: string 
}

const DepartmentSelector = ({ selectedValue, onSelect, valid, disabled, noSelectionItemName }: Props) => {
    const NONE_VALUE = ''
    const { t } = useTranslation('common');

    const [value, setValue] = useState<string | undefined>(selectedValue)
    const [departmentOptions, setDepartmentOptions] = useState<SelectOption[]>([])
    const employees = useAppSelector(state => state.employee.employees)

    useEffect(() => {
        if (employees.length !== 0) {
            let distinctDepartments = [...new Set(employees.filter(x => x.department != null && x.department.length > 0).map(item => item.department))].sort()
            let nameAndValueList = distinctDepartments.map((item) => { return {name: item, value: item}})
            nameAndValueList.unshift({name: noSelectionItemName ? noSelectionItemName : t('allSelectOptions'), value: NONE_VALUE}) 
            setDepartmentOptions(nameAndValueList)
        }
    }, [employees])

    useEffect(() => {
        if (selectedValue !== value) {
            setValue(selectedValue)
        }
    }, [selectedValue])

    const handleSelect = (selectedValue: string) => {
        setValue(selectedValue)

        if (selectedValue == NONE_VALUE) {
            onSelect(undefined)
        } else {
            onSelect(selectedValue)
        }
    }

    return (
        <Select 
            id='departmentSelector'
            key='departmentSelector'
            label={t('department')}
            options={departmentOptions} 
            selectedValue={value}
            onSelect={handleSelect}
            valid={valid}
            disabled={disabled}
        />
    )
}

export default DepartmentSelector