export interface AuthenticationResult {
  regularLogin: boolean
  data: AuthenticationData
}

export interface AuthenticationData {
  user: CurrentUser
  currentEmployerId: string
  accessToken: string
}

export interface PaginationData {
  pageIndex: number
  pageSize: number
  totalItemCount: number
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export enum Language {
  sv = 'sv',
  en = 'en'
}

export type PortalRole = 'Owner' | 'Admin' | 'PayrollAdmin' | 'ActivationAdmin' | 'FinanceAdmin' | 'TechnicalContact' | 'PayrollContact' | 'FinanceContact'
export interface EmployerPortalUser {
  portalUserId: string | null
  givenName: string
  familyName: string
  email: string
  phoneNumber: string
  language: Language
  employerId: string
  employerName: string
  roles: PortalRole[]
}

export interface EmployerPortalUserEditModel {
  portalUserId: string | null
  email: string
  roles: PortalRole[]
}

export interface EmployerRoles {
  employerId: string
  employerName: string
  roles: PortalRole[]
}
export interface CurrentUser {
  portalUserId: string
  givenName: string
  familyName: string
  email: string
  phoneNumber: string
  language: Language
  employers: EmployerRoles[]
}

export interface CurrentUserEditModel {
  portalUserId: string
  givenName: string
  familyName: string
  phoneNumber: string
  language: Language
}

export interface Money {
  amount: number,
  currencyCode: string
}

export interface PayoutSettings {
  minAmountPerPayout: number | undefined | null
  maxAmountPerPayout: number | undefined | null
  maxAmountPerPayday: number | undefined | null
  maxPercentPerPayday: number | undefined | null
  maxNumberOfPayoutsPerPayday: number | undefined | null
  enableCustomShiftSettings: boolean
  maxCustomPercentPerPayday: number | undefined | null
  fee: number
  currency: string
}

export interface EmployeeAndDateRangeFilter {
  employeeId: string | null
  employeeName: string | null
  fromDate: string | null
  toDate: string | null
}

export interface ShiftListFilter {
  employeeId: string | null
  employeeName: string | null
  fromDate: string | null
  toDate: string | null
  status: ShiftStatus | null
}

export type EmployerState = 'active' | 'locked' | 'terminated'
export type EmployeeActivationType = 'bankAccount' | 'manual' | 'automatic'
export type EmployeeDeactivationType = 'manual' | 'automatic'
export type CalendarMonthPayoutBasisReportGenerationType = 'automatic' | 'manual'
export type PayrollFileFormat = 'none' | 'bankGiro' | 'fortnoxPdf' | 'sus' | 'painV03'
export type EmployeeDataParserType = 'csv' | 'hogia' | 'fram' | 'visma600UpdateOnly' | 'visma600CreateAndUpdate'
export type ShiftDataParserType = 'csv' | 'fram'
export type DayOfWeek = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday'
export type PayrollSystemImportFileFormat = 'paXml' | 'tlu' | 'hogia'
export type AdminFlowType = 'basisImport' | 'agiAdjustment'
export type PayrollFileEmployeeIdentifierType = "id10" | "externalId" | "custom" | "id12"

export interface Employer {
  id: string
  name: string
  legalName: string
  abbreviation: string
  companyRegistrationNumber: string
  language: Language
  payoutSettings: PayoutSettings
  creditLimit: Money
  bankAccount: string
  timeZoneId: string
  state: EmployerState
  launchDate: string
  employeeActivationType: EmployeeActivationType
  employeeDeactivationType: EmployeeDeactivationType
  calendarMonthPayoutBasisReportGenerationType: CalendarMonthPayoutBasisReportGenerationType
  calenderMonthPayoutBasisReportGenerationDayOfMonth: number | null
  payrollFileFormat: PayrollFileFormat
  enablePayrollFileAdjustment: boolean
  enableManualPayrollFileRowCreation: boolean
  employeeDataParserType: EmployeeDataParserType
  shiftDataParserType: ShiftDataParserType
  activationNotificationDaysOfWeek: DayOfWeek[]
  creditReportDayOfMonth: number
  invoiceEmailAddress: string
  portalIntegrations: string[]
  payrollSystemImportFileFormat: PayrollSystemImportFileFormat
  newEmployeesReportEnabled: boolean
  adminFlowType: AdminFlowType
  defaultTaxTableIdentifier: string
  defaultFixedTaxRatePercent: number | null
  defaultPayoutTaxInPercent: number
  payoutTaxImmutable: boolean
  logos: EmployerLogoModel[]
  payrollFileEmployeeIdentifierType: PayrollFileEmployeeIdentifierType
  manualPayrollFileRowCreationEnabled: boolean
  employerInitiatedPayoutsEnabled: boolean
}

export interface EmployerLogoModel {
  theme: string
  contentType: string
  image: string
}

export interface CreditUsage {
  totalCreditUsage: Money
  creditLimit: Money
  creditUsagePerPayday: PaydayCreditUsage[]
  dailyCreditUsage: DailyCreditUsage[]
}

export interface PaydayCreditUsage
{
  paydayId: string
  externalPaydayId: string
  paymentDate: string
  creditUsage: Money
}

export interface DailyCreditUsage {
  date: string
  creditUsage: Money
  creditUsageForUpcomingPayday: Money
  creditUsageForFollowingPayday: Money
  creditLimit: Money
}

export enum Periodicity {
  monthly = 'monthly'
}

export enum AccrualType {
  arrears = 'arrears',
  advance = 'advance'
}

export enum PaydayOnWeekendHandling {
  advance = 'advance',
  postpone = 'postpone'
}

export interface PayScheduleModel {
  id: string | null
  identifier: string
  periodicity: Periodicity
  salaryAccrualType: AccrualType
  salaryEarningOnWeekends: boolean
  periodStartDayOfMonth: number
  deviationPeriodEndOffset: number
  defaultPaymentDayOfMonth: number
  paydayOnWeekendHandling: PaydayOnWeekendHandling
  isDefault: boolean
  paydays: PaySchedulePaydayModel[]
}

export interface PaySchedulePaydayModel {
  id: string
  paymentDate: string
  isShared: boolean
  isReadOnly: boolean
  paydayPeriodStartDate: string
  paydayPeriodEndDate: string
  wagesPeriodStartDate: string
  wagesPeriodEndDate: string
  salaryPeriodStartDate: string
  salaryPeriodEndDate: string
  deviationPeriodStartDate: string
  deviationPeriodEndDate: string
}

export interface Payday {
  id: string | null
  externalId: string
  paymentDate: string
  locked: boolean
  completed: boolean
}

export const EmployeeStatus = {
  Registered: 'registered',
  Connected: 'connected',
  Active: 'active',
  Paused: 'paused',
  Terminated: 'terminated'
} as const
export type EmployeeStatus = typeof EmployeeStatus[keyof typeof EmployeeStatus]

export const EmployeeType = {
  Worker: 'worker',
  Salaried: 'salaried'
} as const
export type EmployeeType = typeof EmployeeType[keyof typeof EmployeeType]

export const PayCalculationType = {
  Default: 'default',
  ShiftBasedSalary: 'shiftBasedSalary'
} as const
export type PayCalculationType = typeof PayCalculationType[keyof typeof PayCalculationType]

export const BookkeepingAccountType = {
  SalaryAccount: 'salaryAccount',
  TaxAccount: 'taxAccount',
  CappyAdjustmentAccount: 'cappyAdjustmentAccount',
  SocialFeeAccount: 'socialFeeAccount',
  SocialFeeSettlementAccount: 'socialFeeSettlementAccount'
}
export type BookkeepingAccountType = typeof BookkeepingAccountType[keyof typeof BookkeepingAccountType]

export interface EmployeeTaxSettings {
  taxTableIdentifier: string | null
  fixedTaxRatePercent: number | null
  payoutTaxInPercent: number | null
}
export interface EmployeePayoutSettings {
  maxAmountPerPayday: number | null
  maxPercentagePerPayday: number | null
  maxCustomPercentagePerPayday: number | null
}
export interface Employee extends EditEmployeeModel {
  id: string
  status: EmployeeStatus
  bankAccountNumber: string
  pauseDate: string
  activated: boolean
  activationDate: string
  manuallyCreated: boolean
  dataCollectionConsentFromUser: boolean
  hasUnsettledPayouts: boolean
}
export interface EditEmployeeModel {
  employeeType: EmployeeType
  payCalculationType: PayCalculationType
  externalId: string
  givenName: string
  familyName: string
  identificationNumber: string
  salary: number | null
  hourlyWage: number | null
  payrollFileIdentifier: string | null
  email: string
  phoneNumber: string
  department: string
  startDate: string | null
  terminationDate: string | null
  paused: boolean
  pauseReason: string
  nonEligible: boolean
  underForeclosure: boolean
  taxSettings: EmployeeTaxSettings
  payoutSettings: EmployeePayoutSettings
  handleVacationPay: boolean
  vacationPayPercent: number | null
  location: string
  category: string
  costCenter: string
}

export const EmployeeFlagType = {
  UnderForeclosure: 'underForeclosure',
  PayoutLimitation: 'payoutLimitation',
  UnsettledPayouts: 'unsettledPayouts',
  NonEligible: 'nonEligible',
} as const
export type EmployeeFlagType = typeof EmployeeFlagType[keyof typeof EmployeeFlagType]

export interface EmployeeFilter {
  term: string | undefined,
  departments: string[],
  categories: string[],
  statuses: EmployeeStatus[],
  flags: EmployeeFlagType[]
}

export interface EmployeeMinModel {
  id: string
  externalId: string
  name: string
  identificationNumber: string
}

export interface NewEmployeeInPeriodModel {
  id: string
  externalId: string
  givenName: string
  familyName: string
  identificationNumber: string
}

export interface NewEmployeesWithPayoutsInPeriodModel {
  startDate: string | undefined
  endDate: string | undefined
  employees: NewEmployeeInPeriodModel[] | undefined
}

export interface PaydayMinModel {
  id: string
  paymentDate: string
}

export const ShiftType = {
  Standard: 'standard',
  Custom: 'custom'
} as const
export type ShiftType = typeof ShiftType[keyof typeof ShiftType]

export const ShiftStatus = {
  Completed: 'completed',
  Planned: 'planned'
} as const
export type ShiftStatus = typeof ShiftStatus[keyof typeof ShiftStatus]

export interface Shift {
  id: string
  externalId: string
  date: string
  type: ShiftType
  status: ShiftStatus
  hours: number
  startTime: string | null
  endTime: string | null
  grossWages: Money
  manuallyCreated: boolean
  employee: EmployeeMinModel
  payday: PaydayMinModel
  location: string
  description: string
}
export interface EditShiftModel {
  id: string | null
  externalId: string
  externalEmployeeId: string
  employeeId: string
  employeeName: string
  employeeIdentificationNumber: string
  date: string
  type: ShiftType
  status: ShiftStatus
  hours: number
  grossWages: number
  startTime: string | null
  endTime: string | null
  location: string
  description: string
}

export interface PagedShifts {
  shifts: Shift[]
  totalShiftCount: number
}


export interface PayDeviation {
  id: string
  externalId: string
  description: string
  date: string
  grossAmount: Money
  manuallyCreated: boolean
  employee: EmployeeMinModel
  payday: PaydayMinModel
}

export interface EditPayDeviationModel {
  id: string | null
  externalId: string
  externalEmployeeId: string
  employeeId: string
  employeeName: string
  employeeIdentificationNumber: string
  description: string
  date: string
  grossAmount: number
}

export interface PagedPayDeviations {
  deviations: PayDeviation[]
  totalCount: number
}

export interface EmployeeReadyForActivation {
  id: string
  externalId: string
  givenName: string
  familyName: string
  identificationNumber: string
  department: string
  requestDate: string
  pendingBankAccountActivation: boolean
  bankAccountActivationFailed: boolean
}

export interface EmployeeReadyForDeactivation {
  id: string
  externalId: string
  givenName: string
  familyName: string
  identificationNumber: string
  department: string
}

export const IntegrationLogLevel = {
  Information: 'information',
  Error: 'error'
} as const
export type IntegrationLogLevel = typeof IntegrationLogLevel[keyof typeof IntegrationLogLevel]


export interface IntegrationLogItem {
  date: string
  level: IntegrationLogLevel
  type: string
  txId: string
  parameters: string
}

export interface PagedIntegrationLogItems {
  items: IntegrationLogItem[]
  totalCount: number
}

export interface IntegrationLogFilter {
  level: IntegrationLogLevel | null
  txId: string | null
  fromDate: string | null
  toDate: string | null
}


export type PayrollStatus = 'running' | 'completed'
export interface Payroll {
  paydayId: string
  paydayDate: string
  status: PayrollStatus
  remainingPaysToDeliver: number
  startedDate: string
  completedDate: string
  offCycle: boolean
}

export type PayrollValidationErrorType = 'unknownEmployeeInPayroll' | 'employeeMissingInPayroll' | 'employeeWithNegativeBalance' | 'nonActivatedEmployeeInPayroll' | 'employeeWithIncorrectBankAccount' | 'employeeWithIdentificationNumberMismatch' | 'paymentDateDifferentFromPayday' | 'possibleDuplication'
export interface PayrollValidationError {
  type: PayrollValidationErrorType
  payrollFileRowId: string | undefined
  parameters: string
}

export interface PayrollFile {
  externalId: string
  submitDate: string
  payrollFileRows: PayrollFileRow[]
}

export interface PayrollFileRow {
  id: string
  employeeId: string
  externalEmployeeId: string
  employeeName: string
  employeeIdentificationNumber: string
  netAmount: Money
}

export interface UploadPayrollFileRowsModel {
  originalRowCount: number
  rows: UploadPayrollFileRowModel[]
}

export interface UploadPayrollFileRowModel {
  employeePayrollFileIdentifier: string
  identificationNumber: string
  text: string
  amount: number
  bankAccountNumber: string
}

export interface UpcomingPayroll {
  paydayId: string
  paymentDate: string
  offCycle: boolean
  latestPayrollFileSubmitDate: string
  payrollFileDue: boolean
  payrollFileOverdue: boolean
  payrollFileSubmitted: boolean
  payrollFiles: PayrollFile[]
  validationErrors: PayrollValidationError[]
}

export enum EmployerInitiatedPayoutJobState {
  scheduled = "scheduled",
  cancelled = "cancelled",
  pending = "pending",
  completed = "completed"
}

export enum EmployerInitiatedPayoutBasisState {
  scheduled = "scheduled",
  cancelled = "cancelled",
  pending = "pending",
  unprocessable = "unprocessable",
  failed = "failed",
  completed = "completed"
}

export interface EmployerInitiatedPayoutJobListItem {
  id: string
  scheduledDate: string
  completedDate: string | null
  state: EmployerInitiatedPayoutJobState
}

export interface EmployerInitiatedPayoutJob {
  id: string
  scheduledDate: string
  completedDate: string | null
  state: EmployerInitiatedPayoutJobState
  paydayId: string
  basis: EmployerInitiatedPayoutBasis[]
}

export interface EmployerInitiatedPayoutBasis {
  id: string
  employee: EmployerInitiatedPayoutEmployeeModel
  grossAmount: Money
  netAmount: Money
  taxPercent: number
  state: EmployerInitiatedPayoutBasisState
}

export interface EmployerInitiatedPayoutEmployeeModel {
  id: string
  externalId: string
  identificationNumber: string
  givenName: string
  familyName: string
  status: EmployeeStatus
}

export interface PayrollData {
  payrolls: Payroll[]
  upcomingPayrolls: UpcomingPayroll[]
}

export type PayDeliveryStatus = 'pending' | 'completed'
export interface PayRun {
  id: string
  name: string
  identificationNumber: string
  originalPay: Money
  payDeliveryStatus: PayDeliveryStatus
  needsPaymentMethodUpdate: boolean
}

export interface DashboardMetrics {
  employeeCount: number
  employeeCountAtStartOfMonth: number
  activatedEmployeeCount: number
  activatedEmployeeCountAtStartOfMonth: number
  loginCountCurrentMonth: number
  totalLoginCount: number
  payoutCountCurrentMonth: number
  totalPayoutCount: number
  payoutSumCurrentMonth: Money
  totalPayoutSum: Money
}

export enum FileType {
  payrollSystemPayoutBasisImportFile = 'payrollSystemPayoutBasisImportFile',
  employerPayDeductionPaymentNotificationFile = 'employerPayDeductionPaymentNotificationFile',
  payrollSystemBankAccountImportFile = 'payrollSystemBankAccountImportFile',
  creditReportFile = 'creditReportFile',
  agiAdjustmentSieFile = 'agiAdjustmentSieFile',
  agiAdjustmentReportFile = 'agiAdjustmentReportFile',
  foraAdjustmentReportFile = 'foraAdjustmentReportFile',
  remainingDebtReportFile = 'remainingDebtReportFile',
  employeeForeclosureReportFile = 'employeeForeclosureReportFile',
  grossAdvanceReportFile = 'grossAdvanceReportFile',
}
export enum PayoutBasisFileType {
  deductionBasisForPayday = 'deductionBasisForPayday',
  payoutBasisForCalendarMonth = 'payoutBasisForCalendarMonth'
}
export interface FileModel {
  id: string
  type: FileType
  payrollSystemPayoutBasisImportFile: PayoutBasisImportFileModel
  employerPayDeductionPaymentNotificationFile: PayDeductionPaymentFileModel
  payrollSystemBankAccountImportImportFile: BankAccountImportFileModel
  creditReportFile : CreditReportFileModel
  agiAdjustmentSieFile: AgiAdjustmentSieFileModel
  agiAdjustmentReportFile: AgiAdjustmentReportFileModel,
  foraAdjustmentReportFile: ForaAdjustmentReportFileModel,
  remainingDebtReportFile: RemainingDebtReportFileModel,
  employeeForeclosureReportFile: EmployeeForeclosureReportFileModel,
  grossAdvanceReportFile: GrossAdvanceReportFileModel
}
export interface PayoutBasisImportFileModel {
  fileType: PayoutBasisFileType
  employeeType: EmployeeType
  year: number
  month: number
  paydayId: string
  paydayPaymentDate: string
  mimeType: string
  filename: string
  lastDownloadDate: string | null
}
export interface PayDeductionPaymentFileModel {
  paydayId: string
  paydayPaymentDate: string
  mimeType: string
  filename: string
  lastDownloadDate: string | null
}
export interface BankAccountImportFileModel {
  mimeType: string
  filename: string
}
export interface CreditReportFileModel {
  year: number
  month: number
  mimeType: string
  filename: string
  lastDownloadDate: string | null
}
export interface AgiAdjustmentSieFileModel {
  period: string
  mimeType: string
  filename: string
  lastDownloadDate: string | null
}

export interface AgiAdjustmentReportFileModel {
  period: string
  mimeType: string
  filename: string
}

export interface ForaAdjustmentReportFileModel {
  period: string
  mimeType: string
  filename: string
}

export interface RemainingDebtReportFileModel {
  paydayId: string
  payday: string
  filename: string
  mimeType: string
}

export interface EmployeeForeclosureReportFileModel {
  paydayId: string
  payday: string
  filename: string
  mimeType: string
}
export interface GrossAdvanceReportFileModel {
  year: number
  month: number
  mimeType: string
  filename: string
}

export interface PayoutBasisYearAndMonthModel {
  year: number | null
  month: number | null
}
export interface PayoutBasisGenerationResult {
  generatedImportFileCount: number
}

export interface BulkOperationResult {
  processCount: number
  insertCount: number
  updateCount: number
  errors: string[]
}

export interface AgiAdjustmentsModel {
  currentPeriod: string
  isCurrentPeriodAdjusted: boolean
  adjustedPeriods: AgiAdjustmentPeriodSummary[]
}

export interface AgiAdjustmentPeriodSummary {
  period: string
  adjustmentCount: number
}

export interface AgiAdjustmentsForPeriod {
  period: string
  adjustments: AgiAdjustment[]
  sieFile: FileModel
  agiAdjustmentReportFile: FileModel
}

export interface ForaAdjustmentsModel {
  currentPeriod: string
  isCurrentPeriodAdjusted: boolean
  adjustedPeriods: ForaAdjustmentPeriodSummary[]
}

export interface ForaAdjustmentPeriodSummary {
  period: string
  adjustmentCount: number
}

export interface ForaAdjustmentsForPeriod {
  period: string
  adjustments: ForaAdjustment[]
  foraAdjustmentReportFile: FileModel
}

export interface ForaAdjustment {
  employeeId: string
  externalId: string
  employeeName: string
  identificationNumber: string
  originalGrossPay: number
  grossPayAdditions: number
  grossPayDeductions: number
}

export enum SwedishTaxType {
  default = 'Default',
  aSINK = 'ASINK',
  sINK = 'SINK',
  none = 'None'
}
export enum AgiAdjustmentError {
  ejFastDriftstalleIndividUnsupported = 'EjFastDriftstalleIndividUnsupported',
  konventionMedUnsupported = 'KonventionMedUnsupported',
  payLessThanDeduction = 'PayLessThanDeduction'
}

export interface AgiAdjustment {
  employeeId: string
  externalId: string
  employeeName: string
  identificationNumber: string
  addedByCappy: boolean
  workplaceStreetAddress: string
  workplaceCity: string
  originalGrossPay: number
  grossPayAdditions: number
  grossPayDeductions: number
  originalTax: number
  taxAdditions: number
  taxDeductions: number
  taxType: SwedishTaxType
  socialFeeAdjustment: number
  error?: AgiAdjustmentError
}

export interface BookkeepingConfiguration {
  verificationDescription: string | undefined,
  verificationsPerIndividual: boolean | undefined,
  accountTypeConfigurations: BookkeepingAccountTypeConfiguration[]
}

export interface BookkeepingAccountTypeConfiguration {
  accountType: BookkeepingAccountType
  includeCostCenterDimension: boolean | undefined,
  includeEmploymentDimension: boolean | undefined,
  includeProjectDimension: boolean | undefined
  rules: BookkeepingAccountTypeRule[]
}

export interface BookkeepingAccountTypeRule {
  orderNumber: number
  account: number
  costCenter: string | null
  project: string | null
  filterEmployeeType: EmployeeType | null
  filterDepartment: string | null
  filterCategory: string | null
}
