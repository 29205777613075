import { Spinner } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import { Breakpoints } from '../constants/breakpoints'
import { Colors } from '../constants/colors'

interface Props { 
    title: string
    type?: 'button' | 'reset' | 'submit' | undefined
    loading?: boolean
    disabled?: boolean
    onClick?: () => void
    className?: string
    buttonStyle?: React.CSSProperties
    variant?: 'positive' | 'negative'
}

const Button = (props: Props) => {

    const styles = useStyles()

    return (
        <button 
            type={props.type ?? 'button'}
            className={styles.button}
            style={{backgroundColor: props.disabled ? Colors.grayDisabled : (props.variant === 'negative' ? 'transparent' : Colors.euro_800), borderWidth: props.variant === 'negative' ? 2 : 0, ...props.buttonStyle}}
            onClick={props.onClick}
            disabled={props.disabled || props.loading}
        >
          <div className={styles.innerContainer} style={{color: props.variant === 'negative' ? Colors.dollar_900 : '#ffffff'}}>
            {props.title}
            {props.loading && 
              <Spinner
                className={styles.spinner}
                as='span'
                animation='border'
                size='sm'
              />
            }
          </div>
        </button>
    )
}

export default Button

const useStyles = createUseStyles({
    button: {
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      minWidth: 120,
      height: 40,
      borderColor: Colors.dollar_900,
      borderStyle: 'solid',
      borderRadius: 20,
      borderWidth: 0,
      fontFamily: "Aestetico-SemiBold",
      fontSize: 14,
      backgroundColor: Colors.euro_800,
      color: '#ffffff',
      [`@media (min-width: ${Breakpoints.tablet}px)`]: {
        width: 'auto',
        paddingLeft: 30,
        paddingRight: 30,
        transition: '0.4s',
        '&:hover': {
          backgroundColor: Colors.euro_600,
        }
      },
    },
    innerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },
    spinner: {
      marginLeft: 5
    }
  })