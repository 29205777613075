import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EmployeeReadyForDeactivation } from '../types'

interface EmployeeDeactivationState {
    loading: boolean
    pendingEmployees: EmployeeReadyForDeactivation[]
    pageIndex: number
    pageSize: number
    error: Error | string | null
}

const initialState: EmployeeDeactivationState = {
    loading: false,
    pendingEmployees: [],
    pageIndex: 1,
    pageSize: 15,
    error: null
}

export const employeeDeactivationSlice = createSlice({
    name: 'employeeDeactivation',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        pendingEmployeesFetched: (state, action: PayloadAction<EmployeeReadyForDeactivation[]>) => {
            state.loading = false
            state.pendingEmployees = action.payload
            state.pageIndex = 1
            state.error = null
        },
        pendingEmployeesFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employeesDeactivated: (state, action: PayloadAction<EmployeeReadyForDeactivation[]>) => {
            const deactivatedIds = action.payload.map((employee) => employee.id)

            state.loading = false
            state.pendingEmployees = state.pendingEmployees.filter(x => !deactivatedIds.includes(x.id))
            state.error = null
        },
        employeesDeactivationFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
    }
})

export const { 
    loading, 
    pendingEmployeesFetched, 
    pendingEmployeesFetchFailure, 
    employeesDeactivated, 
    employeesDeactivationFailure,  
    pageIndexChanged 
} = employeeDeactivationSlice.actions

export default employeeDeactivationSlice.reducer