

export const hoursToHoursAndMinutesString = (hours: number) => {
    if (!hours)
    {
        return ''
    }

    var fullHours = Math.floor(hours)
    var decimals = hours - fullHours

    return `${fullHours}:${(decimals * 60).toFixed(0).padStart(2, '0')}`
}

export const hoursAndMinutesStringToHours = (hoursAndMinutesString: string) => {
    if (!hoursAndMinutesString || hoursAndMinutesString.length === 0)
    {
        return 0
    }    

    if (hoursAndMinutesString.indexOf(':') === -1) {
        return parseFloat(hoursAndMinutesString)
    } else {
        var parts = hoursAndMinutesString.split(':')
        var fullHours = parseFloat(parts[0])
        var minutes = parseFloat(parts[1])

        return fullHours + minutes / 60
    }

}