 import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import FullScreenLoadingIndicator from '../../components/FullScreenLoadingIndicator';
import usePlandayIntegrationActions from '../../hooks/integrations/usePlandayIntegrationActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { activationHandled } from '../../redux/slices/integrations/plandayIntegrationSlice';

const PlandayActivation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const { activateIntegration } = usePlandayIntegrationActions()

  const loading = useAppSelector(state => state.plandayIntegration.loading)
  const activationUnhandled = useAppSelector(state => state.plandayIntegration.activationUnhandled)

  const code = (new URLSearchParams(window.location.search)).get("code")
  const state = (new URLSearchParams(window.location.search)).get("state")

  useEffect(() => {
    if (code && state) {
      activateIntegration(code, state)  
    }
  }, [])

  useEffect(() => {
    if (!activationUnhandled) {
      dispatch(activationHandled())   
      navigate(`/integrations`)  
    }
  }, [activationUnhandled])

  return (
    <div>
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default PlandayActivation
