import { Link } from "react-router-dom"


const NotFound = () => {
    return (
        <>
            <div>
                Oops! The page you are looking for does not exist
            </div>
            <Link to={'/'}>Take me to the dashboard</Link>
        </>
    )
}

export default NotFound