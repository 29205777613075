import { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import Card from '../../components/Card'
import InputField from '../../components/InputField'
import Button from '../../components/Button'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Link, useSearchParams } from 'react-router-dom'
import usePasswordActions from '../../hooks/usePasswordActions'
import { useNavigate } from "react-router";
import { Colors } from '../../constants/colors'
import { useTranslation } from 'react-i18next'
import FullScreenLoadingIndicator from '../../components/FullScreenLoadingIndicator'

const CreatePassword = () => {
    const [searchParams] = useSearchParams();
    const emailQueryString = searchParams.get('email')
    const [email, setEmail] = useState<string>(emailQueryString || '')
    const {sendSetPasswordLink, loading, completed, error} = usePasswordActions()
    const navigate = useNavigate()
    const { t } = useTranslation(['common', 'password']);

    useEffect(() => {
        if (emailQueryString) {
            sendSetPasswordLink(emailQueryString)
        }
    }, [emailQueryString])

    useEffect(() => {
        if (completed) {
            navigate(`/passwordmailsent?type=${emailQueryString ? 'create' : 'forgot'}`)
        }
    }, [completed])

    const handleFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        if (email) {
            sendSetPasswordLink(email)
        }
    }

    const styles = useStyles()

    return (
        <div className={styles.container}>
            {!emailQueryString &&
            <Card className={styles.card}>
                <h3 className={styles.title}>{t('forgotPassword', {ns: 'password'})}</h3>
                <p>{t('forgotPasswordSubtitle', {ns: 'password'})}</p>

                <form onSubmit={handleFormSubmit}>
                    <div className={styles.cardRow}>
                        <InputField 
                            id='email'
                            label={t('email', {ns: 'common'})}
                            type='email'
                            value={email ?? ''}
                            icon={faEnvelope}
                            disabled={emailQueryString != null}
                            onChange={setEmail}
                        />
                    </div>
                    <div className={`${styles.cardRow} ${styles.buttonRow}`}>
                        <div> 
                            {!emailQueryString && <Link to={'/login'}>{t('backToLoginPage', {ns: 'password'})}</Link>}
                        </div>
                        <div className={styles.button}>
                            <Button
                                type='submit'
                                disabled={!(email)}
                                title={t('sendCreatePasswordLinkButtonText', {ns: 'password'})}
                            /> 
                        </div>
                    </div>
                    {!loading && error && 
                        <div className={styles.cardRow}>
                            <label className={styles.errorLabel}>
                                {t('requestFailureMessage', {ns: 'password'})}
                            </label>
                        </div>
                    }
                </form>
            </Card>
            }
            <FullScreenLoadingIndicator visible={loading} />
        </div>
    )
}

export default CreatePassword

const useStyles = createUseStyles({
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        marginBottom: 30
    },
    card: {
        width: 470,
        padding: 60,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardRow: {
        marginTop: 10,
        marginBottom: 10,
        width: 350
    },
    buttonRow: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    errorLabel: {
        color: Colors.rubel_700
    }
})