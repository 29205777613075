import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import Card from "../components/Card"
import DashboardMetricCard from '../components/DashboardMetricCard'
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator'
import { Colors } from '../constants/colors'
import { Fonts } from '../constants/fonts'
import useDashboardMetricsActions from '../hooks/useDashboardMetricsActions'
import { useAppSelector } from '../redux/hooks'

const Dashboard = () => {
    const styles = useStyles()
    const { t } = useTranslation('dashboard');
    const { getMetrics } = useDashboardMetricsActions()

    const user = useAppSelector(state => state.auth.authenticatedUser)
    const metrics = useAppSelector(state => state.dashboardMetrics.metrics)
    const metricsLoading = useAppSelector(state => state.dashboardMetrics.loading)

    useEffect(() => {
        if (!metrics) {
            getMetrics()
        }
    }, [])

    const getActivationConversion = (current: boolean) => {
        if (current) {
            if (metrics && metrics.employeeCount > 0) {
                const value = 100 * metrics?.activatedEmployeeCount / metrics?.employeeCount
                return formatPercentageValue(value)
            }
        } else {
            if (metrics && metrics.employeeCountAtStartOfMonth > 0) {
                const value = 100 * metrics?.activatedEmployeeCountAtStartOfMonth / metrics?.employeeCountAtStartOfMonth
                return formatPercentageValue(value)
            }
        }
        return "0"
    }

    const formatPercentageValue = (percentage: number) => {
        const roundedValue = Math.round(percentage * 10) / 10
        return roundedValue.toLocaleString('sv-SE')
    }

    const renderTrend = (percentage: number) => {
        return (
            <div className={styles.trendContainer}>
                <div style={{paddingTop: 8}}>
                    {percentage != null && percentage !== 0 &&
                    <FontAwesomeIcon
                        icon={percentage > 0 ? faChevronUp : faChevronDown}
                        color={percentage > 0 ? Colors.euro_800 : Colors.rubel_700}
                        size='2x'
                    />
                    }
                </div>
                <div className={styles.trendValue}>
                    {formatPercentageValue(percentage)}%
                </div>
            </div>
        )
    }

    const formatAndAddSign = (value: number) => {
        const formattedValue = value.toLocaleString('sv-SE')

        if (value > 0) {
            return '+ ' + formattedValue
        }

        return formattedValue;
    }

    return (
        <>
        <Card className={styles.card}>
            <h1>
                {t('title', {name: `${user?.givenName} ${user?.familyName}`})}
            </h1>
        </Card>
         <div className={styles.infoCardContainer}>
            <DashboardMetricCard title={t('eligibleEmployees')}>
                <div>
                    <div>
                        <div className={styles.metricValue}>
                            {metrics && formatAndAddSign(metrics.employeeCount - metrics.employeeCountAtStartOfMonth)}
                        </div>
                        <div>
                            {t('currentMonth')}
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <div className={styles.metricSubValue}>
                            {metrics?.employeeCount}
                        </div>
                        <div>
                            {t('inTotal')}
                        </div>
                    </div>
                </div>
                <div>
                    {metrics && metrics.employeeCount > 0 &&
                    renderTrend(100 * (metrics.employeeCount - metrics.employeeCountAtStartOfMonth) / metrics.employeeCount)
                    }
                </div>
            </DashboardMetricCard>
            <DashboardMetricCard title={t('activatedEmployees')}>
                <div>
                    <div>
                        <div className={styles.metricValue}>
                            {metrics && formatAndAddSign(metrics.activatedEmployeeCount - metrics.activatedEmployeeCountAtStartOfMonth)}
                        </div>
                        <div>
                            {t('currentMonth')}
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <div className={styles.metricSubValue}>
                            {metrics?.activatedEmployeeCount}
                        </div>
                        <div>
                            {t('inTotal')}
                        </div>
                    </div>
                </div>
                <div>
                    {metrics && metrics.activatedEmployeeCount > 0 &&
                    renderTrend(100 * (metrics.activatedEmployeeCount - metrics.activatedEmployeeCountAtStartOfMonth) / metrics.activatedEmployeeCount)
                    }
                </div>
            </DashboardMetricCard>
            <DashboardMetricCard title={t('activationConversion')}>
                <div>
                    <div>
                        <div className={styles.metricValue}>
                            {getActivationConversion(true)}%
                        </div>
                        <div>
                            {t('current')}
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <div className={styles.metricSubValue}>
                            {getActivationConversion(false)}%
                        </div>
                        <div>
                            {t('monthStart')}
                        </div>
                    </div>
                </div>
                <div>
                    {metrics &&  metrics.employeeCount > 0 && metrics.employeeCountAtStartOfMonth > 0 &&
                    renderTrend(100 * ((metrics.activatedEmployeeCount / metrics.employeeCount) - (metrics.activatedEmployeeCountAtStartOfMonth / metrics.employeeCountAtStartOfMonth)))
                    }
                </div>
            </DashboardMetricCard>
            <DashboardMetricCard title={t('appLogins')}>
                <div>
                    <div>
                        <div className={styles.metricValue}>
                            {metrics?.loginCountCurrentMonth.toLocaleString('sv-SE')}
                        </div>
                        <div>
                            {t('currentMonth')}
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <div className={styles.metricSubValue}>
                            {metrics?.totalLoginCount?.toLocaleString('sv-SE')}
                        </div>
                        <div>
                            {t('inTotal')}
                        </div>
                    </div>
                </div>
            </DashboardMetricCard>
            <DashboardMetricCard title={t('payoutCount')}>
                <div>
                    <div>
                        <div className={styles.metricValue}>
                            {metrics?.payoutCountCurrentMonth.toLocaleString('sv-SE')}
                        </div>
                        <div>
                            {t('currentMonth')}
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <div className={styles.metricSubValue}>
                            {metrics?.totalPayoutCount?.toLocaleString('sv-SE')}
                        </div>
                        <div>
                            {t('inTotal')}
                        </div>
                    </div>
                </div>
            </DashboardMetricCard>
            <DashboardMetricCard title={t('payoutSum')}>
                <div>
                    <div>
                        <div className={styles.metricValue}>
                            <span>{metrics?.payoutSumCurrentMonth?.amount.toLocaleString('sv-SE') ?? 0}</span>
                            <h6 style={{marginBottom: 14}}>{metrics?.payoutSumCurrentMonth?.currencyCode.toUpperCase()}</h6>
                        </div>
                        <div>
                            {t('currentMonth')}
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <div className={styles.metricSubValue}>
                            {metrics?.totalPayoutSum?.amount.toLocaleString('sv-SE') ?? 0}
                            <h6 style={{marginBottom: 14}}>{metrics?.payoutSumCurrentMonth?.currencyCode.toUpperCase()}</h6>
                        </div>
                        <div>
                            {t('inTotal')}
                        </div>
                    </div>
                </div>
            </DashboardMetricCard>
         </div>
         <FullScreenLoadingIndicator visible={metricsLoading} />
         </>
    )
}

export default Dashboard

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    infoCardContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        columnGap: 20
    },
    metricValue: {
        fontFamily: Fonts.semiBold,
        fontSize: 64,
        lineHeight: 1.2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        columnGap: 10,
    },
    metricSubValue: {
        fontFamily: Fonts.semiBold,
        fontSize: 48,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        columnGap: 10,
    },
    trendContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        columnGap: 10,
        marginTop: 4
    },
    trendValue: {
        fontFamily: Fonts.semiBold,
        fontSize: 32,
    }
})
