const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://cappy-api-test-app.azurewebsites.net/'
const PORTAL_API_ENDPOINT = API_ENDPOINT + 'portal/'

const BLAZOR_APP_VERSION = process.env.REACT_APP_BLAZOR_APP_VERSION || '8.0.0'

const FORTNOX_INTEGRATION_API_ENDPOINT = 'https://fortnox-customer-integration-app.azurewebsites.net/'
const PLANDAY_INTEGRATION_API_ENDPOINT = 'https://planday-customer-integration-app.azurewebsites.net/'
const TENGELLA_INTEGRATION_API_ENDPOINT = 'https://tengella-customer-integration-app.azurewebsites.net/'

export const apiConfig = {
    baseUrl: PORTAL_API_ENDPOINT,
    routes: {
        auth: `${PORTAL_API_ENDPOINT}auth/`,
        password: `${PORTAL_API_ENDPOINT}password/`,
        sendPasswordLink: `${PORTAL_API_ENDPOINT}password/sendlink/`,
        users: `${PORTAL_API_ENDPOINT}users/`,
        paySchedules: `${PORTAL_API_ENDPOINT}paySchedules/`,
        employees: `${PORTAL_API_ENDPOINT}employees/`,
        employeeActivation: `${PORTAL_API_ENDPOINT}employeeActivation/`,
        employeeDeactivation: `${PORTAL_API_ENDPOINT}employeeDeactivation/`,
        shifts: `${PORTAL_API_ENDPOINT}shifts/`,
        payDeviations: `${PORTAL_API_ENDPOINT}payDeviations/`,
        creditUsage: `${PORTAL_API_ENDPOINT}creditUsage/`,
        employer: `${PORTAL_API_ENDPOINT}employer/`,
        integrationLog: `${PORTAL_API_ENDPOINT}integrationlog/`,
        payroll: `${PORTAL_API_ENDPOINT}payroll/`,
        employerInitiatedPayouts: `${PORTAL_API_ENDPOINT}employerInitiatedPayouts/`,
        files: `${PORTAL_API_ENDPOINT}files/`,
        payoutBasis: `${PORTAL_API_ENDPOINT}payoutBasis/`,
        payoutBasisImportFiles: `${PORTAL_API_ENDPOINT}files/import/payoutbasis/`,
        bankAccountImportFile: `${PORTAL_API_ENDPOINT}files/import/bankaccount/`,
        paymentNotificationFiles: `${PORTAL_API_ENDPOINT}files/paymentNotification/`,
        creditReportFiles: `${PORTAL_API_ENDPOINT}files/creditreport/`,
        agiAdjustmentSieFiles: `${PORTAL_API_ENDPOINT}files/agiAdjustment/sie/`,
        agiAdjustmentReportFile: `${PORTAL_API_ENDPOINT}files/agiAdjustment/adjustmentReport/`,
        foraAdjustmentReportFile: `${PORTAL_API_ENDPOINT}files/foraAdjustment/adjustmentReport/`,
        remainingDebtReportFile: `${PORTAL_API_ENDPOINT}files/remainingDebtReport/`,
        employeeForeclosureReportFile: `${PORTAL_API_ENDPOINT}files/employeeForeclosureReport/`,
        grossAdvanceReportFile: `${PORTAL_API_ENDPOINT}files/grossAdvanceReport/`,
        dashboardMetrics: `${PORTAL_API_ENDPOINT}dashboard/metrics/`,
        currentUser: `${PORTAL_API_ENDPOINT}currentUser/`,
        agiAdjustment: `${PORTAL_API_ENDPOINT}agiAdjustment/`,
        foraAdjustment: `${PORTAL_API_ENDPOINT}foraAdjustment/`,
    },
    fortnoxIntegrationBaseUrl: FORTNOX_INTEGRATION_API_ENDPOINT,
    plandayIntegrationBaseUrl: PLANDAY_INTEGRATION_API_ENDPOINT,
    tengellaIntegrationBaseUrl: TENGELLA_INTEGRATION_API_ENDPOINT,
    blazorAppVersion: BLAZOR_APP_VERSION,
    isInternalUrl: (url: string) => {
        return url.startsWith(apiConfig.baseUrl) ||
        url.startsWith(apiConfig.fortnoxIntegrationBaseUrl) ||
        url.startsWith(apiConfig.plandayIntegrationBaseUrl) ||
        url.startsWith(apiConfig.tengellaIntegrationBaseUrl)
    }
}

