import { apiConfig } from "../../apiConfig"
import { useAppDispatch } from "../../redux/hooks"
import { 
    activationStatusFetched, 
    activationStatusFetchFailue, 
    authenticationUrlFetched, 
    authenticationUrlFetchFailue, 
    integrationActivated, 
    integrationActivationFailue, 
    loading 
} from "../../redux/slices/integrations/plandayIntegrationSlice"
import useFetchClient from "../useFetchClient"

const usePlandayIntegrationActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()
  
    const getStatus = async () => {
      dispatch(loading())
  
      const url = apiConfig.plandayIntegrationBaseUrl + 'activation/status'
  
      return fetchClient.get(url)
        .then(response => {
            dispatch(activationStatusFetched(response.data.activated as boolean))
        })
        .catch(error => {
            console.log('getStatus error:', error)
            dispatch(activationStatusFetchFailue(error))
        })
    }
  
    const getAuthenticationUrl = async () => {
      dispatch(loading())
  
      const url = apiConfig.plandayIntegrationBaseUrl + 'activation/authenticationUrl'
  
      return fetchClient.get(url)
        .then(response => {
            dispatch(authenticationUrlFetched(response.data.url as string))
        })
        .catch(error => {
            console.log('getAuthenticationUrl error:', error)
            dispatch(authenticationUrlFetchFailue(error))
        })
    }
  
    const activateIntegration = async (code: string, state: string) => {
      dispatch(loading())
  
      const url = apiConfig.plandayIntegrationBaseUrl + `activation/activate?code=${code}&state=${state}`
  
      return fetchClient.post(url)
        .then(response => {
            dispatch(integrationActivated())
        })
        .catch(error => {
            console.log('activateIntegration error:', error)
            dispatch(integrationActivationFailue(error))
        })
    }
  
    return {
      getStatus,
      getAuthenticationUrl,
      activateIntegration
    }
  }
  
  export default usePlandayIntegrationActions
  