import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { createUseStyles } from "react-jss"
import { Colors } from "../constants/colors"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const BackButton = () => {
    const styles = useStyles()
    const { t } = useTranslation('common');
    const navigate = useNavigate()
    
    const renderContainer = () => {
        return (
            <div
                className={styles.container} 
                onClick={() => navigate(-1)}
            >
                <FontAwesomeIcon className={styles.icon}
                    icon={faChevronLeft}
                    color={Colors.euro_800}
                />
                {t('back')}
            </div>
        )
    }

    return renderContainer()
}

export default BackButton

const useStyles = createUseStyles({
    container: {
        width: 100,
        justifyContent: 'center',
        alignItems: 'center',
        height: 32,
        borderRadius: 16,
        borderStyle: 'solid',
        borderWidth: 0,
        borderColor: Colors.euro_800,
        textAlign: 'center',
        fontSize: 14,
        paddingTop: 5,
        cursor: 'pointer',
        
        paddingLeft: 10,
        paddingRight: 10,
        transition: '0.4s',
    },
    icon: {
        marginTop: 3,
        marginRight: "5px",
    }
  })
  