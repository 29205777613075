import { useEffect } from "react"
import { createUseStyles } from 'react-jss'
import { useAppSelector } from "../redux/hooks"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import Card from "../components/Card"
import { useTranslation } from "react-i18next"
import useFileActions from "../hooks/useFileActions"
import { FileModel, FileType, PayoutBasisFileType } from "../redux/types"
import InfoCard from "../components/InfoCard"
import { Colors } from "../constants/colors"
import File from "../components/File"

interface Props {
    showEmployerPayDeductionPaymentNotificationFiles: boolean
    showPayoutBasisForCalendarMonthFiles: boolean
    showDeductionBasisForPaydayFiles: boolean
    showCreditReportFiles: boolean
    showBankAccountImportFiles: boolean
    showAgiAdjustmentSieFiles: boolean
    showAgiAdjustmentReportFiles: boolean
    showForaAdjustmentReportFiles: boolean
    showRemainingDebtReportFiles: boolean
    showEmployeeForeclosureReportFiles: boolean
    showGrossAdvanceReportFiles: boolean
}

const FilesByMonth = (props: Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['files', 'common']);

    const { getFiles } = useFileActions()
    const loading = useAppSelector(state => state.files.loading)
    const files = useAppSelector(state => state.files.files)

    useEffect(() => {
        if (files.length === 0) {
            getFiles()
        }
    }, [])

    const fileFilter = (file: FileModel) => {
        if (props.showCreditReportFiles && file.type === FileType.creditReportFile) {
            return true
        }
        if (props.showEmployerPayDeductionPaymentNotificationFiles && file.type === FileType.employerPayDeductionPaymentNotificationFile) {
            return true
        }
        if (props.showPayoutBasisForCalendarMonthFiles && file.type === FileType.payrollSystemPayoutBasisImportFile && file.payrollSystemPayoutBasisImportFile.fileType === PayoutBasisFileType.payoutBasisForCalendarMonth) {
            return true
        }
        if (props.showDeductionBasisForPaydayFiles && file.type === FileType.payrollSystemPayoutBasisImportFile && file.payrollSystemPayoutBasisImportFile.fileType === PayoutBasisFileType.deductionBasisForPayday) {
            return true
        }
        if (props.showAgiAdjustmentSieFiles && file.type === FileType.agiAdjustmentSieFile) {
            return true
        }
        if (props.showAgiAdjustmentReportFiles && file.type === FileType.agiAdjustmentReportFile) {
            return true
        }
        if (props.showForaAdjustmentReportFiles && file.type === FileType.foraAdjustmentReportFile) {
            return true
        }
        if (props.showRemainingDebtReportFiles && file.type === FileType.remainingDebtReportFile) {
            return true
        }
        if (props.showEmployeeForeclosureReportFiles && file.type === FileType.employeeForeclosureReportFile) {
            return true
        }
        if (props.showGrossAdvanceReportFiles && file.type === FileType.grossAdvanceReportFile) {
            return true
        }
        return false
    }

    const getYearAndMonth = (file: FileModel) => {
        if (file.type === FileType.employerPayDeductionPaymentNotificationFile) {
            return file.employerPayDeductionPaymentNotificationFile.paydayPaymentDate.substring(0, 7)
        }

        if (file.type === FileType.payrollSystemPayoutBasisImportFile) {
            return file.payrollSystemPayoutBasisImportFile.fileType === PayoutBasisFileType.deductionBasisForPayday
                ? file.payrollSystemPayoutBasisImportFile.paydayPaymentDate.substring(0, 7)
                : `${file.payrollSystemPayoutBasisImportFile.year}-${padWithLeadingZeroes(file.payrollSystemPayoutBasisImportFile.month, 2)}`
        }

        if (file.type === FileType.creditReportFile) {
            return `${file.creditReportFile.year}-${padWithLeadingZeroes(file.creditReportFile.month, 2)}`
        }

        if (file.type === FileType.agiAdjustmentSieFile) {
            return file.agiAdjustmentSieFile.period.substring(0, 4) + '-' + file.agiAdjustmentSieFile.period.substring(4)
        }

        if (file.type === FileType.agiAdjustmentReportFile) {
            return file.agiAdjustmentReportFile.period.substring(0, 4) + '-' + file.agiAdjustmentReportFile.period.substring(4)
        }

        if (file.type === FileType.foraAdjustmentReportFile) {
            return file.foraAdjustmentReportFile.period.substring(0, 4) + '-' + file.foraAdjustmentReportFile.period.substring(4)
        }

        if (file.type === FileType.remainingDebtReportFile) {
            return file.remainingDebtReportFile.payday.substring(0, 7)
        }

        if (file.type === FileType.employeeForeclosureReportFile) {
            return file.employeeForeclosureReportFile.payday.substring(0, 7)
        }

        if (file.type === FileType.grossAdvanceReportFile) {
            return `${file.grossAdvanceReportFile.year}-${padWithLeadingZeroes(file.grossAdvanceReportFile.month, 2)}`
        }

        return ''
    }

    const renderFiles = () => {
        const filesGroupedByYearAndMonth = files?.filter(fileFilter).reduce(function (r, file) {
            const yearAndMonth = getYearAndMonth(file)
            r[yearAndMonth] = r[yearAndMonth] || [];
            r[yearAndMonth].push(file);
            return r;
        }, Object.create(null));

        const orderedList = Object.entries(filesGroupedByYearAndMonth).sort(([key1, value1]: any, [key2, value2]: any) => {
            return new Date(key2 + "-01").getTime() - new Date(key1 + "-01").getTime()
        });

        return orderedList.map(([key, value], index) => {
            const filesForPayday = value as FileModel[]

            if (props.showBankAccountImportFiles) {
                var bankAccountImportFiles = files?.filter(x => x.type === FileType.payrollSystemBankAccountImportFile)
                if (bankAccountImportFiles.length > 0) {
                    filesForPayday.unshift(bankAccountImportFiles[0])
                }
            }

            return (
                <InfoCard title={getTitleFromYearAndMonthString(key)} className={styles.infoCard} key={`container_${index}`}>
                    { renderFilesCore(filesForPayday) }
                </InfoCard>
            )
        })
    }

    const getTitleFromYearAndMonthString = (str: string) => {
        const year = str.substring(0, 4)
        const monthNumber = parseFloat(str.substring(5))

        return t(`month${monthNumber}`, {ns: 'common'}) + ' ' + year
    }

    const renderFilesCore = (files: FileModel[]) => {
        return files.map((file, index) => {
            return (
                <File file={file} key={`file_${index}`}/>
            )
        })
    }

    const padWithLeadingZeroes = (num: number, size: number) => {
        var numberString = num?.toString() ?? '';
        while (numberString.length < size) {
            numberString = "0" + numberString;
        }
        return numberString;
    }

    return (
        <>
        <Card className={styles.container}>
            <div className={styles.infoCardContainer}>
                {renderFiles()}
            </div>
        </Card>
        <FullScreenLoadingIndicator visible={loading} />
        </>
    )
}

export default FilesByMonth

const useStyles = createUseStyles({
    container: {
        marginBottom: 40
    },
    card: {
        marginBottom: 20
    },
    infoCardContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        columnGap: 20
    },
    infoCard: {
        flex: 1,
        backgroundColor: Colors.grayBackgroundApp,
        minWidth: 450
    }
})
