import { ChangeEvent, useState, useEffect, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import useTimer from "../hooks/useTimer"

const MIN_SEARCH_TERM_LENGTH = 2

export interface AutocompleteItem {
    name: string
    value: string
}

interface Props {
    id: string
    value: string
    label?: string
    disabled?: boolean
    valid?: boolean
    placeholder: string
    onTermChange?: (term: string) => void
    searchResult: AutocompleteItem[]
    onSelect?: (selectedItem: AutocompleteItem | null) => void
    mandatory?: boolean
}

const AutocompleteField = ({
    id = '',
    value,
    label,
    disabled,
    valid = true,
    placeholder = '',
    onTermChange = () => '',
    searchResult = [],
    onSelect = () => '',
    mandatory
}: Props) => {
    const styles = useStyles()
    const [searchTerm, setSearchTerm] = useState(value)
    const [dirty, setDirty] = useState(false)
    const [result, setResult] = useState<AutocompleteItem[]>()
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const { isRunning: isTimerRunning, restartTimer } = useTimer(400)

    const { t } = useTranslation(['common']);

    useEffect(() => {
        if (value !== searchTerm) {
            setSearchTerm(value)
        }           
    }, [value])

    useEffect(() => {
        setResult([...searchResult])

        if (searchResult?.length > 0) {
            setSelectedIndex(0)
        } else {
            setSelectedIndex(undefined)  
        }
    }, [searchResult])

    useEffect(() => {
        if (!isTimerRunning && searchTerm?.length >= MIN_SEARCH_TERM_LENGTH) {
            onTermChange(searchTerm)
        }           
    }, [isTimerRunning])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value !== searchTerm) {
            setSearchTerm(event.target.value)
            restartTimer()
        }

        setDirty(true)
    }

    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            if (result && selectedIndex != null) {
                onSelect && onSelect(result[selectedIndex])
                setSearchTerm(result[selectedIndex].name)
                setResult(undefined)
            } else if (!searchTerm || searchTerm.length === 0) {
                onSelect && onSelect(null) 
            }
        }

        if (event.key === 'ArrowUp' && result && selectedIndex != null && selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1)
        }
        
        if (event.key === 'ArrowDown' && result && selectedIndex != null && selectedIndex < result.length - 1) {
            setSelectedIndex(selectedIndex + 1)
        }
    }

    const handleItemClick = (index: number) => {
        if (result && index < result.length && index >= 0)
        {
            onSelect && onSelect(result[index])
            setSearchTerm(result[index].name)
            setResult(undefined) 
        }   
    }

    const handleBlur = () => {
        setResult(undefined)  
    }

    const renderResultItems = () => {
        if (result?.length == 0) {
            return (
                <div className={`${styles.resultItem}`}>
                    {t('noHits')}
                </div>
            )    
        }

        return result?.map((item, index) => {
            return (
                <div className={`${styles.resultItem} ${index === selectedIndex ? styles.selected : ''}`} key={item.value} id={item.value} onMouseDown={() => handleItemClick(index)}>
                    {item.name}
                </div>
            )
        })
    }

    const renderLabel = () => {
        return label && (
            <div>
                <label className={styles.label}>{label}</label>
                {mandatory && <label className={styles.mandatoryIndicator}>*</label>}
            </div>
        )
    } 

    return (
        <div className={styles.container} >
            {renderLabel()}
            <div className={styles.inputContainer} style={{borderColor: valid ? Colors.euro_600 : Colors.rubel_700, backgroundColor: disabled ? Colors.grayDisabled : Colors.grayCard}} onBlur={handleBlur}>
                <input
                    id={id}
                    key={id}
                    disabled={disabled ?? false}
                    value={searchTerm}
                    onChange={handleChange}
                    onKeyUp={handleKeyUp}
                    autoComplete='off'
                    className={styles.input}
                    placeholder={placeholder}
                />
                {dirty && result && searchTerm?.length >= MIN_SEARCH_TERM_LENGTH &&
                    <div className={styles.resultContainer}>
                        {renderResultItems()}
                    </div>
                }
            </div>
        </div>
    )
}

export default AutocompleteField

const useStyles = createUseStyles({
    container: {
        width: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    inputContainer: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.euro_600,
        backgroundColor: Colors.grayCard,
    },
    resultContainer: {
        position: 'absolute',
        top: '47px',
        zIndex: 100,
        width: '100%',
        borderColor: Colors.euro_800, 
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 8,
        backgroundColor: Colors.gray4,
        fontFamily: 'Aestetico-Regular',
        padding: '5px',
        '& div': {
        margin: '5px'
        }
    },
    resultItem: {
        padding: '5px', 
        '&:hover': {
            cursor: 'pointer',
        }
    },
    selected: {
        backgroundColor: Colors.euro_300,
        borderRadius: 8
    },
    label: {
        marginBottom: 3,
        marginRight: 4
    },
    mandatoryIndicator: {
        color: Colors.rubel_700,
        marginRight: 4
    },
    input: {
        width: '100%',
        height: 40,
        border: 'none',
        borderRadius: 5,
        backgroundColor: 'transparent',
        padding: 15,
        '&:focus': {
            border: 'none',
            outline: 'none',
        },
    },
})
