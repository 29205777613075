import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { BulkOperationResult, EditPayDeviationModel, PayDeviation } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { createUrlWithQueryString } from "../util/url";
import { getPaginationDataFromResponse } from "../util/pagination";
import { loading, payDeviationCreated, payDeviationCreateFailure, payDeviationDeleted, payDeviationDeleteFailure, payDeviationsBulkUploaded, 
    payDeviationsBulkUploadFailure, payDeviationsFetched, payDeviationsFetchFailure, payDeviationUpdated, payDeviationUpdateFailure } from "../redux/slices/payDeviationSlice";

const usePayDeviationActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getPayDeviations = (employeeId: string | undefined, fromDate: string | undefined, toDate: string | undefined, pageIndex: number, pageSize: number) => {
        dispatch(loading())

        const params = {
            employeeId: employeeId,
            fromDate: fromDate,
            toDate: toDate,
            pageNumber: pageIndex,
            pageSize: pageSize
        }

        const url = createUrlWithQueryString(apiConfig.routes.payDeviations, params)

        return fetchClient.get(url)
            .then(response => {
                const paginationData = getPaginationDataFromResponse(response.raw)
                dispatch(payDeviationsFetched({ deviations: response.data as PayDeviation[], totalCount: paginationData?.totalItemCount ?? 0}))
            })
            .catch(error => {
                console.log('getPayDeviations error:', error)
                dispatch(payDeviationsFetchFailure(error))
            })
    }

    const payDeviationExists = (externalId: string, onComplete: (exists: boolean) => void) => {
        const params = {
            externalId: externalId
        }

        const url = createUrlWithQueryString(apiConfig.routes.payDeviations + 'exists', params)

        return fetchClient.get(url)
            .then(response => {
                onComplete(response.data.exists as boolean)    
            })
            .catch(error => {
                console.log('payDeviationExists error:', error)
            })
    }

    const createPayDeviation = (deviation: EditPayDeviationModel, onComplete?: () => void) => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.payDeviations, deviation)
            .then(response => {
                dispatch(payDeviationCreated(response.data as PayDeviation))
                onComplete && onComplete()
            })
            .catch(error => {
                console.log('createPayDeviation error:', error)
                dispatch(payDeviationCreateFailure(error))
            })
    }

    const updatePayDeviation = (deviation: EditPayDeviationModel) => {
        dispatch(loading())
        const url = `${apiConfig.routes.payDeviations}${deviation.id}` 

        return fetchClient.post(url, deviation)
            .then(response => {
                dispatch(payDeviationUpdated(response.data as PayDeviation))
            })
            .catch(error => {
                console.log('updatePayDeviation error:', error)
                dispatch(payDeviationUpdateFailure(error))
            })
    }

    const deletePayDeviation = (deviation: PayDeviation) => {
        dispatch(loading())
        const url = `${apiConfig.routes.payDeviations}${deviation.id}` 

        return fetchClient.delete(url)
            .then(response => {
                dispatch(payDeviationDeleted(deviation))
            })
            .catch(error => {
                console.log('deletePayDeviation error:', error)
                dispatch(payDeviationDeleteFailure(error))
            })
    }

    const bulkUploadPayDeviations = (file: File, onComplete?: (result: BulkOperationResult) => void) => {
        dispatch(loading())

        return fetchClient.postFile(apiConfig.routes.payDeviations + 'csv', file)
            .then(response => {
                dispatch(payDeviationsBulkUploaded())
                onComplete && onComplete(response.data as BulkOperationResult)
            })
            .catch(error => {
                console.log('bulkUploadPayDeviations error:', error)
                dispatch(payDeviationsBulkUploadFailure(error))
            })
    }

    return {
        getPayDeviations,
        createPayDeviation,
        updatePayDeviation,
        deletePayDeviation,
        payDeviationExists,
        bulkUploadPayDeviations
    }
}

export default usePayDeviationActions