import { useEffect, useState } from "react"
import { createUseStyles } from 'react-jss'
import ConfirmModal from "../components/ConfirmModal"
import EditPortalUserModal from "../components/EditPortalUserModal"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import UserList from "../components/UserList/UserList"
import usePortalUserActions from "../hooks/usePortalUserActions"
import { EmployerPortalUser, EmployerPortalUserEditModel } from "../redux/types"
import Button from '../components/Button'
import { useAppSelector } from "../redux/hooks"
import Card from "../components/Card"
import { useTranslation } from "react-i18next"

const PortalUsers = () => {
    const styles = useStyles()
    const { t } = useTranslation('portalUsers');

    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState<boolean>(false)
    const [addModalVisible, setAddModalVisible] = useState<boolean>(false)
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
    const [deleteCandidate, setDeleteCandidate] = useState<EmployerPortalUser>() 
    const [editedUser, setEditedUser] = useState<EmployerPortalUser>()

    const { getUsers, createUser, updateUser, deleteUser } = usePortalUserActions()
    const loading = useAppSelector(state => state.portalUser.loading)
    const portalUsers = useAppSelector(state => state.portalUser.portalUsers)
    const pageIndex = useAppSelector(state => state.portalUser.pageIndex)
    const pageSize = useAppSelector(state => state.portalUser.pageSize)

    useEffect(() => {
        if (portalUsers.length === 0) {
            getUsers()
        }
    }, [])

    const onAddClick = () => {
        setAddModalVisible(true)
    }

    const handleUserAdded = (user: EmployerPortalUserEditModel) => {
        createUser(user)
        setAddModalVisible(false)   
    }

    const onEditClick = (user: EmployerPortalUser) => {
        setEditedUser(user)
        setEditModalVisible(true)
    }

    const handleUserEdited = (user: EmployerPortalUserEditModel) => {
        updateUser(user)
        setEditedUser(undefined)
        setEditModalVisible(false)   
    }

    const handleUserEditCancelled = () => {
        setEditedUser(undefined)
        setEditModalVisible(false)   
    }

    const onDeleteClick = (user: EmployerPortalUser) => {
        setDeleteCandidate(user)
        setConfirmDeleteModalVisible(true)      
    }

    const handleDeleteConfirmed = () => {
        if (deleteCandidate) {
            deleteUser(deleteCandidate)
            setDeleteCandidate(undefined)
        }
        setConfirmDeleteModalVisible(false)     
    }

    return (
        <>
        <Card className={styles.card}>
            <h2>
                {t('title')}
            </h2>
        </Card>
        <Card>
            <div className={styles.buttonContainer}>
                <Button onClick={onAddClick} title={t('addPortalUser')} />
            </div>
            {portalUsers && 
            <UserList 
                users={portalUsers}
                pageIndex={pageIndex}
                pageSize={pageSize}  
                onEditClick={onEditClick} 
                onDeleteClick={onDeleteClick} 
            />}
            <EditPortalUserModal 
                key='addUserModal'
                visible={addModalVisible} 
                onCancelClick={() => setAddModalVisible(false)}
                onSaveClick={handleUserAdded}
            />
            <EditPortalUserModal 
                key='editUserModal'
                initialValue={editedUser}
                visible={editModalVisible} 
                onCancelClick={handleUserEditCancelled}
                onSaveClick={handleUserEdited}
            />
            <ConfirmModal      
                visible={confirmDeleteModalVisible}
                confirmText={t('confirmDeleteTemplate', {user: `${deleteCandidate?.givenName} ${deleteCandidate?.familyName}`})}
                onConfirmClick={handleDeleteConfirmed}
                onCancelClick={() => setConfirmDeleteModalVisible(false)}
            />
            <FullScreenLoadingIndicator visible={loading} />
        </Card>
        </>
    )
}

export default PortalUsers

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        paddingBottom: 10
    }
})