import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DashboardMetrics } from '../types'

interface DashboardMetricsState {
    loading: boolean
    metrics: DashboardMetrics | null
    error: Error | string | null
}

const initialState: DashboardMetricsState = {
    loading: false,
    metrics: null,
    error: null
}

export const dashboardMetricsSlice = createSlice({
    name: 'dashboardMetrics',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        metricsFetched: (state, action: PayloadAction<DashboardMetrics>) => {
            state.loading = false
            state.metrics = action.payload
            state.error = null
        },
        metricsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { 
    loading, 
    metricsFetched, 
    metricsFetchFailure 
} = dashboardMetricsSlice.actions

export default dashboardMetricsSlice.reducer