import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {EmployerInitiatedPayoutJob, EmployerInitiatedPayoutJobListItem} from '../types'

interface EmployerInitiatedPayoutState {
  loading: boolean
  jobs: EmployerInitiatedPayoutJobListItem[]
  currentJob: EmployerInitiatedPayoutJob | null
  pageIndex: number
  pageSize: number
  error: Error | string | null
}

const initialState: EmployerInitiatedPayoutState = {
  loading: false,
  jobs: [],
  currentJob: null,
  pageIndex: 1,
  pageSize: 15,
  error: null
}

export const employerInitiatedPayoutSlice = createSlice({
  name: 'employerInitiatedPayout',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
      state.error = null
    },
    jobsFetched: (state, action: PayloadAction<EmployerInitiatedPayoutJobListItem[]>) => {
      state.loading = false
      state.jobs = action.payload
      state.error = null
    },
    jobsFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    jobFetched: (state, action: PayloadAction<EmployerInitiatedPayoutJob>) => {
      state.loading = false
      state.currentJob = action.payload
      state.error = null
    },
    jobFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    jobCancelled: (state, action: PayloadAction<EmployerInitiatedPayoutJobListItem[]>) => {
      state.loading = false
      state.jobs = action.payload
      state.error = null
    },
    cancelJobFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    },
    pageIndexChanged: (state, action: PayloadAction<number>) => {
      state.pageIndex = action.payload
    },
    errorHandled: (state) => {
      state.error = null
    }
  }
})

export const {
  loading,
  jobsFetched,
  jobsFetchFailure,
  jobFetched,
  jobFetchFailure,
  jobCancelled,
  cancelJobFailure,
  pageIndexChanged,
  errorHandled
} = employerInitiatedPayoutSlice.actions

export default employerInitiatedPayoutSlice.reducer
