import { createUseStyles } from "react-jss"
import { BookkeepingAccountTypeRule, EmployeeType } from "../../redux/types"
import ListItemCard from "../ListItemCard"
import { useTranslation } from "react-i18next"
import { faArrowDown, faArrowUp, faEdit, faSave, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons"
import { Colors } from "../../constants/colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import InputField from "../InputField"
import { useEffect, useState } from "react"
import Select from "../Select"
import EmployeeCategorySelector from "../EmployeeCategorySelector"
import DepartmentSelector from "../DepartmentSelector"

interface Props {
    rule: BookkeepingAccountTypeRule
    index: number
    ruleCount: number
    editMode: boolean
    onEdit: (index: number) => void
    onEditCancelled: (index: number) => void
    onSave: (config: BookkeepingAccountTypeRule) => void
    onDelete: (index: number) => void
    onMoveUp: (index: number) => void
    onMoveDown: (index: number) => void
  }
    
  const BookkeepingAccountTypeRuleListItem = ({rule, index, ruleCount, editMode, onEdit, onEditCancelled, onSave, onDelete, onMoveUp, onMoveDown}: Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'companySettings', 'employees']);
  
    const [internalRule, setInternalRule] = useState<BookkeepingAccountTypeRule>()

    useEffect(() => {
        setInternalRule({...rule}) 
    }, [rule])

    const handleChange = (propName: string, value: any) => {
        if (internalRule)
            setInternalRule({...internalRule, [propName]: value})
    }

    const handleEmployeeTypeChange = (propName: string, value: any) => {
        if (internalRule) {
            if (value == '') {
                setInternalRule({...internalRule, [propName]: null})
            } else {
                setInternalRule({...internalRule, [propName]: value as EmployeeType})
            }
        }   
    }

    const getEmployeeTypeOptions = () => {
        let values = Object.values(EmployeeType).map((value) => value.toString())
        let options = values.map((value) => { return { name: t(`type_${value}`, {ns: 'employees'}), value: value }})
        options.unshift({name: t('any', {ns: 'common'}), value: ''})
        return options
    }

    const renderReadOnlyRule = () => {
        return (
            <>
            {!editMode &&
            <ListItemCard className={styles.listItemContainer} index={index}>
                <div className={styles.indexCell}>
                    {index + 1}
                </div>
                <div className={styles.smallCell}>
                    {rule.account}
                </div>
                <div className={styles.smallCell}>
                    {rule.costCenter}
                </div>
                <div className={styles.smallCell}>
                    {rule.project}
                </div>
                <div className={styles.cell}>
                    {rule.filterEmployeeType != null ? t(`type_${rule.filterEmployeeType}`, {ns: 'employees'}) : ''}
                </div>
                <div className={styles.cell}>
                    {rule.filterCategory}
                </div>
                <div className={styles.cell}>
                    {rule.filterDepartment}
                </div>
                <div className={styles.buttonContainer}>
                    {index > 0 && 
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faArrowUp}
                        color={Colors.gray1}
                        title={t('moveUp', {ns: 'common'})}
                        onClick={() => onMoveUp(index)}
                    />
                    }
                    {index < (ruleCount - 1) &&
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faArrowDown}
                        color={Colors.gray1}
                        title={t('moveDown', {ns: 'common'})}
                        onClick={() => onMoveDown(index)}
                    />
                    }
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faEdit}
                        color={Colors.gray1}
                        title={t('edit', {ns: 'common'})}
                        onClick={() => onEdit(index)}
                    />
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faTrash}
                        color={Colors.gray1}
                        title={t('delete', {ns: 'common'})}
                        onClick={() => onDelete(index)}
                    />
                </div>
            </ListItemCard>
            }
          </>
        )
    }

    const renderEditModeRule = () => {
        return (
            <>
            {editMode && internalRule &&
            <ListItemCard className={styles.listItemContainer} index={index} style={{backgroundColor: Colors.euro_100}}>
                <div className={styles.indexCell}>
                    {index + 1}
                </div>

                <div style={{display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', columnGap: 30, marginLeft: 30}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <span>{t('use', {ns: 'companySettings'})}</span>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', rowGap: 10, marginBottom: 30}}>
                        <InputField
                            className={styles.smallInputField}
                            id='account'
                            label={t('account', {ns: 'companySettings'})}
                            value={internalRule?.account.toString() ?? '0'}
                            onChange={value => handleChange('account', value)}
                            placeholder=''
                        />
                        <InputField
                            className={styles.smallInputField}
                            id='costCenter'
                            label={t('costCenter', {ns: 'common'})}
                            value={internalRule?.costCenter ?? undefined}
                            onChange={value => handleChange('costCenter', value)}
                            placeholder={t('none', {ns: 'common'})}
                        />
                        <InputField
                            className={styles.smallInputField}
                            id='project'
                            label={t('projectDimension', {ns: 'companySettings'})}
                            value={internalRule?.project ?? undefined}
                            onChange={value => handleChange('project', value)}
                            placeholder={t('none', {ns: 'common'})}
                        />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', columnGap: 10}}>
                        <span><span>{t('forEmployeesWith', {ns: 'companySettings'})}</span></span>
                    </div>
                    <div style={{display: 'flex', flex: 1, flexGrow: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', columnGap: 60, marginBottom: 30}}>
                        <Select 
                            className={styles.largeInputField}
                            id='employeeType'
                            key='employeeType'
                            label={t('employeeType', {ns: 'companySettings'})}
                            options={getEmployeeTypeOptions()} 
                            selectedValue={internalRule?.filterEmployeeType?.toString() ?? ''}
                            onSelect={(value) => handleEmployeeTypeChange('filterEmployeeType', value)}
                        />
                        <EmployeeCategorySelector 
                            key='filterCategory'
                            selectedValue={internalRule?.filterCategory ?? ''}
                            onSelect={value => handleChange('filterCategory', value)}
                            noSelectionItemName={t('any', {ns: 'common'})}
                        />
                        <DepartmentSelector 
                            key='filterDepartment'
                            selectedValue={internalRule?.filterDepartment ?? ''}
                            onSelect={value => handleChange('filterDepartment', value)}
                            noSelectionItemName={t('any', {ns: 'common'})}
                        />
                    </div>
                </div>
                
                <div className={styles.buttonContainer}>
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faSave}
                        color={Colors.gray1}
                        title={t('save', {ns: 'common'})}
                        onClick={() => onSave(internalRule)}
                    />
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faTimes}
                        color={Colors.gray1}
                        title={t('cancel', {ns: 'common'})}
                        onClick={() => onEditCancelled(index)}
                    />
                </div>
            </ListItemCard>
            }
          </>
        )
    }

    return (
        <div>
           {renderReadOnlyRule()}
           {renderEditModeRule()}
        </div>
    )
}
  
export default BookkeepingAccountTypeRuleListItem

  const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    cell: {
        width: 200,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    indexCell: {
        width: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    smallCell: {
        width: 120,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonContainer: {
        display: 'flex',
        columnGap: 10,
        width: 80,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    button: {
        transition: '0.4s',
        '&:hover': {
          color: Colors.euro_600,
          cursor: 'pointer',
        }
    },
    smallInputField: {
        maxWidth: 120
    },
    largeInputField: {
        maxWidth: 200
    }
  })
  