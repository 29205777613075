import { useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Breakpoints } from '../../constants/breakpoints'
import { Colors } from "../../constants/colors"
import { EmployeeReadyForActivation } from "../../redux/types"
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'
import Button from '../Button'
import Badge from '../Badge'
import { formatDateTimeString } from '../../util/date'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons'

interface Props {
    employees: EmployeeReadyForActivation[]
    pageIndex: number
    pageSize: number
    onActivateClick: (employee: EmployeeReadyForActivation) => void
    onPageChange: (pageIndex: number) => void
    onSelectionChanged: (selection: EmployeeReadyForActivation[]) => void
}

const ActivationList = ({ employees, pageIndex, pageSize, onActivateClick, onPageChange, onSelectionChanged } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'activations']);

    const [selection, setSelection] = useState<EmployeeReadyForActivation[]>([])

    const toggleSelection = (employee: EmployeeReadyForActivation) => {
        var newSelection = [...selection]
        if (selection.find(x => x.externalId === employee.externalId) !== undefined) {
            newSelection = selection.filter(x => x.externalId !== employee.externalId)
        } else (
            newSelection.push(employee)
        )

        setSelection(newSelection)
        onSelectionChanged(newSelection)
    }

    const toggleSelectAll = () => {
        var newSelection: EmployeeReadyForActivation[] = []

        if (selection.length !== itemsOnCurrentPage.length) {
            newSelection = [...itemsOnCurrentPage]
        }

        setSelection(newSelection)
        onSelectionChanged(newSelection)
    }

    const itemsOnCurrentPage = useMemo(() => {
        if (selection.length > 0) {
            setSelection([])
            onSelectionChanged([])
        }
        const firstPageIndex = (pageIndex - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return employees.slice(firstPageIndex, lastPageIndex);
    }, [employees, pageIndex, pageSize])

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('idNumber', {ns: 'common'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('name', {ns: 'common'})}</strong>
                </div> 
                <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                    <strong>{t('requestDate', {ns: 'activations'})}</strong>
                </div> 
                <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                    <strong>{t('department', {ns: 'common'})}</strong>
                </div>
                <div className={styles.badgeContainer}>
                    <strong>{t('status', {ns: 'common'})}</strong>
                </div>
                <div className={styles.buttonContainer}>
                   
                </div>  
                <div className={styles.selectContainer}>
                   <div>
                       <strong>{t('selectAll', {ns: 'common'})}</strong>
                    </div>
                   <FontAwesomeIcon 
                        style={{cursor: 'pointer'}}
                        icon={selection.length === itemsOnCurrentPage.length ? faCheckSquare : faSquare}
                        size='2x'
                        onClick={toggleSelectAll}
                        color={Colors.gray1}
                    />
                </div>  
            </ListItemCard>
        )    
    }

    const renderListItems = () => {
        return itemsOnCurrentPage?.map((employee, index) => {
            return (
                <ListItemCard key={`employee${index}`} className={styles.listItemContainer} index={index}>
                    <div className={styles.defaultCellContainer}>
                        {employee.identificationNumber}
                    </div> 
                    <div className={styles.defaultCellContainer}>
                        {employee.givenName} {employee.familyName}
                    </div> 
                    <div className={styles.defaultCellContainer}>
                        {formatDateTimeString(employee.requestDate)}
                    </div>
                    <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                        {employee.department}
                    </div> 
                    <div className={styles.badgeContainer}>
                        {employee.bankAccountActivationFailed &&
                        <Badge 
                            text={t('activationFailed')}
                            backgroundColor={Colors.yuan_100}
                            textColor={Colors.rubel_700}
                            tooltip={t('activationFailedMessage')}
                        />
                        }
                        {!employee.bankAccountActivationFailed && employee.pendingBankAccountActivation &&
                        <Badge 
                            text={t('pending')}
                            backgroundColor={Colors.yen_300}
                            textColor={Colors.gray1}
                            tooltip={t('employeeWillBeActivatedNextSyncMessage')}
                        />
                        }
                    </div>
                    <div className={styles.buttonContainer}>
                        <div>
                            <Button 
                                title={employee.pendingBankAccountActivation && !employee.bankAccountActivationFailed ? t('redo', {ns: 'activations'}) : t('activate', {ns: 'activations'})} 
                                variant={employee.pendingBankAccountActivation && !employee.bankAccountActivationFailed ? 'negative' : 'positive'}
                                onClick={() => onActivateClick && onActivateClick(employee)} 
                            />
                        </div>
                    </div> 
                    <div className={styles.selectContainer}>
                        <div>
                            <FontAwesomeIcon 
                                style={{cursor: 'pointer'}}
                                icon={selection.find(x => x.externalId === employee.externalId) !== undefined ? faCheckSquare : faSquare}
                                size='2x'
                                onClick={() => toggleSelection(employee)}
                                color={Colors.gray1}
                            />
                        </div>
                    </div>  
                </ListItemCard>
            ) 
         })
    }

    return (
        <div>
           {renderHeader()}
           {renderListItems()}
           <div className={styles.pagination}>
            <Pagination
                pageIndex={pageIndex}
                itemCount={employees.length}
                pageSize={pageSize}
                siblingCount={1}
                onPageChange={onPageChange}
            />
            </div>
        </div>
    )
}

export default ActivationList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    defaultCellContainer: {
        width: 170
    },
    buttonContainer: {
        display: 'flex',
        width: 80,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center',   
        paddingLeft: 0
    },
    selectContainer: {
        display: 'flex',
        width: 140,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignContent: 'center',   
        columnGap: 10,
        paddingLeft: 0
    },
    badgeContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start'
    },
    smallHidden: {
        display: 'none',
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            display: 'flex'
        },
    },
    button: {
        marginRight: 15,
        transition: '0.4s',
        '&:hover': {
          color: Colors.euro_600,
          cursor: 'pointer',
        }
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
  })