import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Card from "../components/Card"
import FilesByMonth from "../components/FilesByMonth"

const Files = () => {
    const styles = useStyles()
    const { t } = useTranslation(['files', 'common']);

    return (
        <>
            <Card className={styles.card}>
                <h2>
                    {t('title', {ns: 'files'})}
                </h2>
            </Card>
            <FilesByMonth
                showBankAccountImportFiles={true}
                showCreditReportFiles={true}
                showDeductionBasisForPaydayFiles={true}
                showEmployerPayDeductionPaymentNotificationFiles={true}
                showPayoutBasisForCalendarMonthFiles={true}
                showAgiAdjustmentSieFiles={true}
                showAgiAdjustmentReportFiles={true}
                showForaAdjustmentReportFiles={true}
                showRemainingDebtReportFiles={true}
                showEmployeeForeclosureReportFiles={true}
                showGrossAdvanceReportFiles={true}
            />
        </>

    )
}

export default Files

const useStyles = createUseStyles({
    container: {
        marginBottom: 40
    },
    card: {
        marginBottom: 20
    }
})
