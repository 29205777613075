import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { PayoutBasisGenerationResult, PayoutBasisYearAndMonthModel } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { loading, payoutBasisGenerated, payoutBasisGenerationFailure, upcomingYearAndMonthFetched, upcomingYearAndMonthFetchFailure } from "../redux/slices/payoutBasisSlice";

const usePayoutBasisActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getUpcomingYearAndMonthForGeneration = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.payoutBasis + 'month')
            .then(response => {
                dispatch(upcomingYearAndMonthFetched(response.data as PayoutBasisYearAndMonthModel))
            })
            .catch(error => {
                console.log('getUpcomingYearAndMonthForGeneration error:', error)
                dispatch(upcomingYearAndMonthFetchFailure(error))
            })
    }

    const generatePayoutBasis = () => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.payoutBasis, undefined)
            .then(response => {
                dispatch(payoutBasisGenerated(response.data as PayoutBasisGenerationResult))
            })
            .catch(error => {
                console.log('generatePayoutBasis error:', error)
                dispatch(payoutBasisGenerationFailure(error))
            })
    }

    return {
        getUpcomingYearAndMonthForGeneration,
        generatePayoutBasis
    }
}

export default usePayoutBasisActions