import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Form from 'react-bootstrap/Form'
import { Colors } from '../constants/colors'
import InfoIcon from './InfoIcon'

interface Props {
    id?: string
    label?: string
    options: SelectOption[]
    selectedValue?: string
    tooltipText?: string
    onSelect?: (value: string) => void 
    valid?: boolean
    disabled?: boolean
    mandatory?: boolean
    className?: string
    style?: React.CSSProperties | undefined
}

export interface SelectOption {
    name: string
    value: string
}

const Select = ({ 
    id = "select",
    label,
    options = [],
    selectedValue,
    tooltipText,
    onSelect,
    valid = true,
    disabled = false,
    mandatory,
    className, 
    style
} : Props) => {
    const styles = useStyles()
    const [value, setValue] = useState(selectedValue)

    useEffect(() => {
      setValue(selectedValue)             
    }, [selectedValue])

    const handleOnChange = (value: string) => {
        setValue(value)
        onSelect && onSelect(value)
    }

    const renderLabel = () => {
        return label && (
            <div>
                <label className={styles.label}>{label}</label>
                {mandatory && <label className={styles.mandatoryIndicator}>*</label>}
                {tooltipText && <InfoIcon text={tooltipText} />}
            </div>
        )
    }
    
    const renderOptions = () => {
        return options?.map((option) => {
            return (
                <option key={option.value} value={option.value}>{option.name}</option>
            )
        })
    }

    return (
        <div className={`${styles.container} ${className}`} style={style}>
            {renderLabel()}
            <div className={`${styles.inputContainer}`} style={{borderColor: valid ? Colors.euro_600 : Colors.rubel_700, backgroundColor: disabled ? Colors.grayDisabled : Colors.grayCard}}>
                <Form.Control as="select" onChange={(event) => handleOnChange(event.target.value)} value={value} className={styles.input} disabled={disabled}>    
                    {renderOptions()}
                </Form.Control>
            </div>
        </div>
    )
}

export default Select

const useStyles = createUseStyles({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    inputContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        width: '100%',
        alignItems: 'center',
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.euro_600,
        backgroundColor: Colors.grayCard,
    },
    input: {
        width: '100%',
        height: 40,
        border: 'none',
        backgroundColor: 'transparent',
        borderWidth: 0,
        '&:focus': {
            border: 'none',
            outline: 'none',
        },
        '&:active': {
            border: 'none',
            outline: 'none',
        },
        '&:disabled': {
            backgroundColor: 'transparent',    
        }
    },
    label: {
        marginBottom: 3,
        marginRight: 4
    },
    mandatoryIndicator: {
        color: Colors.rubel_700,
        marginRight: 4
    },
  })
  