import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreditUsage } from '../types'

interface CreditUsageState {
    loading: boolean
    creditUsage: CreditUsage | null
    error: Error | string | null
}

const initialState: CreditUsageState = {
    loading: false,
    creditUsage: null,
    error: null
}

export const creditUsageSlice = createSlice({
    name: 'creditUsage',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        creditUsageFetched: (state, action: PayloadAction<CreditUsage>) => {
            state.loading = false
            state.creditUsage = action.payload
            state.error = null
        },
        creditUsageFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { 
    loading, 
    creditUsageFetched, 
    creditUsageFetchFailure 
} = creditUsageSlice.actions

export default creditUsageSlice.reducer