import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { Employee, EmployeeReadyForDeactivation } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { employeeUpdated } from "../redux/slices/employeeSlice";
import { employeesDeactivated, employeesDeactivationFailure, loading, pendingEmployeesFetched, pendingEmployeesFetchFailure } from "../redux/slices/employeeDeactivationSlice";

const useEmployeeDeactivationActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getPendingEmployees = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.employeeDeactivation)
            .then(response => {
                dispatch(pendingEmployeesFetched(response.data as EmployeeReadyForDeactivation[]))
            })
            .catch(error => {
                console.log('getPendingEmployees error:', error)
                dispatch(pendingEmployeesFetchFailure(error))
            })
    }

    const deactivateEmployees = (employees: EmployeeReadyForDeactivation[]) => {
        dispatch(loading())

        var body = { ids: employees.map((employee) => employee.id )} 

        return fetchClient.post(apiConfig.routes.employeeDeactivation, body)
            .then(response => {
                const deactivatedEmployees = response.data as Employee[]
                dispatch(employeesDeactivated(deactivatedEmployees))
                deactivatedEmployees.forEach((employee) => dispatch(employeeUpdated(employee)))
            })
            .catch(error => {
                console.log('deactivateEmployees error:', error)
                dispatch(employeesDeactivationFailure(error))
            })
    }

    return {
        getPendingEmployees,
        deactivateEmployees
    }
}

export default useEmployeeDeactivationActions