import { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'

interface Props {
    children: ReactNode
    className?: string
    style?: React.CSSProperties | undefined
}

const Card = ({ children, className, style } : Props) => {
    const classes = useStyles()
    return (
        <div className={`${classes.container} ${className}`} style={style}>
            {children}
        </div>
    )
}

export default Card

const useStyles = createUseStyles({
    container: {
        backgroundColor: Colors.grayCard,
        borderStyle: 'solid',
        borderColor: Colors.grayGraph,
        borderWidth: 0,
        borderRadius: 8,
        padding: 30,
        //boxShadow:  '0 1px 1px 0 rgba(var(--cui-elevation-base-color,60,75,100),.14), 0 2px 1px -1px rgba(var(--cui-elevation-base-color,60,75,100),.12), 0 1px 3px 0 rgba(var(--cui-elevation-base-color,60,75,100),.2)'
    },
})
