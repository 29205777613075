import { ReactNode } from 'react'
import ReactDOM from 'react-dom'
import { createUseStyles } from 'react-jss'

interface Props {
    visible: boolean
    children: ReactNode
}

const FullScreenModal = ({visible, children} : Props) => {
    const styles = useStyles()
    return visible ? ReactDOM.createPortal(<div className={styles.container}>{children}</div>, document.body) : null
}

export default FullScreenModal

const useStyles = createUseStyles({
    container: {
        position: 'fixed',
        height: '100%',
        top: 0,
        left: 0,
        bottom:0,
        right:0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 1000
    }
})