import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { Employee, EmployeeReadyForActivation } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { loading, pendingEmployeesFetched, pendingEmployeesFetchFailure, employeesActivated, employeeActivationFailure, setBankAccountActivationPending, setBankAccountActivationPendingFailure } from "../redux/slices/employeeActivationSlice";
import { employeeUpdated } from "../redux/slices/employeeSlice";

const useEmployeeActivationActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getPendingEmployees = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.employeeActivation)
            .then(response => {
                dispatch(pendingEmployeesFetched(response.data as EmployeeReadyForActivation[]))
            })
            .catch(error => {
                console.log('getPendingEmployees error:', error)
                dispatch(pendingEmployeesFetchFailure(error))
            })
    }

    const activateEmployees = (employees: EmployeeReadyForActivation[]) => {
        dispatch(loading())

        var body = { ids: employees.map((employee) => employee.id )} 

        return fetchClient.post(apiConfig.routes.employeeActivation, body)
            .then(response => {
                const activatedEmployees = response.data as Employee[]
                dispatch(employeesActivated(activatedEmployees))
                activatedEmployees.forEach((employee) => dispatch(employeeUpdated(employee)))
            })
            .catch(error => {
                console.log('activateEmployees error:', error)
                dispatch(employeeActivationFailure(error))
            })
    }

    const setBankAccountActivationPendingForEmployees = (employees: EmployeeReadyForActivation[]) => {
        dispatch(loading())

        var body = { ids: employees.map((employee) => employee.id )} 
        const url = `${apiConfig.routes.employeeActivation}bankAccountActivationPending` 
        return fetchClient.post(url, body)
            .then(response => {
                dispatch(setBankAccountActivationPending(response.data as EmployeeReadyForActivation[]))
            })
            .catch(error => {
                console.log('setBankAccountActivationPendingForEmployees error:', error)
                dispatch(setBankAccountActivationPendingFailure(error))
            })
    }

    return {
        getPendingEmployees,
        activateEmployees,
        setBankAccountActivationPendingForEmployees
    }
}

export default useEmployeeActivationActions