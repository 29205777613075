import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from './Modal'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../redux/hooks'
import Select from './Select'
import useAuthUserActions from '../hooks/useAuthUserActions'

interface Props {
    onCloseClick?: () => void
    visible: boolean
}

const EmployerSelectorModal = ({
    onCloseClick,
    visible
} : Props) => {
    const styles = useStyles()
    const { t } = useTranslation('common');
    const [selectedEmployerId, setSelectedEmployerId] = useState<string>()

    const authenticatedUser = useAppSelector(state => state.auth.authenticatedUser)
    const currentEmployerId = useAppSelector(state => state.auth.currentEmployerId)

    const { switchEmployer } = useAuthUserActions()

    useEffect(() => {
        if (visible && currentEmployerId) {
            setSelectedEmployerId(currentEmployerId)
        }           
    }, [visible])

    const handleOnSelect = () => {
        if (selectedEmployerId && selectedEmployerId !== currentEmployerId)
            switchEmployer(selectedEmployerId)

        handleOnClose()
    }

    const handleOnClose = () => {
        onCloseClick && onCloseClick()
    }

    const getOptions = () => {
        if (!authenticatedUser)
            return []

        return authenticatedUser.employers.map(x => {
            return { name: x.employerName, value: x.employerId }
        })
    }

    return (
        <Modal
            title={t('selectEmployer')}
            visible={visible}
            okButtonTitle={t('select')}
            cancelButtonTitle={t('cancel')}
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSelect()}
            onCancelClick={() => handleOnClose()}
        >
            <div className={styles.row}>
                <Select 
                    id='employer'
                    key='employer'
                    label={t('company')}
                    options={getOptions()} 
                    selectedValue={currentEmployerId ?? undefined}
                    onSelect={(value) => setSelectedEmployerId(value)}
                />
            </div>
        </Modal>
    )
}

export default EmployerSelectorModal

const useStyles = createUseStyles({
    row: {
        paddingBottom: 12
    }
})
