import { createRef, ReactNode } from 'react'
import useFetchClient from '../hooks/useFetchClient'
import { useAppDispatch } from '../redux/hooks'
import { fileDownloaded } from '../redux/slices/fileSlice'

interface Props {
    fileId: string
    url: string
    filename: string
    children: ReactNode
}

export const AuthenticatedFileLink = ({ fileId, url, filename,  children }: Props) => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()
  const link = createRef<HTMLAnchorElement>()
  
  const onClick = async () => {
    if (!link.current) {
      return
    }

    if (link.current?.href) { 
      return 
    }
  
    const result = await fetchClient.getRaw(url)
    const blob = await result.blob()
    const href = window.URL.createObjectURL(blob)
    
    link.current.download = filename
    link.current.href = href
      
    link.current.click()

    dispatch(fileDownloaded(fileId))
  }

  return (
    <>
      <a role='button' ref={link} onClick={onClick}>{children}</a>
    </>
  )
}

export default AuthenticatedFileLink