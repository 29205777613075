import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { PayrollFileRow } from "../../redux/types"
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'

interface Props {
    rows: PayrollFileRow[]
    pageIndex: number
    pageSize: number
    onPageChange: (pageIndex: number) => void
}

const PayrollFileRowList = ({ rows, pageIndex, pageSize, onPageChange } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'payrolls']);

    const itemsOnCurrentPage = useMemo(() => {
        const firstPageIndex = (pageIndex - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return rows.slice(firstPageIndex, lastPageIndex);
    }, [rows, pageIndex, pageSize])

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.idContainer}>
                    <strong>{t('idNumber', {ns: 'common'})}</strong>
                </div> 
                <div className={styles.nameContainer}>
                    <strong>{t('name', {ns: 'common'})}</strong>
                </div>
                <div className={styles.amountContainer}>
                    <strong>{t('netAmount', {ns: 'payrolls'})}</strong> 
                </div>  
            </ListItemCard>
        )    
    }

    const renderListItems = () => {
        return itemsOnCurrentPage?.map((row, index) => {
            return (
                <ListItemCard key={`row${index}`} className={styles.listItemContainer} index={index}>
                    <div className={styles.idContainer}>
                        {row.employeeIdentificationNumber}
                    </div> 
                    <div className={styles.nameContainer}>
                        {row.employeeName}
                    </div>
                    <div className={styles.amountContainer}>
                        {row.netAmount.amount.toLocaleString('sv-SE', { minimumFractionDigits: 2 })} {row.netAmount.currencyCode.toUpperCase()}
                    </div>  
                </ListItemCard>
            ) 
         })
    }

    return (
        <>
           {renderHeader()}
           {renderListItems()}
           <div className={styles.pagination}>
            <Pagination
                pageIndex={pageIndex}
                itemCount={rows.length}
                pageSize={pageSize}
                siblingCount={1}
                onPageChange={onPageChange}
            />
            </div>
        </>
    )
}

export default PayrollFileRowList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    idContainer: {
        width: 150
    },
    nameContainer: {
        flexGrow: 1
    },
    amountContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: 120
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
  })