import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import {
    BulkOperationResult,
    EditEmployeeModel,
    Employee,
    EmployeeDataParserType,
    NewEmployeesWithPayoutsInPeriodModel
} from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import {
    loading,
    employeesFetched,
    employeesWithPayoutsFetchFailure,
    employeeCreated,
    employeeCreateFailure,
    employeeUpdated,
    employeeUpdateFailure,
    employeesBulkUploaded,
    employeesBulkUploadFailure,
    newEmployeesWithPayoutsFetched
} from "../redux/slices/employeeSlice";

const useEmployeeActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getEmployees = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.employees)
            .then(response => {
                dispatch(employeesFetched(response.data as Employee[]))
            })
            .catch(error => {
                console.log('getEmployees error:', error)
                dispatch(employeesWithPayoutsFetchFailure(error))
            })
    }

    const getNewEmployeesWithPayouts = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.employees + 'newEmployeesWithPayouts')
          .then(response => {
              dispatch(newEmployeesWithPayoutsFetched(response.data as NewEmployeesWithPayoutsInPeriodModel))
          })
          .catch(error => {
              console.log('getNewEmployees error:', error)
              dispatch(employeesWithPayoutsFetchFailure(error))
          })
    }

    const createEmployee = (employee: EditEmployeeModel) => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.employees, employee)
            .then(response => {
                dispatch(employeeCreated(response.data as Employee))
            })
            .catch(error => {
                console.log('createEmployee error:', error)
                dispatch(employeeCreateFailure(error))
            })
    }

    const updateEmployee = (id: string, employee: EditEmployeeModel) => {
        dispatch(loading())
        const url = `${apiConfig.routes.employees}${id}`

        return fetchClient.post(url, employee)
            .then(response => {
                dispatch(employeeUpdated(response.data as Employee))
            })
            .catch(error => {
                console.log('updateEmployee error:', error)
                dispatch(employeeUpdateFailure(error))
            })
    }

    const bulkUploadEmployees = (file: File, dataParser: EmployeeDataParserType, onlyUpdates: boolean, onComplete?: (result: BulkOperationResult) => void) => {
        dispatch(loading())

        return fetchClient.postFile(apiConfig.routes.employees + 'fileContent?dataParser=' + dataParser + '&onlyUpdates=' + onlyUpdates, file)
            .then(response => {
                dispatch(employeesBulkUploaded())
                onComplete && onComplete(response.data as BulkOperationResult)
            })
            .catch(error => {
                console.log('bulkUploadEmployees error:', error)
                dispatch(employeesBulkUploadFailure(error))
            })
    }

    return {
        getEmployees,
        getNewEmployeesWithPayouts,
        createEmployee,
        updateEmployee,
        bulkUploadEmployees
    }
}

export default useEmployeeActions
