import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from "../../constants/colors"
import { pageIndexChanged } from '../../redux/slices/portalUserSlice'
import { CurrentUser, EmployerPortalUser } from "../../redux/types"
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'
import { useAppDispatch } from '../../redux/hooks'
import Badge from '../Badge'
import { useTranslation } from 'react-i18next'

interface Props {
    users: EmployerPortalUser[]
    pageIndex: number
    pageSize: number
    onEditClick: (user: EmployerPortalUser) => void
    onDeleteClick: (user: EmployerPortalUser) => void
}

const UserList = ({ users, pageIndex, pageSize, onEditClick, onDeleteClick } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'portalUsers']);
    const dispatch = useAppDispatch()

    const itemsOnCurrentPage = useMemo(() => {
        const firstPageIndex = (pageIndex - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return users.slice(firstPageIndex, lastPageIndex);
    }, [users, pageIndex, pageSize])

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.emailContainer}>
                    <strong>{t('email', {ns: 'common'})}</strong>
                </div> 
                <div className={styles.cellContainer}>
                    <strong>{t('name', {ns: 'common'})}</strong>
                </div> 
                <div className={styles.cellContainer}>
                    <strong>{t('phoneNumber', {ns: 'common'})}</strong>
                </div> 
                <div className={styles.badgeContainer}>
                    <strong>{t('roles', {ns: 'portalUsers'})}</strong>
                </div>
                <div className={styles.buttonContainer}>
                   
                </div>  
            </ListItemCard>
        )    
    }

    const renderListItems = () => {
        return itemsOnCurrentPage?.map((user, index) => {
            return (
                <ListItemCard key={`user${index}`} className={styles.listItemContainer} index={index}>
                    <div className={styles.emailContainer}>
                        {user.email}
                    </div> 
                    <div className={styles.cellContainer}>
                        {user.givenName} {user.familyName}
                    </div>
                    <div className={styles.cellContainer}>
                        {user.phoneNumber}
                    </div>  
                    <div className={styles.badgeContainer}>
                        {renderRoleBadges(user)}
                    </div>
                    <div className={styles.buttonContainer}>
                        <div>
                            <FontAwesomeIcon
                                className={styles.button}
                                icon={faEdit}
                                color={Colors.gray1}
                                title={t('edit', {ns: 'common'})}
                                onClick={() => onEditClick && onEditClick(user)}
                            />
                        </div>
                        {(!user.roles || !user.roles.includes('Owner')) && <div>
                            <FontAwesomeIcon
                                className={styles.button}
                                icon={faTrashAlt}
                                color={Colors.gray1}
                                title={t('delete', {ns: 'common'})}
                                onClick={() => onDeleteClick && onDeleteClick(user)}
                            />
                        </div>
                        }  
                    </div>  
                </ListItemCard>
            ) 
         })
    }

    const renderRoleBadges = (user: EmployerPortalUser) => {
        return user.roles?.map((role, index) => {
            return (
                <Badge 
                    key={`roleBadge${index}`}
                    text={t(`role_${role}`, {ns: 'portalUsers'})}
                    backgroundColor={Colors.euro_300}
                    textColor={Colors.dollar_900}
                />
            )
        })
    }

    return (
        <>
           {renderHeader()}
           {renderListItems()}
           <div className={styles.pagination}>
            <Pagination
                    pageIndex={pageIndex}
                    itemCount={users.length}
                    pageSize={pageSize}
                    siblingCount={1}
                    onPageChange={index => dispatch(pageIndexChanged(index))}
                />
            </div>
        </>
    )
}

export default UserList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    emailContainer: {
        width: 280
    },
    cellContainer: {
        width: 160
    },
    buttonContainer: {
        display: 'flex',
        width: 80,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center',   
        paddingLeft: 0
    },
    badgeContainer: {
        display: 'flex',
        flex: 1,
        maxWidth: 400,
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    },
    button: {
        marginRight: 15,
        transition: '0.4s',
        '&:hover': {
          color: Colors.euro_600,
          cursor: 'pointer',
        }
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
  })