import { useState } from "react"
import { EmployeeAndDateRangeFilter as EmployeeAndDateRangeFilterType } from "../redux/types"
import { createUseStyles } from 'react-jss'
import { Breakpoints } from "../constants/breakpoints"
import EmployeeSelector from "./EmployeeSelector"
import { AutocompleteItem } from "./AutocompleteField"
import DatePicker from "./DatePicker"
import { isDateValid } from "../util/date"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Colors } from "../constants/colors"
import { useTranslation } from "react-i18next"

interface Props {
    initialFilterValue: EmployeeAndDateRangeFilterType | undefined
    onFilterChange: (filter: EmployeeAndDateRangeFilterType) => void
}

const EmployeeAndDateRangeFilter = ({initialFilterValue, onFilterChange}: Props) => {
    const styles = useStyles()
    const { t } = useTranslation('common');
    const [filterValue, setFilterValue] = useState<EmployeeAndDateRangeFilterType>(initialFilterValue ?? {employeeId: null, employeeName: null, fromDate: null, toDate: null})

    const handleEmployeeSelected = (employee: AutocompleteItem | null) => {
        const newFilter = {...filterValue, employeeId: employee?.value ?? null, employeeName: employee?.name ?? null}
        setFilterValue(newFilter)
        onFilterChange(newFilter)
    }
    
    const handleDateChanged = (propName: string, date: string | null | undefined) => {
        if (!date || date.length < 1) {
            const newFilter = {...filterValue, [propName]: null}
            setFilterValue(newFilter)
            onFilterChange(newFilter)   
        }

        if (date && isDateValid(date)) {
            const newFilter = {...filterValue, [propName]: date}
            setFilterValue(newFilter)
            onFilterChange(newFilter)
        }
    }

    const clearFilters = () => {
        const newFilter = {employeeId: null, employeeName: null, fromDate: null, toDate: null}
        setFilterValue(newFilter)
        onFilterChange(newFilter)    
    }

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <EmployeeSelector
                    externalIdAsValue={false}
                    includeTerminatedEmployees={true}
                    value={filterValue?.employeeName ?? ''}
                    onSelected={handleEmployeeSelected}
                />
            </div>
            <div className={styles.field}>
                <DatePicker 
                    id='fromDate'
                    label={t('fromDate')}
                    value={filterValue.fromDate ?? ''}
                    onChange={(value) => handleDateChanged('fromDate', value)}
                />   
            </div>
            <div className={styles.field}>
                <DatePicker 
                    id='toDate'
                    label={t('toDate')}
                    value={filterValue.toDate ?? ''}
                    onChange={(value) => handleDateChanged('toDate', value)}
                />   
            </div>
            <div className={styles.clearFiltersContainer}>
                <div className={styles.clearFiltersButton} onClick={() => clearFilters()} title={t('clearFilters')}>
                    <FontAwesomeIcon
                        size='lg'
                        icon={faTimes}
                        color={Colors.gray1}
                    />
                </div>
            </div>
        </div>
    )
}

export default EmployeeAndDateRangeFilter

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'flex-start',
    },
    field: {
        width: '100%',    
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            width: 280,
            marginRight: 30,
        },
    },
    clearFiltersContainer: {
       alignSelf: 'flex-end' 
    },
    clearFiltersButton: {
        marginBottom: 8,
        '&:hover': {
            cursor: 'pointer',
        }
    }
  })