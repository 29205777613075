import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {BookkeepingConfiguration} from '../types'

interface BookkeepingConfigurationState {
  loading: boolean
  bookkeepingConfiguration: BookkeepingConfiguration | null
  error: Error | string | null
}

const initialState: BookkeepingConfigurationState = {
  loading: false,
  bookkeepingConfiguration: null,
  error: null
}

export const bookkeepingConfigurationSlice = createSlice({
  name: 'bookkeepingConfiguration',
  initialState,
  reducers: {
    loading: state => {
      state.loading = true
      state.error = null
    },
    bookkeepingConfigurationFetched: (state, action: PayloadAction<BookkeepingConfiguration>) => {
      state.loading = false
      state.bookkeepingConfiguration = action.payload
      state.error = null
    },
    bookkeepingConfigurationFetchFailure: (state, action: PayloadAction<Error | string>) => {
      state.loading = false
      state.error = action.payload
    }
  }
})

export const {
  loading,
  bookkeepingConfigurationFetched,
  bookkeepingConfigurationFetchFailure
} = bookkeepingConfigurationSlice.actions

export default bookkeepingConfigurationSlice.reducer
