import { useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { apiConfig } from "../apiConfig";
import { logout } from "../redux/slices/authUserSlice";

const useFetchClient = () => {
    const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const request = (method: string) => {
        return (url: string, body?: Object) => {
            const options: RequestInit = {
                method,
                headers: createHeaders(url, 'application/json'),
                body: body && JSON.stringify(body)
            };
            return fetch(url, options).then(handleResponse);
        }
    }

    const createHeaders = (url: string, contentType: string | undefined) : HeadersInit => {
        const isApiRequest = apiConfig.isInternalUrl(url)
        const authToken = localStorage.getItem('authToken')

        const requestHeaders: HeadersInit = new Headers();

        if (authToken && isApiRequest) {
            requestHeaders.set('Authorization', `bearer ${authToken}`) 
        } 
        
        if (contentType)
        {
            requestHeaders.set('Content-Type', contentType)
        }

        return requestHeaders
    }

    const handleResponse = (response: Response) => {
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            
            if (!response.ok) {
                if ([401, 403].includes(response.status) && isAuthenticated) {
                    localStorage.removeItem('authToken')
                    dispatch(logout())
                    navigate('/login')
                }
    
                const error = (data && data.message) || response.statusText || response.status.toString();
                return Promise.reject(error);
            }
    
            return {data: data, raw: response};
        });
    }  
    
    const getRaw = async (url: string) => {
        const options: RequestInit = {
            method: 'GET',
            headers: createHeaders(url, 'application/json'),
        };

        return await fetch(url, options)
    }

    const postFile = async (url: string, file: File) => {
        const formData = new FormData();
        formData.append("file", file);

        return fetch(url, {
            method: "POST",
            body: formData,
            headers: createHeaders(url, undefined)
        }).then(handleResponse);    
    }

    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE'),
        getRaw: getRaw,
        postFile
    };
}

export default useFetchClient