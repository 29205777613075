import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { useAppDispatch } from "../redux/hooks";
import { createUrlWithQueryString } from "../util/url";
import { getPaginationDataFromResponse } from "../util/pagination";
import { IntegrationLogFilter, IntegrationLogItem } from "../redux/types";
import { integrationLogItemsFetched, integrationLogItemsFetchFailure, loading } from "../redux/slices/integrationLogSlice";

const useIntegrationLogActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getIntegrationLogs = (filter: IntegrationLogFilter, pageIndex: number, pageSize: number) => {
        dispatch(loading())

        const params = {
            level: filter.level,
            txId: filter.txId,
            fromDate: filter.fromDate,
            toDate: filter.toDate,
            pageNumber: pageIndex,
            pageSize: pageSize
        }

        const url = createUrlWithQueryString(apiConfig.routes.integrationLog, params)

        return fetchClient.get(url)
            .then(response => {
                const paginationData = getPaginationDataFromResponse(response.raw)
                dispatch(integrationLogItemsFetched({ items: response.data as IntegrationLogItem[], totalCount: paginationData?.totalItemCount ?? 0}))
            })
            .catch(error => {
                console.log('getIntegrationLogs error:', error)
                dispatch(integrationLogItemsFetchFailure(error))
            })
    }

    return {
        getIntegrationLogs
    }
}

export default useIntegrationLogActions