import { Navigate, RouteObject, Location } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import CreatePassword from '../pages/password/CreatePassword'
import PasswordMailSent from '../pages/password/PasswordMailSent'
import SetPassword from '../pages/password/SetPassword'
import Login from '../pages/Login'
import NotFound from '../pages/NotFound'
import AuthenticatedLayout from '../pages/layout/AuthenticatedLayout'
import { ReactNode } from 'react'
import PortalUsers from '../pages/PortalUsers'
import CreditUsage from '../pages/CreditUsage'
import Employees from '../pages/Employees'
import Shifts from '../pages/Shifts'
import Activations from '../pages/Activations'
import IntegrationLog from '../pages/IntegrationLog'
import Payrolls from '../pages/Payrolls'
import CompanySettings from '../pages/CompanySettings'
import { CurrentUser, PortalRole } from '../redux/types'
import { hasAnyRequiredRole } from '../util/role'
import Deviations from '../pages/Deviations'
import PaySchedules from '../pages/PaySchedules'
import Files from '../pages/Files'
import Integrations from '../pages/integrations/Integrations'
import FortnoxActivation from '../pages/integrations/FortnoxActivation'
import PayoutBasis from '../pages/PayoutBasis'
import Deactivations from '../pages/Deactivations'
import PaymentNotifications from '../pages/PaymentNotifications'
import PlandayActivation from '../pages/integrations/PlandayActivation'
import AgiAdjustments from '../pages/AgiAdjustments'
import AgiAdjustmentDetails from '../pages/AgiAdjustmentDetails'
import AgiAdjustment from '../pages/AgiAdjustment'
import PayrollFileProcessor from "../pages/PayrollFileProcessor";
import ForaAdjustments from '../pages/ForaAdjustments'
import ForaAdjustmentDetails from '../pages/ForaAdjustmentDetails'
import ForaAdjustment from '../pages/ForaAdjustment'
import ForaAdjustment2023 from '../pages/ForaAdjustment2023'
import EmployeeImport from "../pages/EmployeeImport";
import EmployerInitiatedPayoutProcessor from "../pages/EmployerInitiatedPayoutProcessor";

const AuthenticatedRoute = (isAuthenticated: boolean, user: CurrentUser | null, currentEmployerId: string | null, requiredRoles: PortalRole[], location: Location, children: ReactNode) => {

  if (isAuthenticated) {
    if (hasAnyRequiredRole(user, currentEmployerId, requiredRoles)) {
      return (
        <AuthenticatedLayout>{children}</AuthenticatedLayout>
      )
    }
    else {
      return (
        <Navigate to="/" />
      )
    }
  }

  return (
    <Navigate to="/login" replace state={{ from: location }} />
  )
}

const routes = (isAuthenticated: boolean, user: CurrentUser | null, currentEmployerId: string | null, location: Location) : RouteObject[] => [
  {
    path: '/',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, [], location, <Dashboard />)
  },
  {
    path: '/creditusage',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'FinanceAdmin'], location, <CreditUsage />)
  },
  {
    path: '/paySchedules',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <PaySchedules />)
  },
  {
    path: '/employees',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <Employees />)
  },
  {
    path: '/employees/import',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <EmployeeImport />)
  },
  {
    path: '/shifts',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin'], location, <Shifts />)
  },
  {
    path: '/deviations',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin'], location, <Deviations />)
  },
  {
    path: '/payoutBasis',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <PayoutBasis />)
  },
  {
    path: '/agiAdjustment',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <AgiAdjustments />)
  },
  {
    path: '/agiAdjustment/create',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <AgiAdjustment />)
  },
  {
    path: '/agiAdjustment/:period',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <AgiAdjustmentDetails />)
  },
  {
    path: '/foraAdjustment',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <ForaAdjustments />)
  },
  {
    path: '/foraAdjustment/create',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <ForaAdjustment />)
  },
  {
    path: '/foraAdjustment2023/create',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <ForaAdjustment2023 />)
  },
  {
    path: '/foraAdjustment/:period',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <ForaAdjustmentDetails />)
  },
  {
    path: '/paymentNotifications',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'FinanceAdmin'], location, <PaymentNotifications />)
  },
  {
    path: '/payroll',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <Payrolls />)
  },
  {
    path: '/payroll/create',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <PayrollFileProcessor />)
  },
  {
    path: '/payroll/createEmployerInitiatedPayout',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin'], location, <EmployerInitiatedPayoutProcessor />)
  },
  {
    path: '/activations',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'ActivationAdmin'], location, <Activations />)
  },
  {
    path: '/deactivations',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin', 'ActivationAdmin'], location, <Deactivations />)
  },
  {
    path: '/files',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, [], location, <Files />)
  },
  {
    path: '/integrations/fortnox/activation',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin'], location, <FortnoxActivation />)
  },
  {
    path: '/integrations/planday/activation',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin'], location, <PlandayActivation />)
  },
  {
    path: '/integrations',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin'], location, <Integrations />)
  },
  {
    path: '/integrationlog',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin'], location, <IntegrationLog />)
  },
  {
    path: '/companysettings',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin'], location, <CompanySettings />)
  },
  {
    path: '/portalusers',
    element: AuthenticatedRoute(isAuthenticated, user, currentEmployerId, ['Owner', 'Admin'], location, <PortalUsers />)
  },
  {
    path: '/login',
    element: !isAuthenticated ? <Login /> : <Navigate to="/" />,
  },
  {
    path: '/forgotpassword',
    element: <CreatePassword />,
  },
  {
    path: '/createpassword',
    element: <CreatePassword />,
  },
  {
    path: '/passwordmailsent',
    element: <PasswordMailSent />,
  },
  {
    path: '/setpassword',
    element: <SetPassword />,
  },
  {
    path: '*',
    element: <NotFound />,
  }
]

  export default routes
