import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IntegrationLogFilter, IntegrationLogItem, PagedIntegrationLogItems } from '../types'

interface IntegrationLogState {
    loading: boolean
    itemsOnPage: IntegrationLogItem[]
    totalCount: number
    pageIndex: number
    pageSize: number    
    filter: IntegrationLogFilter | null
    error: Error | string | null
}

const initialState: IntegrationLogState = {
    loading: false,
    itemsOnPage: [],
    totalCount: 0,
    pageIndex: 1,
    pageSize: 15,
    filter: null,
    error: null
}

export const integrationLogSlice = createSlice({
    name: 'integrationLog',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        integrationLogItemsFetched: (state, action: PayloadAction<PagedIntegrationLogItems>) => {
            state.loading = false
            state.itemsOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        integrationLogItemsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<IntegrationLogFilter>) => {
            state.filter = action.payload
            state.pageIndex = 1
        },
    }
})

export const { 
    loading, 
    integrationLogItemsFetched, 
    integrationLogItemsFetchFailure, 
    pageIndexChanged, 
    filterChanged 
} = integrationLogSlice.actions

export default integrationLogSlice.reducer