import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PagedPayDeviations, PayDeviation, EmployeeAndDateRangeFilter } from '../types'

interface PayDeviationState {
    loading: boolean
    deviationsOnPage: PayDeviation[]
    totalCount: number
    pageIndex: number
    pageSize: number    
    filter: EmployeeAndDateRangeFilter | null
    error: Error | string | null
}

const initialState: PayDeviationState = {
    loading: false,
    deviationsOnPage: [],
    totalCount: 0,
    pageIndex: 1,
    pageSize: 15,
    filter: null,
    error: null
}

export const payDeviationSlice = createSlice({
    name: 'payDeviation',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        payDeviationsFetched: (state, action: PayloadAction<PagedPayDeviations>) => {
            state.loading = false
            state.deviationsOnPage = action.payload.deviations
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        payDeviationsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        payDeviationCreated: (state, action: PayloadAction<PayDeviation>) => {
            state.loading = false
            state.error = null
        },
        payDeviationCreateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        payDeviationUpdated: (state, action: PayloadAction<PayDeviation>) => {
            const deviationsOnPageClone = [...state.deviationsOnPage]
            const indexToUpdate = state.deviationsOnPage.findIndex(x => x.id === action.payload.id)

            if (indexToUpdate !== -1)
                deviationsOnPageClone[indexToUpdate] = action.payload

            state.loading = false
            state.deviationsOnPage = deviationsOnPageClone
            state.error = null
        },
        payDeviationUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        payDeviationDeleted: (state, action: PayloadAction<PayDeviation>) => {
            state.loading = false
            state.deviationsOnPage = state.deviationsOnPage.filter(x => x.id !== action.payload.id)
            state.error = null
        },
        payDeviationDeleteFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        payDeviationsBulkUploaded: (state) => {
            state.loading = false
            state.error = null
        },
        payDeviationsBulkUploadFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<EmployeeAndDateRangeFilter>) => {
            state.filter = action.payload
            state.pageIndex = 1
        },
        errorHandled: (state) => {
            state.error = null    
        }
    }
})

export const { 
    loading, 
    payDeviationsFetched, 
    payDeviationsFetchFailure, 
    payDeviationCreated, 
    payDeviationCreateFailure, 
    payDeviationUpdated, 
    payDeviationUpdateFailure, 
    payDeviationDeleted, 
    payDeviationDeleteFailure,
    payDeviationsBulkUploaded,
    payDeviationsBulkUploadFailure,
    pageIndexChanged, 
    filterChanged,
    errorHandled 
} = payDeviationSlice.actions

export default payDeviationSlice.reducer