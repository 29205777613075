import {faFile, faFilePdf} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {useTranslation} from "react-i18next"
import {createUseStyles} from "react-jss"
import {apiConfig} from "../apiConfig"
import {Colors} from "../constants/colors"
import {Fonts} from "../constants/fonts"
import {FileModel, FileType, Language, PayoutBasisFileType} from "../redux/types"
import AuthenticatedFileLink from "./AuthenticatedFileLink"
import InfoIcon from "./InfoIcon"
import {useAppSelector} from "../redux/hooks";

interface Props {
    file: FileModel
}

const File = ({ file }:Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['files', 'employees']);
    const language = useAppSelector(state => state.auth.authenticatedUser?.language) ?? Language.en

    const renderFile = () => {
        return (
            <div className={styles.fileContainer} key={`file_${file.id}`}>
                <FontAwesomeIcon
                    icon={file.type === FileType.employerPayDeductionPaymentNotificationFile ? faFilePdf : faFile}
                    style={{marginTop: 0}}
                    color={Colors.gray1}
                />
                { renderFileLink(file) }
                <InfoIcon text={getFileDescription(file) ?? ''}/>
            </div>
        )
    }

    const renderFileLink = (file: FileModel) => {
        let url;
        let className;

        switch (file.type) {
            case FileType.employerPayDeductionPaymentNotificationFile:
                url = apiConfig.routes.paymentNotificationFiles + file.id
                className = file.employerPayDeductionPaymentNotificationFile?.lastDownloadDate == null ? styles.newFile : styles.readFile
                break
            case FileType.payrollSystemPayoutBasisImportFile:
                url = apiConfig.routes.payoutBasisImportFiles + file.id
                className = file.payrollSystemPayoutBasisImportFile?.lastDownloadDate == null ? styles.newFile : styles.readFile
                break
            case FileType.payrollSystemBankAccountImportFile:
                url = apiConfig.routes.bankAccountImportFile
                className = styles.newFile
                break
            case FileType.creditReportFile:
                url = apiConfig.routes.creditReportFiles + file.id
                className = file.creditReportFile?.lastDownloadDate == null ? styles.newFile : styles.readFile
                break
            case FileType.agiAdjustmentSieFile:
                url = apiConfig.routes.agiAdjustmentSieFiles + file.id
                className = file.agiAdjustmentSieFile?.lastDownloadDate == null ? styles.newFile : styles.readFile
                break
            case FileType.agiAdjustmentReportFile:
                url = apiConfig.routes.agiAdjustmentReportFile + `?period=${file.agiAdjustmentReportFile.period}&language=${language}`
                className = styles.readFile
                break
            case FileType.foraAdjustmentReportFile:
                url = apiConfig.routes.foraAdjustmentReportFile + `?period=${file.foraAdjustmentReportFile.period}&language=${language}`
                className = styles.readFile
                break
            case FileType.remainingDebtReportFile:
                url = apiConfig.routes.remainingDebtReportFile + `?paydayId=${file.remainingDebtReportFile.paydayId}&language=${language}`
                className = styles.readFile
                break
            case FileType.employeeForeclosureReportFile:
                url = apiConfig.routes.employeeForeclosureReportFile + `?paydayId=${file.employeeForeclosureReportFile.paydayId}&language=${language}`
                className = styles.readFile
                break
            case FileType.grossAdvanceReportFile:
                url = apiConfig.routes.grossAdvanceReportFile + `?year=${file.grossAdvanceReportFile.year}&month=${file.grossAdvanceReportFile.month}&language=${language}`
                className = styles.readFile
        }

        return (
            <AuthenticatedFileLink fileId={file.id} url={url} filename={getFilename(file)}>
                <span style={{flexWrap: 'nowrap', fontSize: 13}} className={className}>{getFileTitle(file)}</span>
            </AuthenticatedFileLink>
        )
    }

    const getFileTitle = (file: FileModel) => {
        switch (file.type) {
            case FileType.employerPayDeductionPaymentNotificationFile:
                return t(`payDeductionPaymentNotificationFilename`, {ns: 'files', payday: file.employerPayDeductionPaymentNotificationFile.paydayPaymentDate})
            case FileType.payrollSystemPayoutBasisImportFile:
                const employeeTypeString = t(`type_${file.payrollSystemPayoutBasisImportFile.employeeType}`, {ns: 'employees'})

                if (file.payrollSystemPayoutBasisImportFile.fileType === PayoutBasisFileType.deductionBasisForPayday) {
                    return t(`paydayDeductionFilename`, {ns: 'files', payday: file.payrollSystemPayoutBasisImportFile.paydayPaymentDate, employeeType: employeeTypeString})
                } else {
                    return t(`calendarMonthPayoutsFilename`, {ns: 'files', month: `${file.payrollSystemPayoutBasisImportFile.year}-${padWithLeadingZeroes(file.payrollSystemPayoutBasisImportFile.month, 2)}`, employeeType: employeeTypeString})
                }
            case FileType.payrollSystemBankAccountImportFile:
                return t(`bankAccountsFilename`, {ns: 'files'})
            case FileType.creditReportFile:
                return t(`creditReportFilename`, {ns: 'files'})
            case FileType.agiAdjustmentSieFile:
                return t(`agiAdjustmentSieFilename`, {ns: 'files'})
            case FileType.agiAdjustmentReportFile:
                return t(`agiAdjustmentReportFilename`, {ns: 'files'})
            case FileType.foraAdjustmentReportFile:
                return t(`foraAdjustmentReportFilename`, {ns: 'files'})
            case FileType.remainingDebtReportFile:
                return t(`remainingDebtReportFilename`, {ns: 'files'})
            case FileType.employeeForeclosureReportFile:
                return t(`employeeForeclosureReportFilename`, {ns: 'files'})
            case FileType.grossAdvanceReportFile:
                return t(`grossAdvanceReportFilename`, {ns: 'files'})
        }
    }

    const getFileDescription = (file: FileModel) => {
        switch (file.type) {
            case FileType.employerPayDeductionPaymentNotificationFile:
                return t(`payDeductionPaymentNotificationDescription`, {ns: 'files', payday: file.employerPayDeductionPaymentNotificationFile.paydayPaymentDate})
            case FileType.payrollSystemPayoutBasisImportFile:
                if (file.payrollSystemPayoutBasisImportFile.fileType === PayoutBasisFileType.deductionBasisForPayday) {
                    return t(`paydayDeductionDescription`, {ns: 'files', payday: file.payrollSystemPayoutBasisImportFile.paydayPaymentDate})
                } else {
                    return t(`calendarMonthPayoutsDescription`, {ns: 'files', month: `${file.payrollSystemPayoutBasisImportFile.year}-${padWithLeadingZeroes(file.payrollSystemPayoutBasisImportFile.month, 2)}`})
                }
            case FileType.payrollSystemBankAccountImportFile:
                return t(`bankAccountsDescription`, {ns: 'files'})
            case FileType.creditReportFile:
                return t(`creditReportDescription`, {ns: 'files'})
            case FileType.agiAdjustmentSieFile:
                return t(`agiAdjustmentSieFileDescription`, {ns: 'files'})
            case FileType.agiAdjustmentReportFile:
                return t(`agiAdjustmentReportFileDescription`, {ns: 'files'})
            case FileType.foraAdjustmentReportFile:
                return t(`foraAdjustmentReportFileDescription`, {ns: 'files'})
            case FileType.remainingDebtReportFile:
                return t(`remainingDebtReportFileDescriptionTemplate`, {ns: 'files', payday: file.remainingDebtReportFile.payday})
            case FileType.employeeForeclosureReportFile:
                return t(`employeeForeclosureReportFileDescriptionTemplate`, {ns: 'files', payday: file.employeeForeclosureReportFile.payday})
            case FileType.grossAdvanceReportFile:
                return t(`grossAdvanceReportFileDescriptionTemplate`, {ns: 'files', period: `${file.grossAdvanceReportFile.year}${String(file.grossAdvanceReportFile.month).padStart(2, '0')}`})
        }
    }

    const getFilename = (file: FileModel) => {
        let filename = ''

        if (file.type === FileType.payrollSystemPayoutBasisImportFile) {
            filename = file.payrollSystemPayoutBasisImportFile.filename
        } else if (file.type === FileType.employerPayDeductionPaymentNotificationFile) {
            filename = file.employerPayDeductionPaymentNotificationFile.filename
        } else if (file.type === FileType.payrollSystemBankAccountImportFile) {
            filename = file.payrollSystemBankAccountImportImportFile.filename
        } else if (file.type === FileType.creditReportFile) {
            filename = file.creditReportFile.filename
        } else if (file.type === FileType.agiAdjustmentSieFile) {
            return file.agiAdjustmentSieFile.filename
        } else if (file.type === FileType.agiAdjustmentReportFile) {
            return file.agiAdjustmentReportFile.filename
        } else if (file.type === FileType.foraAdjustmentReportFile) {
            return file.foraAdjustmentReportFile.filename
        } else if (file.type === FileType.remainingDebtReportFile) {
            return file.remainingDebtReportFile.filename
        } else if (file.type === FileType.employeeForeclosureReportFile) {
            return file.employeeForeclosureReportFile.filename
        } else if (file.type === FileType.grossAdvanceReportFile) {
            return file.grossAdvanceReportFile.filename
        }

        let extension = filename.substring(filename.indexOf('.'))
        return getFileTitle(file).replaceAll(' ', '_') + extension
    }

    const padWithLeadingZeroes = (num: number, size: number) => {
        let numberString = num?.toString() ?? '';
        while (numberString.length < size) {
            numberString = "0" + numberString;
        }
        return numberString;
    }

    return (
        <>
        { renderFile() }
        </>
    )
}

export default File

const useStyles = createUseStyles({
    fileContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 8,
        width: '100%'
    },
    readFile: {
        '&:hover': {
            color: Colors.euro_800,
        }
    },
    newFile: {
        fontFamily: Fonts.semiBold,
        '&:hover': {
            color: Colors.euro_800,
        }
    }
})
