import { useEffect, useRef, useState } from 'react'

const DEFAULT_DELAY_MS = 1000

const useTimer = (timeoutMs : number | null) => {
	const timer: React.MutableRefObject<NodeJS.Timeout | null> = useRef(null)

	const [ isRunning, setIsRunning ] = useState(false)

	useEffect(() => {
		return () => {
			if (timer.current) {
				clearTimeout(timer.current)
			}
		}
	}, [])

	const startTimer = () => {
		setIsRunning(true)
		timer.current = setTimeout(() => {
			setIsRunning(false)
		}, timeoutMs || DEFAULT_DELAY_MS)
	}

	const restartTimer = () => {
		if (isRunning && timer.current) {
			clearTimeout(timer.current)
			timer.current = setTimeout(() => {
				setIsRunning(false)
			}, timeoutMs || DEFAULT_DELAY_MS)
		} else {
			startTimer()
		}
	}

	return { startTimer, restartTimer, isRunning }
}

export default useTimer
