import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Payroll, PayrollData, PayRun, UpcomingPayroll } from '../types'

interface PayrollState {
    loading: boolean
    upcomingPayrolls: UpcomingPayroll[]
    payrolls: Payroll[]
    payRuns: PayRun[]
    pageIndex: number
    pageSize: number    
    error: Error | string | null
}

const initialState: PayrollState = {
    loading: false,
    upcomingPayrolls: [],
    payrolls: [],
    payRuns: [],
    pageIndex: 1,
    pageSize: 15,
    error: null
}

export const payrollSlice = createSlice({
    name: 'payroll',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        payrollDataFetched: (state, action: PayloadAction<PayrollData>) => {
            state.loading = false
            state.payrolls = action.payload.payrolls
            state.upcomingPayrolls = action.payload.upcomingPayrolls
            state.pageIndex = 1
            state.error = null
        },
        payrollDataFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        payRunsFetched: (state, action: PayloadAction<PayRun[]>) => {
            state.loading = false
            state.payRuns = action.payload
            state.error = null
        },
        payRunsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        clearPayRuns: (state) => {
            state.payRuns = []
        },
        payrollFileUploaded: (state, action: PayloadAction<UpcomingPayroll>) => {
            const upcomingPayrollsClone = [...state.upcomingPayrolls]
            const indexToUpdate = upcomingPayrollsClone.findIndex(x => x.paydayId === action.payload.paydayId)
            upcomingPayrollsClone[indexToUpdate] = action.payload

            state.loading = false
            state.upcomingPayrolls = upcomingPayrollsClone
            state.error = null
        },
        payrollFileUploadFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        payrollFileDeleted: (state, action: PayloadAction<PayrollData>) => {
            state.loading = false
            state.payrolls = action.payload.payrolls
            state.upcomingPayrolls = action.payload.upcomingPayrolls
            state.error = null
        },
        payrollFileDeleteFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        offCyclePayrollFileUploaded: (state, action: PayloadAction<PayrollData>) => {
            state.loading = false
            state.payrolls = action.payload.payrolls
            state.upcomingPayrolls = action.payload.upcomingPayrolls
            state.error = null
        },
        offCyclePayrollFileUploadFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        offCyclePayrollFileDeleted: (state, action: PayloadAction<PayrollData>) => {
            state.loading = false
            state.payrolls = action.payload.payrolls
            state.upcomingPayrolls = action.payload.upcomingPayrolls
            state.error = null
        },
        offCyclePayrollFileDeleteFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        errorHandled: (state) => {
            state.error = null    
        }
    }
})

export const { 
    loading, 
    payrollDataFetched, 
    payrollDataFetchFailure, 
    payRunsFetched,
    payRunsFetchFailure,
    clearPayRuns,
    payrollFileUploaded, 
    payrollFileUploadFailure, 
    payrollFileDeleted, 
    payrollFileDeleteFailure, 
    offCyclePayrollFileUploaded, 
    offCyclePayrollFileUploadFailure, 
    offCyclePayrollFileDeleted, 
    offCyclePayrollFileDeleteFailure, 
    pageIndexChanged,
    errorHandled 
} = payrollSlice.actions

export default payrollSlice.reducer