import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import { PayrollValidationError } from "../../redux/types"
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'

interface Props {
    errors: PayrollValidationError[]
    pageIndex: number
    pageSize: number
    onPageChange: (pageIndex: number) => void
}

const PayrollFileValidationErrorList = ({ errors, pageIndex, pageSize, onPageChange } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'payrolls']);

    const itemsOnCurrentPage = useMemo(() => {
        const firstPageIndex = (pageIndex - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return errors.slice(firstPageIndex, lastPageIndex);
    }, [errors, pageIndex, pageSize])

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.errorTypeContainer}>
                    <strong>{t('error', {ns: 'common'})}</strong>
                </div> 
                <div className={styles.employeeContainer}>
                    <strong>{t('employee', {ns: 'common'})}</strong>
                </div>
                <div className={styles.cellContainer}>
                    <strong>{t('reason', {ns: 'common'})}</strong> 
                </div>  
                <div className={styles.cellContainer}>
                    <strong>{t('consequence', {ns: 'common'})}</strong> 
                </div>  
                <div className={styles.cellContainer}>
                    <strong>{t('action', {ns: 'common'})}</strong> 
                </div>  
            </ListItemCard>
        )    
    }

    const renderListItems = () => {
        return itemsOnCurrentPage?.map((error, index) => {
            var params = JSON.parse(error.parameters)

            switch (error.type) {
                case 'unknownEmployeeInPayroll':
                    return renderUnknownEmployeeInListItem(params, index)   
                case 'employeeMissingInPayroll':
                    return renderMissingInPayrollListItem(params, index)
                case 'employeeWithNegativeBalance':
                    return renderEmployeeWithNegativeBalanceListItem(params, index)   
                case 'nonActivatedEmployeeInPayroll':
                    return renderNonActivatedEmployeeFoundInPayrollListItem(params, index)   
                case 'employeeWithIncorrectBankAccount':
                    return renderEmployeeWithIncorrectBankAccountListItem(params, index)  
                case 'employeeWithIdentificationNumberMismatch': 
                    return renderEmployeeWithIdentificationNumberMismatchListItem(params, index)  
                case 'paymentDateDifferentFromPayday':
                    return renderPaymentDateDiffListItem(params, index) 
                case 'possibleDuplication':
                    return renderPossibleDuplicationListItem(params, index) 
            } 
         })
    }

    const renderMissingInPayrollListItem = (errorParams: any, index: number) => {
        return renderListItem(t('missingEmployee', {ns: 'payrolls'}), `${errorParams.employeeName} (${errorParams.identificationNumber})`, t('missingEmployeeReason', {ns: 'payrolls'}), t('missingEmployeeConsequence', {ns: 'payrolls'}), [], index)
    } 

    const renderUnknownEmployeeInListItem = (errorParams: any, index: number) => {
        return renderListItem(t('unknownEmployee', {ns: 'payrolls'}), errorParams.payrollFileIdentifier, t('unknownEmployeeReason', {ns: 'payrolls'}), t('unknownEmployeeConsequence', {ns: 'payrolls'}), [t('unknownEmployeeAction1', {ns: 'payrolls'}), t('unknownEmployeeAction2', {ns: 'payrolls'}), t('unknownEmployeeAction3', {ns: 'payrolls'}), t('unknownEmployeeAction4', {ns: 'payrolls'})], index)
    } 
    
    const renderEmployeeWithNegativeBalanceListItem = (errorParams: any, index: number) => {
        return renderListItem(t('negativeBalance', {ns: 'payrolls'}), `${errorParams.employeeName} (${errorParams.identificationNumber})`, t('negativeBalanceReason', {ns: 'payrolls'}), t('negativeBalanceConsequence', {ns: 'payrolls'}), [], index)
    } 

    const renderNonActivatedEmployeeFoundInPayrollListItem = (errorParams: any, index: number) => {
        return renderListItem(t('notActivated', {ns: 'payrolls'}), `${errorParams.employeeName} (${errorParams.identificationNumber})`, t('notActivatedReason', {ns: 'payrolls'}),  t('notActivatedConsequence', {ns: 'payrolls'}), [t('notActivatedAction1', {ns: 'payrolls'}), t('notActivatedAction2', {ns: 'payrolls'}), t('notActivatedAction3', {ns: 'payrolls'})], index)
    } 

    const renderEmployeeWithIncorrectBankAccountListItem = (errorParams: any, index: number) => {
        return renderListItem(t('wrongBankAccount', {ns: 'payrolls'}), `${errorParams.employeeName} (${errorParams.identificationNumber})`, t('wrongBankAccountReason', {ns: 'payrolls'}), t('wrongBankAccountConsequence', {ns: 'payrolls'}), [t('wrongBankAccountAction1', {ns: 'payrolls'})], index)
    } 

    const renderEmployeeWithIdentificationNumberMismatchListItem = (errorParams: any, index: number) => {
        return renderListItem(t('identificationNumberMismatch', {ns: 'payrolls'}), `${errorParams.employeeName} (${errorParams.identificationNumber})`, t('identificationNumberMismatchReason', {ns: 'payrolls'}), t('identificationNumberMismatchConsequence', {ns: 'payrolls'}), [t('identificationNumberMismatchAction', {ns: 'payrolls'})], index)
    }

    const renderPaymentDateDiffListItem = (errorParams: any, index: number) => {
        return renderListItem(t('paymentDateDiff', {ns: 'payrolls'}), '', `${errorParams.paymentDate}/${errorParams.payday}`, '', [], index)
    } 

    const renderPossibleDuplicationListItem = (errorParams: any, index: number) => {
        return renderListItem(t('possibleDuplication', {ns: 'payrolls'}), `${errorParams.employeeName} (${errorParams.identificationNumber})`, t('possibleDuplicationReason', {ns: 'payrolls'}), '', [], index)
    } 

    const renderListItem = (errorType: string, employee: string, reason: string, consequence: string, actions: string[], index: number) => {
        return (
            <ListItemCard key={`row${index}`} className={styles.listItemContainer} index={index}>
                <div className={styles.errorTypeContainer}>
                    <FontAwesomeIcon 
                        icon={faExclamationTriangle} 
                        color={Colors.rubel_700} 
                        style={{marginTop: 3}} /> 
                    
                    {errorType}
                </div> 
                <div className={styles.employeeContainer}>
                    {employee}
                </div>
                <div className={styles.cellContainer}>
                    {reason}
                </div>
                <div className={styles.cellContainer}>
                    {consequence}
                </div>
                <div className={styles.cellContainer}>
                    {renderActions(actions)}
                </div>
            </ListItemCard>
        ) 
    }

    const renderActions = (actions: string[]) => {
        if (actions.length === 1) {
            return actions[0];
        } else if (actions.length > 1) {
            return (
                <ol>
                    {actions.map((action) => {
                        return <li>{action}</li>
                    })}
                </ol>
            )
        }
    }
    

    return (
        <>
            {renderHeader()}
            {renderListItems()}
            <div className={styles.pagination}>
            <Pagination
                pageIndex={pageIndex}
                itemCount={errors.length}
                pageSize={pageSize}
                siblingCount={1}
                onPageChange={onPageChange}
            />
            </div>
        </>
    )
}

export default PayrollFileValidationErrorList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
        columnGap: 10
    },
    errorTypeContainer: {
        width: 200,
        display: 'flex',
        flexDirection: 'row',
        columnGap: 5,
    },
    employeeContainer: {
        width: 270
    },
    cellContainer: {
        width: 200, 
        display: 'flex',
        flexGrow: 1
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
})