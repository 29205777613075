import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AgiAdjustmentPeriodSummary, AgiAdjustmentsForPeriod, AgiAdjustmentsModel } from '../types'

interface AgiAdjustmentState {
    loading: boolean
    currentPeriod: string | null
    isCurrentPeriodAdjusted: boolean
    periods: AgiAdjustmentPeriodSummary[]
    pageIndex: number
    pageSize: number

    periodDetails: AgiAdjustmentsForPeriod | null
    periodDetailsPageIndex: number
    periodDetailsPageSize: number
    error: Error | string | null
}

const initialState: AgiAdjustmentState = {
    loading: false,
    currentPeriod: null,
    isCurrentPeriodAdjusted: false,
    periods: [],
    pageIndex: 1,
    pageSize: 15,
    periodDetails: null,
    periodDetailsPageIndex: 1,
    periodDetailsPageSize: 15,
    error: null
}

export const agiAdjustmentSlice = createSlice({
    name: 'agiAdjustment',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        periodsFetched: (state, action: PayloadAction<AgiAdjustmentsModel>) => {
            state.loading = false
            state.currentPeriod = action.payload.currentPeriod
            state.isCurrentPeriodAdjusted = action.payload.isCurrentPeriodAdjusted
            state.periods = action.payload.adjustedPeriods
            state.error = null
        },
        periodsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        periodDetailsFetched: (state, action: PayloadAction<AgiAdjustmentsForPeriod>) => {
            state.loading = false
            state.periodDetails = action.payload
            state.error = null
        },
        periodDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        periodDetailsPageIndexChanged: (state, action: PayloadAction<number>) => {
            state.periodDetailsPageIndex = action.payload
        }
    }
})

export const { 
    loading, 
    periodsFetched, 
    periodsFetchFailure, 
    periodDetailsFetched, 
    periodDetailsFetchFailure,
    pageIndexChanged,
    periodDetailsPageIndexChanged
} = agiAdjustmentSlice.actions

export default agiAdjustmentSlice.reducer