import Modal from "../Modal"
import {createUseStyles} from 'react-jss'
import {useState} from "react"
import {Employee, Employer, UploadPayrollFileRowModel, UploadPayrollFileRowsModel} from "../../redux/types"
import {useTranslation} from "react-i18next"
import Button from "../Button";
import ListItemCard from "../ListItemCard";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";
import {Colors} from "../../constants/colors";
import EmployeeSelector from "../EmployeeSelector";
import {AutocompleteItem} from "../AutocompleteField";
import InputField from "../InputField";
import {useAppSelector} from "../../redux/hooks";

interface Props {
  visible: boolean
  onCreateClick: (model: UploadPayrollFileRowsModel) => void
  onCloseClick: () => void
}

const initialRows = [] as UploadPayrollFileRowModel[]

const CreatePayrollFileRowsModal = ({visible, onCreateClick, onCloseClick}: Props) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'payrolls']);
  const employer = useAppSelector(state => state.employer.employer)
  const employees = useAppSelector(state => state.employee.employees)
  const [rows, setRows] = useState<UploadPayrollFileRowModel[]>(initialRows)
  const [employee, setEmployee] = useState<Employee>()
  const [amount, setAmount] = useState<number>()
  const [amountValid, setAmountValid] = useState(true)

  const createPayrollFileIdentifierExtractor = (employer: Employer | null) => {
    switch (employer?.payrollFileEmployeeIdentifierType) {
      case "id10":
        return (x: Employee) => x.identificationNumber.slice(-10)
      case "id12":
        return (x: Employee) => x.identificationNumber
      case "externalId":
        return (x: Employee) => x.externalId
      case "custom":
        return (x: Employee) => x.payrollFileIdentifier
    }

    return (x: Employee) => x.externalId
  }


  const handleOnCreate = () => {
    const uploadPayrollFileRowsModel = {
      rows: rows,
      originalRowCount: rows.length,
    } as UploadPayrollFileRowsModel

    onCreateClick(uploadPayrollFileRowsModel)
    setRows(initialRows)
    setEmployee(undefined)
    setAmount(undefined)
  }

  const handleOnClose = () => {
    onCloseClick()
    setRows(initialRows)
    setEmployee(undefined)
    setAmount(undefined)
  }

  const renderHeader = () => {
    return (
      <ListItemCard className={styles.listItemContainer}>
        <div className={styles.defaultCellContainer}>
          <strong>{t('idNumber', {ns: 'common'})}</strong>
        </div>
        <div className={styles.nameCellContainer}>
          <strong>{t('name', {ns: 'common'})}</strong>
        </div>
        <div className={styles.defaultCellContainer}>
          <strong>{t('netAmount', {ns: 'payrolls'})}</strong>
        </div>
        <div className={styles.defaultCellContainer}>
          <strong>{t('bankAccount', {ns: 'payrolls'})}</strong>
        </div>
        <div className={styles.buttonContainer}/>
      </ListItemCard>
    )
  }

  const renderRows = (rows: UploadPayrollFileRowModel[]) => {
    const removeRow = (row: UploadPayrollFileRowModel) => {
      const newRows = rows.filter(r => r !== row)
      setRows(newRows)
    }

    return rows.map((row, index) => {
      return (
        <ListItemCard key={`employee${index}`} className={styles.listItemContainer} index={index}>
          <div className={styles.defaultCellContainer}>
            {row.identificationNumber}
          </div>
          <div className={styles.nameCellContainer}>
            {row.text}
          </div>
          <div className={styles.defaultCellContainer}>
            {row.amount.toLocaleString('sv-SE', {minimumFractionDigits: 2})} {employer?.payoutSettings.currency.toUpperCase()}
          </div>
          <div className={styles.defaultCellContainer}>
            {row.bankAccountNumber}
          </div>

          <div className={styles.buttonContainer}>
            <div>
              <FontAwesomeIcon
                className={styles.button}
                icon={faTrash}
                color={Colors.gray1}
                title={t('delete', {ns: 'common'})}
                onClick={() => removeRow(row)}
              />
            </div>
          </div>
        </ListItemCard>
      )
    })
  }

  const renderAddRow = () => {
    const payrollFileIdentifierExtractor = createPayrollFileIdentifierExtractor(employer)

    const handleOnClick = () => {
      if (!employer || !employee || !amount || !amountValid) {
        return
      }

      const newRow = {
        employeePayrollFileIdentifier: payrollFileIdentifierExtractor(employee),
        identificationNumber: employee.identificationNumber,
        text: employee.givenName + ' ' + employee.familyName,
        amount: amount,
        bankAccountNumber: employer.bankAccount
      } as UploadPayrollFileRowModel

      const newRows = [...rows]
      newRows.push(newRow)
      setRows(newRows)
      setEmployee(undefined)
      setAmount(undefined)
    }

    const handleEmployeeSelected = (employee: AutocompleteItem | null) => {
      if (employee != null) {
        const selectedEmployee = employees.find(e => e.externalId === employee.value)
        setEmployee(selectedEmployee)
      }
    }

    const handleAmountChange = (value: string) => {
      const valueAsNumber = Number.parseFloat(value)
      if (isNaN(valueAsNumber)) {
        setAmountValid(false)
        return
      }

      if (valueAsNumber < 0) {
        setAmountValid(false)
        return
      }

      setAmountValid(true)
      setAmount(valueAsNumber)
    }

    return (
      <div className={styles.formContainer}>
        <div className={styles.employeeSelectorContainer}>
          <EmployeeSelector
            mandatory={true}
            externalIdAsValue={true}
            includeUnconnectedEmployees={false}
            includeEmployeesWithoutDataCollectionConsent={false}
            value={!!employee ? `${employee.givenName} ${employee.familyName} (${employee.identificationNumber})` : ''}
            onSelected={handleEmployeeSelected}
          />
        </div>
        <div className={styles.formFieldContainer}>
          <InputField
            valid={amountValid}
            mandatory={true}
            id='amount'
            type='number'
            value={amount?.toString()}
            onChange={handleAmountChange}
            label={t('netAmount', {ns: 'payrolls'})}
          />
        </div>
        <div className={styles.formFieldContainer}>
          <InputField
            disabled={true}
            id='bankAccount'
            value={employer?.bankAccount}
            label={t('bankAccount', {ns: 'payrolls'})}
          />
        </div>
        <Button
          disabled={!employee || !amount || !amountValid}
          title={t('add', {ns: 'common'})}
          variant={'positive'}
          onClick={handleOnClick}
        />
      </div>
    )
  }

  return (
    <Modal
      title={t('createPayrollFileRowsManually', {ns: 'payrolls'})}
      visible={visible}
      okButtonTitle={t('save', {ns: 'common'})}
      cancelButtonTitle={t('cancel', {ns: 'common'})}
      onHide={handleOnClose}
      onOkClick={handleOnCreate}
      okButtonDisabled={rows.length === 0}
      onCancelClick={handleOnClose}
      size={'xl'}
    >
      <div className={styles.container}>
        {renderAddRow()}
        {renderHeader()}
        {renderRows(rows)}
      </div>
    </Modal>
  )
}

export default CreatePayrollFileRowsModal

const useStyles = createUseStyles({
  container: {
    minHeight: 400,
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
    alignItems: 'end',
    paddingBottom: 20
  },
  employeeSelectorContainer: {
    flex: 2
  },
  formFieldContainer: {
    flex: 1,
    minWidth: 130
  },
  listItemContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
    gap: 6
  },
  smallCellContainer: {
    width: 100
  },
  defaultCellContainer: {
    width: 140
  },
  nameCellContainer: {
    width: 200
  },
  buttonContainer: {
    display: 'flex',
    width: 80,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'center',
    paddingLeft: 0
  },
  button: {
    marginRight: 15,
    transition: '0.4s',
    '&:hover': {
      color: Colors.euro_600,
      cursor: 'pointer',
    }
  }
})
