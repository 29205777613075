import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { ForaAdjustmentPeriodSummary } from "../../redux/types"
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Colors } from '../../constants/colors'

interface Props {
    periods: ForaAdjustmentPeriodSummary[]
    pageIndex: number
    pageSize: number
    onPageChange: (pageIndex: number) => void
    onRowClick: (period: string) => void
}

const ForaAdjustmentPeriodList = ({ periods, pageIndex, pageSize, onPageChange, onRowClick } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'foraAdjustment']);

    const itemsOnCurrentPage = useMemo(() => {
        const firstPageIndex = (pageIndex - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return periods.slice(firstPageIndex, lastPageIndex);
    }, [periods, pageIndex, pageSize])

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('period', {ns: 'common'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('adjustmentCount', {ns: 'foraAdjustment'})}</strong>
                </div>
                <div className={styles.iconContainer}>
                </div>
            </ListItemCard>
        )
    }

    const renderListItems = () => {
        return itemsOnCurrentPage?.map((period, index) => {
            return (
                <ListItemCard key={`period${index}`} className={styles.listItemContainer} index={index} onClick={() => onRowClick(period.period)}>
                    <div className={styles.defaultCellContainer}>
                        {period.period}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {period.adjustmentCount}
                    </div>
                    <div className={styles.iconContainer}>
                        <div>
                            <FontAwesomeIcon
                                icon={faChevronRight}
                                color={Colors.gray1}
                            />
                        </div>
                    </div>
                </ListItemCard>
            )
         })
    }

    return (
        <div>
           {renderHeader()}
           {renderListItems()}
           <div className={styles.pagination}>
            <Pagination
                pageIndex={pageIndex}
                itemCount={periods.length}
                pageSize={pageSize}
                siblingCount={1}
                onPageChange={onPageChange}
            />
            </div>
        </div>
    )
}

export default ForaAdjustmentPeriodList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    defaultCellContainer: {
        width: 140
    },
    iconContainer: {
        display: 'flex',
        width: 50,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center',
        paddingLeft: 0,
        marginRight: 10
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
  })
