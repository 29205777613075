import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import InfoIcon from './InfoIcon'
import { MultiSelect, Option } from 'react-multi-select-component'
import { useTranslation } from 'react-i18next'

interface Props {
    id?: string
    label?: string
    options: SelectOption[]
    selectedValues?: string[]
    tooltipText?: string
    onSelect: (values: string[]) => void 
    valid?: boolean
    disabled?: boolean
    mandatory?: boolean
    className?: string
    style?: React.CSSProperties | undefined
}

export interface SelectOption {
    name: string
    value: string
}

const Multiselect = ({ 
    id = "multiselect",
    label,
    options = [],
    selectedValues,
    tooltipText,
    onSelect,
    valid = true,
    disabled = false,
    mandatory,
    className, 
    style
} : Props) => {
    const styles = useStyles()
    const { t } = useTranslation('common');

    const handleOnChange = (options: Option[]) => {
        const values = options.map((item) => {return item.value})
        onSelect && onSelect(values)
    }

    const renderLabel = () => {
        return label && (
            <div>
                <label className={styles.label}>{label}</label>
                {mandatory && <label className={styles.mandatoryIndicator}>*</label>}
                {tooltipText && <InfoIcon text={tooltipText} />}
            </div>
        )
    }
    
    return (
        <div className={`${styles.container} ${className}`} style={style}>
            {renderLabel()}
            <div className={`${styles.inputContainer}`} style={{borderColor: valid ? Colors.euro_600 : Colors.rubel_700, backgroundColor: disabled ? Colors.grayDisabled : Colors.grayCard}}>
                <MultiSelect
                    className={styles.multiSelectInput}
                    hasSelectAll={true}
                    labelledBy=''
                    ClearSelectedIcon={null}
                    options={options.map((item) => { return {label: item.name, value: item.value, disabled: false}})}
                    value={options.map((item) => { return {label: item.name, value: item.value, disabled: false}}).filter(x => selectedValues?.includes(x.value) ?? false) ?? []}
                    onChange={(selectedItems: any) => handleOnChange(selectedItems)}
                    disabled={disabled}
                    overrideStrings={{
                        "allItemsAreSelected": t('allItemsAreSelected'),
                        "clearSearch": t('clearSearch'),
                        "noOptions": t('noOptions'),
                        "search": t('search'),
                        "selectAll": t('selectAll'),
                        "selectAllFiltered": t('selectAllFiltered'),
                        "selectSomeItems": t('selectSomeItems')
                    }}
                />
            </div>
        </div>
    )
}

export default Multiselect

const useStyles = createUseStyles({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    inputContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        width: '100%',
        alignItems: 'center',
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.euro_600,
        backgroundColor: Colors.grayCard,
    },
    multiSelectInput: {
        width: '100%',
        height: 40,
        backgroundColor: 'transparent',
        border: 'none',
        '&:focus': {
            border: 'none',
            outline: 'none',
        },
        '&:active': {
            border: 'none',
            outline: 'none',
        },
    },
    label: {
        marginBottom: 3,
        marginRight: 4
    },
    mandatoryIndicator: {
        color: Colors.rubel_700,
        marginRight: 4
    },
  })
  