import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { DayOfWeek, Employer } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { loading, employerFetched, employerFetchFailure, activationNotificationDaysOfWeekChanged, activationNotificationDaysOfWeekChangeFailure, invoiceEmailAddressChanged, invoiceEmailAddressChangeFailure } from "../redux/slices/employerSlice";

const useEmployerActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getEmployer = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.employer)
            .then(response => {
                dispatch(employerFetched(response.data as Employer))
            })
            .catch(error => {
                console.log('getEmployer error:', error)
                dispatch(employerFetchFailure(error))
            })
    }

    const setActivationNotificationDaysOfWeek = (daysOfWeek: DayOfWeek[]) => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.employer + 'activationNotifications', { daysOfWeek: daysOfWeek })
            .then(response => {
                dispatch(activationNotificationDaysOfWeekChanged(response.data as Employer))
            })
            .catch(error => {
                console.log('setActivationNotificationDaysOfWeek error:', error)
                dispatch(activationNotificationDaysOfWeekChangeFailure(error))
            })
    }

    const setInvoiceEmailAddress = (email: string) => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.employer + 'invoiceEmailAddress', { invoiceEmailAddress: email })
            .then(response => {
                dispatch(invoiceEmailAddressChanged(response.data as Employer))
            })
            .catch(error => {
                console.log('setInvoiceEmailAddress error:', error)
                dispatch(invoiceEmailAddressChangeFailure(error))
            })
    }

    return {
        getEmployer,
        setActivationNotificationDaysOfWeek,
        setInvoiceEmailAddress
    }
}

export default useEmployerActions