import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PagedShifts, Shift, ShiftListFilter } from '../types'

interface ShiftState {
    loading: boolean
    shiftsOnPage: Shift[]
    totalShiftCount: number
    pageIndex: number
    pageSize: number    
    filter: ShiftListFilter | null
    error: Error | string | null
}

const initialState: ShiftState = {
    loading: false,
    shiftsOnPage: [],
    totalShiftCount: 0,
    pageIndex: 1,
    pageSize: 15,
    filter: null,
    error: null
}

export const shiftSlice = createSlice({
    name: 'shift',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        shiftsFetched: (state, action: PayloadAction<PagedShifts>) => {
            state.loading = false
            state.shiftsOnPage = action.payload.shifts
            state.totalShiftCount = action.payload.totalShiftCount
            state.error = null
        },
        shiftsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        shiftCreated: (state, action: PayloadAction<Shift>) => {
            state.loading = false
            state.shiftsOnPage = [...state.shiftsOnPage, action.payload]
            state.error = null
        },
        shiftCreateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        shiftUpdated: (state, action: PayloadAction<Shift>) => {
            const shiftsOnPageClone = [...state.shiftsOnPage]
            const indexToUpdate = state.shiftsOnPage.findIndex(x => x.id === action.payload.id)

            if (indexToUpdate !== -1)
                shiftsOnPageClone[indexToUpdate] = action.payload

            state.loading = false
            state.shiftsOnPage = shiftsOnPageClone
            state.error = null
        },
        shiftUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        shiftDeleted: (state, action: PayloadAction<Shift>) => {
            state.loading = false
            state.shiftsOnPage = state.shiftsOnPage.filter(x => x.id !== action.payload.id)
            state.error = null
        },
        shiftDeleteFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        shiftsBulkUploaded: (state) => {
            state.loading = false
            state.error = null
        },
        shiftsBulkUploadFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<ShiftListFilter>) => {
            state.filter = action.payload
            state.pageIndex = 1
        },
        errorHandled: (state) => {
            state.error = null    
        }
    }
})

export const { 
    loading, 
    shiftsFetched, 
    shiftsFetchFailure, 
    shiftCreated, 
    shiftCreateFailure, 
    shiftUpdated, 
    shiftUpdateFailure, 
    shiftDeleted, 
    shiftDeleteFailure, 
    shiftsBulkUploaded, 
    shiftsBulkUploadFailure, 
    pageIndexChanged, 
    filterChanged, 
    errorHandled 
} = shiftSlice.actions

export default shiftSlice.reducer