import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Button from "./Button";
import Modal from "./Modal"
import UploadFileModal from "./UploadFileModal"

interface Props {
    onGenerateClick: () => void
    onCancelClick: () => void
    onEmployeeFileUpload: (file: File) => void
    visible: boolean
    payoutBasisGenerationEnabled: boolean
    earliestPayoutBasisGenerationDayOfMonth: number
    employeeFileUploadSuccess: boolean
    year: number | null
    month: number | null
}

const GeneratePayoutBasisModal = ({ onGenerateClick, onCancelClick, onEmployeeFileUpload, visible, payoutBasisGenerationEnabled, earliestPayoutBasisGenerationDayOfMonth, employeeFileUploadSuccess, year, month } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['payoutBasis', 'common']);
    
    const [fileUploadVisible, setFileUploadVisible] = useState<boolean>(false)

    const handleEmployeeFileUploaded = (file: File) => {
        setFileUploadVisible(false)
        onEmployeeFileUpload(file)
    }

    const getFormattedMonth = (year: number, month: number) => {
        return t(`month${month}`, {ns: 'common'}) + ' ' + year
    }

    const renderEnabledContent = () => {
        return (
            <div className={styles.container}>
                <h4>
                    {getFormattedMonth(year as number, month as number)}
                </h4>
                <div>
                    {t('generatePayoutBasisGuide', {ns: 'payoutBasis'})}                
                </div>
                <div className={styles.buttonContainer}>
                    <Button title={t('uploadEmployeeFile', {ns: 'payoutBasis'})} onClick={() => setFileUploadVisible(true)} disabled={employeeFileUploadSuccess} />
                </div>
            </div>
        )    
    }

    const renderDisabledContent = () => {
        return (
            <div>
                <p>
                    {earliestPayoutBasisGenerationDayOfMonth > 0 
                        ? t('payoutBasisGenerationNotPossibleDayOfMonth', {ns: 'payoutBasis', dayOfMonth: earliestPayoutBasisGenerationDayOfMonth})
                        : t('payoutBasisGenerationNotPossibleMonthEnd', {ns: 'payoutBasis'})
                    }
                </p>
            </div>
        )    
    }

    return (
        <>
        <Modal
            title={t('generatePayoutBasis', {ns: 'payoutBasis'})}
            visible={visible && !fileUploadVisible}
            okButtonTitle={t('generatePayoutBasis', {ns: 'payoutBasis'})}
            cancelButtonTitle={t('cancel', {ns: 'common'})}
            onHide={() => onCancelClick()}
            onOkClick={() => onGenerateClick()}
            onCancelClick={() => onCancelClick()}
            okButtonDisabled={!payoutBasisGenerationEnabled || !employeeFileUploadSuccess}
        >
            {payoutBasisGenerationEnabled && renderEnabledContent()}    
            {!payoutBasisGenerationEnabled && renderDisabledContent()}    
        </Modal>
        <UploadFileModal 
            visible={fileUploadVisible}
            multiFileUpload={false}
            title={t('uploadEmployeeFile', {ns: 'payoutBasis'})}
            infoText={t('uploadEmployeeFileDescription', {ns: 'payoutBasis'})}
            onCancelClick={() => setFileUploadVisible(false)}
            onUpload={(file) => handleEmployeeFileUploaded(file[0])}
        />
    </>
    ) 
}

export default GeneratePayoutBasisModal

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20 
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 30
    }
})