import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import {PayRun, PayrollData, UpcomingPayroll, UploadPayrollFileRowsModel} from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import {
    clearPayRuns,
    loading,
    offCyclePayrollFileDeleted,
    offCyclePayrollFileDeleteFailure,
    offCyclePayrollFileUploaded,
    offCyclePayrollFileUploadFailure,
    payrollDataFetched,
    payrollDataFetchFailure,
    payrollFileDeleted,
    payrollFileDeleteFailure,
    payRunsFetched,
    payRunsFetchFailure,
    payrollFileUploaded,
    payrollFileUploadFailure
} from "../redux/slices/payrollSlice";

const usePayrollActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getPayrollData = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.payroll)
            .then(response => {
                dispatch(payrollDataFetched(response.data as PayrollData))
            })
            .catch(error => {
                console.log('getPayrollData error:', error)
                dispatch(payrollDataFetchFailure(error))
            })
    }

    const getPayRunsForPayday = (paydayId: string) => {
        dispatch(loading())
        dispatch(clearPayRuns())

        return fetchClient.get(apiConfig.routes.payroll + `payruns/${paydayId}`)
            .then(response => {
                dispatch(payRunsFetched(response.data as PayRun[]))
            })
            .catch(error => {
                console.log('getPayRunsForPayday error:', error)
                dispatch(payRunsFetchFailure(error))
            })
    }

    const uploadPayrollFile = (externalId: string, payday: string, file: File) => {
        dispatch(loading())

        return fetchClient.postFile(apiConfig.routes.payroll + `file/${payday}/${externalId}`, file)
            .then(response => {
                dispatch(payrollFileUploaded(response.data as UpcomingPayroll))
            })
            .catch(error => {
                console.log('uploadPayrollFile error:', error)
                dispatch(payrollFileUploadFailure(error))
            })
    }

    const uploadPayrollFileRows = (externalId: string, payday: string, uploadPayrollFileRowsModel: UploadPayrollFileRowsModel) => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.payroll + `payrollFileRows/${payday}/${externalId}`, uploadPayrollFileRowsModel)
          .then(response => {
              dispatch(payrollFileUploaded(response.data as UpcomingPayroll))
          })
          .catch(error => {
              console.log('uploadPayrollFileRows error:', error)
              dispatch(payrollFileUploadFailure(error))
          })
    }

    const deletePayrollFile = (externalId: string, payday: string) => {
        dispatch(loading())

        return fetchClient.delete(apiConfig.routes.payroll + `file/${payday}/${externalId}`)
            .then(response => {
                dispatch(payrollFileDeleted(response.data as PayrollData))
            })
            .catch(error => {
                console.log('deletePayrollFile error:', error)
                dispatch(payrollFileDeleteFailure(error))
            })
    }

    const uploadOffCyclePayrollFile = (externalId: string, payday: string, file: File) => {
        dispatch(loading())

        return fetchClient.postFile(apiConfig.routes.payroll + `offcyclefile/${payday}/${externalId}`, file)
            .then(response => {
                dispatch(offCyclePayrollFileUploaded(response.data as PayrollData))
            })
            .catch(error => {
                console.log('uploadOffCyclePayrollFile error:', error)
                dispatch(offCyclePayrollFileUploadFailure(error))
            })
    }

    const uploadOffCyclePayrollFileRows = (externalId: string, payday: string, uploadPayrollFileRowsModel: UploadPayrollFileRowsModel) => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.payroll + `offcyclerows/${payday}/${externalId}`, uploadPayrollFileRowsModel)
          .then(response => {
              dispatch(offCyclePayrollFileUploaded(response.data as PayrollData))
          })
          .catch(error => {
              console.log('uploadOffCyclePayrollFile error:', error)
              dispatch(offCyclePayrollFileUploadFailure(error))
          })
    }

    const deleteOffCyclePayrollFile = (externalId: string, payday: string) => {
        dispatch(loading())

        return fetchClient.delete(apiConfig.routes.payroll + `offcyclefile/${payday}/${externalId}`)
            .then(response => {
                dispatch(offCyclePayrollFileDeleted(response.data as PayrollData))
            })
            .catch(error => {
                console.log('deleteOffCyclePayrollFile error:', error)
                dispatch(offCyclePayrollFileDeleteFailure(error))
            })
    }

    return {
        getPayrollData,
        getPayRunsForPayday,
        uploadPayrollFile,
        deletePayrollFile,
        uploadOffCyclePayrollFile,
        deleteOffCyclePayrollFile,
        uploadPayrollFileRows,
        uploadOffCyclePayrollFileRows
    }
}

export default usePayrollActions
