import { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import Card from './Card'

interface Props {
    title: string
    color?: string
    children: ReactNode
    className?: string
    style?: React.CSSProperties | undefined
}

const InfoCard = ({title, color, children, className, style}: Props) => {
    const styles = useStyles()

    return (
        <Card className={`${styles.infoCard} ${className}`} style={color != null ? {...style, backgroundColor: color} : {...style}}>
            <div className={styles.infoCardHeader}>
                <h4>{title}</h4>
            </div>
            <div style={{flex: 1}}>
                {children}
            </div>
            
        </Card>
    )
}

export default InfoCard

const useStyles = createUseStyles({
    infoCard: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
        marginBottom: 20
    },
    infoCardHeader: {
        color: Colors.euro_800
    },
})