import { apiConfig } from "../apiConfig"
import { useAppDispatch } from "../redux/hooks"
import useFetchClient from "./useFetchClient"
import { loading, authenticationSuccess, authenticationFailure, logout as logoutAction } from "../redux/slices/authUserSlice";
import { useNavigate } from "react-router";
import { AuthenticationData } from "../redux/types";
import { useTranslation } from "react-i18next";

const useAuthUserActions = () => {
    const url = `${apiConfig.routes.auth}`
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { i18n } = useTranslation();

    const login = (email: string, password: string, redirectTo?: string) => {
        dispatch(loading())

        return fetchClient.post(url, {email: email, password: password})
            .then(response => {
                const authResult = response.data as AuthenticationData
                
                localStorage.setItem('authToken', authResult.accessToken)
                dispatch(authenticationSuccess({regularLogin: true, data: authResult}))
                i18n.changeLanguage(authResult.user.language)

                navigate(redirectTo ? redirectTo : '/')
            })
            .catch(error => {
                console.log('Fetch error:', error)
                dispatch(authenticationFailure(error))
            })
    }

    const switchEmployer = (employerId: string) => {
        dispatch(loading())

        return fetchClient.post(url + `selectedemployer/${employerId}`)
            .then(response => {
                const authResult = response.data as AuthenticationData
                
                localStorage.setItem('authToken', authResult.accessToken)
                dispatch(authenticationSuccess({regularLogin: false, data: authResult}))

                window.location.reload();
            })
            .catch(error => {
                console.log('switchEmployer error:', error)
                dispatch(authenticationFailure(error))
            })    
    }

    const initialAuthenticatedUserRefetch = () => {
        dispatch(loading())

        return fetchClient.get(url)
            .then(response => {
                const authResult = response.data as AuthenticationData
                localStorage.setItem('authToken', authResult.accessToken) // refresh token
                dispatch(authenticationSuccess({regularLogin: false, data: authResult}))
            })
            .catch(error => {
                console.log('refetchAuthenticatedUser error:', error)
                localStorage.removeItem('authToken')
                dispatch(authenticationFailure(null))
            })    
    }

    const logout = () => {
        console.log('Logging out')
        dispatch(logoutAction())
        navigate('/login')
        localStorage.removeItem('authToken')
    }

    return {
        login, 
        initialAuthenticatedUserRefetch,
        switchEmployer,
        logout
    }
}

export default useAuthUserActions