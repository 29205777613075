import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { CreditUsage } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { creditUsageFetched, creditUsageFetchFailure, loading } from "../redux/slices/creditUsageSlice";

const useCreditUsageActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getCreditUsage = (days?: number) => {
        dispatch(loading())

        const url = `${apiConfig.routes.creditUsage}?days=${days ?? ''}` 

        return fetchClient.get(url)
            .then(response => {
                dispatch(creditUsageFetched(response.data as CreditUsage))
            })
            .catch(error => {
                console.log('getCreditUsage error:', error)
                dispatch(creditUsageFetchFailure(error))
            })
    }

    return {
        getCreditUsage
    }
}

export default useCreditUsageActions