import {createUseStyles} from "react-jss"
import Card from "../components/Card"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import {useTranslation} from "react-i18next"
import {apiConfig} from "../apiConfig"
import BackButton from "../components/BackButton"
import useExternalScript, {ExternalScriptState} from "../hooks/useExternalScript"
import {useNavigate, useSearchParams} from "react-router-dom";
import {useAppSelector} from "../redux/hooks";
import {isDateValid} from "../util/date";

declare global {
  // We register a global callback function that is called from the WASM code
  // using JSInterop, as we can't register a callback function directly.
  interface Window {
    OnPayrollFileProcessingComplete: () => void;
  }
}

const PayrollFileProcessor = () => {
  const [searchParams] = useSearchParams();
  const styles = useStyles()
  const {t} = useTranslation('payrollFileProcessor');
  const wasmScriptState = useExternalScript('_framework/blazor.webassembly.js?version=' + apiConfig.blazorAppVersion);
  const navigate = useNavigate()
  const upcomingPayrolls = useAppSelector(state => state.payroll.upcomingPayrolls)

  window.OnPayrollFileProcessingComplete = () => {
    navigate('/payroll?refresh=true')
  }

  const paymentDate = searchParams.get('payday') ?? upcomingPayrolls[0].paymentDate
  const validDate = isDateValid(paymentDate)

  return (
    <>
      <BackButton/>
      <Card className={styles.card}>
        <h2>
          {t('title')}
        </h2>
        <h6>
          {`${t('forPayday')} ${paymentDate}`}
        </h6>
      </Card>
      <Card>
        {wasmScriptState == ExternalScriptState.ready && validDate &&
            <payroll-file-processor api-base-url={apiConfig.baseUrl}
                                    auth-token={localStorage.getItem('authToken')}
                                    payday-date={paymentDate} />
        }
        <FullScreenLoadingIndicator visible={wasmScriptState === ExternalScriptState.loading}/>
      </Card>
    </>
  )
}

export default PayrollFileProcessor

const useStyles = createUseStyles({
  card: {
    marginBottom: 20
  },
  infoCardContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    alignItems: 'center'
  },
  button: {
    alignSelf: 'flex-end'
  }
})
