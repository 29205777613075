 import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import FullScreenLoadingIndicator from '../../components/FullScreenLoadingIndicator';
import useFortnoxIntegrationActions from '../../hooks/integrations/useFortnoxIntegrationActions';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { activationHandled } from '../../redux/slices/integrations/fortnoxIntegrationSlice';

const FortnoxActivation = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const { activateIntegration } = useFortnoxIntegrationActions()

  const loading = useAppSelector(state => state.fortnoxIntegration.loading)
  const activationUnhandled = useAppSelector(state => state.fortnoxIntegration.activationUnhandled)

  const code = (new URLSearchParams(window.location.search)).get("code")
  const state = (new URLSearchParams(window.location.search)).get("state")

  useEffect(() => {
    if (code && state) {
      activateIntegration(code, state)  
    }
  }, [])

  useEffect(() => {
    if (!activationUnhandled) {
      dispatch(activationHandled())   
      navigate(`/integrations`)  
    }
  }, [activationUnhandled])

  return (
    <div>
      <FullScreenLoadingIndicator visible={loading} />
    </div>
  )
}

export default FortnoxActivation
