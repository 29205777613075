import { useEffect, useState } from "react"
import { createUseStyles } from 'react-jss'
import { useAppSelector, useAppDispatch } from "../redux/hooks"
import { EditPayDeviationModel, PayDeviation, EmployeeAndDateRangeFilter, BulkOperationResult } from "../redux/types"
import Button from '../components/Button'
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import Card from "../components/Card"
import ConfirmModal from "../components/ConfirmModal"
import { pageIndexChanged, filterChanged, errorHandled } from "../redux/slices/payDeviationSlice"
import FilterComponent from "../components/EmployeeAndDateRangeFilter"
import useDidUpdateEffect from "../hooks/useDidUpdateEffect"
import { useTranslation } from "react-i18next"
import usePayDeviationActions from "../hooks/usePayDeviationActions"
import PayDeviationList from "../components/PayDeviationList/PayDeviationList"
import EditPayDeviationModal from "../components/EditPayDeviationModal"
import ErrorModal from "../components/ErrorModal"
import UploadFileModal from "../components/UploadFileModal"
import SuccessModal from "../components/SuccessModal"

const Deviations = () => {
    const styles = useStyles()
    const { t } = useTranslation(['deviations', 'common']);
    const dispatch = useAppDispatch()

    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState<boolean>(false)
    const [addModalVisible, setAddModalVisible] = useState<boolean>(false)
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
    const [uploadCsvFileModalVisible, setUploadCsvFileModalVisible] = useState<boolean>(false)
    const [errorModalVisible, setErrorModalVisible] = useState<boolean>(false)
    const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false)

    const [bulkOperationResult, setBulkOperationResult] = useState<BulkOperationResult>()

    const [editedPayDeviation, setEditedPayDeviation] = useState<EditPayDeviationModel>()
    const [deleteCandidate, setDeleteCandidate] = useState<PayDeviation>() 

    const { getPayDeviations, createPayDeviation, deletePayDeviation, updatePayDeviation, bulkUploadPayDeviations } = usePayDeviationActions()
    const loading = useAppSelector(state => state.payDeviations.loading)
    const deviationsOnPage = useAppSelector(state => state.payDeviations.deviationsOnPage)
    const totalDeviationCount = useAppSelector(state => state.payDeviations.totalCount)
    const pageIndex = useAppSelector(state => state.payDeviations.pageIndex)
    const pageSize = useAppSelector(state => state.payDeviations.pageSize)
    const filter = useAppSelector(state => state.payDeviations.filter)
    const error = useAppSelector(state => state.shift.error)

    useEffect(() => {
        if (deviationsOnPage.length === 0)
            getPayDeviations(filter?.employeeId ?? undefined, filter?.fromDate ?? undefined, filter?.toDate ?? undefined, pageIndex, pageSize)
    }, [])

    useDidUpdateEffect(() => {
        getPayDeviations(filter?.employeeId ?? undefined, filter?.fromDate ?? undefined, filter?.toDate ?? undefined, pageIndex, pageSize)
    }, [filter, pageIndex])

    useDidUpdateEffect(() => {
        if (error) {
            setErrorModalVisible(true)
        }
    }, [error])

    const handleError = () => {
        dispatch(errorHandled())   
        setErrorModalVisible(false)
    }

    const onAddClick = () => {
        setAddModalVisible(true)
    }

    const handleAdded = (deviation: EditPayDeviationModel) => {
        createPayDeviation(deviation, () => {
            getPayDeviations(filter?.employeeId ?? undefined, filter?.fromDate ?? undefined, filter?.toDate ?? undefined, pageIndex, pageSize)
        })
        setAddModalVisible(false)   
    }

    const onEditClick = (deviation: PayDeviation) => {
        setEditedPayDeviation({
            id: deviation.id,
            externalId: deviation.externalId,
            externalEmployeeId: deviation.employee.externalId,
            employeeId: deviation.employee.id,
            employeeName: deviation.employee.name,
            employeeIdentificationNumber: deviation.employee.identificationNumber,
            grossAmount: deviation.grossAmount.amount,
            date: deviation.date,
            description: deviation.description
        })
        setEditModalVisible(true)
    }

    const handleEdited = (editModel: EditPayDeviationModel) => {
        updatePayDeviation(editModel)
        setEditedPayDeviation(undefined)
        setEditModalVisible(false)   
    }

    const handleEditCancelled = () => {
        setEditedPayDeviation(undefined)
        setEditModalVisible(false)   
    }

    const onDeleteClick = (deviation: PayDeviation) => {
        setDeleteCandidate(deviation)
        setConfirmDeleteModalVisible(true)      
    }

    const handleDeleteConfirmed = () => {
        if (deleteCandidate) {
            deletePayDeviation(deleteCandidate)
            setDeleteCandidate(undefined)
        }
        setConfirmDeleteModalVisible(false)     
    }

    const handleCsvFileUploaded = (file: File) => {
        bulkUploadPayDeviations(file, (result: BulkOperationResult) => {
            setBulkOperationResult(result)
            getPayDeviations(filter?.employeeId ?? undefined, filter?.fromDate ?? undefined, filter?.toDate ?? undefined, pageIndex, pageSize)
            setSuccessModalVisible(true)  
        })
        setUploadCsvFileModalVisible(false)
    }

    const onPageChange = (pageIndex: number) => {
        dispatch(pageIndexChanged(pageIndex))
    }

    const handleFilterChange = (filter: EmployeeAndDateRangeFilter) => {
        dispatch(filterChanged(filter))
    }

    return (
        <>
        <Card className={styles.card}>
            <h2>
                {t('title')}
            </h2>
        </Card>
        <Card>
            <div className={styles.buttonContainer}>
                <Button onClick={onAddClick} title={t('addDeviation', {ns: 'deviations'})} className={styles.button} />
                <Button onClick={() => setUploadCsvFileModalVisible(true)} title={t('upload', {ns: 'common'})} className={styles.button}  />
            </div>
            <div className={styles.filterContainer}>
                <FilterComponent
                    initialFilterValue={filter ?? undefined}
                    onFilterChange={handleFilterChange} 
                />
            </div>
            {deviationsOnPage && 
            <PayDeviationList 
                deviationsOnPage={deviationsOnPage}
                pageIndex={pageIndex}
                pageSize={pageSize} 
                totalCount={totalDeviationCount}
                onEditClick={onEditClick} 
                onDeleteClick={onDeleteClick}
                onPageChange={onPageChange} 
            />}
            <EditPayDeviationModal 
                key='addModal'
                visible={addModalVisible} 
                onCancelClick={() => setAddModalVisible(false)}
                onSaveClick={handleAdded}
            />
            <EditPayDeviationModal 
                key='editModal'
                initialValue={editedPayDeviation}
                visible={editModalVisible} 
                onCancelClick={handleEditCancelled}
                onSaveClick={handleEdited}
            />
            <UploadFileModal 
                visible={uploadCsvFileModalVisible}
                multiFileUpload={false}
                title={t('uploadCsvFile', {ns: 'deviations'})}
                infoText={t('uploadCsvFileDescription', {ns: 'deviations'})}
                onCancelClick={() => setUploadCsvFileModalVisible(false)}
                onUpload={files => handleCsvFileUploaded(files[0])}
            />
            <ConfirmModal      
                visible={confirmDeleteModalVisible}
                confirmText={t('confirmDelete', {ns: 'deviations'})}
                onConfirmClick={handleDeleteConfirmed}
                onCancelClick={() => setConfirmDeleteModalVisible(false)}
            />
            <ErrorModal      
                visible={errorModalVisible}
                errorText={error?.toString()}
                onCloseClick={handleError}
            />
            <SuccessModal 
                visible={successModalVisible}
                bulkOperationResult={bulkOperationResult}
                onCloseClick={() => setSuccessModalVisible(false)}
            />
            <FullScreenLoadingIndicator visible={loading} />
        </Card>
        </>
    )
} 

export default Deviations

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: 20,
        columnGap: 10
    },
    filterContainer: {
        marginBottom: 20,
    },
    button: {
        alignSelf: 'flex-end'
    }
})