import { useEffect, useState } from "react";

export enum ExternalScriptState {
    idle,
    loading,
    ready,
    error
  }

const useExternalScript = (url: string) => {
    let [state, setState] = useState<ExternalScriptState>(url ? ExternalScriptState.loading : ExternalScriptState.idle);

    useEffect(() => {
        if (!url) {
            setState(ExternalScriptState.idle);
            return;
        }
        let script = document.querySelector(`script[src="${url}"]`) as HTMLScriptElement;

        const handleScript = (e: any) => {
            setState(e.type === "load" ? ExternalScriptState.ready : ExternalScriptState.error)
        }

        if (!script) {
            script = document.createElement("script")
            script.type = "application/javascript"
            script.src = url
            script.async = true
            document.body.appendChild(script)
            script.addEventListener("load", handleScript)
            script.addEventListener("error", handleScript)
        }
        else {
            setState(ExternalScriptState.ready)
        }

        script.addEventListener("load", handleScript)
        script.addEventListener("error", handleScript)

        return () => {
            if (script) {
                script.removeEventListener("load", handleScript)
                script.removeEventListener("error", handleScript)
            }
        };
    }, [url])

    return state
};

export default useExternalScript
