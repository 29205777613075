import { createUseStyles } from "react-jss"
import Card from "../components/Card"
import Button from "../components/Button"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { useEffect } from "react"
import { pageIndexChanged } from "../redux/slices/agiAdjustmentSlice"
import ForaAdjustmentPeriodList from "../components/ForaAdjustment/ForaAdjustmentPeriodList"
import useForaAdjustmentActions from "../hooks/useForaAdjustmentActions"

const ForaAdjustments = () => {
    const styles = useStyles()
    const navigate = useNavigate()
    const { t } = useTranslation('foraAdjustment');
    const { getPeriods } = useForaAdjustmentActions()
    const dispatch = useAppDispatch()

    const loading = useAppSelector(state => state.foraAdjustment.loading)
    const periods = useAppSelector(state => state.foraAdjustment.periods)
    const currentPeriod = useAppSelector(state => state.foraAdjustment.currentPeriod)
    const isCurrentPeriodAdjusted = useAppSelector(state => state.foraAdjustment.isCurrentPeriodAdjusted)
    const pageIndex = useAppSelector(state => state.foraAdjustment.pageIndex)
    const pageSize = useAppSelector(state => state.foraAdjustment.pageSize)

    useEffect(() => {
        getPeriods()
    }, [])

    const onPageChange = (pageIndex: number) => {
        dispatch(pageIndexChanged(pageIndex))
    }

    const renderAdjustFora2023FileButton = () => {
        return (
          <div className={styles.buttonContainer}>
              <Button
                onClick={() => navigate('/foraAdjustment2023/create')}
                title={t('adjustFora2023')}
                className={styles.button}
              />
          </div>
        )
    }

    const renderAdjustForaFileButton = () => {
        return (
            <div className={styles.buttonContainer}>
                <Button
                    onClick={() => navigate('/foraAdjustment/create')}
                    title={t('adjustFora')}
                    className={styles.button}
                />
            </div>
        )
    }

    return (
        <>
        <Card className={styles.card}>
            <h2>
                {t('title')}
            </h2>
        </Card>

        <Card className={`${styles.card} ${styles.adjustmentDueContainer}`}>
            <div style={{flex: 2}}>
                {t('adjustment2023', {currentPeriod: currentPeriod})}
            </div>
            {renderAdjustFora2023FileButton()}
        </Card>

        {!isCurrentPeriodAdjusted &&
        <Card className={`${styles.card} ${styles.adjustmentDueContainer}`}>
            <div>
                {t('adjustmentDue', {currentPeriod: currentPeriod})}
            </div>
            {renderAdjustForaFileButton()}
        </Card>
        }
        <Card>
            {isCurrentPeriodAdjusted &&
                renderAdjustForaFileButton()
            }
            {periods &&
            <ForaAdjustmentPeriodList
                periods={periods}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onPageChange={onPageChange}
                onRowClick={(period) => {navigate('/foraAdjustment/' + period)}}
            />}
            <FullScreenLoadingIndicator visible={loading} />
        </Card>
        </>
    )
}

export default ForaAdjustments

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    adjustmentDueContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    info: {
        marginTop: 20,
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 10
    },
    button: {
        alignSelf: 'flex-end'
    }
})
