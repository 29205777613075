import { FC, ReactNode } from 'react'
import { Modal as RBSModal } from 'react-bootstrap'
import { createUseStyles } from 'react-jss'
import { Fonts } from '../constants/fonts'
import Button from './Button'

interface ModalProps {
  title?: string
  okButtonTitle?: string,
  cancelButtonTitle?: string,
  children: ReactNode
  visible: boolean
  okButtonDisabled?: boolean
  hideCancelButton?: boolean
  size?: 'sm' | 'lg' | 'xl' | undefined
  onHide: () => void
  onOkClick: () => void
  onCancelClick: () => void
}

const Modal: FC<ModalProps> = ({
  children,
  title = 'Modal title',
  okButtonTitle = 'Save changes',
  cancelButtonTitle = 'Close',
  visible,
  okButtonDisabled = false,
  hideCancelButton = false,
  size,
  onHide,
  onOkClick: onSaveClick,
  onCancelClick,
}) => {

  const styles = useStyles()

  return (
    <RBSModal show={visible} onHide={onHide} scrollable={true} size={size}>
      <RBSModal.Header closeButton>
        <RBSModal.Title className={styles.modalTitle}>{title}</RBSModal.Title>
      </RBSModal.Header>
      <RBSModal.Body>{children}</RBSModal.Body>
      <RBSModal.Footer>
        {!hideCancelButton && <Button title={cancelButtonTitle} onClick={onCancelClick} variant='negative' />}
        <Button title={okButtonTitle} onClick={onSaveClick} disabled={okButtonDisabled} />
      </RBSModal.Footer>
    </RBSModal>
  )
}

export default Modal

const useStyles = createUseStyles({
  modalTitle: {
    fontFamily: Fonts.semiBold,
  },
})