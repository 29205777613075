import { useState } from "react"
import { ShiftListFilter as ShiftListFilterType, ShiftStatus } from "../../redux/types"
import { createUseStyles } from 'react-jss'
import { Breakpoints } from "../../constants/breakpoints"
import EmployeeSelector from "./../EmployeeSelector"
import { AutocompleteItem } from "./../AutocompleteField"
import DatePicker from "./../DatePicker"
import { isDateValid } from "../../util/date"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Colors } from "../../constants/colors"
import { useTranslation } from "react-i18next"
import Select from "../Select"

interface Props {
    initialFilterValue: ShiftListFilterType | undefined
    onFilterChange: (filter: ShiftListFilterType) => void
}

const ShiftListFilter = ({initialFilterValue, onFilterChange}: Props) => {
    const styles = useStyles()
    const { t } = useTranslation('common');
    const [filterValue, setFilterValue] = useState<ShiftListFilterType>(initialFilterValue ?? {employeeId: null, employeeName: null, fromDate: null, toDate: null, status: null})
    const [selectedStatus, setSelectedStatus] = useState<string | null>(initialFilterValue?.status ?? null)
    const allSelectOptions = t('allSelectOptions', {ns: 'common'})

    const handleEmployeeSelected = (employee: AutocompleteItem | null) => {
        const newFilter = {...filterValue, employeeId: employee?.value ?? null, employeeName: employee?.name ?? null}
        setFilterValue(newFilter)
        onFilterChange(newFilter)
    }
    
    const handleDateChanged = (propName: string, date: string | null | undefined) => {
        if (!date || date.length < 1) {
            const newFilter = {...filterValue, [propName]: null}
            setFilterValue(newFilter)
            onFilterChange(newFilter)   
        }

        if (date && isDateValid(date)) {
            const newFilter = {...filterValue, [propName]: date}
            setFilterValue(newFilter)
            onFilterChange(newFilter)
        }
    }

    const handleSelectedStatus = (value: string) => {
        var status: ShiftStatus | null = null
        if (value !== allSelectOptions) 
            status = value as ShiftStatus
        
        const newFilter = {...filterValue, status: status}
        setFilterValue(newFilter)
        setSelectedStatus(status)
        onFilterChange(newFilter)
    }

    const clearFilters = () => {
        const newFilter = {employeeId: null, employeeName: null, fromDate: null, toDate: null, status: null}
        setSelectedStatus(allSelectOptions)
        setFilterValue(newFilter)
        onFilterChange(newFilter)    
    }

    const getStatusOptions = () => {
        let values = Object.values(ShiftStatus).map((value) => value.toString())
        const options = values.map((value) => { return { name: t(`status_${value}`, {ns: 'shifts'}), value: value }})
        options.unshift({name: allSelectOptions, value: allSelectOptions})
        return options
    }

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <EmployeeSelector
                    externalIdAsValue={false}
                    includeTerminatedEmployees={true}
                    value={filterValue?.employeeName ?? ''}
                    onSelected={handleEmployeeSelected}
                />
            </div>
            <div className={styles.field}>
                <DatePicker 
                    id='fromDate'
                    label={t('fromDate')}
                    value={filterValue.fromDate ?? ''}
                    onChange={(value) => handleDateChanged('fromDate', value)}
                />   
            </div>
            <div className={styles.field}>
                <DatePicker 
                    id='toDate'
                    label={t('toDate')}
                    value={filterValue.toDate ?? ''}
                    onChange={(value) => handleDateChanged('toDate', value)}
                />   
            </div>
            <div className={styles.field}>
                <Select 
                    id='status'
                    key='status'
                    label={t('status', {ns: 'common'})}
                    options={getStatusOptions()} 
                    selectedValue={selectedStatus ?? undefined}
                    onSelect={handleSelectedStatus}
                />
            </div>
            <div className={styles.clearFiltersContainer}>
                <div className={styles.clearFiltersButton} onClick={() => clearFilters()} title={t('clearFilters')}>
                    <FontAwesomeIcon
                        size='lg'
                        icon={faTimes}
                        color={Colors.gray1}
                    />
                </div>
            </div>
        </div>
    )
}

export default ShiftListFilter

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'flex-start',
    },
    field: {
        width: '100%',    
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            width: 280,
            marginRight: 30,
        },
    },
    clearFiltersContainer: {
       alignSelf: 'flex-end' 
    },
    clearFiltersButton: {
        marginBottom: 8,
        '&:hover': {
            cursor: 'pointer',
        }
    }
  })