import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { Breakpoints } from '../../constants/breakpoints'
import { Colors } from "../../constants/colors"
import { Fonts } from '../../constants/fonts'
import { PaySchedulePaydayModel } from "../../redux/types"
import ListItemCard from "../ListItemCard"

interface Props {
    paydays: PaySchedulePaydayModel[]
    onEditClick: (payday: PaySchedulePaydayModel) => void
}

const PaydayList = ({ paydays, onEditClick } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'paySchedules']);

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.paydayCellContainer}>
                    <strong>{t('payday', {ns: 'paySchedules'})}</strong>
                </div> 
                <div className={`${styles.periodCellContainer} ${styles.mediumHidden}`}>
                    <strong>{t('wagesPeriod', {ns: 'paySchedules'})}</strong>
                </div>
                <div className={`${styles.periodCellContainer} ${styles.mediumHidden}`}>
                    <strong>{t('salaryPeriod', {ns: 'paySchedules'})}</strong>
                </div>
                <div className={`${styles.periodCellContainer} ${styles.mediumHidden}`}>
                    <strong>{t('deviationPeriod', {ns: 'paySchedules'})}</strong>
                </div>
                <div className={styles.badgeContainer}>
                    <strong>{t('status', {ns: 'common'})}</strong>
                </div>
                <div className={styles.buttonContainer}>
                   
                </div>  
            </ListItemCard>
        )    
    }

    const renderListItems = () => {
        return paydays?.map((payday, index) => {
            return (
                <ListItemCard key={`payday${index}`} className={styles.listItemContainer} index={index}>
                    <div className={styles.paydayCellContainer}>
                        {payday.paymentDate}
                    </div> 
                    <div className={`${styles.periodCellContainer} ${styles.mediumHidden}`}>
                        {payday.wagesPeriodStartDate} - {payday.wagesPeriodEndDate}
                    </div> 
                    <div className={`${styles.periodCellContainer} ${styles.mediumHidden}`}>
                        {payday.salaryPeriodStartDate} - {payday.salaryPeriodEndDate}
                    </div> 
                    <div className={`${styles.periodCellContainer} ${styles.mediumHidden}`}>
                        {payday.deviationPeriodStartDate} - {payday.deviationPeriodEndDate}
                    </div> 
                    <div className={styles.badgeContainer}>
                        {renderBadges(payday)}
                    </div>
                    <div className={styles.buttonContainer}>
                        {!payday.isReadOnly && 
                        <div>
                            <FontAwesomeIcon
                                className={styles.button}
                                icon={faEdit}
                                color={Colors.gray1}
                                title={t('edit', {ns: 'common'})}
                                onClick={() => onEditClick && onEditClick(payday)}
                            />
                        </div>
                        }
                    </div>  
                </ListItemCard>
            ) 
         })
    }

    const renderBadges = (payday: PaySchedulePaydayModel) => {
        return (
            <>
            {!payday.isReadOnly &&
                <div key={`payday${payday.id}open`} className={styles.badge} style={{backgroundColor: Colors.euro_800}}>
                    <span className={styles.badgeText}>{t('open', {ns: 'common'})}</span>
                </div>
            } 
            {payday.isReadOnly &&
                <div key={`payday${payday.id}readOnly`} className={styles.badge} style={{backgroundColor: Colors.gray2}}>
                    <span className={styles.badgeText}>{t('locked', {ns: 'common'})}</span>
                </div>
            }
            {payday.isShared &&
                <div key={`payday${payday.id}shared`} className={styles.badge} style={{backgroundColor: Colors.gray3}} title={t('sharedPaydayDescription', {ns: 'paySchedules'})}>
                    <span className={styles.badgeText}>{t('sharedPayday', {ns: 'paySchedules'})}</span>
                </div>
            }
            </>
        )
    }

    return (
        <>
           {renderHeader()}
           {renderListItems()}
        </>
    )
}

export default PaydayList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    paydayCellContainer: {
        width: 100,
        fontWeight: 'bold'
    },
    periodCellContainer: {
        width: 200
    },
    buttonContainer: {
        display: 'flex',
        width: 80,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center',   
        paddingLeft: 0
    },
    badgeContainer: {
        display: 'flex',
        width: 170,
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    },
    badge: {
        height: '20px',
        paddingLeft: 7,
        paddingRight: 7,
        borderRadius: 20,
        backgroundColor: Colors.euro_800,
        margin: 2
    },
    badgeText: {
        display: 'block',
        fontSize: 14,
        color: Colors.gray4,
        fontFamily: Fonts.regular, 
        lineHeight: '21px'   
    },
    button: {
        marginRight: 15,
        transition: '0.4s',
        '&:hover': {
          color: Colors.euro_600,
          cursor: 'pointer',
        }
    },
    smallHidden: {
        display: 'none',
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            display: 'flex'
        },
    },
    mediumHidden: {
        display: 'none',
        [`@media (min-width: ${Breakpoints.desktop}px)`]: {
            display: 'flex'
        },
    },
  })