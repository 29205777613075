import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next';
import Modal from './Modal'

interface Props {
    title?: string
    errorText?: string
    children?: ReactNode
    onCloseClick?: () => void
    visible: boolean
}

const ErrorModal = ({
    title,
    errorText,
    children,    
    onCloseClick,
    visible
}: Props) => {
    const { t } = useTranslation('common');

    const handleClose = () => {
        onCloseClick && onCloseClick()
    }

    return (
        <Modal
            title={title ?? t('error')}
            visible={visible}
            okButtonTitle={t('close')}
            hideCancelButton={true}
            onHide={() => handleClose()}
            onOkClick={() => handleClose()}
            onCancelClick={() => handleClose()}
        >
            {errorText && <h6>{errorText}</h6>}
            {children && children}
        </Modal>
    )
}

export default ErrorModal
