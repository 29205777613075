import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Employer } from '../types'

interface EmployerState {
    loading: boolean
    employer: Employer | null
    error: Error | string | null
}

const initialState: EmployerState = {
    loading: false,
    employer: null,
    error: null
}

export const employerSlice = createSlice({
    name: 'employer',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        employerFetched: (state, action: PayloadAction<Employer>) => {
            state.loading = false
            state.employer = action.payload
            state.error = null
        },
        employerFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        activationNotificationDaysOfWeekChanged: (state, action: PayloadAction<Employer>) => {
            state.loading = false
            state.employer = action.payload
            state.error = null
        },
        activationNotificationDaysOfWeekChangeFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        invoiceEmailAddressChanged: (state, action: PayloadAction<Employer>) => {
            state.loading = false
            state.employer = action.payload
            state.error = null
        },
        invoiceEmailAddressChangeFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { 
    loading, 
    employerFetched, 
    employerFetchFailure,
    activationNotificationDaysOfWeekChanged, 
    activationNotificationDaysOfWeekChangeFailure,
    invoiceEmailAddressChanged,
    invoiceEmailAddressChangeFailure
} = employerSlice.actions

export default employerSlice.reducer