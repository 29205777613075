import { createSlice } from '@reduxjs/toolkit'

interface AppState {
    sidebarCollapsed: boolean
    initialDataFetchTriggered: boolean
}

const initialState: AppState = {
    sidebarCollapsed: false,
    initialDataFetchTriggered: false
}

export const appStateSlice = createSlice({
    name: 'appState',
    initialState,
    reducers: {
        sidebarStateToggle: state => {
            state.sidebarCollapsed = !state.sidebarCollapsed
        },
        initialDataFetchTriggered: state => {
            state.initialDataFetchTriggered = true
        },
    }
})

export const { 
    sidebarStateToggle, 
    initialDataFetchTriggered 
} = appStateSlice.actions

export default appStateSlice.reducer