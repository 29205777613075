import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Breakpoints } from '../../constants/breakpoints'
import { Colors } from "../../constants/colors"
import { Fonts } from '../../constants/fonts'
import { Employee, EmployeeStatus } from "../../redux/types"
import { formatDateString } from '../../util/date'
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'
import Badge from '../Badge'
import { useTranslation } from 'react-i18next'
import InfoIcon from '../InfoIcon'

interface Props {
    employees: Employee[]
    pageIndex: number
    pageSize: number
    onEditClick: (employee: Employee) => void
    onPageChange: (pageIndex: number) => void
}

const EmployeeList = ({ employees, pageIndex, pageSize, onEditClick, onPageChange } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'employees']);

    const itemsOnCurrentPage = useMemo(() => {
        const firstPageIndex = (pageIndex - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return employees.slice(firstPageIndex, lastPageIndex);
    }, [employees, pageIndex, pageSize])

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.smallCellContainer}>
                    <strong>{t('id', {ns: 'common'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('idNumber', {ns: 'common'})}</strong>
                </div>
                <div className={styles.nameCellContainer}>
                    <strong>{t('name', {ns: 'common'})}</strong>
                </div>
                <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                    <strong>{t('category', {ns: 'common'})}</strong>
                </div>
                <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                    <strong>{t('department', {ns: 'common'})}</strong>
                </div>
                <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                    <strong>{t('activationDate', {ns: 'common'})}</strong>
                </div>
                <div className={`${styles.statusContainer} ${styles.smallHidden}`}>
                    <strong>{t('status', {ns: 'common'})}</strong>
                </div>
                <div className={`${styles.statusContainer} ${styles.smallHidden}`}>
                    <strong>{t('flags', {ns: 'common'})}</strong>
                </div>
                <div className={styles.buttonContainer}>

                </div>
            </ListItemCard>
        )
    }

    const renderListItems = () => {
        return itemsOnCurrentPage?.map((employee, index) => {
            return (
                <ListItemCard key={`employee${index}`} className={styles.listItemContainer} index={index}>
                    <div className={styles.smallCellContainer}>
                        {employee.externalId}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {employee.identificationNumber}
                    </div>
                    <div className={styles.nameCellContainer}>
                        {employee.givenName} {employee.familyName}
                    </div>
                    <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                        {employee.category}
                    </div>
                    <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                        {employee.department}
                    </div>
                    <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                        {formatDateString(employee.activationDate)}
                    </div>
                    <div className={`${styles.statusContainer} ${styles.smallHidden}`}>
                        {renderStatusBadge(employee)}
                    </div>
                    <div className={`${styles.statusContainer} ${styles.smallHidden}`}>
                        {renderFlagBadges(employee)}
                    </div>
                    <div className={styles.buttonContainer}>
                        <div>
                            <FontAwesomeIcon
                                className={styles.button}
                                icon={faEdit}
                                color={Colors.gray1}
                                title={t('edit', {ns: 'common'})}
                                onClick={() => onEditClick && onEditClick(employee)}
                            />
                        </div>
                    </div>
                </ListItemCard>
            )
         })
    }

    const renderStatusBadge = (employee: Employee) => {
        return (
            <Badge
                text={t(`employeeStatus_${employee.status}`, {ns: 'employees'})}
                backgroundColor={getStatusColor(employee.status)}
                textColor={getStatusTextColor(employee.status)}
                tooltip={t(`employeeStatusDescription_${employee.status}`, {ns: 'employees'})}
            />
        )
    }

    const getStatusColor = (status: EmployeeStatus) => {
        switch (status) {
            case 'registered': return Colors.euro_50
            case 'connected': return Colors.euro_300
            case 'active': return Colors.euro_800
            case 'paused': return Colors.yen_300
            case 'terminated': return Colors.yuan_100
        }
    }

    const getStatusTextColor = (status: EmployeeStatus) => {
        switch (status) {
            case 'registered': return Colors.gray0
            case 'connected': return Colors.gray0
            case 'active': return Colors.gray4
            case 'paused': return Colors.gray0
            case 'terminated': return Colors.rubel_700
        }
    }

  const renderFlagBadges = (employee: Employee) => {
    return (
      <>
        {employee.underForeclosure &&
            <Badge
                text={t('underForeclosure', {ns: 'employees'})}
                backgroundColor={Colors.gray3}
                textColor={Colors.gray4}
            />
        }
        {employee.nonEligible &&
            <Badge
                text={t('nonEligible', {ns: 'employees'})}
                backgroundColor={Colors.gray3}
                textColor={Colors.gray4}
            />
        }
        {(employee.payoutSettings?.maxAmountPerPayday != null || employee.payoutSettings?.maxPercentagePerPayday != null || employee.payoutSettings?.maxCustomPercentagePerPayday != null) &&
            <Badge
                text={t('payoutLimitation', {ns: 'employees'})}
                backgroundColor={Colors.gray3}
                textColor={Colors.gray4}
            />
        }
        {employee.hasUnsettledPayouts &&
            <Badge
                text={t('unsettledPayouts', {ns: 'employees'})}
                backgroundColor={Colors.gray3}
                textColor={Colors.gray4}
            />
        }
      </>
    )
  }

    return (
        <div>
           {renderHeader()}
           {renderListItems()}
           <div className={styles.pagination}>
            <Pagination
                pageIndex={pageIndex}
                itemCount={employees.length}
                pageSize={pageSize}
                siblingCount={1}
                onPageChange={onPageChange}
            />
            </div>
        </div>
    )
}

export default EmployeeList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    smallCellContainer: {
        width: 100
    },
    defaultCellContainer: {
        width: 140
    },
    nameCellContainer: {
        width: 200
    },
    buttonContainer: {
        display: 'flex',
        width: 80,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center',
        paddingLeft: 0
    },
    statusContainer: {
        display: 'flex',
        flex: 1,
        maxWidth: 140,
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    },
    smallHidden: {
        display: 'none',
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            display: 'flex'
        },
    },
    badge: {
        height: '20px',
        paddingLeft: 7,
        paddingRight: 7,
        borderRadius: 20,
        backgroundColor: Colors.euro_800,
        margin: 2
    },
    badgeText: {
        display: 'block',
        fontSize: 14,
        color: Colors.gray4,
        fontFamily: Fonts.regular,
        lineHeight: '21px'
    },
    button: {
        marginRight: 15,
        transition: '0.4s',
        '&:hover': {
          color: Colors.euro_600,
          cursor: 'pointer',
        }
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
  })
