import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from './Modal'
import { EditEmployeeModel, Employee, EmployeeType, PayCalculationType } from '../redux/types'
import InputField from './InputField'
import Switch from './Switch'
import DatePicker from './DatePicker'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../redux/hooks'
import Select from './Select'

interface Props {
    initialValue?: Employee | null
    onSaveClick?: (employee: EditEmployeeModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const defaultValidationState = {
    externalIdValid: true,
    identificationNumberValid: true,
    payrollFileIdentifierValid: true,
    givenNameValid: true,
    familyNameValid: true,
    taxTableValid: true,
    fixTaxPercentageValid: true,
    maxPercentageValid: true, 
    maxCustomPercentageValid: true,
    maxAmountValid: true, 
    payoutTaxValid: true,
    vacationPayPercentValid: true
}

const EditEmployeeModal = ({
    initialValue,
    onSaveClick,
    onCancelClick,
    visible
} : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'employees']);

    const employer = useAppSelector(state => state.employer.employer)

    const emptyEmployee: EditEmployeeModel = {
        employeeType: 'worker',
        payCalculationType: 'default',
        externalId: '', 
        identificationNumber: '', 
        givenName: '', 
        familyName: '', 
        email: '', 
        phoneNumber: '', 
        payrollFileIdentifier: null, 
        department: '',
        location: '',
        category: '',
        costCenter: '',
        paused: false,
        pauseReason: '',
        startDate: null,
        terminationDate: null,
        underForeclosure: false,
        nonEligible: false,
        salary: null,
        hourlyWage: null,
        payoutSettings: {
            maxAmountPerPayday: null,
            maxPercentagePerPayday: null,
            maxCustomPercentagePerPayday: null
        },
        taxSettings: {
            fixedTaxRatePercent: (employer?.defaultTaxTableIdentifier ?? '').length > 0 ? null : (employer?.defaultFixedTaxRatePercent ?? null),
            taxTableIdentifier: (employer?.defaultTaxTableIdentifier ?? '').length > 0 ? employer?.defaultTaxTableIdentifier ?? null : null,
            payoutTaxInPercent: employer?.defaultPayoutTaxInPercent ?? 30
        },
        handleVacationPay: false,
        vacationPayPercent: null
    }
    
    const [employee, setEmployee] = useState<EditEmployeeModel>(emptyEmployee)
    const [isSalaryReadOnly, setIsSalaryReadonly] = useState<boolean>(false)
    const [valid, setValid] = useState<{
        externalIdValid: boolean
        identificationNumberValid: boolean
        givenNameValid: boolean
        familyNameValid: boolean
        taxTableValid: boolean
        fixTaxPercentageValid: boolean
        maxPercentageValid: boolean
        maxCustomPercentageValid: boolean
        maxAmountValid: boolean
        payoutTaxValid: boolean
        vacationPayPercentValid: boolean
    }>(defaultValidationState)

    useEffect(() => {
        if (initialValue) {
            setEmployee({...initialValue, payoutSettings: {...initialValue.payoutSettings}, taxSettings: {...initialValue.taxSettings}})
            setIsSalaryReadonly(!initialValue.dataCollectionConsentFromUser)
        } else {
            setIsSalaryReadonly(true)
            setEmployee(emptyEmployee)
        }          
    }, [initialValue, employer])

    const handleOnSave = () => {
        if (!validateInput())
            return

        employee && onSaveClick && onSaveClick(employee)
        setEmployee(emptyEmployee)
        setValid(defaultValidationState)
    }

    const handleOnCancel = () => {
        onCancelClick && onCancelClick()
        setEmployee(emptyEmployee)
        setValid(defaultValidationState)
    }

    const validateInput = () => {
        let result = true

        let validClone = {...valid}

        if (!employee.externalId || employee.externalId.length === 0) {
            validClone = {...validClone, externalIdValid: false}
            result = false
        }
        
        if (!employee.identificationNumber || employee.identificationNumber.length !== 12 || !isNumeric(employee.identificationNumber)) {
            validClone = {...validClone, identificationNumberValid: false}
            result = false
        }

        if (!employee.givenName || employee.givenName.length === 0) {
            validClone = {...validClone, givenNameValid: false}
            result = false
        }

        if (!employee.familyName || employee.familyName.length === 0) {
            validClone = {...validClone, familyNameValid: false}
            result = false
        }

        if (employee.taxSettings?.taxTableIdentifier && (!isNumeric(employee.taxSettings?.taxTableIdentifier) || parseFloat(employee.taxSettings?.taxTableIdentifier) < 29 || parseFloat(employee.taxSettings?.taxTableIdentifier) > 40)) {
            validClone = {...validClone, taxTableValid: false}
            result = false
        }

        if (employee.taxSettings?.fixedTaxRatePercent && (employee.taxSettings?.fixedTaxRatePercent < 0 || employee.taxSettings?.fixedTaxRatePercent > 100)) {
            validClone = {...validClone, fixTaxPercentageValid: false}
            result = false
        }

        if (employee.taxSettings?.payoutTaxInPercent && (employee.taxSettings?.payoutTaxInPercent < 0 || employee.taxSettings?.payoutTaxInPercent > 100)) {
            validClone = {...validClone, payoutTaxValid: false}
            result = false
        }

        if (employee.payoutSettings?.maxAmountPerPayday && employee.payoutSettings?.maxAmountPerPayday < 0) {
            validClone = {...validClone, maxAmountValid: false}
            result = false
        }

        if (employee.payoutSettings?.maxPercentagePerPayday && (employee.payoutSettings?.maxPercentagePerPayday < 0 || employee.payoutSettings?.maxPercentagePerPayday > 100)) {
            validClone = {...validClone, maxPercentageValid: false}
            result = false
        }

        if (employee.payoutSettings?.maxCustomPercentagePerPayday && (employee.payoutSettings?.maxCustomPercentagePerPayday < 0 || employee.payoutSettings?.maxCustomPercentagePerPayday > 100)) {
            validClone = {...validClone, maxCustomPercentageValid: false}
            result = false
        }

        if (employee.vacationPayPercent && (employee.vacationPayPercent < 0 || employee.vacationPayPercent > 100)) {
            validClone = {...validClone, vacationPayPercentValid: false}
            result = false
        }

        setValid(validClone)
        return result
    }

    const isNumeric = (str: string) => {
        return !isNaN(parseFloat(str)) 
      }

    const handleChange = (propName: string, value: any) => {
        if (employee)
            setEmployee({...employee, [propName]: value})
    }

    const handleTaxSettingsChange = (propName: string, value: any) => {
        if (employee)
            setEmployee({...employee, taxSettings: {...employee.taxSettings, [propName]: value === '' ? null : value}})
    }

    const handlePayoutSettingsChange = (propName: string, value: any) => {
        if (employee)
            setEmployee({...employee, payoutSettings: {...employee.payoutSettings, [propName]: value === '' ? undefined : value}})
    }

    const getEmployeeTypeOptions = () => {
        let values = Object.values(EmployeeType).map((value) => value.toString())
        return values.map((value) => { return { name: t(`type_${value}`, {ns: 'employees'}), value: value }})
    }

    const getPayCalculationTypeOptions = () => {
        let values = Object.values(PayCalculationType).map((value) => value.toString())
        return values.map((value) => { return { name: t(`payCalculationType_${value}`, {ns: 'employees'}), value: value }})
    }

    return (
        <Modal
            title={initialValue != null ? t('editEmployee', {ns: 'employees'}) : t('addEmployee', {ns: 'employees'})}
            visible={visible}
            okButtonTitle={t('save', {ns: 'common'})}
            cancelButtonTitle={t('cancel', {ns: 'common'})}
            onHide={() => handleOnCancel()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnCancel()}
        >
            <div className={styles.sectionRow}>
                <h5 className={styles.sectionTitle}>{t('identification', {ns: 'employees'})}</h5>
                <div className={styles.row}>
                    <InputField
                        id='externalId'
                        disabled={initialValue != null}
                        valid={valid.externalIdValid}
                        mandatory={true}
                        value={employee?.externalId ?? ''}
                        onChange={value => {
                            setValid({...valid, externalIdValid: true})
                            handleChange('externalId', value)
                        }}
                        label={t('uniqueIdentifier', {ns: 'employees'})}
                        placeholder=''
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        id='identificationNumber'
                        valid={valid.identificationNumberValid}
                        mandatory={true}
                        value={employee?.identificationNumber ?? ''}
                        onChange={value => {
                            setValid({...valid, identificationNumberValid: true})
                            handleChange('identificationNumber', value)}}
                        label={t('identificationNumber', {ns: 'common'})}
                        placeholder='YYYYMMDDXXXX'
                        tooltipText={t('identificationNumberDescription', {ns: 'employees'})}
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        id='payrollFileIdentifier'
                        mandatory={false}
                        value={employee?.payrollFileIdentifier ?? undefined}
                        onChange={value => {handleChange('payrollFileIdentifier', value)}}
                        label={t('payrollFileIdentifier', {ns: 'employees'})}
                        placeholder=''
                        tooltipText={t('payrollFileIdentifier', {ns: 'employees'})}
                    />
                </div>
            </div>
            <div className={styles.sectionRow}>
                <h5 className={styles.sectionTitle}>{t('general', {ns: 'common'})}</h5>
                <div className={styles.row}>
                    <Select 
                        id='employeeType'
                        key='employeeType'
                        mandatory={true}
                        label={t('type', {ns: 'common'})}
                        options={getEmployeeTypeOptions()} 
                        selectedValue={employee?.employeeType.toString()}
                        onSelect={(value) => handleChange('employeeType', value as EmployeeType)}
                    />
                </div>
                <div className={styles.row}>
                    <Select 
                        id='payCalculationType'
                        key='payCalculationType'
                        mandatory={true}
                        label={t('payCalculationType', {ns: 'employees'})}
                        options={getPayCalculationTypeOptions()} 
                        selectedValue={employee?.payCalculationType.toString()}
                        onSelect={(value) => handleChange('payCalculationType', value as PayCalculationType)}
                        tooltipText={t('payCalculationTypeDescription', {ns: 'employees'})}
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        id='givenName'
                        valid={valid.givenNameValid}
                        value={employee?.givenName ?? ''}
                        onChange={value => {
                            setValid({...valid, givenNameValid: true})
                            handleChange('givenName', value)}}
                        label={t('givenName', {ns: 'common'})}
                        placeholder=''
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        id='familyName'
                        valid={valid.familyNameValid}
                        value={employee?.familyName ?? ''}
                        onChange={value => {
                            setValid({...valid, familyNameValid: true})
                            handleChange('familyName', value)}}
                        label={t('familyName', {ns: 'common'})}
                        placeholder=''
                    />
                </div>
                <div className={styles.row}>
                    <DatePicker
                      id='startDate'
                      value={employee?.startDate ?? undefined}
                      label={t('startDate', {ns: 'employees'})}
                      onChange={value => handleChange('startDate', value)}
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        id='department'
                        value={employee?.department ?? ''}
                        onChange={value => handleChange('department', value)}
                        label={t('department', {ns: 'common'})}
                        placeholder=''
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        id='category'
                        value={employee?.category ?? ''}
                        onChange={value => handleChange('category', value)}
                        label={t('category', {ns: 'common'})}
                        placeholder=''
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        id='location'
                        value={employee?.location ?? ''}
                        onChange={value => handleChange('location', value)}
                        label={t('location', {ns: 'common'})}
                        placeholder=''
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        id='costCenter'
                        value={employee?.costCenter ?? ''}
                        onChange={value => handleChange('costCenter', value)}
                        label={t('costCenter', {ns: 'common'})}
                        placeholder=''
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        id='email'
                        value={employee?.email ?? ''}
                        onChange={value => handleChange('email', value)}
                        label={t('email', {ns: 'common'})}
                        placeholder=''
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        id='phoneNumber'
                        value={employee?.phoneNumber ?? ''}
                        onChange={value => handleChange('phoneNumber', value)}
                        label={t('phoneNumber', {ns: 'common'})}
                        placeholder=''
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        type='number'
                        id='salary'
                        disabled={isSalaryReadOnly}
                        value={employee?.salary?.toString() ?? ''}
                        onChange={value => handleChange('salary', value == null || value === '' ? 0 : value)}
                        label={t('salary', {ns: 'employees'})}
                        placeholder=''
                        tooltipText={t('salaryDescription', {ns: 'employees'})}
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        type='number'
                        id='hourlyWage'
                        disabled={isSalaryReadOnly}
                        value={employee?.hourlyWage?.toString() ?? ''}
                        onChange={value => handleChange('hourlyWage', value == null || value === '' ? 0 : value)}
                        label={t('hourlyWage', {ns: 'employees'})}
                        placeholder=''
                        tooltipText={t('hourlyWageDescription', {ns: 'employees'})}
                    />
                </div>
            </div>
            {employer?.payrollSystemImportFileFormat === "paXml" &&
              <div className={styles.sectionRow}>
                  <h5 className={styles.sectionTitle}>{t('vacationPay', {ns: 'employees'})}</h5>
                  <p>{t('vacationPayDescription', {ns: 'employees'})}</p>
                  <div className={styles.row}>
                      <Switch
                        id='handleVacationPay'
                        key='handleVacationPay'
                        checked={employee?.handleVacationPay ?? false}
                        label={t('handleVacationPay', {ns: 'employees'})}
                        onChange={value => handleChange('handleVacationPay', !(employee?.handleVacationPay ?? false))}
                        tooltipText={t('handleVacationPayDescription', {ns: 'employees'})}
                      />
                  </div>
                  <div className={styles.row}>
                      <InputField
                        type='number'
                        id='vacationPayPercent'
                        valid={valid.vacationPayPercentValid}
                        value={employee?.vacationPayPercent?.toString() ?? ''}
                        onChange={value => {
                            setValid({...valid, vacationPayPercentValid: true})
                            handleChange('vacationPayPercent', value)
                        }}
                        label={t('vacationPayPercent', {ns: 'employees'})}
                        placeholder=''
                        tooltipText={t('vacationPayPercentDescription', {ns: 'employees'})}
                      />
                  </div>
              </div>
            }
             <div className={styles.sectionRow}>
                <h5 className={styles.sectionTitle}>{t('taxSettings', {ns: 'employees'})}</h5>
                <p>{t('taxSettingsDescription', {ns: 'employees'})}</p>
                <div className={styles.row}>
                    <InputField
                        id='taxTableIdentifier'
                        valid={valid.taxTableValid}
                        mandatory={true}
                        value={employee?.taxSettings.taxTableIdentifier ?? ''}
                        onChange={value => {
                            setValid({...valid, taxTableValid: true})
                            handleTaxSettingsChange('taxTableIdentifier', value)}}
                        label={t('taxTable', {ns: 'employees'})}
                        placeholder=''
                        tooltipText={t('taxTableDescription', {ns: 'employees'})}
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        type='number'
                        id='fixedTaxRatePercent'
                        valid={valid.fixTaxPercentageValid}
                        mandatory={true}
                        disabled={employee?.taxSettings.taxTableIdentifier != null && employee?.taxSettings.taxTableIdentifier !== ''}
                        value={(employee?.taxSettings.taxTableIdentifier == null || employee?.taxSettings.taxTableIdentifier === '') ? employee?.taxSettings.fixedTaxRatePercent?.toString() : undefined }
                        onChange={value => {
                            setValid({...valid, fixTaxPercentageValid: true})
                            handleTaxSettingsChange('fixedTaxRatePercent', value)}}
                        label={t('fixTaxPercentage', {ns: 'employees'})}
                        placeholder=''
                        tooltipText={t('fixTaxPercentageDescription', {ns: 'employees'})}
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        type='number'
                        id='payoutTax'
                        disabled={employer?.payoutTaxImmutable}
                        valid={valid.payoutTaxValid}
                        value={employee?.taxSettings.payoutTaxInPercent?.toString() ?? ''}
                        onChange={value => {
                            setValid({...valid, payoutTaxValid: true})
                            handleTaxSettingsChange('payoutTaxInPercent', value)}}
                        label={t('payoutTax', {ns: 'employees'})}
                        placeholder=''
                        tooltipText={t('payoutTaxDescription', {ns: 'employees'})}
                    />
                </div>
            </div>
            <div className={styles.sectionRow}>
                <h5 className={styles.sectionTitle}>{t('payoutLimitations', {ns: 'employees'})}</h5>
                <p>{t('payoutLimitationDescription', {ns: 'employees'})}</p>
                <div className={styles.row}>
                    <InputField
                        type='number'
                        id='maxAmountPerPayday'
                        valid={valid.maxAmountValid}
                        value={employee?.payoutSettings.maxAmountPerPayday?.toString() ?? ''}
                        onChange={value => handlePayoutSettingsChange('maxAmountPerPayday', value)}
                        label={t('maxAmountPerPayday', {ns: 'employees'})}
                        placeholder=''
                        tooltipText={t('maxAmountPerPaydayDescription', {ns: 'employees'})}
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        type='number'
                        id='maxPercentagePerPayday'
                        valid={valid.maxPercentageValid}
                        value={employee?.payoutSettings.maxPercentagePerPayday?.toString() ?? ''}
                        onChange={value => {
                            setValid({...valid, maxPercentageValid: true})
                            handlePayoutSettingsChange('maxPercentagePerPayday', value)}}
                        label={t('maxPercentagePerPayday', {ns: 'employees'})}
                        placeholder=''
                        tooltipText={t('maxPercentagePerPaydayDescription', {ns: 'employees'})}
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        type='number'
                        id='maxCustomPercentagePerPayday'
                        valid={valid.maxCustomPercentageValid}
                        value={employee?.payoutSettings.maxCustomPercentagePerPayday?.toString() ?? ''}
                        onChange={value => {
                            setValid({...valid, maxPercentageValid: true})
                            handlePayoutSettingsChange('maxCustomPercentagePerPayday', value)}}
                        label={t('maxCustomPercentagePerPayday', {ns: 'employees'})}
                        placeholder=''
                        tooltipText={t('maxCustomPercentagePerPaydayDescription', {ns: 'employees'})}
                    />
                </div>
            </div>
            <div className={styles.sectionRow}>
                <h5 className={styles.sectionTitle}>{t('pause', {ns: 'employees'})}</h5>
                <div className={styles.row}>
                    <Switch 
                        id='paused'
                        key='paused'
                        checked={employee?.paused ?? false} 
                        label={t('paused', {ns: 'common'})}
                        onChange={value => handleChange('paused', !(employee?.paused ?? false))}
                        tooltipText={t('pausedDescription', {ns: 'employees'})}
                    />
                </div>
                <div className={styles.row}>
                    <InputField
                        id='pauseReason'
                        disabled={!employee?.paused}
                        value={employee?.paused ? employee?.pauseReason : ''}
                        onChange={value => handleChange('pauseReason', value)}
                        label={t('pauseReason', {ns: 'employees'})}
                        placeholder=''
                    />
                </div>
            </div>
            <div className={styles.sectionRow}>
                <h5 className={styles.sectionTitle}>{t('termination', {ns: 'employees'})}</h5>
                <div className={styles.row}>
                    <DatePicker 
                        id='terminationDate'
                        value={employee?.terminationDate ?? undefined}
                        label={t('terminationDate', {ns: 'employees'})}
                        onChange={value => handleChange('terminationDate', value)}
                        tooltipText={t('terminationDateDescription', {ns: 'employees'})}
                    />
                </div>
            </div>
            <div className={styles.sectionRow}>
                <h5 className={styles.sectionTitle}>{t('flags', {ns: 'common'})}</h5>
                <div className={styles.row}>
                    <Switch 
                        id='nonEligible'
                        key='nonEligible'
                        checked={employee?.nonEligible ?? false} 
                        label={t('nonEligible', {ns: 'employees'})}
                        onChange={value => handleChange('nonEligible', !(employee?.nonEligible ?? false))}
                        tooltipText={t('nonEligibleDescription', {ns: 'employees'})}
                    />
                </div>
                <div className={styles.row}>
                    <Switch 
                        id='underForeclosure'
                        key='underForeclosure'
                        checked={employee?.underForeclosure ?? false} 
                        label={t('underForeclosure', {ns: 'employees'})}
                        onChange={value => handleChange('underForeclosure', !(employee?.underForeclosure ?? false))}
                        tooltipText={t('underForeclosureDescription', {ns: 'employees'})}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default EditEmployeeModal

const useStyles = createUseStyles({
    row: {
        paddingBottom: 12
    },
    sectionTitle: {
        marginBottom: 15
    },
    sectionRow: {
        paddingBottom: 25
    }
})
