import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface PlandayIntegrationState {
    loading: boolean
    fileUploaded: boolean
    error: Error | string | null
}

const initialState: PlandayIntegrationState = {
    loading: false,
    fileUploaded: false,
    error: null
}

export const tengellaIntegrationSlice = createSlice({
    name: 'tengellaIntegration',
    initialState,
    reducers: {
        loading: state => {
            state.fileUploaded = false;
            state.loading = true
            state.error = null
        },
        fileUploaded: state => {
            state.fileUploaded = true;
            state.loading = false
            state.error = null
        },
        fileUploadFailure: (state, action: PayloadAction<Error | string>) => {
            state.fileUploaded = false;
            state.loading = false
            state.error = action.payload
        }
    }
})

export const { 
    loading, 
    fileUploaded, 
    fileUploadFailure
} = tengellaIntegrationSlice.actions

export default tengellaIntegrationSlice.reducer