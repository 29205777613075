import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FileModel, FileType } from '../types'

interface FileState {
    loading: boolean
    files: FileModel[]
    error: Error | string | null
}

const initialState: FileState = {
    loading: false,
    files: [],
    error: null
}

export const fileSlice = createSlice({
    name: 'fileSlice',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        filesFetched: (state, action: PayloadAction<FileModel[]>) => {
            state.loading = false
            state.files = action.payload
            state.error = null
        },
        filesFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        fileDownloaded: (state, action: PayloadAction<string>) => {
            const filesClone = [...state.files]
            var indexToUpdate = filesClone.findIndex(x => x.id === action.payload)

            if (indexToUpdate !== -1) {
                if (filesClone[indexToUpdate].type === FileType.payrollSystemPayoutBasisImportFile) {
                    filesClone[indexToUpdate].payrollSystemPayoutBasisImportFile.lastDownloadDate = new Date().toISOString()
                } else if (filesClone[indexToUpdate].type === FileType.employerPayDeductionPaymentNotificationFile) {
                    filesClone[indexToUpdate].employerPayDeductionPaymentNotificationFile.lastDownloadDate = new Date().toISOString()
                } else if (filesClone[indexToUpdate].type === FileType.creditReportFile) {
                    filesClone[indexToUpdate].creditReportFile.lastDownloadDate = new Date().toISOString()
                } else if (filesClone[indexToUpdate].type === FileType.agiAdjustmentSieFile) {
                    filesClone[indexToUpdate].agiAdjustmentSieFile.lastDownloadDate = new Date().toISOString()
                }

                state.files = filesClone
            }
        }
    }
})

export const { 
    loading, 
    filesFetched, 
    filesFetchFailure, 
    fileDownloaded 
} = fileSlice.actions

export default fileSlice.reducer