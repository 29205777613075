import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { AgiAdjustment } from "../../redux/types"
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Colors } from '../../constants/colors'
import { roundToTwoDecimals } from '../../util/number'

interface Props {
    adjustments: AgiAdjustment[]
    pageIndex: number
    pageSize: number
    onPageChange: (pageIndex: number) => void
}

const AgiAdjustmentList = ({ adjustments, pageIndex, pageSize, onPageChange } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'agiAdjustment']);

    const itemsOnCurrentPage = useMemo(() => {
        const firstPageIndex = (pageIndex - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return adjustments.slice(firstPageIndex, lastPageIndex);
    }, [adjustments, pageIndex, pageSize])

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('identificationNumber', {ns: 'common'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('name', {ns: 'common'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('originalGrossPay', {ns: 'agiAdjustment'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('grossPayAdditions', {ns: 'agiAdjustment'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('grossPayDeductions', {ns: 'agiAdjustment'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('originalTax', {ns: 'agiAdjustment'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('taxAdditions', {ns: 'agiAdjustment'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('taxDeductions', {ns: 'agiAdjustment'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('taxType', {ns: 'agiAdjustment'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('socialFeeAdjustment', {ns: 'agiAdjustment'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('added', {ns: 'agiAdjustment'})}</strong>
                </div>
            </ListItemCard>
        )
    }

    const renderListItems = () => {
        return itemsOnCurrentPage?.map((adjustment, index) => {
            return (
                <ListItemCard key={`period${index}`} className={styles.listItemContainer} index={index}>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.identificationNumber}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.employeeName}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.originalGrossPay}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.grossPayAdditions}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.grossPayDeductions}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.originalTax}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.taxAdditions}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.taxDeductions}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {t('taxType_' + adjustment.taxType, {ns: 'agiAdjustment'})}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {roundToTwoDecimals(adjustment.socialFeeAdjustment)}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.addedByCappy &&
                            <FontAwesomeIcon
                                icon={faCheck}
                                color={Colors.euro_600}
                            />
                        }
                    </div>
                </ListItemCard>
            )
         })
    }

    return (
        <div>
           {renderHeader()}
           {renderListItems()}
           <div className={styles.pagination}>
            <Pagination
                pageIndex={pageIndex}
                itemCount={adjustments.length}
                pageSize={pageSize}
                siblingCount={1}
                onPageChange={onPageChange}
            />
            </div>
        </div>
    )
}

export default AgiAdjustmentList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    defaultCellContainer: {
        width: 100
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
  })
