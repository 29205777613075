import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { Breakpoints } from '../../constants/breakpoints'
import { Colors } from "../../constants/colors"
import { PayDeviation } from '../../redux/types'
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'

interface Props {
    deviationsOnPage: PayDeviation[]
    pageIndex: number
    pageSize: number
    totalCount: number
    onEditClick: (deviation: PayDeviation) => void
    onDeleteClick: (deviation: PayDeviation) => void
    onPageChange: (pageIndex: number) => void
}

const PayDeviationList = ({ deviationsOnPage, pageIndex, pageSize, totalCount, onEditClick, onDeleteClick, onPageChange } : Props) => {
    const { t } = useTranslation(['common', 'deviations']);
    const styles = useStyles()

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('payday', {ns: 'common'})}</strong>
                </div>
                <div className={styles.nameCellContainer}>
                    <strong>{t('employee', {ns: 'common'})}</strong>
                </div> 
                <div className={styles.defaultCellContainer}>
                    <strong>{t('date', {ns: 'common'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('description', {ns: 'deviations'})}</strong>
                </div> 
                <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                    <strong>{t('grossAmount', {ns: 'deviations'})}</strong>
                </div> 
                <div className={styles.buttonContainer}>
                   
                </div>  
            </ListItemCard>
        )    
    }

    const renderListItems = () => {
        return deviationsOnPage?.map((deviation, index) => {
            return (
                <ListItemCard key={`daviation_${index}`} className={styles.listItemContainer} index={index}>
                    <div className={styles.defaultCellContainer}>
                        {deviation.payday.paymentDate}
                    </div> 
                    <div className={styles.nameCellContainer} title={deviation.employee.identificationNumber}>
                        {deviation.employee.name} 
                    </div> 
                    <div className={`${styles.defaultCellContainer} ${styles.mediumHidden}`}>
                        {deviation.date}
                    </div> 
                    <div className={`${styles.defaultCellContainer} ${styles.mediumHidden}`}>
                        {deviation.description}
                    </div> 
                    <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                        {deviation.grossAmount.amount.toLocaleString('sv-SE', { minimumFractionDigits: 2 })} {deviation.grossAmount.currencyCode.toUpperCase()}
                    </div> 
                    <div className={styles.buttonContainer}>
                        <div>
                            <FontAwesomeIcon
                                className={styles.button}
                                icon={faEdit}
                                color={Colors.gray1}
                                title={t('edit', {ns: 'common'})}
                                onClick={() => onEditClick && onEditClick(deviation)}
                            />
                        </div>
                        <div>
                            <FontAwesomeIcon
                                className={styles.button}
                                icon={faTrashAlt}
                                color={Colors.gray1}
                                title={t('delete', {ns: 'common'})}
                                onClick={() => onDeleteClick && onDeleteClick(deviation)}
                            />
                        </div>
                    </div>  
                </ListItemCard>
            ) 
         })
    }

    return (
        <div>
           {renderHeader()}
           {renderListItems()}
           <div className={styles.pagination}>
                <Pagination
                    pageIndex={pageIndex}
                    itemCount={totalCount}
                    pageSize={pageSize}
                    siblingCount={1}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
    )
}

export default PayDeviationList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    defaultCellContainer: {
        width: 130
    },
    nameCellContainer: {
        width: 200
    },
    buttonContainer: {
        display: 'flex',
        width: 80,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center',   
        paddingLeft: 0
    },
    smallHidden: {
        display: 'none',
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            display: 'flex'
        },
    },
    mediumHidden: {
        display: 'none',
        [`@media (min-width: ${Breakpoints.desktop}px)`]: {
            display: 'flex'
        },
    },
    button: {
        marginRight: 15,
        transition: '0.4s',
        '&:hover': {
          color: Colors.euro_600,
          cursor: 'pointer',
        }
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
  })