import { useEffect, useState } from "react"
import { createUseStyles } from 'react-jss'
import { useAppSelector } from "../redux/hooks"
import { PaySchedulePaydayModel } from "../redux/types"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import EditPaydayModal from "../components/PaySchedule/EditPaydayModal"
import Card from "../components/Card"
import { useTranslation } from "react-i18next"
import usePayScheduleActions from "../hooks/usePayScheduleActions"
import PayScheduleList from "../components/PaySchedule/PayScheduleList"

const PaySchedules = () => {
    const styles = useStyles()
    const { t } = useTranslation('paySchedules');

    const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
    const [editedPayday, setEditedPayday] = useState<PaySchedulePaydayModel>()

    const { getPaySchedules, updatePaydayDate } = usePayScheduleActions()
    const loading = useAppSelector(state => state.paySchedule.loading)
    const paySchedules = useAppSelector(state => state.paySchedule.paySchedules)

    useEffect(() => {
        if (paySchedules.length === 0) {
            getPaySchedules()
        }
    }, [])

    const onEditPaydayClick = (payday: PaySchedulePaydayModel) => {
        setEditedPayday(payday)
        setEditModalVisible(true)
    }

    const handlePaydayEdited = (date: string) => {
        if (editedPayday) {
            updatePaydayDate(editedPayday.id, date)
        }
        
        setEditedPayday(undefined)
        setEditModalVisible(false)   
    }

    const handlePayDayEditCancelled = () => {
        setEditedPayday(undefined)
        setEditModalVisible(false)   
    }

    return (
        <>
        <Card className={styles.card}>
            <h2>
                {t('title')}
            </h2>
        </Card>
        {paySchedules && 
        <PayScheduleList 
            paySchedules={paySchedules}
            onEditPaydayClick={onEditPaydayClick} 
        />}
        <EditPaydayModal 
            initialValue={editedPayday?.paymentDate ?? null}
            minDate={editedPayday?.paydayPeriodStartDate ?? null}
            maxDate={editedPayday?.paydayPeriodEndDate ?? null}
            visible={editModalVisible} 
            onCancelClick={handlePayDayEditCancelled}
            onSaveClick={handlePaydayEdited}
        />
        <FullScreenLoadingIndicator visible={loading} />
        </>
    )
} 

export default PaySchedules

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    }
})