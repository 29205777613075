import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from './Modal'
import { EditPayDeviationModel } from '../redux/types'
import InputField from './InputField'
import DatePicker from './DatePicker'
import EmployeeSelector from './EmployeeSelector'
import { AutocompleteItem } from './AutocompleteField'
import { isDateValid } from '../util/date'
import { Colors } from '../constants/colors'
import usePayDeviationActions from '../hooks/usePayDeviationActions'
import { useTranslation } from 'react-i18next'

interface Props {
    initialValue?: EditPayDeviationModel | null
    onSaveClick?: (payday: EditPayDeviationModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const emptyPayDeviation: EditPayDeviationModel = {
    id: null,
    externalId: '',
    externalEmployeeId: '',
    employeeId: '',
    employeeName: '',
    employeeIdentificationNumber: '',
    grossAmount: 0,
    date: '',
    description: ''
}

const EditPayDeviationModal = ({
    initialValue,
    onSaveClick,
    onCancelClick,
    visible
} : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'deviations']);

    const [deviation, setDeviation] = useState<EditPayDeviationModel>(emptyPayDeviation)
    const [deviationAlreadyExists, setDeviationAlreadyExists] = useState(false)
    const [externalIdValid, setExternalIdValid] = useState(true)
    const [employeeValid, setEmployeeValid] = useState(true)
    const [amountValid, setAmountValid] = useState(true)
    const [dateValid, setDateValid] = useState(true)

    const { payDeviationExists } = usePayDeviationActions()

    useEffect(() => {
        if (initialValue) {
            setDeviation({...initialValue})
        }           
    }, [initialValue])

    const handleOnSave = () => {
        if (!validateInput())
            return

        deviation && onSaveClick && onSaveClick(deviation)
        setDeviation(emptyPayDeviation)
        setEmployeeValid(true)
        setAmountValid(true)
        setDateValid(true)
    }

    const validateBeforeSave = () => {
        if (!initialValue) {
            payDeviationExists(deviation.externalId, (exists: boolean) => {
                if (exists) {
                    setDeviationAlreadyExists(true)
                } else {
                    setDeviationAlreadyExists(false)
                    handleOnSave()    
                }
            })
        } else {
            handleOnSave()
        }
    }

    const handleOnCancel = () => {
        onCancelClick && onCancelClick()
        setDeviation(emptyPayDeviation)
        setEmployeeValid(true)
        setAmountValid(true)
        setDateValid(true)
    }

    const handleOnChange = (propName: string, value: any) => {
        if (deviation)
            setDeviation({...deviation, [propName]: value})
    }

    const handleEmployeeSelected = (employee: AutocompleteItem | null) => {
        if (employee != null) {
            setEmployeeValid(true)
            setDeviation({...deviation, externalEmployeeId: employee.value})    
        }
    } 

    const validateInput = () => {
        let result = true

        if (deviation.externalId == null || deviation.externalId.length < 1) {
            setExternalIdValid(false)
            result = false
        }

        if (deviation.externalEmployeeId == null || deviation.externalEmployeeId.length < 1) {
            setEmployeeValid(false)
            result = false
        }

        if (deviation.grossAmount == null) {
            setAmountValid(false)
            result = false
        }

        if (!isDateValid(deviation.date)) {
            setDateValid(false)
            result = false
        }

        return result
    }

    return (
        <Modal
            title={initialValue != null ? t('editDeviation', {ns: 'deviations'}) : t('addDeviation', {ns: 'deviations'})}
            visible={visible}
            okButtonTitle={t('save', {ns: 'common'})}
            cancelButtonTitle={t('cancel', {ns: 'common'})}
            onHide={() => handleOnCancel()}
            onOkClick={() => validateBeforeSave()}
            onCancelClick={() => handleOnCancel()}
        >
            <div className={styles.row}>
                <InputField
                    id='externalId'
                    valid={externalIdValid}
                    disabled={initialValue != null}
                    value={deviation?.externalId ?? undefined}
                    onChange={value => handleOnChange('externalId', value)}
                    label={t('externalId', {ns: 'deviations'})}
                    placeholder=''
                    tooltipText={t('externalIdDescription', {ns: 'deviations'})}
                />
            </div>
            <div className={styles.row}>
                <EmployeeSelector
                    valid={employeeValid}
                    mandatory={true}
                    externalIdAsValue={true}
                    includeUnconnectedEmployees={false}
                    includeEmployeesWithoutDataCollectionConsent={false}
                    value={deviation?.employeeIdentificationNumber ? `${deviation.employeeName} (${deviation.employeeIdentificationNumber})` : ''}
                    onSelected={handleEmployeeSelected}
                />
            </div>
            <div className={styles.row}>
                <DatePicker 
                    valid={dateValid}
                    mandatory={true}
                    id='date'
                    label={t('date', {ns: 'common'})}
                    includeTime={false}
                    value={deviation?.date ?? undefined}
                    onChange={value => {
                        handleOnChange('date', value)
                    }}
                />
            </div>
            <div className={styles.row}>
                <InputField
                    mandatory={false}
                    id='description'
                    value={deviation?.description}
                    onChange={value => handleOnChange('description', value)}
                    label={t('description', {ns: 'deviations'})}
                />
            </div>
            <div className={styles.row}>
                <InputField
                    valid={amountValid}
                    mandatory={true}
                    id='grossAmount'
                    type='number'
                    value={deviation?.grossAmount.toString()}
                    onChange={value => {
                        setAmountValid(true)
                        handleOnChange('grossAmount', Number.parseFloat(value))
                    }}
                    label={t('grossAmount', {ns: 'deviations'})}
                    tooltipText={t('grossAmountDescription', {ns: 'deviations'})}
                />
            </div>
            
            {deviationAlreadyExists && 
            <div className={styles.errorMessage}>
                {t('deviationAlreadyExists', {ns: 'deviations'})}
            </div>
            }
        </Modal>
    )
}

export default EditPayDeviationModal

const useStyles = createUseStyles({
    row: {
        paddingBottom: 12
    },
    errorMessage: {
        color: Colors.rubel_700
    }
})