import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from '../Modal'
import DatePicker from '../DatePicker'
import { useTranslation } from 'react-i18next'
import { Colors } from '../../constants/colors'

interface Props {
    initialValue: string | null
    minDate: string | null
    maxDate: string | null
    onSaveClick?: (date: string) => void
    onCancelClick?: () => void
    visible: boolean
}

const EditPaydayModal = ({
    initialValue,
    minDate,
    maxDate,
    onSaveClick,
    onCancelClick,
    visible
} : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'paySchedules']);

    const [payday, setPayday] = useState(initialValue)
    const [dateValid, setDateValid] = useState<boolean>(true)

    useEffect(() => {
        if (initialValue) {
            setPayday(initialValue)
        }           
    }, [initialValue])

    const handleOnSave = () => {
        if (!validateInput())
            return

        payday && onSaveClick && onSaveClick(payday)
        setPayday(null)
    }

    const handleOnCancel = () => {
        onCancelClick && onCancelClick()
        setPayday(null)
    }

    const validateInput = () => {
        if (!payday || !minDate || !maxDate) {
            return false
        }

        const date = Date.parse(payday)
        const isDateValid = !isNaN(date) && date >= new Date(minDate).getTime() && date <= new Date(maxDate).getTime()
        setDateValid(isDateValid)
        return isDateValid
    }

    return (
        <Modal
            title={t('editPayday', {ns: 'paySchedules'})}
            visible={visible}
            okButtonTitle={t('save', {ns: 'common'})}
            cancelButtonTitle={t('cancel', {ns: 'common'})}
            onHide={() => handleOnCancel()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnCancel()}
        >
            <div className={styles.row}>
                <DatePicker 
                    id='paymentDate'
                    label={`${t('paymentDate', {ns: 'paySchedules'})} (${minDate} - ${maxDate})`}
                    valid={dateValid}
                    value={payday ?? ''}
                    onChange={value => {
                        setPayday(value ?? null)
                        setDateValid(true)
                    }}
                />
            </div>
            <div style={{height: 240}}>

            </div>
        </Modal>
    )
}

export default EditPaydayModal

const useStyles = createUseStyles({
    row: {
        paddingBottom: 12
    },
    errorMessage: {
        color: Colors.rubel_700
    }
})
