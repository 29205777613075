import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { Breakpoints } from '../../constants/breakpoints'
import { Colors } from "../../constants/colors"
import { Fonts } from '../../constants/fonts'
import { IntegrationLogItem } from '../../redux/types'
import { formatDateTimeString } from '../../util/date'
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'

interface Props {
    itemsOnPage: IntegrationLogItem[]
    pageIndex: number
    pageSize: number
    totalItemCount: number
    onPageChange: (pageIndex: number) => void
}

const IntegrationLogList = ({ itemsOnPage, pageIndex, pageSize, totalItemCount, onPageChange } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'integrationLog']);

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.dateCellContainer}>
                    <strong>{t('date', {ns: 'common'})}</strong>
                </div>
                <div className={`${styles.defaultCellContainer}`}>
                    <strong>{t('type', {ns: 'common'})}</strong>
                </div> 
                <div className={styles.levelCellContainer}>
                    <strong>{t('level', {ns: 'integrationLog'})}</strong>
                </div> 
                <div className={styles.messageCellContainer}>
                    <strong>{t('parameters', {ns: 'integrationLog'})}</strong>
                </div> 
            </ListItemCard>
        )    
    }

    const renderListItems = () => {
        return itemsOnPage?.map((item, index) => {
            return (
                <ListItemCard key={`integrationLog${index}`} className={styles.listItemContainer} index={index}>
                    <div className={styles.dateCellContainer}>
                        {formatDateTimeString(item.date)}
                    </div> 
                    <div className={styles.defaultCellContainer}>
                        {t(`type_${item.type}`, {ns: 'integrationLog'})} 
                    </div> 
                    <div className={`${styles.levelCellContainer}`}>
                        {renderLevelBadge(item)}
                    </div> 
                    <div className={`${styles.messageCellContainer}`}>
                        {item.parameters}
                    </div>  
                </ListItemCard>
            ) 
         })
    }

    const renderLevelBadge = (item: IntegrationLogItem) => {
        return (
            <>
                <div className={styles.badge} style={{backgroundColor: getBadgeColor(item)}}>
                    <span className={styles.badgeText} style={{color: getBadgeTextColor(item)}}>{t(`level_${item.level}`, {ns: 'integrationLog'})}</span>
                </div>
            </>
        )
    }

    const getBadgeColor = (item: IntegrationLogItem) => {
        switch (item.level) {
            case 'error': return Colors.rubel_700
            case 'information': return Colors.euro_800
        }
    }

    const getBadgeTextColor = (item: IntegrationLogItem) => {
        switch (item.level) {
            case 'error': return Colors.gray4
            case 'information': return Colors.gray4
        }
    }

    return (
        <div>
           {renderHeader()}
           {renderListItems()}
           <div className={styles.pagination}>
                <Pagination
                    pageIndex={pageIndex}
                    itemCount={totalItemCount}
                    pageSize={pageSize}
                    siblingCount={1}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
    )
}

export default IntegrationLogList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '13px'
    },
    dateCellContainer: {
        width: 130,
    },
    levelCellContainer: {
        width: 100,
        display: 'flex',
        justifyContent: 'flex-start'
    },
    idCellContainer: {
        width: 150,
    },
    defaultCellContainer: {
        width: 250,
    },
    messageCellContainer: {
        flexGrow: 1
    },
    buttonContainer: {
        display: 'flex',
        width: 80,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center',   
        paddingLeft: 0
    },
    smallHidden: {
        display: 'none',
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            display: 'flex'
        },
    },
    mediumHidden: {
        display: 'none',
        [`@media (min-width: ${Breakpoints.desktop}px)`]: {
            display: 'flex'
        },
    },
    badge: {
        height: '20px',
        paddingLeft: 7,
        paddingRight: 7,
        borderRadius: 20,
        backgroundColor: Colors.euro_800,
        margin: 2
    },
    badgeText: {
        display: 'block',
        fontSize: 11,
        color: Colors.gray4,
        fontFamily: Fonts.regular, 
        lineHeight: '21px'   
    },
    button: {
        marginRight: 15,
        transition: '0.4s',
        '&:hover': {
          color: Colors.euro_600,
          cursor: 'pointer',
        }
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
  })