import { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import Card from './Card'

interface Props {
    title: string
    children: ReactNode
    className?: string
    style?: React.CSSProperties | undefined
}

const DashboardMetricCard = ({title, children, className, style}: Props) => {
    const styles = useStyles()

    return (
        <Card className={`${styles.container} ${className}`} style={style}>
            <div className={styles.header}>
                <span>{title}</span>
            </div>
            <div className={styles.content}>
                {children}  
            </div>
        </Card>
    )
}

export default DashboardMetricCard

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
        marginBottom: 20
    },
    header: {
        color: Colors.euro_800,
        fontSize: 15,
        textTransform: 'uppercase'
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        columnGap: 50,
        width: 350,
    },
    footer: {}
})