import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { BulkOperationResult, EditShiftModel, Shift, ShiftDataParserType } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { loading, shiftCreated, shiftCreateFailure, shiftDeleted, shiftDeleteFailure, shiftsBulkUploaded, shiftsBulkUploadFailure, shiftsFetched, shiftsFetchFailure, shiftUpdated, shiftUpdateFailure } from "../redux/slices/shiftSlice";
import { createUrlWithQueryString } from "../util/url";
import { getPaginationDataFromResponse } from "../util/pagination";

const useShiftActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getShifts = (employeeId: string | undefined, fromDate: string | undefined, toDate: string | undefined, status: string | undefined, pageIndex: number, pageSize: number) => {
        dispatch(loading())

        const params = {
            employeeId: employeeId,
            fromDate: fromDate,
            toDate: toDate,
            shiftStatus: status,
            pageNumber: pageIndex,
            pageSize: pageSize
        }

        const url = createUrlWithQueryString(apiConfig.routes.shifts, params)

        return fetchClient.get(url)
            .then(response => {
                const paginationData = getPaginationDataFromResponse(response.raw)
                dispatch(shiftsFetched({ shifts: response.data as Shift[], totalShiftCount: paginationData?.totalItemCount ?? 0}))
            })
            .catch(error => {
                console.log('getShifts error:', error)
                dispatch(shiftsFetchFailure(error))
            })
    }

    const shiftExists = (externalId: string, onComplete: (exists: boolean) => void) => {
        const params = {
            externalId
        }

        const url = createUrlWithQueryString(apiConfig.routes.shifts + 'exists', params)

        return fetchClient.get(url)
            .then(response => {
                onComplete(response.data.exists as boolean)    
            })
            .catch(error => {
                console.log('shiftExists error:', error)
            })
    }

    const createShift = (shift: EditShiftModel, onComplete?: () => void) => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.shifts, shift)
            .then(response => {
                dispatch(shiftCreated(response.data as Shift))
                onComplete && onComplete()
            })
            .catch(error => {
                console.log('createShift error:', error)
                dispatch(shiftCreateFailure(error))
            })
    }

    const updateShift = (shift: EditShiftModel) => {
        dispatch(loading())
        const url = `${apiConfig.routes.shifts}${shift.id}` 

        return fetchClient.post(url, shift)
            .then(response => {
                dispatch(shiftUpdated(response.data as Shift))
            })
            .catch(error => {
                console.log('updateShift error:', error)
                dispatch(shiftUpdateFailure(error))
            })
    }

    const deleteShift = (shift: Shift) => {
        dispatch(loading())
        const url = `${apiConfig.routes.shifts}${shift.id}` 

        return fetchClient.delete(url)
            .then(response => {
                dispatch(shiftDeleted(shift))
            })
            .catch(error => {
                console.log('deleteShift error:', error)
                dispatch(shiftDeleteFailure(error))
            })
    }

    const bulkUploadShifts = (file: File, dataParser: ShiftDataParserType, onComplete?: (result: BulkOperationResult) => void) => {
        dispatch(loading())

        return fetchClient.postFile(apiConfig.routes.shifts + 'fileContent?dataParser=' + dataParser, file)
            .then(response => {
                dispatch(shiftsBulkUploaded())
                onComplete && onComplete(response.data as BulkOperationResult)
            })
            .catch(error => {
                console.log('bulkUploadShifts error:', error)
                dispatch(shiftsBulkUploadFailure(error))
            })
    }

    return {
        getShifts,
        createShift,
        updateShift,
        deleteShift,
        shiftExists,
        bulkUploadShifts
    }
}

export default useShiftActions