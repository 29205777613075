import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { Colors } from "../../../constants/colors";
import { createUseStyles } from 'react-jss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom"
import NumberDot from "../../NumberDot";

interface Props {
    title: string
    linkTo: string
    active: boolean
    icon: string | IconDefinition
    dotNumber?: number
}

const SidebarLink = ({title, linkTo, active, icon, dotNumber} : Props) => {
    const styles = useStyles()

    return (
        <div className={styles.container} style={{borderRightWidth: active ? 5 : 0}}>
            <div className={styles.iconContainer}>
                <FontAwesomeIcon
                    icon={icon as IconDefinition}
                    style={{marginTop: 5}}
                    color={active ? Colors.euro_800 : Colors.gray1}
                />
            </div>
            <div style={{position: 'relative'}}>
                <Link className={active ? styles.activeLink : styles.link} to={linkTo}>{title}</Link>
                {dotNumber != null && dotNumber > 0 && <NumberDot className={styles.dot} number={dotNumber} />}  
            </div>
        </div>
    )   
}

export default SidebarLink
const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'row',
        height: 30,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'flex-start',
        borderRightStyle: 'solid',
        borderRightColor: Colors.euro_800,
        borderRightWidth: 0,
    },
    iconContainer: {
        width: 25,
        marginRight: 10,
        marginLeft: 40,
        textAlign: 'center'
    },
    link: {
        color: Colors.gray1,
        textDecoration: 'none',
        '&:hover': {
            color: Colors.euro_800,
        }
    },
    activeLink: {
        color: Colors.euro_800,
        textDecoration: 'none',
    },
    dot: {
        top: -3,
        right: -20
    }
})