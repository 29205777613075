import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { ForaAdjustmentsForPeriod, ForaAdjustmentsModel } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { loading, periodDetailsFetchFailure, periodDetailsFetched, periodsFetchFailure, periodsFetched } from "../redux/slices/foraAdjustmentSlice";

const useForaAdjustmentActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getPeriods = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.foraAdjustment)
            .then(response => {
                dispatch(periodsFetched(response.data as ForaAdjustmentsModel))
            })
            .catch(error => {
                console.log('getPeriods error:', error)
                dispatch(periodsFetchFailure(error))
            })
    }

    const getPeriodDetails = (period: string) => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.foraAdjustment + period)
            .then(response => {
                dispatch(periodDetailsFetched(response.data as ForaAdjustmentsForPeriod))
            })
            .catch(error => {
                console.log('getPeriodDetails error:', error)
                dispatch(periodDetailsFetchFailure(error))
            })
    }

    return {
        getPeriods,
        getPeriodDetails
    }
}

export default useForaAdjustmentActions