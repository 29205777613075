import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { Colors } from "../constants/colors";
import Modal from "./Modal";

interface Props {
    visible: boolean
    title: string
    infoText?: string
    multiFileUpload?: boolean
    enableAddOrUpdateOption?: boolean
    updateOnlyOptionText?: string,
    addAndUpdateOptionText?: string,
    onUpload: (files: File[], updateOnly: boolean | null) => void
    onCancelClick: () => void
}

const UploadFileModal = ({ visible, title, infoText, multiFileUpload, enableAddOrUpdateOption, updateOnlyOptionText, addAndUpdateOptionText, onUpload, onCancelClick }: Props) => {
    const styles = useStyles()
    const { t } = useTranslation('common');

    const [updateOnly, setUpdateOnly] = useState(true)
    const [selectedFiles, setSelectedFiles] = useState<File[]>()
    const {acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({ maxFiles: (multiFileUpload ?? false) ? undefined : 1})

    useEffect(() => {
        if (acceptedFiles.length > 0)
            setSelectedFiles(acceptedFiles)
    }, [acceptedFiles])

    const handleOnFileUpload = () => {
        if (selectedFiles) {
            onUpload(selectedFiles, enableAddOrUpdateOption === true ? updateOnly : null)
            setSelectedFiles(undefined)   
        }
    }

    const handleOnCancel = () => {
        setSelectedFiles(undefined)
        onCancelClick()
    }

    const renderSelectedFileInfo = () => {
        if (selectedFiles) {
            return (
                <div className={styles.fileInfoContainer}>
                    {selectedFiles.map((file, index) => {
                        return (
                            <div key={`file_${index}`}>
                                <span>{t('selectedFile')}: </span><span><strong>{file.name}</strong> ({file.size / 1000} kB)</span>    
                            </div>
                        )
                    })}
                </div>
            )
        }
    }

    return (
        <Modal
            title={title}
            visible={visible}
            okButtonTitle={t('upload')}
            okButtonDisabled={!selectedFiles}
            cancelButtonTitle={t('cancel')}
            onHide={handleOnCancel}
            onOkClick={handleOnFileUpload}
            onCancelClick={handleOnCancel}
        >
            {infoText != null && 
            <p>{infoText}</p>
            }
            {enableAddOrUpdateOption && 
            <div className={styles.addOrUpdateOptionContainer}>
                <label>
                    <input
                        type='radio'
                        name='addOrUpdate'
                        value='updateOnly'
                        checked={updateOnly}
                        className={styles.radioButton}
                        onChange={evt => setUpdateOnly(evt.target.value === 'updateOnly')}
                    />
                    {updateOnlyOptionText}
                </label>
                <label>
                    <input
                        type='radio'
                        name='addOrUpdate'
                        value='addAndUpdate'
                        checked={!updateOnly}
                        className={styles.radioButton}
                        onChange={evt => setUpdateOnly(evt.target.value === 'updateOnly')}
                    />
                    {addAndUpdateOptionText}
                </label>
            </div>
            }
            <div className={styles.container}>
                <div {...getRootProps({className: styles.dropzone})}>
                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                        <p>{t(multiFileUpload ? 'dropFilesHere' : 'dropFileHere')}</p> :
                        <p>{t(multiFileUpload ? 'dropFilesHereDescription' : 'dropFileHereDescription')}</p>
                    }
                </div>
            </div>
            {renderSelectedFileInfo()}
        </Modal>
    )
}

export default UploadFileModal

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flex: 1,
    },
    fileInfoContainer: {
        marginTop: 20
    },
    dropzone: {
        display: 'flex',
        height: 200,
        width: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 8,
        borderColor: Colors.gray2,
        backgroundColor: Colors.grayChevron,
        color: Colors.gray2,
        textAlign: 'center'
    },
    addOrUpdateOptionContainer: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
        marginBottom: 20
    },
    radioButton: {
        marginRight: 5
    }
})