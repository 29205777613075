import React from 'react';
import {createUseStyles} from 'react-jss';
import {Colors} from '../constants/colors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import Card from "./Card";

interface CardButtonProps {
  onClick: () => void;
  disabled?: boolean;
  selected: boolean;
  icon: IconProp;
  label: string;
}

const useStyles = createUseStyles({
  cardButton: {
    fontFamily: "Aestetico-SemiBold",
    fontSize: 14,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    gap: 10,
    cursor: 'pointer',
    border: '1px solid',
    borderColor: (props: { disabled?: boolean; selected: boolean }) =>
      props.disabled ? Colors.gray3 : Colors.euro_800,
    transition: '0.4s',
    backgroundColor: (props: { disabled?: boolean; selected: boolean }) =>
      props.selected ? Colors.euro_800 : Colors.grayCard,
    '&:hover': {
      backgroundColor: (props: { disabled?: boolean }) =>
        props.disabled
          ? Colors.grayCard
          : Colors.euro_400
    },
    pointerEvents: (props: { disabled?: boolean }) => props.disabled ? 'none' : 'auto',
  },
  icon: {
    color: (props: { disabled?: boolean; selected: boolean }) =>
      props.selected ? Colors.gray4 : props.disabled ? Colors.gray3 : Colors.euro_900,
  },
  label: {
    color: (props: { disabled?: boolean; selected: boolean }) =>
      props.selected ? Colors.gray4 : props.disabled ? Colors.gray3 : Colors.euro_900,
  }
});

const CardButton = ({onClick, disabled, selected, icon, label}: CardButtonProps) => {
  const styles = useStyles({disabled, selected});

  const handleOnClick = () => {
    if (disabled) {
      return;
    }

    onClick();
  }

  return (
    <div onClick={handleOnClick}>
      <Card className={styles.cardButton}>
        <FontAwesomeIcon size={"2x"} icon={icon} className={styles.icon}/>
        <span className={styles.label}>{label}</span>
      </Card>
    </div>
  );
};

export default CardButton;
