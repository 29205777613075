import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import { Fonts } from '../constants/fonts'
import Card from './Card'

interface Props {
    title: string
    value?: string
    unit?: string
    footer?: string
    color: string
    className?: string
    style?: React.CSSProperties | undefined
}

const ValueCard = ({title, value, unit, footer, color, className, style}: Props) => {
    const styles = useStyles()

    return (
        <Card className={`${styles.container} ${className}`} style={color != null ? {...style, backgroundColor: color} : {...style}}>
            <div className={styles.header}>
                <span>{title}</span>
            </div>
            <div className={styles.content}>
                <span className={styles.contentValue}>{value}</span>
                {unit && <h6 style={{marginBottom: 12}}>{unit}</h6>}
            </div>
            <div className={styles.footer}>
                {footer && <h6>{footer}</h6>}
            </div>
        </Card>
    )
}

export default ValueCard

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
        marginBottom: 20
    },
    header: {
        color: Colors.euro_800,
        fontSize: 13,
        textTransform: 'uppercase'
    },
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
        columnGap: 10,
        width: 220,
    },
    contentValue: {
        fontFamily: Fonts.semiBold,
        fontSize: 36
    },
    footer: {}
})