import { useEffect } from "react"
import { createUseStyles } from 'react-jss'
import { useAppSelector } from "../redux/hooks"
import { useAppDispatch } from '../redux/hooks'
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import Card from "../components/Card"
import { pageIndexChanged, filterChanged } from "../redux/slices/integrationLogSlice"
import useDidUpdateEffect from "../hooks/useDidUpdateEffect"
import useIntegrationLogActions from "../hooks/useIntegrationLogActions"
import { IntegrationLogFilter } from "../redux/types"
import IntegrationLogList from "../components/IntegrationLogList/IntegrationLogList"
import IntegrationLogFilterComponent from "../components/IntegrationLogList/IntegrationLogFilter"
import { useTranslation } from "react-i18next"

const IntegrationLog = () => {
    const styles = useStyles()
    const { t } = useTranslation('integrationLog');
    const dispatch = useAppDispatch()

    const { getIntegrationLogs } = useIntegrationLogActions()
    const loading = useAppSelector(state => state.integrationLog.loading)
    const itemsOnPage = useAppSelector(state => state.integrationLog.itemsOnPage)
    const totalItemCount = useAppSelector(state => state.integrationLog.totalCount)
    const pageIndex = useAppSelector(state => state.integrationLog.pageIndex)
    const pageSize = useAppSelector(state => state.integrationLog.pageSize)
    const filter = useAppSelector(state => state.integrationLog.filter)

    useEffect(() => {
        if (itemsOnPage.length === 0)
            getIntegrationLogs(filter ?? {level: null, txId: null, fromDate: null, toDate: null}, pageIndex, pageSize)
    }, [])

    useDidUpdateEffect(() => {
        getIntegrationLogs(filter ?? {level: null, txId: null, fromDate: null, toDate: null}, pageIndex, pageSize)
    }, [filter, pageIndex])

    const onPageChange = (pageIndex: number) => {
        dispatch(pageIndexChanged(pageIndex))
    }

    const handleFilterChange = (filter: IntegrationLogFilter) => {
        dispatch(filterChanged(filter))
    }

    return (
        <>
        <Card className={styles.card}>
            <h2>
                {t('title')}
            </h2>
        </Card>
        <Card>
            <div className={styles.buttonContainer}>
                <IntegrationLogFilterComponent
                    initialFilterValue={filter ?? undefined}
                    onFilterChange={handleFilterChange} 
                />
            </div>
            {itemsOnPage && 
            <IntegrationLogList 
                itemsOnPage={itemsOnPage}
                pageIndex={pageIndex}
                pageSize={pageSize} 
                totalItemCount={totalItemCount}
                onPageChange={onPageChange} 
            />}
            <FullScreenLoadingIndicator visible={loading} />
        </Card>
        </>
    )
} 

export default IntegrationLog

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap-reverse',
        justifyContent: 'space-between',
        alignContent: 'flex-end',
        padding: 10
    },
    button: {
        alignSelf: 'flex-end'
    }
})