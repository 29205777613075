import { apiConfig } from "../../apiConfig"
import { useAppDispatch } from "../../redux/hooks"
import { 
    fileUploaded,
    fileUploadFailure,
    loading 
} from "../../redux/slices/integrations/tengellaIntegrationSlice"
import useFetchClient from "../useFetchClient"

const useTengellaIntegrationActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()
  
    const uploadFile = async (file: File) => {
      dispatch(loading())
  
      const url = apiConfig.tengellaIntegrationBaseUrl
  
      return fetchClient.postFile(url, file)
        .then(response => {
            dispatch(fileUploaded())
        })
        .catch(error => {
            console.log('uploadFile error:', error)
            dispatch(fileUploadFailure(error))
        })
    }
        
    return {
      uploadFile
    }
  }
  
  export default useTengellaIntegrationActions
