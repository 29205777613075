export const isDateValid = (dateStr: string) => {
    const date = Date.parse(dateStr)
    return !isNaN(date)
}

export const formatDateTimeString = (dateStr: string | undefined): string => {
    if (!dateStr)
        return ''

    const date = new Date(dateStr) 

    const year = date.getFullYear().toString()
    const month = (date.getMonth()+1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hour = date.getHours().toString().padStart(2, '0')
    const minute = date.getMinutes().toString().padStart(2, '0')

    return `${year}-${month}-${day} ${hour}:${minute}`
}

export const formatDateString = (dateStr: string | undefined): string => {
    if (!dateStr)
        return ''

    const date = new Date(dateStr) 

    const year = date.getFullYear().toString()
    const month = (date.getMonth()+1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')

    return `${year}-${month}-${day}`
}

export const formatTimeString = (dateStr: string | undefined): string => {
    if (!dateStr)
        return ''

    const date = new Date(dateStr) 
    const hour = date.getHours().toString().padStart(2, '0')
    const minute = date.getMinutes().toString().padStart(2, '0')

    return `${hour}:${minute}`
}
