import { usePagination, DOTS } from '../hooks/usePagination'
import { createUseStyles } from 'react-jss'
import { Fonts } from '../constants/fonts'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Colors } from '../constants/colors'
import { useTranslation } from 'react-i18next'

interface Props {
    itemCount: number
    pageIndex: number,
    pageSize: number,
    siblingCount: number,
    onPageChange: (pageIndex: number) => void
}

const Pagination = ({itemCount, pageIndex, pageSize, siblingCount, onPageChange}: Props) => {
    const styles = useStyles()
    const paginationRange = usePagination({itemCount, pageIndex, pageSize, siblingCount})
    const { t } = useTranslation('common');

    if (pageIndex === 0 || !paginationRange || paginationRange.length < 2) {
        return null
    }

    const onNextClick = () => {
        onPageChange(pageIndex + 1)
    };

    const onPreviousClick = () => {
        onPageChange(pageIndex - 1)
    };

    let lastPageIndex = paginationRange[paginationRange.length - 1]

    return (
        <div>
            {pageIndex !== 0 && paginationRange && paginationRange.length > 1 &&
            <ul className={styles.container}>
                <li key='left' className={`${styles.item} ${pageIndex === 1 ? styles.disabled : ''}`} onClick={() => pageIndex > 1 && onPreviousClick()}>
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        color={pageIndex > 1 ? Colors.gray1 : Colors.grayDisabled}
                    />
                </li>
                {paginationRange.map(pageNumber => {
                    if (pageNumber === DOTS) {
                        return <li key='dots' className={styles.dots}>&#8230;</li>
                    }

                    return (
                        <li key={`page${pageNumber}`} className={`${styles.item} ${pageIndex === pageNumber ? styles.selected : ''}`} onClick={() => onPageChange(pageNumber as number)}>
                            {pageNumber}
                        </li>
                    )
                })}
                <li key='right' className={`${styles.item} ${pageIndex === lastPageIndex ? styles.disabled : ''}`} onClick={() => pageIndex !== lastPageIndex && onNextClick()}>
                    <FontAwesomeIcon
                        icon={faChevronRight}
                        color={pageIndex !== lastPageIndex ? Colors.gray1 : Colors.grayDisabled}
                    />
                </li>
            </ul>
            }
            <div className={styles.itemCountContainer}>
                {t('rowCount')}: {itemCount}
            </div>
        </div>
    )
}

export default Pagination

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        listStyleType: 'none',
        padding: 0
    },
    itemCountContainer: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 14,
        marginTop: -12
    },
    item: {
        display: 'flex',
        padding: '0 12px',
        height: '32px',
        textAlign: 'center',
        margin: 'auto 4px',
        color: 'rgba(0, 0, 0, 0.87)',
        boxSizing: 'border-box',
        alignItems: 'center',
        letterSpacing: '0.01071em',
        borderRadius: 8,
        lineHeight: 1.43,
        fontSize: '13px',
        minWidth: '32px',
        cursor: 'pointer'
    },
    dots: {

    },
    selected: {
        fontFamily: Fonts.semiBold,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        }
    },
    disabled: {
        cursor: 'default'
    }
})