import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'

interface Props {
    style?: {},
    className?: string,
    number: number
}

const NumberDot = ({number, className, style} : Props) => {
    const styles = useStyles()

    return (
        <div className={`${styles.container} ${className ?? ''}`} style={style && {...style}}>
            <p className={styles.text}>{number}</p>
        </div>
    )
}

export default NumberDot

const notificationDotSize = 17

const useStyles = createUseStyles({
    container: {
        position: 'absolute',
        width: notificationDotSize,
        height: notificationDotSize,
        borderRadius: 8,
        borderColor: '#ffffff',
        backgroundColor: Colors.rubel_700,
        top: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    text: {
        fontSize: notificationDotSize * 0.62,
        lineHeight: 1.81,
        color: '#ffffff',
        textAlign: 'center'
    }
})