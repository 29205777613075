import Modal from "../Modal"
import { createUseStyles } from 'react-jss'
import { useEffect, useState } from "react"
import { PayrollFileRow } from "../../redux/types"
import PayrollFileRowList from "./PayrollFileRowList"
import { useTranslation } from "react-i18next"

interface Props {
    visible: boolean
    rows: PayrollFileRow[]
    onCloseClick: () => void
}

const PayrollFileRowsModal = ({ visible, rows, onCloseClick}: Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'payrolls']);

    const [pageIndex, setPageIndex] = useState<number>(1)

    useEffect(() => {
        setPageIndex(1)
    }, [rows])

    const handleOnClose = () => {
        onCloseClick()
    }

    return (
        <Modal
            title={t('payrollFileContent', {ns: 'payrolls'})}
            visible={visible}
            okButtonTitle={t('close', {ns: 'common'})}
            hideCancelButton={true}
            onHide={handleOnClose}
            onOkClick={handleOnClose}
            onCancelClick={handleOnClose}
        >
            <div className={styles.container}>
               <PayrollFileRowList 
                    pageIndex={pageIndex}
                    pageSize={15}
                    rows={rows}
                    onPageChange={(pageIndex: number) => setPageIndex(pageIndex)}
               />
            </div>
        </Modal>
    )
}

export default PayrollFileRowsModal

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
})