import {
  EmployerInitiatedPayoutJobListItem,
  EmployerInitiatedPayoutJobState,
  Payroll,
  UpcomingPayroll
} from "../../redux/types";
import {useAppSelector} from "../../redux/hooks";
import {useMemo} from "react";


export class PayrollItem {
  _type: 'payroll' | 'upcomingPayroll' | 'employerInitiatedPayout';
  _data: Payroll | UpcomingPayroll | EmployerInitiatedPayoutJobListItem;

  constructor(type: 'payroll' | 'upcomingPayroll' | 'employerInitiatedPayout', data: Payroll | UpcomingPayroll | EmployerInitiatedPayoutJobListItem) {
    this._type = type;
    this._data = data;
  }

  static createPayrollItem(data: Payroll): PayrollItem {
    return new PayrollItem('payroll', data);
  }

  static createUpcomingPayrollItem(data: UpcomingPayroll): PayrollItem {
    return new PayrollItem('upcomingPayroll', data);
  }

  static createEmployerInitiatedPayoutItem(data: EmployerInitiatedPayoutJobListItem): PayrollItem {
    return new PayrollItem('employerInitiatedPayout', data);
  }

  get date(): string {
    switch (this.type) {
      case 'payroll':
        return (this._data as Payroll).paydayDate || '';
      case 'upcomingPayroll':
        return (this._data as UpcomingPayroll).paymentDate || '';
      case 'employerInitiatedPayout':
        return (this._data as EmployerInitiatedPayoutJobListItem).scheduledDate || '';
    }
  }

  get type(): 'payroll' | 'upcomingPayroll' | 'employerInitiatedPayout' {
    return this._type;
  }

  get payroll(): Payroll {
    return this._data as Payroll;
  }

  get upcomingPayroll(): UpcomingPayroll {
    return this._data as UpcomingPayroll;
  }

  get employerInitiatedPayout(): EmployerInitiatedPayoutJobListItem {
    return this._data as EmployerInitiatedPayoutJobListItem;
  }
}


const usePayrollItems = () => {
  const payrolls = useAppSelector(state => state.payroll.payrolls)
  const upcomingPayrolls = useAppSelector(state => state.payroll.upcomingPayrolls)

  const historicPayrollItems = useMemo(() => payrolls.map(PayrollItem.createPayrollItem), [payrolls])
  const upcomingPayrollItems = useMemo(() => upcomingPayrolls.map(PayrollItem.createUpcomingPayrollItem), [upcomingPayrolls])

  const employerInitiatedPayouts = useAppSelector(state => state.employerInitiatedPayouts.jobs)

  const {upcomingEmployerInitiatedPayoutItems, historicEmployerInitiatedPayoutItems} = useMemo(() => {
    const upcoming = []
    const historic = []

    for (const payout of employerInitiatedPayouts) {
      const item = PayrollItem.createEmployerInitiatedPayoutItem(payout)
      if (payout.state === EmployerInitiatedPayoutJobState.scheduled) {
        upcoming.push(item)
      } else {
        historic.push(item)
      }
    }

    return {
      upcomingEmployerInitiatedPayoutItems: upcoming,
      historicEmployerInitiatedPayoutItems: historic
    }
  }, [employerInitiatedPayouts])

  const historicPayrollPageItems = useMemo(() => {
    return [...historicPayrollItems, ...historicEmployerInitiatedPayoutItems].sort((a, b) => {
      return new Date(b.date).getTime() - new Date(a.date).getTime()
    })
  }, [historicPayrollItems, historicEmployerInitiatedPayoutItems])

  const upcomingPayrollPageItems = useMemo(() => {
    return [...upcomingPayrollItems, ...upcomingEmployerInitiatedPayoutItems].sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime()
    })
  }, [upcomingPayrollItems, upcomingEmployerInitiatedPayoutItems])

  return {
    historicPayrollPageItems,
    upcomingPayrollPageItems
  }
}

export default usePayrollItems
