export const createUrlWithQueryString = (url: string, queryStringParams: any) => {
    const uriEncoder = encodeURIComponent

    const queryString = Object.keys(queryStringParams)
      .map(key => {
        const value = queryStringParams[key]
        return value ? uriEncoder(key) + '=' + uriEncoder(value) : null
      })
      .filter(val => !!val)
      .join('&')

    return url + '?' + queryString
  }
