import { useEffect, useMemo, useState } from "react"
import { createUseStyles } from 'react-jss'
import { useAppSelector } from "../redux/hooks"
import { useAppDispatch } from '../redux/hooks'
import { BulkOperationResult, EditEmployeeModel, Employee, EmployeeDataParserType, EmployeeFilter, EmployeeFlagType } from "../redux/types"
import Button from '../components/Button'
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import Card from "../components/Card"
import useEmployeeActions from "../hooks/useEmployeeActions"
import EmployeeList from "../components/EmployeeList/EmployeeList"
import EditEmployeeModal from "../components/EditEmployeeModal"
import EmployeeFilterComponent from "../components/EmployeeList/EmployeeFilter"
import { errorHandled, filterChanged, pageIndexChanged } from "../redux/slices/employeeSlice"
import { useTranslation } from "react-i18next"
import useDidUpdateEffect from "../hooks/useDidUpdateEffect"
import UploadFileModal from "../components/UploadFileModal"
import ErrorModal from "../components/ErrorModal"
import SuccessModal from "../components/SuccessModal"
import {useNavigate} from "react-router-dom";

const Employees = () => {
    const styles = useStyles()
    const { t } = useTranslation(['employees', 'common']);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [addModalVisible, setAddModalVisible] = useState<boolean>(false)
    const [editModalVisible, setEditModalVisible] = useState<boolean>(false)
    const [editedEmployee, setEditedEmployee] = useState<Employee>()
    const [uploadFileModalVisible, setUploadFileModalVisible] = useState<boolean>(false)
    const [uploadFileDataType, setUploadFileDataType] = useState<EmployeeDataParserType>()
    const [errorModalVisible, setErrorModalVisible] = useState<boolean>(false)
    const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false)

    const [bulkOperationResult, setBulkOperationResult] = useState<BulkOperationResult>()

    const { getEmployees, createEmployee, updateEmployee, bulkUploadEmployees } = useEmployeeActions()
    const loading = useAppSelector(state => state.employee.loading)
    const employees = useAppSelector(state => state.employee.employees)
    const pageIndex = useAppSelector(state => state.employee.pageIndex)
    const pageSize = useAppSelector(state => state.employee.pageSize)
    const filter = useAppSelector(state => state.employee.filter)
    const error = useAppSelector(state => state.employee.error)

    const employer = useAppSelector(state => state.employer.employer)

    const wasmEmployeeDataParsers = ['visma600UpdateOnly', 'visma600CreateAndUpdate']

    const filteredEmployees = useMemo(() => {
        if (filter && ((filter.term && filter.term?.length > 0) || filter.statuses !== undefined || filter.categories !== undefined || filter.departments !== undefined || filter.flags !== undefined)) {
            const lowerCaseSearchTerm = filter.term?.toLowerCase().trimStart().trimEnd() ?? undefined
            return employees.filter((employee) =>
                (!lowerCaseSearchTerm ||
                    employee.givenName?.toLowerCase().includes(lowerCaseSearchTerm) ||
                    employee.familyName?.toLowerCase().includes(lowerCaseSearchTerm) ||
                    (employee.givenName?.toLowerCase() + ' ' + employee.familyName?.toLowerCase()).includes(lowerCaseSearchTerm) ||
                    employee.externalId.toLowerCase().includes(lowerCaseSearchTerm) ||
                    employee.identificationNumber.toLowerCase().includes(lowerCaseSearchTerm)) &&
                (filter.statuses.includes(employee.status)) &&
                (filter.categories.includes(employee.category ?? '')) &&
                (filter.departments.includes(employee.department ?? '')) &&
                (filter.flags.length == 0 ||
                    (filter.flags.includes(EmployeeFlagType.PayoutLimitation) && (employee.payoutSettings?.maxAmountPerPayday != null || employee.payoutSettings?.maxPercentagePerPayday != null || employee.payoutSettings?.maxCustomPercentagePerPayday != null)) ||
                    (filter.flags.includes(EmployeeFlagType.UnderForeclosure) && employee.underForeclosure) ||
                    (filter.flags.includes(EmployeeFlagType.NonEligible) && employee.nonEligible) ||
                    (filter.flags.includes(EmployeeFlagType.UnsettledPayouts) && employee.hasUnsettledPayouts)
                )
                )
        }
        return employees;
    }, [employees, filter])

    useEffect(() => {
        if (employees.length === 0) {
            getEmployees()
        }
    }, [])

    useDidUpdateEffect(() => {
        if (error) {
            setErrorModalVisible(true)
        }
    }, [error])

    const handleError = () => {
        dispatch(errorHandled())
        setErrorModalVisible(false)
    }

    const onAddClick = () => {
        setAddModalVisible(true)
    }

    const handleAdded = (employee: EditEmployeeModel) => {
        createEmployee(employee)
        setAddModalVisible(false)
    }

    const onEditClick = (employee: Employee) => {
        setEditedEmployee(employee)
        setEditModalVisible(true)
    }

    const handleEdited = (editModel: EditEmployeeModel) => {
        updateEmployee((editedEmployee as Employee).id, editModel)
        setEditedEmployee(undefined)
        setEditModalVisible(false)
    }

    const handleEditCancelled = () => {
        setEditedEmployee(undefined)
        setEditModalVisible(false)
    }

    const handleFileUploaded = (file: File, onlyUpdates: boolean) => {
        bulkUploadEmployees(file, uploadFileDataType as EmployeeDataParserType, onlyUpdates, (result: BulkOperationResult) => {
            setBulkOperationResult(result)
            getEmployees()
            setSuccessModalVisible(true)
        })
        setUploadFileModalVisible(false)
    }

    const onPageChange = (pageIndex: number) => {
        dispatch(pageIndexChanged(pageIndex))
    }

    const handleFilterChange = (filter: EmployeeFilter) => {
        dispatch(filterChanged(filter))
    }

    const handleUploadFileButtonClick = (dataType: EmployeeDataParserType) => {
        setUploadFileDataType(dataType)
        setUploadFileModalVisible(true)
    }

    return (
        <>
        <Card className={styles.card}>
            <h2>
                {t('title', {ns: 'employees'})}
            </h2>
        </Card>
        <Card>
            <div className={styles.buttonContainer}>
                <Button onClick={onAddClick} title={t('addEmployee', {ns: 'employees'})} className={styles.button}  />
                <Button onClick={() => handleUploadFileButtonClick("csv")} title={t('uploadCsvFile', {ns: 'common'})} className={styles.button}  />

                {employer && wasmEmployeeDataParsers.includes(employer.employeeDataParserType) &&
                    <Button onClick={() => navigate('/employees/import')}
                        title={t('uploadCustomFileTemplate', {
                        ns: 'common',
                        type: t(`parser_${employer.employeeDataParserType}`, 'employees')
                    })} className={styles.button}/>
                }

                {employer && employer.employeeDataParserType !== "csv" && !wasmEmployeeDataParsers.includes(employer.employeeDataParserType) &&
                    <Button onClick={() => handleUploadFileButtonClick(employer.employeeDataParserType)}
                        title={t('uploadCustomFileTemplate', {
                              ns: 'common',
                              type: employer.employeeDataParserType.toString().toUpperCase()
                        })} 
                        className={styles.button}/>
                }
            </div>
            <div className={styles.filterContainer}>
                <EmployeeFilterComponent
                    initialFilterValue={filter ?? undefined}
                    onFilterChange={handleFilterChange}
                />
            </div>
            {employees &&
            <EmployeeList
                employees={filteredEmployees}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onEditClick={onEditClick}
                onPageChange={onPageChange}
            />}
            <EditEmployeeModal
                key='addModal'
                visible={addModalVisible}
                onCancelClick={() => setAddModalVisible(false)}
                onSaveClick={handleAdded}
            />
            <EditEmployeeModal
                key='editModal'
                initialValue={editedEmployee}
                visible={editModalVisible}
                onCancelClick={handleEditCancelled}
                onSaveClick={handleEdited}
            />
            <UploadFileModal
                visible={uploadFileModalVisible}
                multiFileUpload={false}
                enableAddOrUpdateOption={true}
                updateOnlyOptionText={t('updateExistingEmployees', {ns: 'employees'})}
                addAndUpdateOptionText={t('addNewAndUpdateExistingEmployees', {ns: 'employees'})}
                title={t('uploadFile', {ns: 'employees'})}
                infoText={t('uploadFileDescription', {ns: 'employees'})}
                onCancelClick={() => setUploadFileModalVisible(false)}
                onUpload={(files, onlyUpdates) => handleFileUploaded(files[0], onlyUpdates ?? true)}
            />
            <ErrorModal
                visible={errorModalVisible}
                errorText={error?.toString()}
                onCloseClick={handleError}
            />
            <SuccessModal
                visible={successModalVisible}
                bulkOperationResult={bulkOperationResult}
                onCloseClick={() => setSuccessModalVisible(false)}
            />
            <FullScreenLoadingIndicator visible={loading} />
        </Card>
        </>
    )
}

export default Employees

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: 20,
        columnGap: 10
    },
    filterContainer: {
        marginBottom: 20,
    },
    button: {
        alignSelf: 'flex-end'
    }
})
