import { createUseStyles } from 'react-jss'
import Card from '../../components/Card'
import { Link, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const PasswordMailSent = () => {
    const [searchParams] = useSearchParams();
    const type = searchParams.get('type')
    const styles = useStyles()
    const { t } = useTranslation(['common', 'password']);

    return (
        <div className={styles.container}>
            <Card className={styles.card}>
                {type === 'create' && 
                <>
                    <h3 className={styles.title}>{t('requestProcessed', {ns: 'password'})}</h3>
                    <p>{t('createRequestProcessedSubtitle', {ns: 'password'})}</p>
                </>
                }
                {type !== 'create' && 
                <>
                   <h3 className={styles.title}>{t('requestProcessed', {ns: 'password'})}</h3>
                    <p>{t('forgotRequestProcessedSubtitle', {ns: 'password'})}</p>
                </>
                }
                <div> 
                    <Link to={'/login'}>{t('backToLoginPage', {ns: 'password'})}</Link>
                </div>
            </Card>
        </div>
    )
}

export default PasswordMailSent

const useStyles = createUseStyles({
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        marginBottom: 30
    },
    card: {
        width: 450,
        padding: 60,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    cardRow: {
        marginTop: 10,
        marginBottom: 10
    }
})