import { useEffect, useState } from "react"
import { Employee, EmployeeFilter as EmployeeFilterType, EmployeeFlagType, EmployeeStatus } from "../../redux/types"
import InputField from "../InputField"
import { createUseStyles } from 'react-jss'
import { Breakpoints } from "../../constants/breakpoints"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Colors } from "../../constants/colors"
import { useTranslation } from "react-i18next"
import Multiselect, { SelectOption } from "../Multiselect"
import { useAppSelector } from "../../redux/hooks"
import { setEqual } from "../../util/set"

interface Props {
    initialFilterValue: EmployeeFilterType | undefined
    onFilterChange: (filter: EmployeeFilterType) => void
}

const EmployeeFilter = ({initialFilterValue, onFilterChange}: Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'employees']);

    const defaultEmployeeStatuses = [EmployeeStatus.Active, EmployeeStatus.Connected, EmployeeStatus.Paused, EmployeeStatus.Registered]
    const defaultFlagValues: EmployeeFlagType[] = []

    const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([])
    const [departmentOptions, setDepartmentOptions] = useState<SelectOption[]>([])

    const [filterValue, setFilterValue] = useState<string | undefined>(initialFilterValue?.term)
    const [selectedDepartments, setSelectedDepartments] = useState<string[]>(initialFilterValue?.departments ?? [])
    const [selectedCategories, setSelectedCategories] = useState<string[]>(initialFilterValue?.categories ?? [])
    const [selectedStatuses, setSelectedStatuses] = useState<EmployeeStatus[]>(initialFilterValue?.statuses ?? defaultEmployeeStatuses)
    const [selectedFlags, setSelectedFlags] = useState<EmployeeFlagType[]>(initialFilterValue?.flags ?? defaultFlagValues)

    const employees = useAppSelector(state => state.employee.employees)

    const noValueOption = t('noValueOption', {ns: 'common'})

    useEffect(() => {
        if (employees.length !== 0) {
            let dirty = false
            
            const employeeCategories = getEmployeeCategoryOptions(employees)
            const currentCategoryValues = categoryOptions.map((item) => {return item.value})
            const newCategoryValues = employeeCategories.map((item) => {return item.value})

            if (!setEqual(new Set(currentCategoryValues), new Set(newCategoryValues))) {
                setCategoryOptions(employeeCategories)
                dirty = true
            }

            const employeeDepartments = getEmployeeDepartmentOptions(employees)
            const currentDepartmentValues = departmentOptions.map((item) => {return item.value})
            const newDepartmentValues = employeeDepartments.map((item) => {return item.value})

            if (!setEqual(new Set(currentDepartmentValues), new Set(newDepartmentValues))) {
                setDepartmentOptions(employeeDepartments)
                dirty = true
            }

            if (dirty) {
                const categorySelection = employeeCategories.map((item) => {return item.value})
                setSelectedCategories(categorySelection)   

                const departmentSelection = employeeDepartments.map((item) => {return item.value})
                setSelectedDepartments(departmentSelection)

                onFilterChange({
                    term: filterValue, 
                    departments: departmentSelection, 
                    categories: categorySelection,
                    statuses: selectedStatuses,
                    flags: selectedFlags})
            }
            
        }
    }, [employees])

    useEffect(() => {
        let employeeCategories = selectedCategories
        let employeeDepartments = selectedDepartments

        if (initialFilterValue?.categories == null)
        {
            employeeCategories = getEmployeeCategoryOptions(employees).map((item) => {return item.value})
            setSelectedCategories(employeeCategories)
        }

        if (initialFilterValue?.departments == null)
        {
            employeeDepartments = getEmployeeDepartmentOptions(employees).map((item) => {return item.value})
            setSelectedDepartments(employeeDepartments)
        }

        onFilterChange({
            term: filterValue, 
            departments: employeeDepartments, 
            categories: employeeCategories,
            statuses: selectedStatuses,
            flags: selectedFlags})
    }, []) 

    const handleFilter = (value: string) => {
        setFilterValue(value)
        onFilterChange({
            term: value, 
            departments: selectedDepartments, 
            categories: selectedCategories,
            statuses: selectedStatuses,
            flags: selectedFlags})
    }

    const handleSelectedStatuses = (values: string[]) => {
        var statuses = values.map((value) => {return value as EmployeeStatus})
            
        setSelectedStatuses(statuses)
        onFilterChange({
            term: filterValue, 
            departments: selectedDepartments, 
            categories: selectedCategories,
            statuses: statuses,
            flags: selectedFlags})
    }

    const handleSelectedFlags = (values: string[]) => {
        var flags = values.map((value) => {return value as EmployeeFlagType})
            
        setSelectedFlags(flags)
        onFilterChange({
            term: filterValue, 
            departments: selectedDepartments, 
            categories: selectedCategories,
            statuses: selectedStatuses,
            flags: flags})
    }

    const handleSelectedDepartments = (values: string[]) => {        
        setSelectedDepartments(values)      
        onFilterChange({
            term: filterValue, 
            departments: values, 
            categories: selectedCategories,
            statuses: selectedStatuses,
            flags: selectedFlags})
    }

    const handleSelectedCategories = (values: string[]) => {
        setSelectedCategories(values)      
        onFilterChange({
            term: filterValue, 
            departments: selectedDepartments, 
            categories: values,
            statuses: selectedStatuses,
            flags: selectedFlags})
    }

    const getStatusOptions = () => {
        let values = Object.values(EmployeeStatus).map((value) => value.toString())
        const options = values.map((value) => { return { name: t(`employeeStatus_${value}`, {ns: 'employees'}), value: value }})
        return options
    }

    const getFlagOptions = () => {
        let values = Object.values(EmployeeFlagType).map((value) => value.toString())
        const options = values.map((value) => { return { name: t(value, {ns: 'employees'}), value: value }})
        return options
    }

    const getEmployeeCategoryOptions = (employees: Employee[]) => {
        let distinctCategories = [...new Set(employees.filter(x => x.category != null && x.category.length > 0).map(item => item.category))].sort()
        const options = distinctCategories.map((item) => { return {name: item, value: item} })
        options.unshift({name: noValueOption, value: ''})
        return options
    }

    const getEmployeeDepartmentOptions = (employees: Employee[]) => {
        let distinctDepartments = [...new Set(employees.filter(x => x.department != null && x.department.length > 0).map(item => item.department))].sort()
        const options = distinctDepartments.map((item) => { return {name: item, value: item} })
        options.unshift({name: noValueOption, value: ''})
        return options
    }

    const clearFilters = () => {
        let employeeCategories = getEmployeeCategoryOptions(employees).map((item) => {return item.value})
        let employeeDepartments = getEmployeeDepartmentOptions(employees).map((item) => {return item.value})

        setFilterValue(undefined)
        setSelectedStatuses(defaultEmployeeStatuses)
        setSelectedFlags(defaultFlagValues)
        setSelectedCategories(employeeCategories)
        setSelectedDepartments(employeeDepartments)

        onFilterChange({
            term: undefined, 
            departments: employeeDepartments, 
            categories: employeeCategories, 
            statuses: defaultEmployeeStatuses, 
            flags: defaultFlagValues})   
    }

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <Multiselect 
                    id='categories'
                    key='categories'
                    label={t('category', {ns: 'common'})}
                    options={categoryOptions} 
                    selectedValues={selectedCategories}
                    onSelect={handleSelectedCategories}
                />
            </div>
            <div className={styles.field}>
                <Multiselect 
                    id='departments'
                    key='departments'
                    label={t('department', {ns: 'common'})}
                    options={departmentOptions} 
                    selectedValues={selectedDepartments}
                    onSelect={handleSelectedDepartments}
                />
            </div>
            <div className={styles.field}>
                <Multiselect 
                    id='statuses'
                    key='statuses'
                    label={t('status', {ns: 'common'})}
                    options={getStatusOptions()} 
                    selectedValues={selectedStatuses}
                    onSelect={handleSelectedStatuses}
                />
            </div>
            <div className={styles.field}>
                <Multiselect 
                    id='flags'
                    key='flags'
                    label={t('flags', {ns: 'common'})}
                    options={getFlagOptions()} 
                    selectedValues={selectedFlags}
                    onSelect={handleSelectedFlags}
                />
            </div>
            <div className={styles.field}>
                <InputField
                    id='searchField'
                    value={filterValue}
                    onEnterKeyOrBlur={handleFilter}
                    label={t('search', {ns: 'common'})}
                    placeholder=''
                />
            </div>
            <div className={styles.clearFiltersContainer}>
                <div className={styles.clearFiltersButton} onClick={() => clearFilters()} title={t('clearFilters', {ns: 'common'})}>
                    <FontAwesomeIcon
                        size='lg'
                        icon={faTimes}
                        color={Colors.gray1}
                    />
                </div>
            </div>
        </div>
    )
}

export default EmployeeFilter

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'flex-start',
    },
    field: {
        width: '100%',    
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            width: 280,
            marginRight: 30,
        },
    },
    clearFiltersContainer: {
        alignSelf: 'flex-end' 
     },
     clearFiltersButton: {
         marginBottom: 8,
         '&:hover': {
             cursor: 'pointer',
         }
     }
  })