import { useTranslation } from "react-i18next"
import { createUseStyles } from "react-jss"
import { Colors } from "../../constants/colors"
import { PayScheduleModel, PaySchedulePaydayModel } from "../../redux/types"
import Card from "../Card"
import InfoCard from "../InfoCard"
import InfoIcon from "../InfoIcon"
import PaydayList from "./PaydayList"

interface Props {
    paySchedule: PayScheduleModel,
    onEditPaydayClick: (payday: PaySchedulePaydayModel) => void
}

const PaySchedule = ({paySchedule, onEditPaydayClick}: Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'paySchedules']);

    return (
        <Card className={styles.container}>
            <div className={styles.infoCardContainer}>
                <InfoCard title={t('general', {ns: 'paySchedules'})} className={styles.infoCard}>
                    <div className={styles.row}>
                        <div className={styles.label}>{t('id', {ns: 'common'})}</div>
                        <div>{paySchedule.identifier}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>{t('periodicity', {ns: 'paySchedules'})}</div>
                        <div>{t(`periodicity_${paySchedule.periodicity}`, {ns: 'paySchedules'})}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>{t('isDefault', {ns: 'paySchedules'})}</div>
                        <div>{paySchedule.isDefault ? t('yes', {ns: 'common'}) : t('no', {ns: 'common'})}</div>
                    </div>
                </InfoCard> 
                <InfoCard title={t('periodSettings', {ns: 'paySchedules'})} className={styles.infoCard}>
                    <div className={styles.row}>
                        <div className={styles.label}>{t('periodStartDayOfMonth', {ns: 'paySchedules'})}</div>
                        <div>{paySchedule.periodStartDayOfMonth}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>{t('defaultPaymentDayOfMonth', {ns: 'paySchedules'})}</div>
                        <div>{paySchedule.defaultPaymentDayOfMonth}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>{t('paydayOnWeekendHandling', {ns: 'paySchedules'})}</div>
                        <div>{t(`paydayOnWeekendHandling_${paySchedule.paydayOnWeekendHandling}`, {ns: 'paySchedules'})}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>{t('deviationPeriodEndOffset', {ns: 'paySchedules'})} <InfoIcon text={t('deviationPeriodEndOffsetDescription', {ns: 'paySchedules'})} /></div>
                        <div>{paySchedule.deviationPeriodEndOffset}</div>
                    </div>
                </InfoCard> 
                <InfoCard title={t('salarySettings', {ns: 'paySchedules'})} className={styles.infoCard}>
                    <div className={styles.row}>
                        <div className={styles.label}>{t('salaryAccrual', {ns: 'paySchedules'})}</div>
                        <div>{t(`salaryAccrual_${paySchedule.salaryAccrualType}`, {ns: 'paySchedules'})}</div>
                    </div>
                    <div className={styles.row}>
                        <div className={styles.label}>{t('salaryEarningOnWeekends', {ns: 'paySchedules'})} <InfoIcon text={t('salaryEarningOnWeekendsDescription', {ns: 'paySchedules'})} /></div>
                        <div>{paySchedule.salaryEarningOnWeekends ? t('yes', {ns: 'common'}) : t('no', {ns: 'common'})}</div>
                    </div>
                </InfoCard> 
            </div>
            <div>
                <PaydayList 
                    paydays={paySchedule?.paydays}
                    onEditClick={onEditPaydayClick} 
                />
            </div>
        </Card>
    )
}

export default PaySchedule

const useStyles = createUseStyles({
    container: {
        marginBottom: 40
    },
    infoCardContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        columnGap: 20
    },
    infoCard: {
        flex: 1,
        backgroundColor: Colors.grayBackgroundApp
    },
    row: {
        flex: 1,
        marginBottom: 15,
    },
    label: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 10,
        textTransform: 'uppercase',
        fontSize: 13,
        color: Colors.gray3
    },
    '& p': {
        fontSize: 12,
        margin: 0,
        '& span': {
            fontFamily: 'Aestetico-Bold',
            color: Colors.gray1,
        }
    }
})