import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ForaAdjustmentPeriodSummary, ForaAdjustmentsForPeriod, ForaAdjustmentsModel } from '../types'

interface ForaAdjustmentState {
    loading: boolean
    currentPeriod: string | null
    isCurrentPeriodAdjusted: boolean
    periods: ForaAdjustmentPeriodSummary[]
    pageIndex: number
    pageSize: number

    periodDetails: ForaAdjustmentsForPeriod | null
    periodDetailsPageIndex: number
    periodDetailsPageSize: number
    error: Error | string | null
}

const initialState: ForaAdjustmentState = {
    loading: false,
    currentPeriod: null,
    isCurrentPeriodAdjusted: false,
    periods: [],
    pageIndex: 1,
    pageSize: 15,
    periodDetails: null,
    periodDetailsPageIndex: 1,
    periodDetailsPageSize: 15,
    error: null
}

export const foraAdjustmentSlice = createSlice({
    name: 'foraAdjustment',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        periodsFetched: (state, action: PayloadAction<ForaAdjustmentsModel>) => {
            state.loading = false
            state.currentPeriod = action.payload.currentPeriod
            state.isCurrentPeriodAdjusted = action.payload.isCurrentPeriodAdjusted
            state.periods = action.payload.adjustedPeriods
            state.error = null
        },
        periodsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        periodDetailsFetched: (state, action: PayloadAction<ForaAdjustmentsForPeriod>) => {
            state.loading = false
            state.periodDetails = action.payload
            state.error = null
        },
        periodDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        periodDetailsPageIndexChanged: (state, action: PayloadAction<number>) => {
            state.periodDetailsPageIndex = action.payload
        }
    }
})

export const { 
    loading, 
    periodsFetched, 
    periodsFetchFailure, 
    periodDetailsFetched, 
    periodDetailsFetchFailure,
    pageIndexChanged,
    periodDetailsPageIndexChanged
} = foraAdjustmentSlice.actions

export default foraAdjustmentSlice.reducer