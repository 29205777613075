import { ReactNode } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'

interface Props {
    children: ReactNode
    index?: number
    className?: string
    style?: React.CSSProperties
    onClick?: () => void | undefined
}

const ListItemCard = ({ children, index, className, style, onClick } : Props) => {
    const classes = useStyles()
    return (
        <div className={`${classes.container} ${className} ${(index ?? 1) % 2 === 0 ? classes.alternatingRow1 : classes.alternatingRow2} ${onClick != null ? classes.clickable : ''}`} style={style} onClick={() => onClick && onClick()}>
            {children}
        </div>
    )
}

export default ListItemCard

const useStyles = createUseStyles({
    container: {
        backgroundColor: Colors.grayCard,
        borderColor: Colors.grayCard,
        borderWidth: 2,
        borderRadius: 5,
        padding: 10
    },
    alternatingRow1: {
        backgroundColor: Colors.grayBorder    
    },
    alternatingRow2: {
        backgroundColor: Colors.gray4    
    },
    clickable: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: Colors.euro_50,
        }
    }
})
