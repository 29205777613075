import { useState } from "react"
import { IntegrationLogFilter as IntegrationLogFilterType, IntegrationLogLevel } from "../../redux/types"
import { createUseStyles } from 'react-jss'
import { Breakpoints } from "../../constants/breakpoints"
import DatePicker from "../DatePicker"
import { isDateValid } from "../../util/date"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"
import { Colors } from "../../constants/colors"
import InputField from "../InputField"
import Select from "../Select"
import { useTranslation } from "react-i18next"

interface Props {
    initialFilterValue: IntegrationLogFilterType | undefined
    onFilterChange: (filter: IntegrationLogFilterType) => void
}

const IntegrationLogFilter = ({initialFilterValue, onFilterChange}: Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'integrationLog']);
    const [filterValue, setFilterValue] = useState<IntegrationLogFilterType>(initialFilterValue ?? {level: null, txId: null, fromDate: null, toDate: null})

    const allSelectOptions = t('allSelectOptions', {ns: 'common'})

    const handleTxIdChanged = (value: string) => {
        if (value !== filterValue.txId) {
            const newFilter = {...filterValue, txId: value}
            setFilterValue(newFilter)
            onFilterChange(newFilter)    
        }
    }

    const handleDateChanged = (propName: string, date: string | null | undefined) => {
        if (!date || date.length < 1) {
            const newFilter = {...filterValue, [propName]: null}
            setFilterValue(newFilter)
            onFilterChange(newFilter)   
        }

        if (date && isDateValid(date)) {
            const newFilter = {...filterValue, [propName]: date}
            setFilterValue(newFilter)
            onFilterChange(newFilter)
        }
    }

    const handleLevelSelected = (value: string) => {
        var status: IntegrationLogLevel | null = null
        if (value !== allSelectOptions) 
            status = value as IntegrationLogLevel
        
        const newFilter = {...filterValue, level: status}

        setFilterValue(newFilter)
        onFilterChange(newFilter)   
    }

    const getLevelOptions = () => {
        let values = Object.values(IntegrationLogLevel).map((value) => value.toString())
        const options = values.map((value) => { return { name: t(`level_${value}`, {ns: 'integrationLog'}), value: value }})
        options.unshift({name: allSelectOptions, value: allSelectOptions})
        return options
    }

    const clearFilters = () => {
        const newFilter = {level: null, txId: null, fromDate: null, toDate: null}
        setFilterValue(newFilter)
        onFilterChange(newFilter)    
    }

    return (
        <div className={styles.container}>
            <div className={styles.field}>
                <Select 
                    id='level'
                    key='level'
                    label={t('level', {ns: 'integrationLog'})}
                    options={getLevelOptions()} 
                    selectedValue={filterValue.level != null ? filterValue.level.toString() : 'all'}
                    onSelect={handleLevelSelected}
                />
            </div>
            <div className={styles.field}>
                <InputField 
                    label={t('txId', {ns: 'integrationLog'})}
                    id='txID'
                    onEnterKeyOrBlur={handleTxIdChanged}
                />
            </div>
            <div className={styles.field}>
                <DatePicker 
                    id='fromDate'
                    label={t('fromDate', {ns: 'common'})}
                    value={filterValue.fromDate ?? ''}
                    onChange={(value) => handleDateChanged('fromDate', value)}
                />   
            </div>
            <div className={styles.field}>
                <DatePicker 
                    id='toDate'
                    label={t('toDate', {ns: 'common'})}
                    value={filterValue.toDate ?? ''}
                    onChange={(value) => handleDateChanged('toDate', value)}
                />   
            </div>
            <div className={styles.clearFiltersContainer}>
                <div className={styles.clearFiltersButton} onClick={() => clearFilters()} title={t('clearFilters', {ns: 'common'})}>
                    <FontAwesomeIcon
                        size='lg'
                        icon={faTimes}
                        color={Colors.gray1}
                    />
                </div>
            </div>
        </div>
    )
}

export default IntegrationLogFilter

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'flex-start',
    },
    field: {
        width: '100%',    
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            width: 240,
            marginRight: 30,
        },
    },
    clearFiltersContainer: {
        alignSelf: 'flex-end' 
     },
     clearFiltersButton: {
         marginBottom: 8,
         marginTop: 8,
         '&:hover': {
             cursor: 'pointer',
           }
     }
  })