import Modal from "../Modal"
import { createUseStyles } from 'react-jss'
import { useEffect, useState } from "react"
import { PayRun } from "../../redux/types"
import { useTranslation } from "react-i18next"
import PayRunList from "./PayRunList"

interface Props {
    visible: boolean
    rows: PayRun[]
    onCloseClick: () => void
}

const PayRunsModal = ({ visible, rows, onCloseClick}: Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'payrolls']);

    const [pageIndex, setPageIndex] = useState<number>(1)

    useEffect(() => {

        setPageIndex(1)
    }, [rows])

    const handleOnClose = () => {
        onCloseClick()
    }

    return (
        <Modal
            size="xl"
            title={t('payRuns', {ns: 'payrolls'})}
            visible={visible}
            okButtonTitle={t('close', {ns: 'common'})}
            hideCancelButton={true}
            onHide={handleOnClose}
            onOkClick={handleOnClose}
            onCancelClick={handleOnClose}
        >
            <div className={styles.container}>
               <PayRunList
                    pageIndex={pageIndex}
                    pageSize={15}
                    rows={rows}
                    onPageChange={(pageIndex: number) => setPageIndex(pageIndex)}
               />
            </div>
        </Modal>
    )
}

export default PayRunsModal

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
})
