import {useState} from 'react'
import {useTranslation} from 'react-i18next';
import DatePicker from './DatePicker';
import Modal from './Modal'
import {createUseStyles} from "react-jss";
import {faBars, faFileUpload} from "@fortawesome/free-solid-svg-icons";
import CardButton from "./CardButton";

export type OffCyclePaymentCreationMethod = 'file' | 'rows'

interface Props {
  allowManualCreation: boolean
  onOkClick: (payoutDate: string, method: OffCyclePaymentCreationMethod) => void
  onCancelClick: () => void
  visible: boolean
}

const AddOffCyclePaymentModal = ({
                                   allowManualCreation,
                                   onOkClick,
                                   onCancelClick,
                                   visible
                                 }: Props) => {
  const {t} = useTranslation(['payrolls', 'common']);
  const styles = useStyles()

  var defaultDate = new Date()
  defaultDate.setDate(new Date().getDate() + 1)

  const [selectedDate, setSelectedDate] = useState<string>(defaultDate.toISOString().slice(0, 10))
  const [method, setMethod] = useState<OffCyclePaymentCreationMethod|undefined>(allowManualCreation ? undefined : 'file')

  const handleOnOkClick = () => {
    if (!method || !selectedDate) {
      return
    }

    onOkClick(selectedDate, method)
    setSelectedDate(defaultDate.toISOString().slice(0, 10))
    setMethod(undefined)
  }

  const handleOnCancelClick = () => {
    onCancelClick()
    setSelectedDate(defaultDate.toISOString().slice(0, 10))
    setMethod(undefined)
  }

  return (
    <Modal
      title={t('addOffCyclePayment', {ns: 'payrolls'})}
      visible={visible}
      cancelButtonTitle={t('cancel', {ns: 'common'})}
      okButtonTitle={t('continue', {ns: 'common'})}
      onHide={handleOnCancelClick}
      onOkClick={handleOnOkClick}
      okButtonDisabled={!method || !selectedDate}
      onCancelClick={handleOnCancelClick}
    >
      <div style={{minHeight: 350}}>
        <DatePicker
          id='payoutDate'
          value={selectedDate}
          label={t('selectOffCyclePaymentDate', {ns: 'payrolls'})}
          onChange={value => setSelectedDate(value ?? selectedDate)}
        />

        {allowManualCreation &&
            <>
                <div style={{height: 20}}/>
                <div className={styles.buttonContainer}>
                    <CardButton
                        onClick={() => setMethod("file")}
                        selected={method === "file"}
                        icon={faFileUpload}
                        label={t('uploadPayrollFile', {ns: 'payrolls'})}
                    />
                    <CardButton
                        onClick={() => setMethod("rows")}
                        selected={method === "rows"}
                        icon={faBars}
                        label={t('createPayrollFileRowsManually', {ns: 'payrolls'})}
                    />
                </div>
            </>
        }
      </div>
    </Modal>
  )
}

export default AddOffCyclePaymentModal

const useStyles = createUseStyles({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 10,
  }
})
