import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import { Fonts } from '../constants/fonts'

interface Props {
    text: string
    backgroundColor: string
    textColor: string
    tooltip?: string
}

const Badge = ({text, backgroundColor, textColor, tooltip}: Props) => {
    const styles = useStyles()

    return (
        <div className={styles.badge} style={{backgroundColor: backgroundColor}} title={tooltip}>
            <span className={styles.badgeText} style={{color: textColor}}>{text}</span>
        </div>
    )
}

export default Badge

const useStyles = createUseStyles({
    badge: {
        height: '20px',
        paddingLeft: 7,
        paddingRight: 7,
        borderRadius: 20,
        backgroundColor: Colors.euro_800,
        margin: 2
    },
    badgeText: {
        display: 'block',
        fontSize: 14,
        color: Colors.gray4,
        fontFamily: Fonts.regular, 
        lineHeight: '21px',
        whiteSpace: 'pre'   
    },
  })