import {faBan, faCalendar, faCheck, faSpinner, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {createUseStyles} from 'react-jss'
import {Colors} from "../../constants/colors"
import {Fonts} from '../../constants/fonts'
import {EmployerInitiatedPayoutJobListItem, EmployerInitiatedPayoutJobState} from "../../redux/types"
import {formatDateString} from '../../util/date'
import Card from '../Card'
import Button from '../Button'
import {useTranslation} from 'react-i18next'
import EmployerInitiatedPayoutJobModal from "./EmployerInitiatedPayoutJobModal";
import {useState} from "react";
import ConfirmModal from '../ConfirmModal'

interface Props {
  employerInitiatedPayoutJobListItem: EmployerInitiatedPayoutJobListItem
  onCancel: (job: EmployerInitiatedPayoutJobListItem) => void
}

const EmployerInitiatedPayoutCard = ({employerInitiatedPayoutJobListItem, onCancel}: Props) => {
  const styles = useStyles()
  const {t} = useTranslation(['employerInitiatedPayout', 'common']);
  const [detailsModalVisible, setDetailsModalVisible] = useState(false)
  const [confirmCancelModalVisible, setConfirmCancelModalVisible] = useState(false)
  
  const handleCancelJobConfirmed = () => {
    onCancel(employerInitiatedPayoutJobListItem)
    setConfirmCancelModalVisible(false)
  }

  const getCardBackgroundColor = () => {
    switch (employerInitiatedPayoutJobListItem.state) {
      case EmployerInitiatedPayoutJobState.scheduled:
        return Colors.euro_50
      case EmployerInitiatedPayoutJobState.pending:
        return Colors.yen_300
      default:
        return Colors.grayBackgroundApp
    }
  }

  const renderStatusIcon = () => {
    let icon = undefined
    let color = undefined

    switch (employerInitiatedPayoutJobListItem.state) {
      case EmployerInitiatedPayoutJobState.scheduled:
        icon = faCalendar
        color = Colors.dollar_900
        break
      case EmployerInitiatedPayoutJobState.pending:
        icon = faSpinner
        color = Colors.yen_700
        break
      case EmployerInitiatedPayoutJobState.completed:
        icon = faCheck
        color = Colors.dollar_900
        break
      case EmployerInitiatedPayoutJobState.cancelled:
        icon = faBan
        color = Colors.gray2
        break
      default:
        break
    }

    return (
      <>
        {icon &&
            <FontAwesomeIcon
                icon={icon}
                size='3x'
                color={color}
            />
        }
      </>)
  }

  const renderStatusMessage = (message: string, subMessage?: string) => {
    return (
      <div className={styles.statusMessageContainer}>
        <h5>{message}</h5>
        {subMessage && <h6>{subMessage}</h6>}
      </div>
    )
  }

  const renderHeader = (labelText: string) => {
    return (
      <div className={styles.headerContainer}>
                <span className={styles.headerLabel}>
                    {labelText}
                </span>
        <span style={{fontFamily: Fonts.semiBold, fontSize: 20}}>
                    {formatDateString(employerInitiatedPayoutJobListItem.scheduledDate)}
                </span>
      </div>
    )
  }

  const renderContentByStatus = () => {
    switch (employerInitiatedPayoutJobListItem.state) {
      case EmployerInitiatedPayoutJobState.scheduled:
        return renderScheduledContent()
      case EmployerInitiatedPayoutJobState.pending:
        return renderPendingContent()
      case EmployerInitiatedPayoutJobState.completed:
        return renderCompletedContent()
      case EmployerInitiatedPayoutJobState.cancelled:
        return renderCancelledContent()
      default:
        return null
    }
  }

  const renderScheduledContent = () => {
    return (
      <>
        {renderHeader(t('employerInitiatedPayout'))}
        {renderStatusMessage(t('scheduledEmployerInitiatedPayout'))}
        {renderCancelButton()}
        <div style={{height: '100%'}}></div>
      </>
    )
  }

  const renderCancelButton = () => {
    return (
      <FontAwesomeIcon
        style={{cursor: 'pointer'}}
        icon={faTrashAlt}
        color={Colors.gray1}
        title={t('delete', {ns: 'common'})}
        onClick={() => {
          setConfirmCancelModalVisible(true)
        }}
      />
    )
  }

  const renderPendingContent = () => {
    return (
      <>
        {renderHeader(t('employerInitiatedPayout'))}
        {renderStatusMessage(t('pendingEmployerInitiatedPayout'))}
        <div style={{height: '100%'}}></div>
      </>
    )
  }

  const renderCompletedContent = () => {
    return (
      <>
        {renderHeader(t('employerInitiatedPayout'))}
        {renderStatusMessage(t('completedEmployerInitiatedPayout'))}
        <div style={{height: '100%'}}></div>
      </>
    )
  }

  const renderCancelledContent = () => {
    return (
      <>
        {renderHeader(t('employerInitiatedPayout'))}
        {renderStatusMessage(t('cancelledEmployerInitiatedPayout'))}
        <div style={{height: '100%'}}></div>
      </>
    )
  }

  const renderDetailsButton = () => {
    return (
      <div className={styles.buttonContainer}>
        <Button
          variant={'negative'}
          title={t('showDetails')}
          onClick={() => setDetailsModalVisible(true)}
        />
      </div>
    )
  }

  return (
    <Card className={styles.container} style={{backgroundColor: getCardBackgroundColor()}}>
      <div className={styles.statusIconContainer}>
        {renderStatusIcon()}
      </div>
      {renderContentByStatus()}
      {renderDetailsButton()}

      <EmployerInitiatedPayoutJobModal
        visible={detailsModalVisible}
        jobId={employerInitiatedPayoutJobListItem.id}
        onCloseClick={() => setDetailsModalVisible(false)}
      />
      <ConfirmModal
        visible={confirmCancelModalVisible}
        confirmText={t('confirmCancelEmployerInitiatedPayoutJob', {ns: 'employerInitiatedPayout'})}
        onConfirmClick={handleCancelJobConfirmed}
        onCancelClick={() => setConfirmCancelModalVisible(false)}
      />
    </Card>
  )
}

export default EmployerInitiatedPayoutCard

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    minWidth: 400
  },
  statusIconContainer: {
    position: 'absolute',
    right: 20,
    top: 15
  },
  headerContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column'
  },
  headerLabel: {
    color: Colors.euro_800,
    fontSize: 13,
    textTransform: 'uppercase'
  },
  statusMessageContainer: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 10,
    rowGap: 15
  },
  buttonContainer: {
    marginTop: 40,
    display: 'flex',
    flex: 1,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    rowGap: 6
  }
})
