import React, { useState } from "react"
import { createUseStyles } from 'react-jss'
import Card from '../components/Card'
import InputField from '../components/InputField'
import Button from '../components/Button'
import { faKey, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation } from "react-router-dom"
import useAuthUserActions from "../hooks/useAuthUserActions"
import { useAppSelector } from '../redux/hooks'
import { Colors } from "../constants/colors"
import { useTranslation } from "react-i18next"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"

const Login = () => {
    let loading = useAppSelector(state => state.auth.loading)
    let error = useAppSelector(state => state.auth.error)
    const [email, setEmail] = useState<string>()
    const [password, setPassword] = useState<string>()
    const { login } = useAuthUserActions()
    const { t } = useTranslation(['common', 'login']);
    const location = useLocation()

    const handleFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        if (email && password)
            login(email, password, location?.state?.from)
    }

    const renderErrorMessage = () => {
        if (error === '401') {
            return t('loginFailureIncorrectCredentials', {ns: 'login'})
        }
        return t('loginFailure', {ns: 'login'})
    }

    const styles = useStyles()

    return (
        <div className={styles.container}>
            <Card className={styles.card}>
                <h3 className={styles.title}>{t('title', {ns: 'login'})}</h3>
                <form onSubmit={handleFormSubmit}>
                    <div className={styles.cardRow}>
                        <InputField 
                            id='email'
                            label={t('email', {ns: 'common'})}
                            type='email'
                            icon={faEnvelope}
                            onChange={setEmail}
                        />
                    </div>
                    <div className={styles.cardRow}>
                        <InputField 
                            id='password'
                            label={t('password', {ns: 'common'})}
                            type='password'
                            icon={faKey}
                            onChange={setPassword}
                        />
                    </div>
                    <div className={`${styles.cardRow} ${styles.buttonRow}`}>
                        <div> 
                            <Link to={'/forgotpassword'}>{t('forgotPassword', {ns: 'login'})}</Link>
                        </div>
                        <div className={styles.button}>
                            <Button
                                type='submit'
                                disabled={!(email && password)}
                                title={t('loginButtonText', {ns: 'login'})}
                            /> 
                        </div>
                    </div>
                    { !loading && error && 
                    <div className={styles.cardRow}>
                        <label className={styles.errorLabel}>
                            {renderErrorMessage()}
                        </label>
                    </div>}
                </form>
            </Card>
            <Card className={styles.card} style={{width: 500, padding: 70, paddingTop: 30, paddingBottom: 30}}>
                <span>{t('contactCustomerServiceIntro', {ns: 'login'})} <a href="https://www.cappy.se/kundservice">{t('customerService', {ns: 'login'})}</a> {t('contactCustomerServiceOutro', {ns: 'login'})}</span>
            </Card>
            <FullScreenLoadingIndicator visible={loading} />
        </div>
    )
}

export default Login

const useStyles = createUseStyles({
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        marginBottom: 30
    },
    card: {
        padding: 60,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 30
    },
    cardRow: {
        marginTop: 10,
        marginBottom: 10,
        width: 350
    },
    buttonRow: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    errorLabel: {
        color: Colors.rubel_700
    }
})