import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { Breakpoints } from '../../constants/breakpoints'
import { Colors } from "../../constants/colors"
import { Fonts } from '../../constants/fonts'
import { Shift } from '../../redux/types'
import { formatTimeString } from '../../util/date'
import { hoursToHoursAndMinutesString } from '../../util/hourConverter'
import { roundToTwoDecimals } from '../../util/number'
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'

interface Props {
    shiftsOnPage: Shift[]
    pageIndex: number
    pageSize: number
    totalShiftCount: number
    onEditClick: (shift: Shift) => void
    onDeleteClick: (shift: Shift) => void
    onPageChange: (pageIndex: number) => void
}

const ShiftList = ({ shiftsOnPage, pageIndex, pageSize, totalShiftCount, onEditClick, onDeleteClick, onPageChange } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['custom', 'shifts']);

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('payday', {ns: 'common'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('date', {ns: 'common'})}</strong>
                </div>
                <div className={styles.nameCellContainer}>
                    <strong>{t('employee', {ns: 'common'})}</strong>
                </div>
                <div className={`${styles.defaultCellContainer} ${styles.mediumHidden}`}>
                    <strong>{t('workingHours', {ns: 'shifts'})}</strong>
                </div>
                <div className={`${styles.badgeCellContainer} ${styles.mediumHidden}`}>
                    <strong>{t('type', {ns: 'common'})}</strong>
                </div>
              <div className={`${styles.badgeCellContainer} ${styles.mediumHidden}`}>
                <strong>{t('status', {ns: 'common'})}</strong>
              </div>
                <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                    <strong>{t('paidWorkingHours', {ns: 'shifts'})}</strong>
                </div>
                <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                    <strong>{t('grossWages', {ns: 'shifts'})}</strong>
                </div>
                <div className={styles.buttonContainer}>

                </div>
            </ListItemCard>
        )
    }

    const renderListItems = () => {
        return shiftsOnPage?.map((shift, index) => {
            return (
                <ListItemCard key={`shift${index}`} className={styles.listItemContainer} index={index}>
                    <div className={styles.defaultCellContainer}>
                        {shift.payday.paymentDate}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {shift.date}
                    </div>
                    <div className={styles.nameCellContainer} title={shift.employee.identificationNumber}>
                        {shift.employee.name}
                    </div>
                    <div className={`${styles.defaultCellContainer} ${styles.mediumHidden}`}>
                        {shift.startTime && `${formatTimeString(shift.startTime ?? undefined)} - ${formatTimeString(shift.endTime ?? undefined)}`}
                    </div>
                    <div className={`${styles.badgeCellContainer} ${styles.mediumHidden}`}>
                        {renderTypeBadge(shift)}
                    </div>
                  <div className={`${styles.badgeCellContainer} ${styles.mediumHidden}`}>
                    {renderStatusBadge(shift)}
                  </div>
                    <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                        {hoursToHoursAndMinutesString(shift.hours)}
                    </div>
                    <div className={`${styles.defaultCellContainer} ${styles.smallHidden}`}>
                        {shift.grossWages.amount.toLocaleString('sv-SE', { minimumFractionDigits: 2 })} {shift.grossWages.currencyCode.toUpperCase()}
                    </div>
                    <div className={styles.buttonContainer}>
                        <div>
                            <FontAwesomeIcon
                                className={styles.button}
                                icon={faEdit}
                                color={Colors.gray1}
                                title={t('edit', {ns: 'common'})}
                                onClick={() => onEditClick && onEditClick(shift)}
                            />
                        </div>
                        <div>
                            <FontAwesomeIcon
                                className={styles.button}
                                icon={faTrashAlt}
                                color={Colors.gray1}
                                title={t('delete', {ns: 'common'})}
                                onClick={() => onDeleteClick && onDeleteClick(shift)}
                            />
                        </div>
                    </div>
                </ListItemCard>
            )
         })
    }

  const renderTypeBadge = (shift: Shift) => {
    const badgeColor = () => {
      switch (shift.type) {
        case 'standard':
          return Colors.euro_800
        case 'custom':
          return Colors.yen_600
      }
    }

    const badgeTextColor = () => {
      switch (shift.type) {
        case 'standard':
          return Colors.gray4
        case 'custom':
          return Colors.gray0
      }
    }

    return (
      <>
        <div className={styles.badge} style={{backgroundColor: badgeColor()}}>
          <span className={styles.badgeText}
                style={{color: badgeTextColor()}}>{t(`type_${shift.type}`, {ns: 'shifts'})}</span>
        </div>
      </>
    )
  }

  const renderStatusBadge = (shift: Shift) => {
    const badgeColor = () => {
      switch (shift.status) {
        case 'completed':
          return Colors.euro_800
        case 'planned':
          return Colors.yen_600
      }
    }

    const badgeTextColor = () => {
      switch (shift.status) {
        case 'completed':
          return Colors.gray4
        case 'planned':
          return Colors.gray0
      }
    }

    return (
      <>
        <div className={styles.badge} style={{backgroundColor: badgeColor()}}>
          <span className={styles.badgeText}
                style={{color: badgeTextColor()}}>{t(`status_${shift.status}`, {ns: 'shifts'})}</span>
        </div>
      </>
    )
  }

    return (
        <div>
           {renderHeader()}
           {renderListItems()}
           <div className={styles.pagination}>
                <Pagination
                    pageIndex={pageIndex}
                    itemCount={totalShiftCount}
                    pageSize={pageSize}
                    siblingCount={1}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
    )
}

export default ShiftList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    defaultCellContainer: {
        width: 150
    },
    nameCellContainer: {
        width: 200
    },
    badgeCellContainer: {
        width: 120
    },
    buttonContainer: {
        display: 'flex',
        width: 80,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignContent: 'center',
        paddingLeft: 0
    },
    smallHidden: {
        display: 'none',
        [`@media (min-width: ${Breakpoints.tablet}px)`]: {
            display: 'flex'
        },
    },
    mediumHidden: {
        display: 'none',
        [`@media (min-width: ${Breakpoints.desktop}px)`]: {
            display: 'flex'
        },
    },
    badge: {
        height: '20px',
        paddingLeft: 7,
        paddingRight: 7,
        borderRadius: 20,
        backgroundColor: Colors.euro_800,
        margin: 2
    },
    badgeText: {
        display: 'block',
        fontSize: 14,
        color: Colors.gray4,
        fontFamily: Fonts.regular,
        lineHeight: '21px'
    },
    button: {
        marginRight: 15,
        transition: '0.4s',
        '&:hover': {
          color: Colors.euro_600,
          cursor: 'pointer',
        }
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
  })
