import { createUseStyles } from "react-jss"
import Card from "../components/Card"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import { useEffect } from "react"
import { periodDetailsPageIndexChanged } from "../redux/slices/foraAdjustmentSlice"
import { Colors } from "../constants/colors"
import File from "../components/File"
import BackButton from "../components/BackButton"
import ForaAdjustmentList from "../components/ForaAdjustment/ForaAdjustmentList"
import useForaAdjustmentActions from "../hooks/useForaAdjustmentActions"

const ForaAdjustmentDetails = () => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'foraAdjustment']);
    const { period } = useParams()
    const { getPeriodDetails } = useForaAdjustmentActions()
    const dispatch = useAppDispatch()

    const loading = useAppSelector(state => state.foraAdjustment.loading)
    const periodDetails = useAppSelector(state => state.foraAdjustment.periodDetails)
    const pageIndex = useAppSelector(state => state.foraAdjustment.periodDetailsPageIndex)
    const pageSize = useAppSelector(state => state.foraAdjustment.periodDetailsPageSize)

    useEffect(() => {
        if (period && (periodDetails == null || periodDetails.period !== period)) {
            getPeriodDetails(period)
        }
      }, [])

    const onPageChange = (pageIndex: number) => {
        dispatch(periodDetailsPageIndexChanged(pageIndex))
    }

    return (
        <>
        <BackButton />
        <Card className={styles.card}>
            <h2>
                {t('title', {ns: 'foraAdjustment'})} {period}
            </h2>
        </Card>
        <Card className={styles.card}>
            <h4 className={styles.subTitle}>
                {t('files', {ns: 'foraAdjustment'})}
            </h4>
            {periodDetails?.foraAdjustmentReportFile &&
              <File file={periodDetails?.foraAdjustmentReportFile}/>
            }
        </Card>
         <Card>
             <h4 className={styles.subTitle}>
                 {t('adjustments', {ns: 'foraAdjustment'})}
             </h4>
             {periodDetails &&
            <ForaAdjustmentList
                adjustments={periodDetails.adjustments}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onPageChange={onPageChange}
            />}
            <FullScreenLoadingIndicator visible={loading} />
        </Card>
        </>
    )
}

export default ForaAdjustmentDetails

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    info: {
        marginTop: 20,
    },
    subTitle: {
        color: Colors.euro_800,
        marginBottom: 20
    },
    button: {
        alignSelf: 'flex-end'
    }
})
