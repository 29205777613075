import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next';
import Modal from './Modal'

interface Props {
    title?: string
    confirmText?: string
    children?: ReactNode
    okButtonTitle?: string
    cancelButtonTitle?: string
    onConfirmClick?: () => void
    onCancelClick?: () => void
    visible: boolean
}

const ConfirmModal: FC<Props> = ({
    title,
    confirmText,
    children,
    okButtonTitle,
    cancelButtonTitle,
    onConfirmClick,
    onCancelClick,
    visible
}) => {
    const { t } = useTranslation('common');

    const handleOnConfirm = () => {
        onConfirmClick && onConfirmClick()
    }

    const handleOnCancel = () => {
        onCancelClick && onCancelClick()
    }

    const handleOnHide = () => {
        onCancelClick && onCancelClick()
    }

    return (
        <Modal
            title={title ?? t('confirm')}
            visible={visible}
            okButtonTitle={okButtonTitle ?? t('yes')}
            cancelButtonTitle={cancelButtonTitle ?? t('cancel')}
            onHide={() => handleOnHide()}
            onOkClick={() => handleOnConfirm()}
            onCancelClick={() => handleOnCancel()}
        >
            {confirmText && <h6>{confirmText}</h6>}
            {children && children}
        </Modal>
    )
}

export default ConfirmModal
