import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import Modal from './Modal'
import {EditShiftModel, ShiftStatus, ShiftType} from '../redux/types'
import InputField from './InputField'
import DatePicker from './DatePicker'
import Select from './Select'
import EmployeeSelector from './EmployeeSelector'
import { AutocompleteItem } from './AutocompleteField'
import { isDateValid } from '../util/date'
import useShiftActions from '../hooks/useShiftActions'
import { Colors } from '../constants/colors'
import { hoursAndMinutesStringToHours, hoursToHoursAndMinutesString } from '../util/hourConverter'
import { useTranslation } from 'react-i18next'

interface Props {
    initialValue?: EditShiftModel | null
    onSaveClick?: (payday: EditShiftModel) => void
    onCancelClick?: () => void
    visible: boolean
}

const emptyShift: EditShiftModel = {
    id: null,
    externalEmployeeId: '',
    employeeId: '',
    employeeName: '',
    employeeIdentificationNumber: '',
    externalId: '',
    date: '',
    type: 'standard',
    status: 'completed',
    hours: 0,
    grossWages: 0,
    startTime: null,
    endTime: null,
    location: '',
    description: ''
}

const EditShiftModal = ({
    initialValue,
    onSaveClick,
    onCancelClick,
    visible
} : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'shifts']);

    const [shift, setShift] = useState<EditShiftModel>(emptyShift)
    const [shiftAlreadyExists, setShiftAlreadyExists] = useState(false)
    const [dateValid, setDateValid] = useState(true)
    const [externalIdValid, setExternalIdValid] = useState(true)
    const [employeeValid, setEmployeeValid] = useState(true)
    const [hoursValid, setHoursValid] = useState(true)
    const [wagesValid, setWagesValid] = useState(true)

    const { shiftExists } = useShiftActions()

    useEffect(() => {
        if (initialValue) {
            setShift({...initialValue})
        }
    }, [initialValue])

    const handleOnSave = () => {
        if (!validateInput())
            return

        shift && onSaveClick && onSaveClick(shift)
        setShift(emptyShift)
        setExternalIdValid(true)
        setDateValid(true)
        setEmployeeValid(true)
        setHoursValid(true)
        setWagesValid(true)
    }

    const validateBeforeSave = () => {
        if (!initialValue) {
            shiftExists(shift.externalId, (exists: boolean) => {
                if (exists) {
                    setShiftAlreadyExists(true)
                } else {
                    setShiftAlreadyExists(false)
                    handleOnSave()
                }
            })
        } else {
            handleOnSave()
        }
    }

    const handleOnCancel = () => {
        onCancelClick && onCancelClick()
        setShift(emptyShift)
        setDateValid(true)
        setEmployeeValid(true)
        setHoursValid(true)
        setWagesValid(true)
    }

    const handleOnChange = (propName: string, value: any) => {
        if (shift)
            setShift({...shift, [propName]: value})
    }

    const handleEmployeeSelected = (employee: AutocompleteItem | null) => {
        if (employee != null) {
            setEmployeeValid(true)
            setShift({...shift, externalEmployeeId: employee.value})
        }
    }

    const validateInput = () => {
        let result = true

        if (shift.externalId == null || shift.externalId.length < 1) {
            setExternalIdValid(false)
            result = false
        }

        if (!isDateValid(shift.date)) {
            setDateValid(false)
            result = false
        }

        if (shift.externalEmployeeId == null || shift.externalEmployeeId.length < 1) {
            setEmployeeValid(false)
            result = false
        }

        if (shift.hours == null || shift.hours <= 0) {
            setHoursValid(false)
            result = false
        }

        if (shift.grossWages == null || shift.grossWages <= 0) {
            setWagesValid(false)
            result = false
        }

        return result
    }

    const getShiftTypeOptions = () => {
        let values = Object.values(ShiftType).map((value) => value.toString())
        return values.map((value) => { return { name: t(`type_${value}`, {ns: 'shifts'}), value: value }})
    }

    const getShiftStatusOptions = () => {
        let values = Object.values(ShiftStatus).map((value) => value.toString())
        return values.map((value) => { return { name: t(`status_${value}`, {ns: 'shifts'}), value: value }})
    }

    return (
        <Modal
            title={initialValue != null ? t('editShift', {ns: 'shifts'}) : t('addShift', {ns: 'shifts'})}
            visible={visible}
            okButtonTitle={t('save', {ns: 'common'})}
            cancelButtonTitle={t('cancel', {ns: 'common'})}
            onHide={() => handleOnCancel()}
            onOkClick={() => validateBeforeSave()}
            onCancelClick={() => handleOnCancel()}
        >
            <div className={styles.row}>
                <InputField
                    id='externalId'
                    valid={externalIdValid}
                    mandatory={true}
                    disabled={initialValue != null}
                    value={shift?.externalId ?? undefined}
                    onChange={value => handleOnChange('externalId', value)}
                    label={t('externalId', {ns: 'shifts'})}
                    placeholder=''
                    tooltipText={t('externalIdDescription', {ns: 'shifts'})}
                />
            </div>
            <div className={styles.row}>
                <EmployeeSelector
                    valid={employeeValid}
                    mandatory={true}
                    externalIdAsValue={true}
                    includeUnconnectedEmployees={false}
                    includeEmployeesWithoutDataCollectionConsent={false}
                    value={shift?.employeeIdentificationNumber ? `${shift.employeeName} (${shift.employeeIdentificationNumber})` : ''}
                    onSelected={handleEmployeeSelected}
                />
            </div>
            <div className={styles.row}>
                <DatePicker
                    id='date'
                    valid={dateValid}
                    mandatory={true}
                    label={t('date', {ns: 'common'})}
                    value={shift?.date}
                    onChange={value => {
                        setDateValid(true)
                        handleOnChange('date', value)
                    }}
                />
            </div>
            <div className={styles.row}>
                <Select
                    id='type'
                    key='type'
                    mandatory={true}
                    label={t('type', {ns: 'common'})}
                    options={getShiftTypeOptions()}
                    selectedValue={shift?.type.toString()}
                    onSelect={(value) => handleOnChange('type', value as ShiftType)}
                    tooltipText={t('shiftTypeDescription', {ns: 'shifts'})}
                />
            </div>
            <div className={styles.row}>
                <Select
                  id='status'
                  key='status'
                  mandatory={true}
                  label={t('status', {ns: 'common'})}
                  options={getShiftStatusOptions()}
                  selectedValue={shift?.status.toString()}
                  onSelect={(value) => handleOnChange('status', value as ShiftStatus)}
                />
            </div>
            <div className={styles.row}>
                <InputField
                    valid={hoursValid}
                    mandatory={true}
                    id='hours'
                    value={hoursToHoursAndMinutesString(shift?.hours)}
                    onChange={value => {
                        setHoursValid(true)
                        handleOnChange('hours', hoursAndMinutesStringToHours(value))
                    }}
                    label={t('paidWorkingHours', {ns: 'shifts'})}
                    placeholder='hh:mm'
                />
            </div>
            <div className={styles.row}>
                <InputField
                    valid={wagesValid}
                    mandatory={true}
                    id='grossWages'
                    type='number'
                    value={shift?.grossWages.toString()}
                    onChange={value => {
                        setWagesValid(true)
                        handleOnChange('grossWages', Number.parseFloat(value))
                    }}
                    label={t('shiftGrossWages', {ns: 'shifts'})}
                    placeholder=''
                />
            </div>
            <div className={styles.row}>
                <DatePicker
                    id='startDate'
                    label={t('shiftStartTime', {ns: 'shifts'})}
                    includeTime={true}
                    value={shift?.startTime ?? undefined}
                    onChange={value => {
                        handleOnChange('startTime', value)
                    }}
                />
            </div>
            <div className={styles.row}>
                <DatePicker
                    id='endDate'
                    label={t('shiftEndTime', {ns: 'shifts'})}
                    includeTime={true}
                    value={shift?.endTime ?? undefined}
                    onChange={value => {
                        handleOnChange('endtime', value)
                    }}
                />
            </div>
            <div className={styles.row}>
                <InputField
                    id='description'
                    value={shift?.description ?? ''}
                    onChange={value => handleOnChange('description', value)}
                    label={t('description', {ns: 'common'})}
                    placeholder=''
                />
            </div>
            <div className={styles.row}>
                <InputField
                    id='location'
                    value={shift?.location ?? ''}
                    onChange={value => handleOnChange('location', value)}
                    label={t('location', {ns: 'common'})}
                    placeholder=''
                />
            </div>
            {shiftAlreadyExists &&
            <div className={styles.errorMessage}>
                {t('shiftAlreadyExists', {ns: 'shifts'})}
            </div>
            }
        </Modal>
    )
}

export default EditShiftModal

const useStyles = createUseStyles({
    row: {
        paddingBottom: 12
    },
    errorMessage: {
        color: Colors.rubel_700
    }
})
