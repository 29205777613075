import { Spinner } from 'react-bootstrap'
import FullScreenModal from './FullScreenModal'

interface Props {
    visible: boolean
}

const FullScreenLoadingIndicator = ({visible} : Props) => {

    return (
        <FullScreenModal visible={visible}>
            <Spinner
                as='span'
                animation='border'
            />
        </FullScreenModal>
    )
}

export default FullScreenLoadingIndicator
