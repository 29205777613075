import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next';
import { BulkOperationResult } from '../redux/types';
import Modal from './Modal'

interface Props {
    title?: string
    message?: string
    children?: ReactNode
    bulkOperationResult?: BulkOperationResult
    onCloseClick?: () => void
    visible: boolean
}

const SuccessModal = ({
    title,
    message,
    children, 
    bulkOperationResult,   
    onCloseClick,
    visible
}: Props) => {
    const { t } = useTranslation('common');

    const handleClose = () => {
        onCloseClick && onCloseClick()
    }

    const renderBulkOperationResult = () => {
        if (bulkOperationResult) {
            return (
                <div>
                    <div>{t('processed')}: <strong>{bulkOperationResult.processCount}</strong></div>
                    <div>{t('inserted')}: <strong>{bulkOperationResult.insertCount}</strong></div>
                    <div>{t('updated')}: <strong>{bulkOperationResult.updateCount}</strong></div>
                    {bulkOperationResult.errors.length > 0 && 
                        <>
                        <div>{t('errors')}: </div>
                        {
                            bulkOperationResult.errors.map((item, index) => {
                                return (
                                    <div key={`error${index}`}>{item}</div>
                                )
                            })
                        }
                        </> 
                    }
                </div>
            )
        }
    }

    return (
        <Modal
            title={title ?? t('operationSuccessful')}
            visible={visible}
            okButtonTitle={t('close')}
            hideCancelButton={true}
            onHide={() => handleClose()}
            onOkClick={() => handleClose()}
            onCancelClick={() => handleClose()}
        >
            {message && <h6>{message}</h6>}
            {children && children}
            {bulkOperationResult && renderBulkOperationResult()}
        </Modal>
    )
}

export default SuccessModal
