import { CurrentUser, PortalRole } from "../redux/types";

export const hasAnyRequiredRole = (user: CurrentUser | null, currentEmployerId: string | null, requiredRoles: PortalRole[]) => {
    if (requiredRoles.length === 0)
        return true

    if (!user || !currentEmployerId)
        return false

    const employerRoles = user.employers.find(x => x.employerId === currentEmployerId)

    if (!employerRoles)
        return false

    return employerRoles.roles.some(role => requiredRoles.includes(role))
}
