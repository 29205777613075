import React, { useEffect, useRef } from 'react'

const useDidUpdateEffect = (effect: React.EffectCallback, deps: React.DependencyList) => {
    const didUpdate = useRef(false);

    useEffect(() => {
        if (!didUpdate.current) 
            didUpdate.current = true;
        else 
            effect();
    }, deps);
}

export default useDidUpdateEffect;