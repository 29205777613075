import { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { ForaAdjustment } from "../../redux/types"
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'
import { useTranslation } from 'react-i18next'

interface Props {
    adjustments: ForaAdjustment[]
    pageIndex: number
    pageSize: number
    onPageChange: (pageIndex: number) => void
}

const ForaAdjustmentList = ({ adjustments, pageIndex, pageSize, onPageChange } : Props) => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'foraAdjustment']);

    const itemsOnCurrentPage = useMemo(() => {
        const firstPageIndex = (pageIndex - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        return adjustments.slice(firstPageIndex, lastPageIndex);
    }, [adjustments, pageIndex, pageSize])

    const renderHeader = () => {
        return (
            <ListItemCard className={styles.listItemContainer}>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('identificationNumber', {ns: 'common'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('name', {ns: 'common'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('originalGrossPay', {ns: 'foraAdjustment'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('grossPayAdditions', {ns: 'foraAdjustment'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('grossPayDeductions', {ns: 'foraAdjustment'})}</strong>
                </div>
                <div className={styles.defaultCellContainer}>
                    <strong>{t('adjustedGrossPay', {ns: 'foraAdjustment'})}</strong>
                </div>
            </ListItemCard>
        )
    }

    const renderListItems = () => {
        return itemsOnCurrentPage?.map((adjustment, index) => {
            return (
                <ListItemCard key={`period${index}`} className={styles.listItemContainer} index={index}>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.identificationNumber}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.employeeName}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.originalGrossPay}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.grossPayAdditions}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.grossPayDeductions}
                    </div>
                    <div className={styles.defaultCellContainer}>
                        {adjustment.originalGrossPay + adjustment.grossPayAdditions - adjustment.grossPayDeductions }
                    </div>
                </ListItemCard>
            )
         })
    }

    return (
        <div>
           {renderHeader()}
           {renderListItems()}
           <div className={styles.pagination}>
            <Pagination
                pageIndex={pageIndex}
                itemCount={adjustments.length}
                pageSize={pageSize}
                siblingCount={1}
                onPageChange={onPageChange}
            />
            </div>
        </div>
    )
}

export default ForaAdjustmentList

const useStyles = createUseStyles({
    listItemContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'space-between',
    },
    defaultCellContainer: {
        width: 100
    },
    pagination: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'center'
    }
  })
