import Modal from "../Modal"
import {createUseStyles} from 'react-jss'
import {useTranslation} from "react-i18next"
import {EmployerInitiatedPayoutJob, EmployerInitiatedPayoutJobState} from "../../redux/types";
import Card from "../Card";
import {Colors} from "../../constants/colors";
import {faBan, faCalendar, faCheck, faInfo, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatDateString} from "../../util/date";
import EmployerInitiatedPayoutBasisList from "./EmployerInitiatedPayoutBasisList";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../redux/hooks";
import useEmployerInitiatedPayoutActions from "../../hooks/useEmployerInitiatedPayoutActions";
import {Spinner} from "react-bootstrap";

interface Props {
  visible: boolean
  jobId: string | undefined
  onCloseClick: () => void
}

const EmployerInitiatedPayoutJobModal = ({visible, jobId, onCloseClick}: Props) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'employerInitiatedPayout']);

  const {getJob} = useEmployerInitiatedPayoutActions()
  const loading = useAppSelector(state => state.employerInitiatedPayouts.loading)
  const job = useAppSelector(state => state.employerInitiatedPayouts.currentJob)
  const [pageIndex, setPageIndex] = useState<number>(1)

  const handleOnClose = () => {
    onCloseClick()
  }

  useEffect(() => {
    if (visible && jobId) {
      getJob(jobId)
    }
  }, [visible, jobId]);

  useEffect(() => {
    setPageIndex(1)
  }, [job]);

  const getForegroundColor = () => {
    switch (job?.state) {
      case EmployerInitiatedPayoutJobState.scheduled:
        return Colors.dollar_900
      case EmployerInitiatedPayoutJobState.pending:
        return Colors.yen_900
      case EmployerInitiatedPayoutJobState.completed:
        return Colors.euro_900
      default:
        return Colors.gray2
    }
  }

  const getBackgroundColor = () => {
    switch (job?.state) {
      case EmployerInitiatedPayoutJobState.scheduled:
        return Colors.euro_50
      case EmployerInitiatedPayoutJobState.pending:
        return Colors.yen_300
      case EmployerInitiatedPayoutJobState.completed:
        return Colors.euro_50
      default:
        return Colors.grayBackgroundStrong
    }
  }

  const getStatusIcon = () => {
    switch (job?.state) {
      case EmployerInitiatedPayoutJobState.scheduled:
        return faCalendar
      case EmployerInitiatedPayoutJobState.pending:
        return faSpinner
      case EmployerInitiatedPayoutJobState.completed:
        return faCheck
      case EmployerInitiatedPayoutJobState.cancelled:
        return faBan
      default:
        return faInfo
    }
  }

  const renderLoading = () => {
    return (
      <div className={styles.loadingContainer}>
        <Spinner
          as='span'
          animation='border'
          size='sm'
        />
      </div>
    )
  }

  const renderJobDetails = (job: EmployerInitiatedPayoutJob) => {
    return (
      <div className={styles.container}>
        <Card className={styles.stateCard} style={{backgroundColor: getBackgroundColor(), color: getForegroundColor()}}>
          <div className={styles.infoContainer}>
            <p className={styles.infoText}>
              <strong>{t('status', {ns: 'common'})}:</strong>
              {t(`job_state_${job.state}`, {ns: 'employerInitiatedPayout'})}
            </p>
            <p className={styles.infoText}>
              <strong>{t('date', {ns: 'common'})}:</strong>
              {formatDateString(job.completedDate ?? job.scheduledDate)}
            </p>
          </div>
          <FontAwesomeIcon
            icon={getStatusIcon()}
            size='3x'
          />
        </Card>

        <EmployerInitiatedPayoutBasisList
          employerInitiatedPayoutBasisList={job.basis}
          pageIndex={pageIndex}
          pageSize={25}
          onPageChange={(pageIndex: number) => setPageIndex(pageIndex)}
        />
      </div>
    )
  }

  return (
    <Modal
      size={'xl'}
      title={t('employerInitiatedPayout', {ns: 'employerInitiatedPayout'})}
      visible={visible}
      okButtonTitle={t('close', {ns: 'common'})}
      hideCancelButton={true}
      onHide={handleOnClose}
      onOkClick={handleOnClose}
      onCancelClick={handleOnClose}
    >

      {loading || job === null ? renderLoading() : renderJobDetails(job)}
    </Modal>
  )
}

export default EmployerInitiatedPayoutJobModal

const useStyles = createUseStyles({
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  stateCard: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  detailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 10
  },
  basisContainer: {
    marginTop: 10,
    padding: 10,
    border: '1px solid #ccc',
    borderRadius: 5
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 5
  },
  infoText: {
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    marginBottom: 0,
    marginTop: 0
  }
})
