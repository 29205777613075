import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {Employee, EmployeeFilter, NewEmployeesWithPayoutsInPeriodModel} from '../types'

interface EmployeeState {
    loading: boolean
    employees: Employee[]
    newEmployeesWithPayouts: NewEmployeesWithPayoutsInPeriodModel | undefined
    pageIndex: number
    pageSize: number
    filter: EmployeeFilter | null
    error: Error | string | null
}

const initialState: EmployeeState = {
    loading: false,
    employees: [],
    newEmployeesWithPayouts: undefined,
    pageIndex: 1,
    pageSize: 15,
    filter: null,
    error: null
}

export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        employeesFetched: (state, action: PayloadAction<Employee[]>) => {
            state.loading = false
            state.employees = action.payload
            state.pageIndex = 1
            state.error = null
        },
        newEmployeesWithPayoutsFetched: (state, action: PayloadAction<NewEmployeesWithPayoutsInPeriodModel>) => {
            state.loading = false
            state.newEmployeesWithPayouts = action.payload
            state.error = null
        },
        employeesWithPayoutsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employeeCreated: (state, action: PayloadAction<Employee>) => {
            state.loading = false
            state.employees = [action.payload, ...state.employees]
            state.pageIndex = 1
            state.error = null
        },
        employeeCreateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employeeUpdated: (state, action: PayloadAction<Employee>) => {
            const employeesClone = [...state.employees]
            const indexToUpdate = employeesClone.findIndex(x => x.id === action.payload.id)
            employeesClone[indexToUpdate] = action.payload

            state.loading = false
            state.employees = employeesClone
            state.error = null
        },
        employeeUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employeesBulkUploaded: (state) => {
            state.loading = false
            state.error = null
        },
        employeesBulkUploadFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<EmployeeFilter>) => {
            state.filter = action.payload
            state.pageIndex = 1
        },
        errorHandled: (state) => {
            state.error = null
        }
    }
})

export const {
    loading,
    employeesFetched,
    newEmployeesWithPayoutsFetched,
    employeesWithPayoutsFetchFailure,
    employeeCreated,
    employeeCreateFailure,
    employeeUpdated,
    employeeUpdateFailure,
    employeesBulkUploaded,
    employeesBulkUploadFailure,
    pageIndexChanged,
    filterChanged,
    errorHandled
} = employeeSlice.actions

export default employeeSlice.reducer
