import { useEffect, useState } from "react"
import { createUseStyles } from 'react-jss'
import { useAppSelector } from "../redux/hooks"
import { useAppDispatch } from '../redux/hooks'
import { EmployeeReadyForDeactivation } from "../redux/types"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import Card from "../components/Card"
import ConfirmModal from "../components/ConfirmModal"
import { useTranslation } from "react-i18next"
import Button from "../components/Button"
import { pageIndexChanged } from "../redux/slices/employeeDeactivationSlice"
import useEmployeeDeactivationActions from "../hooks/useEmployeeDeactivationActions"
import DeactivationList from "../components/Activation/DeactivationList"

const Deactivations = () => {
    const styles = useStyles()
    const { t } = useTranslation('deactivations');
    const dispatch = useAppDispatch()

    const [selection, setSelection] = useState<EmployeeReadyForDeactivation[]>([])

    const [confirmDeactivationModalVisible, setConfirmDeactivationModalVisible] = useState<boolean>(false)
    const [deactivationCandidates, setDeactivationCandidates] = useState<EmployeeReadyForDeactivation[]>([]) 

    const { getPendingEmployees, deactivateEmployees } = useEmployeeDeactivationActions()
    const loading = useAppSelector(state => state.employeeDeactivation.loading)
    const pendingEmployees = useAppSelector(state => state.employeeDeactivation.pendingEmployees)
    const pageIndex = useAppSelector(state => state.employeeDeactivation.pageIndex)
    const pageSize = useAppSelector(state => state.employeeDeactivation.pageSize)

    useEffect(() => {
        if (pendingEmployees.length === 0) {
            getPendingEmployees()
        }
    }, [])

    const onDeactivateClick = (employee: EmployeeReadyForDeactivation) => {
        setDeactivationCandidates([employee])
        setConfirmDeactivationModalVisible(true)      
    }

    const onDeactivateSelectedClick = () => {
        setDeactivationCandidates([...selection])
        setConfirmDeactivationModalVisible(true)    
    }

    const handleDeactivationConfirmed = () => {
        deactivateEmployees(deactivationCandidates)
        setDeactivationCandidates([])
        setConfirmDeactivationModalVisible(false)   
        setSelection([])  
    }

    const onPageChange = (pageIndex: number) => {
        dispatch(pageIndexChanged(pageIndex))
    }

    const onSelectionChange = (selection: EmployeeReadyForDeactivation[]) => {
        setSelection([...selection])
    }

    return (
        <>
        <Card className={styles.card}>
            <h2>
                {t('title')}
            </h2>
            <div className={styles.info}>
                {t('subtitle')}
            </div>
        </Card>
         <Card>
            <div className={styles.buttonContainer}>
                <Button 
                    disabled={selection.length === 0}
                    onClick={onDeactivateSelectedClick} 
                    title={t('deactivateSelected')} 
                    className={styles.button}  
                />
            </div>
            {pendingEmployees && 
            <DeactivationList 
                employees={pendingEmployees}
                pageIndex={pageIndex}
                pageSize={pageSize} 
                onDeactivateClick={onDeactivateClick} 
                onPageChange={onPageChange} 
                onSelectionChanged={onSelectionChange}
            />}
           <ConfirmModal 
                title={t('deactivateEmployee')}     
                confirmText={t('confirmDeactivation')}
                visible={confirmDeactivationModalVisible}
                onConfirmClick={handleDeactivationConfirmed}
                onCancelClick={() => setConfirmDeactivationModalVisible(false)}
            />
            <FullScreenLoadingIndicator visible={loading} />
        </Card>
        </>
    )
} 

export default Deactivations

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    info: {
        marginTop: 20,
    },
    buttonContainer: {
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 10
    },
    button: {
        alignSelf: 'flex-end'
    }
})