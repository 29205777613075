import { createUseStyles } from 'react-jss'
import ukFlag from '../assets/images/flags/uk.png'
import seFlag from '../assets/images/flags/se.png'
import { Dropdown } from 'react-bootstrap'
import { useAppSelector } from '../redux/hooks'
import { Language } from '../redux/types'
import { useTranslation } from 'react-i18next'
import useCurrentUserActions from '../hooks/useCurrentUserActions'

const LanguagePicker = () => {
    const styles = useStyles()
    const authenticatedUser = useAppSelector(state => state.auth.authenticatedUser)
    const { updateUser } = useCurrentUserActions()
    const { t } = useTranslation('common');

    const handleLanguageSelected = (language: Language) => {
        if (language !== authenticatedUser?.language) {
            updateUser({
                portalUserId: authenticatedUser?.portalUserId ?? '',
                givenName: authenticatedUser?.givenName ?? '',
                familyName: authenticatedUser?.familyName ?? '',
                phoneNumber: authenticatedUser?.phoneNumber ?? '',
                language: language})
        }
    }

    return (
        <Dropdown>
            <Dropdown.Toggle as='div'>
                <img className={styles.selectedLanguageIcon} src={authenticatedUser?.language === Language.sv ? seFlag : ukFlag} title={t('selectLanguage')} alt={t('selectLanguage')} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleLanguageSelected(Language.sv)}><img className={styles.flagIcon} src={seFlag} title='Svenska' alt='Svenska' />Svenska</Dropdown.Item>
                <Dropdown.Item onClick={() => handleLanguageSelected(Language.en)}><img className={styles.flagIcon} src={ukFlag} title='English' alt='English' />English</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default LanguagePicker

const useStyles = createUseStyles({
    selectedLanguageIcon: {
        width: 24,

    },
    flagIcon: {
        width: 24,
        marginRight: 10,
        marginBottom: 5
    }
})
