import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {createUseStyles} from "react-jss"
import Button from "../components/Button"
import Card from "../components/Card"
import ErrorModal from "../components/ErrorModal"
import FilesByMonth from "../components/FilesByMonth"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import GeneratePayoutBasisModal from "../components/GeneratePayoutBasisModal"
import SuccessModal from "../components/SuccessModal"
import useDidUpdateEffect from "../hooks/useDidUpdateEffect"
import useEmployeeActions from "../hooks/useEmployeeActions"
import useFileActions from "../hooks/useFileActions"
import usePayoutBasisActions from "../hooks/usePayoutBasisActions"
import {useAppDispatch, useAppSelector} from "../redux/hooks"
import {errorHandled as employeeErrorHandled} from "../redux/slices/employeeSlice"
import {errorHandled as payoutBasisErrorHandled, payoutBasisGenerationHandled} from "../redux/slices/payoutBasisSlice"
import {EmployeeDataParserType} from "../redux/types"
import NewEmployeesWithPayoutsModal from "../components/NewEmployeesWithPayoutsModal";

const PayoutBasis = () => {
  const styles = useStyles()
  const {t} = useTranslation(['payoutBasis', 'common']);

  const employer = useAppSelector(state => state.employer.employer)
  const upcomingYearAndMonthForGeneration = useAppSelector(state => state.payoutBasis.upcomingYearAndMonth)
  const payoutBasisGenerationFileCount = useAppSelector(state => state.payoutBasis.payoutBasisGenerationFileCount)
  const filesLoading = useAppSelector(state => state.files.loading)
  const payoutBasisLoading = useAppSelector(state => state.payoutBasis.loading)
  const employeeFileUploadError = useAppSelector(state => state.employee.error)
  const payoutBasisError = useAppSelector(state => state.payoutBasis.error)

  const dispatch = useAppDispatch()
  const {bulkUploadEmployees} = useEmployeeActions()
  const {getUpcomingYearAndMonthForGeneration, generatePayoutBasis: generate} = usePayoutBasisActions()
  const {getFiles} = useFileActions()

  const [generationModalVisible, setGenerationModalVisible] = useState<boolean>(false)
  const [newEmployeeModalVisible, setNewEmployeeModalVisible] = useState<boolean>(false)
  const [employeeFileUploaded, setEmployeeFileUploaded] = useState<boolean>(false)
  const [errorModalVisible, setErrorModalVisible] = useState<boolean>(false)
  const [successModalVisible, setSuccessModalVisible] = useState<boolean>(false)

  useEffect(() => {
    if (upcomingYearAndMonthForGeneration == null) {
      getUpcomingYearAndMonthForGeneration()
    }
  }, [])

  useDidUpdateEffect(() => {
    if (payoutBasisGenerationFileCount != null) {
      getFiles()
      setSuccessModalVisible(true)
    }
  }, [payoutBasisGenerationFileCount])

  useDidUpdateEffect(() => {
    if (employeeFileUploadError || payoutBasisError) {
      setGenerationModalVisible(false)
      setErrorModalVisible(true)
    }
  }, [employeeFileUploadError, payoutBasisError])

  const showNewEmployeesModal = () => {
    setNewEmployeeModalVisible(true)
  }

  const showPayoutBasisGenerationModal = () => {
    setEmployeeFileUploaded(false)
    setGenerationModalVisible(true)
  }

  const handleError = () => {
    dispatch(employeeErrorHandled())
    dispatch(payoutBasisErrorHandled())
    setErrorModalVisible(false)
  }

  const handleSuccess = () => {
    dispatch(payoutBasisGenerationHandled())
    setSuccessModalVisible(false)
  }

  const handleEmployeeFileUploaded = (file: File) => {
    bulkUploadEmployees(file, employer?.employeeDataParserType as EmployeeDataParserType, true, () => {
      setEmployeeFileUploaded(true)
    })
  }

  const generatePayoutBasis = () => {
    generate();
    setGenerationModalVisible(false)
  }

  return (
    <>
      <Card className={styles.card}>
        <h2>
          {t('title', {ns: 'payoutBasis'})}
        </h2>
      </Card>

      <Card>
            <div className={styles.buttonContainer}>
              {employer?.newEmployeesReportEnabled &&
                <Button title={t('generateNewEmployeeReport', {ns: 'payoutBasis'})} className={styles.button}
                        onClick={showNewEmployeesModal}/>
              }
              {employer?.calendarMonthPayoutBasisReportGenerationType == 'manual' &&
                <Button title={t('generatePayoutBasis', {ns: 'payoutBasis'})} className={styles.button}
                        onClick={showPayoutBasisGenerationModal}/>
              }
            </div>
        

        <FilesByMonth
          showBankAccountImportFiles={false}
          showCreditReportFiles={false}
          showDeductionBasisForPaydayFiles={false}
          showEmployerPayDeductionPaymentNotificationFiles={false}
          showPayoutBasisForCalendarMonthFiles={true}
          showAgiAdjustmentSieFiles={false}
          showAgiAdjustmentReportFiles={false}
          showForaAdjustmentReportFiles={false}
          showEmployeeForeclosureReportFiles={false}
          showRemainingDebtReportFiles={false}
          showGrossAdvanceReportFiles={false}
        />
      </Card>
      <GeneratePayoutBasisModal
        year={upcomingYearAndMonthForGeneration?.year ?? null}
        month={upcomingYearAndMonthForGeneration?.month ?? null}
        payoutBasisGenerationEnabled={upcomingYearAndMonthForGeneration?.month != null}
        earliestPayoutBasisGenerationDayOfMonth={employer?.calenderMonthPayoutBasisReportGenerationDayOfMonth ?? 0}
        employeeFileUploadSuccess={employeeFileUploaded}
        visible={generationModalVisible}
        onCancelClick={() => setGenerationModalVisible(false)}
        onEmployeeFileUpload={handleEmployeeFileUploaded}
        onGenerateClick={generatePayoutBasis}
      />
      <NewEmployeesWithPayoutsModal onCloseClick={() => setNewEmployeeModalVisible(false)} visible={newEmployeeModalVisible}/>
      <ErrorModal
        visible={errorModalVisible}
        errorText={employeeFileUploadError?.toString() ?? payoutBasisError?.toString()}
        onCloseClick={handleError}
      />
      <SuccessModal
        visible={successModalVisible}
        message={payoutBasisGenerationFileCount != null && payoutBasisGenerationFileCount > 0 ? t('successWithGeneratedFiles', {
          ns: 'payoutBasis',
          count: payoutBasisGenerationFileCount
        }) : t('successWithoutGeneratedFiles', {ns: 'payoutBasis'})}
        onCloseClick={handleSuccess}
      />
      <FullScreenLoadingIndicator visible={filesLoading || payoutBasisLoading}/>
    </>
  )
}

export default PayoutBasis

const useStyles = createUseStyles({
  container: {
    marginBottom: 40
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 20,
    columnGap: 10
  },
  card: {
    marginBottom: 20
  },
  button: {
    alignSelf: 'flex-end'
  }
})
