import { FC } from 'react'
import { createUseStyles } from 'react-jss'
import { FormCheckProps } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import InfoIcon from './InfoIcon'

interface SwitchProps extends FormCheckProps {
  id?: string
  tooltipText?: string
}

const Switch: FC<SwitchProps> = ({ id = 'switch', tooltipText, ...rest }) => {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <Form.Switch id={id} {...rest} />      
      <div className={styles.infoIconContainer}>
        {tooltipText && <InfoIcon text={tooltipText} />}
      </div>
    </div>
  )
}

export default Switch

const useStyles = createUseStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 4,
      marginTop: 0
    },
    infoIconContainer: {
      marginLeft: 10
    }
  })