import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {createUseStyles} from 'react-jss'
import {Breakpoints} from '../../constants/breakpoints'
import {Colors} from "../../constants/colors"
import {Fonts} from '../../constants/fonts'
import {
  EmployerInitiatedPayoutBasis,
  EmployerInitiatedPayoutBasisState,
  EmployerInitiatedPayoutEmployeeModel
} from "../../redux/types"
import ListItemCard from "../ListItemCard"
import Pagination from '../Pagination'
import {formatMoney} from "../../util/money";

interface Props {
  employerInitiatedPayoutBasisList: EmployerInitiatedPayoutBasis[]
  pageIndex: number
  pageSize: number
  onPageChange: (pageIndex: number) => void
}

const EmployerInitiatedPayoutBasisRow = ({basis, index}: {
  basis: EmployerInitiatedPayoutBasis,
  index: number,
}) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'employerInitiatedPayout', 'employees']);

  const getEmployeeStatusColor = (employee: EmployerInitiatedPayoutEmployeeModel) => {
    switch (employee.status) {
      case 'registered':
        return Colors.euro_50
      case 'connected':
        return Colors.euro_300
      case 'active':
        return Colors.euro_800
      case 'paused':
        return Colors.yen_300
      case 'terminated':
        return Colors.yuan_100
    }
  }

  const getEmployeeStatusTextColor = (employee: EmployerInitiatedPayoutEmployeeModel) => {
    switch (employee.status) {
      case 'registered':
        return Colors.gray0
      case 'connected':
        return Colors.gray0
      case 'active':
        return Colors.gray4
      case 'paused':
        return Colors.gray0
      case 'terminated':
        return Colors.rubel_700
    }
  }

  const getPayoutStatusColor = (state: EmployerInitiatedPayoutBasisState) => {
    switch (state) {
      case EmployerInitiatedPayoutBasisState.scheduled:
        return Colors.dollar_300
      case EmployerInitiatedPayoutBasisState.pending:
        return Colors.yen_300
      case EmployerInitiatedPayoutBasisState.completed:
        return Colors.euro_300
      case EmployerInitiatedPayoutBasisState.cancelled:
        return Colors.gray3
      case EmployerInitiatedPayoutBasisState.failed:
        return Colors.rubel_50
      case EmployerInitiatedPayoutBasisState.unprocessable:
        return Colors.gray3
      default:
        return Colors.gray3
    }
  }

  const getPayoutStatusTextColor = (state: EmployerInitiatedPayoutBasisState) => {
    switch (state) {
      case EmployerInitiatedPayoutBasisState.scheduled:
        return Colors.gray0
      case EmployerInitiatedPayoutBasisState.pending:
        return Colors.gray0
      case EmployerInitiatedPayoutBasisState.completed:
        return Colors.dollar_900
      case EmployerInitiatedPayoutBasisState.cancelled:
        return Colors.gray0
      case EmployerInitiatedPayoutBasisState.failed:
        return Colors.gray0
      case EmployerInitiatedPayoutBasisState.unprocessable:
        return Colors.gray0
      default:
        return Colors.gray0
    }
  }

  return (
    <ListItemCard key={`basis_${index}`} className={styles.listItemContainer} index={index}>
      <div className={styles.defaultCellContainer}>
        {basis.employee.externalId}
      </div>
      <div className={styles.defaultCellContainer}>
        {basis.employee.identificationNumber}
      </div>
      <div className={styles.defaultCellContainer}>
        {basis.employee.givenName} {basis.employee.familyName}
      </div>
      <div className={styles.badgeContainer}>
        <div className={styles.badge} style={{backgroundColor: getEmployeeStatusColor(basis.employee)}}>
              <span className={styles.badgeText} style={{color: getEmployeeStatusTextColor(basis.employee)}}>
                {t(`employeeStatus_${basis.employee.status}`, {ns: 'employees'})}
              </span>
        </div>
      </div>
      <div className={styles.defaultCellContainer}>
        {formatMoney(basis.grossAmount)}
      </div>
      <div className={styles.defaultCellContainer}>
        {formatMoney(basis.netAmount)}
      </div>
      <div className={styles.badgeContainer}>
        <div className={styles.badge} style={{backgroundColor: getPayoutStatusColor(basis.state)}}>
              <span className={styles.badgeText} style={{color: getPayoutStatusTextColor(basis.state)}}>
                {t(`basis_state_${basis.state}`, {ns: 'employerInitiatedPayout'})}
              </span>
        </div>
      </div>
    </ListItemCard>
  )
}

const EmployerInitiatedPayoutBasisList = ({
                                            employerInitiatedPayoutBasisList,
                                            pageIndex,
                                            pageSize,
                                            onPageChange
                                          }: Props) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'employerInitiatedPayout']);

  const itemsOnCurrentPage = useMemo(() => {
    const firstPageIndex = (pageIndex - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return employerInitiatedPayoutBasisList.slice(firstPageIndex, lastPageIndex);
  }, [employerInitiatedPayoutBasisList, pageIndex, pageSize])

  const renderHeader = () => {
    return (
      <ListItemCard className={styles.listItemContainer}>
        <div className={styles.defaultCellContainer}>
          <strong>{t('externalId', {ns: 'employerInitiatedPayout'})}</strong>
        </div>
        <div className={styles.defaultCellContainer}>
          <strong>{t('identificationNumber', {ns: 'employerInitiatedPayout'})}</strong>
        </div>
        <div className={`${styles.defaultCellContainer}`}>
          <strong>{t('name', {ns: 'employerInitiatedPayout'})}</strong>
        </div>
        <div className={styles.badgeContainer}>
          <strong>{t('employeeStatus', {ns: 'employerInitiatedPayout'})}</strong>
        </div>
        <div className={styles.defaultCellContainer}>
          <strong>{t('grossAmount', {ns: 'employerInitiatedPayout'})}</strong>
        </div>
        <div className={`${styles.defaultCellContainer}`}>
          <strong>{t('netAmount', {ns: 'employerInitiatedPayout'})}</strong>
        </div>
        <div className={styles.badgeContainer}>
          <strong>{t('payoutStatus', {ns: 'employerInitiatedPayout'})}</strong>
        </div>
      </ListItemCard>
    )
  }

  const renderListItems = () => {
    return itemsOnCurrentPage?.map((basis, index) => {
      return (
        <EmployerInitiatedPayoutBasisRow
          basis={basis}
          index={index}
        />
      )
    })
  }

  return (
    <>
      {renderHeader()}
      {renderListItems()}
      <div className={styles.pagination}>
        <Pagination
          pageIndex={pageIndex}
          itemCount={employerInitiatedPayoutBasisList.length}
          pageSize={pageSize}
          siblingCount={1}
          onPageChange={onPageChange}
        />
      </div>
    </>
  )
}

export default EmployerInitiatedPayoutBasisList

const useStyles = createUseStyles({
  listItemContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'space-between',
  },
  defaultCellContainer: {
    display: 'flex',
    flex: 1,
  },
  smallHidden: {
    display: 'none',
    [`@media (min-width: ${Breakpoints.tablet}px)`]: {
      display: 'flex'
    },
  },
  buttonCell: {
    display: 'flex',
    width: 30
  },
  badgeContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start'
  },
  badge: {
    height: '20px',
    paddingLeft: 7,
    paddingRight: 7,
    borderRadius: 20,
    backgroundColor: Colors.euro_800,
    margin: 2
  },
  badgeText: {
    display: 'block',
    fontSize: 14,
    color: Colors.gray4,
    fontFamily: Fonts.regular,
    lineHeight: '21px'
  },
  pagination: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center'
  }
})
