//https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
import { useMemo } from 'react';

export const DOTS = '...';

interface Props {
    itemCount: number
    pageIndex: number
    pageSize: number
    siblingCount: number
}

export const usePagination = ({
    itemCount,
    pageIndex,
    pageSize,
    siblingCount = 1
} : Props) => {

    const getRange = (start: number, end: number) => {
        const length = end - start + 1;
        return Array.from({ length }, (_, index) => start + index);
    }

    const paginationRange = useMemo(() => {
        const totalPageCount = Math.ceil(itemCount / pageSize);

        // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
        const totalPageNumbers = siblingCount + 5;

        // If the number of pages is less than the page numbers we want to show in our
        // paginationComponent, we return the range [1..totalPageCount]
        if (totalPageNumbers >= totalPageCount) {
            return getRange(1, totalPageCount);
        }

        const leftSiblingIndex = Math.max(pageIndex - siblingCount, 1);
        const rightSiblingIndex = Math.min(pageIndex + siblingCount, totalPageCount);

        // We do not want to show dots if there is only one position left 
        // after/before the left/right page count as that would lead to a change if our Pagination
        // component size which we do not want
        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPageCount;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = getRange(1, leftItemCount);

            return [...leftRange, DOTS, totalPageCount];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = getRange(totalPageCount - rightItemCount + 1, totalPageCount);
            return [firstPageIndex, DOTS, ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = getRange(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
        }
    }, [itemCount, pageIndex, pageSize, siblingCount]);

    return paginationRange
};