import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { AgiAdjustmentsForPeriod, AgiAdjustmentsModel } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { loading, periodDetailsFetchFailure, periodDetailsFetched, periodsFetchFailure, periodsFetched } from "../redux/slices/agiAdjustmentSlice";

const useAgiAdjustmentActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getPeriods = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.agiAdjustment)
            .then(response => {
                dispatch(periodsFetched(response.data as AgiAdjustmentsModel))
            })
            .catch(error => {
                console.log('getPeriods error:', error)
                dispatch(periodsFetchFailure(error))
            })
    }

    const getPeriodDetails = (period: string) => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.agiAdjustment + period)
            .then(response => {
                dispatch(periodDetailsFetched(response.data as AgiAdjustmentsForPeriod))
            })
            .catch(error => {
                console.log('getPeriodDetails error:', error)
                dispatch(periodDetailsFetchFailure(error))
            })
    }

    return {
        getPeriods,
        getPeriodDetails
    }
}

export default useAgiAdjustmentActions