import { useState, ChangeEvent, FocusEvent, KeyboardEvent, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import InfoIcon from './InfoIcon'

interface Props {
    id: string
    className?: string
    value?: string
    label?: string
    placeholder?: string
    type?: string
    valid?: boolean
    disabled?: boolean
    icon?: string | IconDefinition
    tooltipText?: string
    mandatory?: boolean
    inputRef?: React.RefObject<HTMLInputElement>
    onChange?: (value: string) => void
    onEnterKeyOrBlur?: (value: string) => void
}

const InputField = ({
    id,
    className = '',
    value = '',
    label,
    placeholder = '',
    type,
    disabled,
    valid = true,
    icon,
    tooltipText,
    mandatory,
    inputRef,
    onChange = () => {},
    onEnterKeyOrBlur = () => {}
} : Props) => {
    const styles = useStyles()
    const [inputFieldValue, setInputFieldValue] = useState(value)

    useEffect(() => {
        if (value !== inputFieldValue) {
          setInputFieldValue(value)
        }           
    }, [value])

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputFieldValue(event.target.value)
        onChange(event.target.value)
    }
    
    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
        onEnterKeyOrBlur(event.target.value)
    }
    
    const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          onEnterKeyOrBlur(inputFieldValue)
        }
    }

    const renderLabel = () => {
        return label && (
            <div>
                <label className={styles.label}>{label}</label>
                {mandatory && <label className={styles.mandatoryIndicator}>*</label>}
                {tooltipText && <InfoIcon text={tooltipText} />}
            </div>
        )
    }   

    const renderIcon = () => {
        return icon &&  (
            <FontAwesomeIcon
              className={styles.icon}
              icon={icon as IconDefinition}
              color={Colors.grayGraph}
            />
          )
    }

    const inputPaddingLeft = icon ? 40 : 15

    return (
        <div className={styles.container}>
            {renderLabel()}
            <div className={`${styles.inputContainer} ${className}`} style={{borderColor: valid ? Colors.euro_600 : Colors.rubel_700, backgroundColor: disabled ? Colors.grayDisabled : Colors.grayCard}}>
                {renderIcon()}
                <input
                    ref={inputRef}
                    className={`${styles.input}`}
                    style={{ paddingLeft: inputPaddingLeft }}
                    onBlur={handleBlur}
                    onKeyUp={handleKeyUp}
                    onInput={handleChange}
                    value={inputFieldValue}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            </div>
      </div>
    )
}

export default InputField

const useStyles = createUseStyles({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    inputContainer: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.euro_600,
        backgroundColor: Colors.grayCard,
    },
    input: {
        width: '100%',
        height: 40,
        border: 'none',
        backgroundColor: 'transparent',
        borderWidth: 0,
        padding: 15,
        '&:focus': {
            border: 'none',
            outline: 'none',
        },
    },
    label: {
        marginBottom: 3,
        marginRight: 4
    },
    mandatoryIndicator: {
        color: Colors.rubel_700,
        marginRight: 4
    },
    icon: {
        position: 'absolute',
        left: 15,
      },
  })
  