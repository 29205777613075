import { createUseStyles } from "react-jss"
import Card from "../components/Card"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../redux/hooks"
import useAgiAdjustmentActions from "../hooks/useAgiAdjustmentActions"
import { useEffect } from "react"
import AgiAdjustmentList from "../components/AgiAdjustment/AgiAdjustmentList"
import { periodDetailsPageIndexChanged } from "../redux/slices/agiAdjustmentSlice"
import { Colors } from "../constants/colors"
import File from "../components/File"
import BackButton from "../components/BackButton"

const AgiAdjustmentDetails = () => {
    const styles = useStyles()
    const { t } = useTranslation(['common', 'agiAdjustment']);
    const { period } = useParams()
    const { getPeriodDetails } = useAgiAdjustmentActions()
    const dispatch = useAppDispatch()

    const loading = useAppSelector(state => state.agiAdjustment.loading)
    const periodDetails = useAppSelector(state => state.agiAdjustment.periodDetails)
    const pageIndex = useAppSelector(state => state.agiAdjustment.periodDetailsPageIndex)
    const pageSize = useAppSelector(state => state.agiAdjustment.periodDetailsPageSize)

    useEffect(() => {
        if (period && (periodDetails == null || periodDetails.period !== period)) {
            getPeriodDetails(period)
        }
      }, [])

    const onPageChange = (pageIndex: number) => {
        dispatch(periodDetailsPageIndexChanged(pageIndex))
    }

    return (
        <>
        <BackButton />
        <Card className={styles.card}>
            <h2>
                {t('title', {ns: 'agiAdjustment'})} {period}
            </h2>
        </Card>
        <Card className={styles.card}>
            <h4 className={styles.subTitle}>
                {t('files', {ns: 'agiAdjustment'})}
            </h4>
            {periodDetails?.sieFile &&
              <File file={periodDetails?.sieFile}/>
            }
            {periodDetails?.agiAdjustmentReportFile &&
              <File file={periodDetails?.agiAdjustmentReportFile}/>
            }
        </Card>
         <Card>
             <h4 className={styles.subTitle}>
                 {t('adjustments', {ns: 'agiAdjustment'})}
             </h4>
             {periodDetails &&
            <AgiAdjustmentList
                adjustments={periodDetails.adjustments}
                pageIndex={pageIndex}
                pageSize={pageSize}
                onPageChange={onPageChange}
            />}
            <FullScreenLoadingIndicator visible={loading} />
        </Card>
        </>
    )
}

export default AgiAdjustmentDetails

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    info: {
        marginTop: 20,
    },
    subTitle: {
        color: Colors.euro_800,
        marginBottom: 20
    },
    button: {
        alignSelf: 'flex-end'
    }
})
