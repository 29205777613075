import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Employee, EmployeeReadyForActivation } from '../types'

interface EmployeeActivationState {
    loading: boolean
    pendingEmployees: EmployeeReadyForActivation[]
    pageIndex: number
    pageSize: number
    error: Error | string | null
}

const initialState: EmployeeActivationState = {
    loading: false,
    pendingEmployees: [],
    pageIndex: 1,
    pageSize: 15,
    error: null
}

export const employeeActivationSlice = createSlice({
    name: 'employeeActivation',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        pendingEmployeesFetched: (state, action: PayloadAction<EmployeeReadyForActivation[]>) => {
            state.loading = false
            state.pendingEmployees = action.payload
            state.pageIndex = 1
            state.error = null
        },
        pendingEmployeesFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        employeesActivated: (state, action: PayloadAction<Employee[]>) => {
            const activatedIds = action.payload.map((employee) => employee.id)

            state.loading = false
            state.pendingEmployees = state.pendingEmployees.filter(x => !activatedIds.includes(x.id))
            state.error = null
        },
        employeeActivationFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        setBankAccountActivationPending: (state, action: PayloadAction<EmployeeReadyForActivation[]>) => {
            state.loading = false
            state.pendingEmployees = action.payload
            state.error = null
        },
        setBankAccountActivationPendingFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
    }
})

export const { 
    loading, 
    pendingEmployeesFetched, 
    pendingEmployeesFetchFailure, 
    employeesActivated, 
    employeeActivationFailure, 
    setBankAccountActivationPending, 
    setBankAccountActivationPendingFailure, 
    pageIndexChanged 
} = employeeActivationSlice.actions

export default employeeActivationSlice.reducer