import {apiConfig} from "../apiConfig"
import useFetchClient from "./useFetchClient"
import {EmployerInitiatedPayoutJob, EmployerInitiatedPayoutJobListItem} from "../redux/types";
import {useAppDispatch} from "../redux/hooks";
import {
  cancelJobFailure,
  jobCancelled,
  jobFetched,
  jobFetchFailure,
  jobsFetched,
  jobsFetchFailure,
  loading
} from "../redux/slices/employerInitiatedPayoutSlice";

const useEmployerInitiatedPayoutActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getJobs = () => {
    dispatch(loading())

    return fetchClient.get(apiConfig.routes.employerInitiatedPayouts)
      .then(response => {
        dispatch(jobsFetched(response.data as EmployerInitiatedPayoutJobListItem[]))
      })
      .catch(error => {
        console.log('useEmployerInitiatedPayoutActions.getJobs error:', error)
        dispatch(jobsFetchFailure(error))
      })
  }

  const getJob = (jobId: string) => {
    dispatch(loading())

    return fetchClient.get(apiConfig.routes.employerInitiatedPayouts + jobId)
      .then(response => {
        dispatch(jobFetched(response.data as EmployerInitiatedPayoutJob))
      })
      .catch(error => {
        console.log('useEmployerInitiatedPayoutActions.getJob error:', error)
        dispatch(jobFetchFailure(error))
      })
  }

  const cancelJob = (jobId: string) => {
    dispatch(loading())

    return fetchClient.delete(apiConfig.routes.employerInitiatedPayouts + jobId)
      .then(response => {
        dispatch(jobCancelled(response.data as EmployerInitiatedPayoutJobListItem[]))
      })
      .catch(error => {
        console.log('useEmployerInitiatedPayoutActions.cancelJob error:', error)
        dispatch(cancelJobFailure(error))
      })
  }

  return {
    getJobs,
    getJob,
    cancelJob
  }
}

export default useEmployerInitiatedPayoutActions
