import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { useAppDispatch } from "../redux/hooks";
import { loading, metricsFetched, metricsFetchFailure } from "../redux/slices/dashboardMetricsSlice";
import { DashboardMetrics } from "../redux/types";

const useDashboardMetricsActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getMetrics = () => {
        dispatch(loading())
        return fetchClient.get(apiConfig.routes.dashboardMetrics)
            .then(response => {
                dispatch(metricsFetched(response.data as DashboardMetrics))
            })
            .catch(error => {
                console.log('getMetrics error:', error)
                dispatch(metricsFetchFailure(error))
            })
    }

    return {
        getMetrics
    }
}

export default useDashboardMetricsActions