import {apiConfig} from "../apiConfig"
import useFetchClient from "./useFetchClient"
import {BookkeepingConfiguration} from "../redux/types";
import {useAppDispatch} from "../redux/hooks";
import {
  loading,
  bookkeepingConfigurationFetched,
  bookkeepingConfigurationFetchFailure
} from "../redux/slices/bookkeepingConfigurationSlice";

const useBookkeepingConfigurationActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const getBookkeepingConfiguration = () => {
    dispatch(loading())

    return fetchClient.get(apiConfig.routes.employer + 'bookkeepingConfiguration')
      .then(response => {
        dispatch(bookkeepingConfigurationFetched(response.data as BookkeepingConfiguration))
      })
      .catch(error => {
        console.log('getBookkeepingSettings error:', error)
        dispatch(bookkeepingConfigurationFetchFailure(error))
      })
  }

  const updateBookkeepingConfiguration = (bookkeepingConfiguration: BookkeepingConfiguration) => {
    dispatch(loading())

    return fetchClient.post(apiConfig.routes.employer + 'bookkeepingConfiguration', bookkeepingConfiguration)
      .then(response => {
        dispatch(bookkeepingConfigurationFetched(response.data as BookkeepingConfiguration))
      })
      .catch(error => {
        console.log('updateBookkeepingSettings error:', error)
        dispatch(bookkeepingConfigurationFetchFailure(error))
      })
  }


  return {
    getBookkeepingConfiguration,
    updateBookkeepingConfiguration,
  }
}

export default useBookkeepingConfigurationActions
