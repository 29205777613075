import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PayoutBasisGenerationResult, PayoutBasisYearAndMonthModel } from '../types'

interface PayoutBasisState {
    loading: boolean
    upcomingYearAndMonth: PayoutBasisYearAndMonthModel | null
    payoutBasisGenerationFileCount: number | null
    error: Error | string | null
}

const initialState: PayoutBasisState = {
    loading: false,
    upcomingYearAndMonth: null,
    payoutBasisGenerationFileCount: null,
    error: null
}

export const payoutBasisSlice = createSlice({
    name: 'payoutBasis',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        upcomingYearAndMonthFetched: (state, action: PayloadAction<PayoutBasisYearAndMonthModel>) => {
            state.loading = false
            state.upcomingYearAndMonth = action.payload
            state.error = null
        },
        upcomingYearAndMonthFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        payoutBasisGenerated: (state, action: PayloadAction<PayoutBasisGenerationResult>) => {
            state.loading = false
            state.payoutBasisGenerationFileCount = action.payload.generatedImportFileCount
            state.error = null
        },
        payoutBasisGenerationFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        }, 
        payoutBasisGenerationHandled: (state) => {
            state.payoutBasisGenerationFileCount = null
        },
        errorHandled: (state) => {
            state.error = null    
        }
    }
})

export const { 
    loading, 
    upcomingYearAndMonthFetched, 
    upcomingYearAndMonthFetchFailure, 
    payoutBasisGenerated, 
    payoutBasisGenerationFailure,
    payoutBasisGenerationHandled,
    errorHandled
} = payoutBasisSlice.actions

export default payoutBasisSlice.reducer