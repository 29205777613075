import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Colors } from '../constants/colors';

interface Props {
    text: string
}

const InfoIcon = ({ text }: Props) => {
    return (
        <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            placement='right'
            trigger={['hover','click']}
            overlay={(props) => (
                <Tooltip {...props}>
                    {text}
                </Tooltip>
            )}
        >
            <div style={{display: 'inline'}}>
                <FontAwesomeIcon
                    icon={faQuestionCircle}
                    color={Colors.euro_800}
                />
            </div>
        </OverlayTrigger>
    )
}

export default InfoIcon