import { PayScheduleModel, PaySchedulePaydayModel } from "../../redux/types"
import PaySchedule from "./PaySchedule"

interface Props {
    paySchedules: PayScheduleModel[]
    onEditPaydayClick: (payday: PaySchedulePaydayModel) => void
}

const PayScheduleList = ({paySchedules, onEditPaydayClick}: Props) => {

    const renderPaySchedules = () => {
        return paySchedules.map((paySchedule, index) => {
            return (
                <PaySchedule 
                    key={`paySchedule_${index}`}
                    paySchedule={paySchedule}
                    onEditPaydayClick={onEditPaydayClick}
                />
            )
        })
    }

    return (
        <div>
            {renderPaySchedules()}   
        </div>
    )
}

export default PayScheduleList