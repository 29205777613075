import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { FileModel } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import { filesFetched, filesFetchFailure, loading } from "../redux/slices/fileSlice";

const useFileActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getFiles = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.files)
            .then(response => {
                dispatch(filesFetched(response.data as FileModel[]))
            })
            .catch(error => {
                console.log('getFiles error:', error)
                dispatch(filesFetchFailure(error))
            })
    }

    return {
        getFiles
    }
}

export default useFileActions