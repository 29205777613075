import { faCalendar, faCog, faCogs, faCoins, faFile, faFileInvoiceDollar, faMinusSquare, faStopwatch, faTachometerAlt, faUserCheck, faUsers, faUsersCog, faUserTimes } from '@fortawesome/free-solid-svg-icons'
import { createUseStyles } from 'react-jss'
import { headerHeight } from '../../../constants/dimensons'
import SidebarLink from './SidebarLink'
import logo from '../../../assets/images/logo/cappy-symbol-pos.png'
import { Colors } from '../../../constants/colors'
import { useAppSelector } from '../../../redux/hooks'
import { Link, useLocation } from 'react-router-dom'
import { Fonts } from '../../../constants/fonts'
import { hasAnyRequiredRole } from '../../../util/role'
import { useTranslation } from 'react-i18next'
import Emitter from '../../../eventing/Emitter'
import { Events } from '../../../eventing/Events'
import { PayoutBasisFileType } from '../../../redux/types'
import { truncateString } from '../../../util/string'

const Sidebar = () => {
    const styles = useStyles()
    const location = useLocation();
    const { t } = useTranslation('common');

    const authenticatedUser = useAppSelector(state => state.auth.authenticatedUser)
    const currentEmployerId = useAppSelector(state => state.auth.currentEmployerId)
    const employeesPendingActivation = useAppSelector(state => state.employeeActivation.pendingEmployees)
    const employeesPendingDeactivation = useAppSelector(state => state.employeeDeactivation.pendingEmployees)
    const upcomingPayrolls = useAppSelector(state => state.payroll.upcomingPayrolls)
    const employer = useAppSelector(state => state.employer.employer)
    const files = useAppSelector(state => state.files.files)

    const getDuePayrollReportCount = () => {
        return upcomingPayrolls.filter(x => x.payrollFileDue || x.validationErrors?.length > 0).length;
    }

    const getActivationTodoCount = () => {
        return employeesPendingActivation.filter(x => x.bankAccountActivationFailed || !x.pendingBankAccountActivation).length;
    }

    const getDeactivationTodoCount = () => {
        return employeesPendingDeactivation.length;
    }

    const getNewFileCount = () => {
        return files.filter(x => (x.payrollSystemPayoutBasisImportFile?.lastDownloadDate ?? x.employerPayDeductionPaymentNotificationFile?.lastDownloadDate ?? x.creditReportFile?.lastDownloadDate) == null).length;
    }

    const getNewPayoutBasisFileCount = () => {
        return files.filter(x => x.payrollSystemPayoutBasisImportFile?.fileType === PayoutBasisFileType.payoutBasisForCalendarMonth && x.payrollSystemPayoutBasisImportFile?.lastDownloadDate == null).length;
    }

    const getNewPaymentNotificationFileCount = () => {
        return files.filter(x => x.employerPayDeductionPaymentNotificationFile != null && x.employerPayDeductionPaymentNotificationFile.lastDownloadDate == null).length;
    }

    return (
        <>
        <div className={styles.container}>
            <div className={styles.headerContainer}>
                <div className={styles.logoContainer}>
                    <Link to="/"><img className={styles.logo} src={logo} alt='' /></Link>
                </div>
                    <div className={styles.loggedInUserContainer}>
                        <div className={styles.loggedInUser} onClick={() => Emitter.emit(Events.displayProfile)}>
                            {truncateString(`${authenticatedUser?.givenName} ${authenticatedUser?.familyName}`, 26)} 
                        </div>
                        <div className={styles.company} title={employer?.legalName}>
                            {truncateString(employer?.legalName ?? '', 26)}
                        </div>
                    </div>
            </div>
            <div className={styles.mainContainer}>
                <h6 className={styles.sectionTitle}>{t('start')}</h6>
                <SidebarLink title={t('dashboard')} icon={faTachometerAlt} linkTo='/' active={location.pathname === '/'} />
                
                {hasAnyRequiredRole(authenticatedUser, currentEmployerId, ['Owner', 'Admin', 'FinanceAdmin']) && 
                    <>
                    <h6 className={styles.sectionTitle}>{t('reports')}</h6>
                    <SidebarLink title={t('creditUsage')} icon={faCoins} linkTo='/creditusage' active={location.pathname.startsWith('/creditusage')}/>
                    </>
                }

                {hasAnyRequiredRole(authenticatedUser, currentEmployerId, ['Owner', 'Admin', 'ActivationAdmin', 'PayrollAdmin']) && 
                <>
                    <h6 className={styles.sectionTitle}>{t('manage')}</h6>

                    {hasAnyRequiredRole(authenticatedUser, currentEmployerId, ['Owner', 'Admin', 'ActivationAdmin']) && 
                    <>
                        {employer?.employeeActivationType !== 'automatic' && 
                        <SidebarLink title={t('activations')} icon={faUserCheck} linkTo='/activations' dotNumber={getActivationTodoCount()} active={location.pathname.startsWith('/activations')}/>
                        }
                        {employer?.employeeDeactivationType === 'manual' && 
                        <SidebarLink title={t('deactivations')} icon={faUserTimes} linkTo='/deactivations' dotNumber={getDeactivationTodoCount()} active={location.pathname.startsWith('/deactivations')}/>
                        }
                    </>
                    }

                    {hasAnyRequiredRole(authenticatedUser, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin']) && 
                    <>
                        <SidebarLink title={t('employees')} icon={faUsers} linkTo='/employees' active={location.pathname.startsWith('/employees')}/>

                        {hasAnyRequiredRole(authenticatedUser, currentEmployerId, ['Owner', 'Admin']) && 
                        <>
                            <SidebarLink title={t('shifts')} icon={faStopwatch} linkTo='/shifts' active={location.pathname.startsWith('/shifts')}/>
                            <SidebarLink title={t('deviations')} icon={faMinusSquare} linkTo='/deviations' active={location.pathname.startsWith('/deviations')}/>
                        </>
                        }

                        <SidebarLink title={t('paySchedules')} icon={faCalendar} linkTo='/paySchedules' active={location.pathname.startsWith('/paySchedules')}/>
                    </>
                    }

                    {hasAnyRequiredRole(authenticatedUser, currentEmployerId, ['Owner', 'Admin', 'PayrollAdmin']) && 
                    <>
                        {employer?.adminFlowType === 'agiAdjustment' &&
                        <>
                            <SidebarLink title={t('agiAdjustment')} icon={faFileInvoiceDollar} linkTo='/agiAdjustment' active={location.pathname.startsWith('/agiAdjustment')}/>
                            <SidebarLink title={t('foraAdjustment')} icon={faFileInvoiceDollar} linkTo='/foraAdjustment' active={location.pathname.startsWith('/foraAdjustment')}/>
                        </>
                        }
                        {employer?.adminFlowType === 'basisImport' &&
                        <SidebarLink title={t('payoutBasis')} icon={faFileInvoiceDollar} linkTo='/payoutBasis' dotNumber={getNewPayoutBasisFileCount()} active={location.pathname.startsWith('/payoutBasis')}/>
                        }
                        <SidebarLink title={t('payroll')} icon={faFileInvoiceDollar} linkTo='/payroll' dotNumber={getDuePayrollReportCount()} active={location.pathname.startsWith('/payroll')}/>
                    </>
                    }

                    {hasAnyRequiredRole(authenticatedUser, currentEmployerId, ['Owner', 'Admin', 'FinanceAdmin']) && employer?.adminFlowType === 'basisImport' &&
                    <>
                        <SidebarLink title={t('paymentNotifications')} icon={faFileInvoiceDollar} linkTo='/paymentNotifications' dotNumber={getNewPaymentNotificationFileCount()} active={location.pathname.startsWith('/paymentNotifications')}/>
                    </>
                    }
                </>
                }

                <h6 className={styles.sectionTitle}>{t('files')}</h6>
                <SidebarLink title={t('files')} icon={faFile} linkTo='/files' dotNumber={getNewFileCount()} active={location.pathname.startsWith('/files')} />

                {hasAnyRequiredRole(authenticatedUser, currentEmployerId, ['Owner', 'Admin']) && 
                    <>
                    <h6 className={styles.sectionTitle}>{t('integration')}</h6>
                    {(employer?.portalIntegrations?.length ?? 0) > 0 &&
                    <SidebarLink title={t('integration')} icon={faCogs} linkTo='/integrations' active={location.pathname.startsWith('/integrations')}/>
                    }
                    <SidebarLink title={t('log')} icon={faCogs} linkTo='/integrationlog' active={location.pathname.startsWith('/integrationlog')}/>

                    <h6 className={styles.sectionTitle}>{t('settings')}</h6>
                    <SidebarLink title={t('companySettings')} icon={faCog} linkTo='/companysettings' active={location.pathname.startsWith('/companysettings')}/>
                    <SidebarLink title={t('portalUsers')} icon={faUsersCog} linkTo='/portalusers' active={location.pathname.startsWith('/portalusers')}/>
                    </>
                }
            </div>
        </div>
    </>
    )
}

export default Sidebar

const useStyles = createUseStyles({
    container: {
        color: Colors.gray1,
    },
    headerContainer: {
        position: 'relative',
        height: headerHeight,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        columnGap: 10,
        paddingLeft: 30
    },
    mainContainer: {
        zIndex: 0,
        paddingTop: 20,
        paddingBottom: headerHeight,
        backgroundColor: '#ffffff',
        overflowY: 'auto',
        height: '100vh'
    },
    sectionTitle: {
        marginTop: 20,
        marginLeft: 20,
        marginBottom: 10,
        textTransform: 'uppercase',
        fontSize: 15
    },
    logo: {
        width: 50
    },
    loggedInUserContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    logoContainer: {
        padding: 3
    },
    loggedInUser: {
        color: Colors.euro_800,
        fontFamily: Fonts.semiBold,
        cursor: 'pointer'
    },
    company: {
        color: Colors.gray3,
        fontSize: 13
    }
})