import { useState } from "react"
import { createUseStyles } from 'react-jss'
import Card from '../../components/Card'
import InputField from '../../components/InputField'
import Button from '../../components/Button'
import { faKey, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useSearchParams } from 'react-router-dom'
import usePasswordActions from "../../hooks/usePasswordActions"
import { Colors } from "../../constants/colors"
import { useTranslation } from "react-i18next"
import FullScreenLoadingIndicator from "../../components/FullScreenLoadingIndicator"

const SetPassword = () => {
    const [password, setPassword] = useState<string>()
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email')
    const token = searchParams.get('token')
    const {setNewPasswordAndLogin, loading, error} = usePasswordActions()
    const { t } = useTranslation(['common', 'password']);

    const isValidPassword = () => {
        return password && password.length > 7
    }

    const handleFormSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        if (isValidPassword()) {
            setNewPasswordAndLogin(email as string, password as string, token as string)
        }
    }

    const renderErrorMessage = () => {
        if (error === '400') {
            return t('setPasswordExpiredFailure', {ns: 'password'})
        }
        return t('setPasswordFailure', {ns: 'password'})
    }

    const styles = useStyles()

    return (
        <div className={styles.container}>
            <Card className={styles.card}>
                <h3 className={styles.title}>{t('setPassword', {ns: 'password'})}</h3>
                <p>{t('setPasswordSubtitle', {ns: 'password'})}</p>
                <form onSubmit={handleFormSubmit}>
                    <div className={styles.cardRow}>
                        <InputField 
                            id='email'
                            label={t('email', {ns: 'common'})}
                            type='email'
                            value={email ?? ''}
                            icon={faEnvelope}
                            disabled={true}
                        />
                    </div>
                    <div className={styles.cardRow}>
                            <InputField 
                                id='password'
                                label={t('password', {ns: 'common'})}
                                type='password'
                                icon={faKey}
                                onChange={setPassword}
                            />
                        </div>
                        <div className={`${styles.cardRow} ${styles.buttonRow}`}>
                        <div className={styles.button}>
                            <Button
                                type='submit'
                                disabled={!isValidPassword() || !email}
                                title={t('setPasswordButtonText', {ns: 'password'})}
                            /> 
                        </div>
                    </div>
                    {!loading && error && 
                        <div className={styles.cardRow}>
                            <label className={styles.errorLabel}>
                                {renderErrorMessage()}
                            </label>
                        </div>
                    }
                </form>
            </Card>
            <FullScreenLoadingIndicator visible={loading} />
        </div>
    )
}

export default SetPassword

const useStyles = createUseStyles({
    container: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        marginBottom: 30
    },
    card: {
        width: 470,
        padding: 60,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    cardRow: {
        marginTop: 10,
        marginBottom: 10,
        width: 350
    },
    buttonRow: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    errorLabel: {
        color: Colors.rubel_700
    }
})