import { faBan, faPen, faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import InputField from "./InputField"

interface Props {
    value?: string
    placeholder?: string
    validationRegex?: RegExp
    onEdited: (value: string) => void
}

const EditableField = ({value, placeholder, validationRegex, onEdited}: Props) => {
    const styles = useStyles()
    const { t } = useTranslation('common');
    const [editMode, setEditMode] = useState(false)
    const [editFieldValue, setEditFieldValue] = useState(value)
    const [valid, setValid] = useState(true)
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setEditFieldValue(value)
        setValid(true)
    }, [value])

    useEffect(() => {
        if (editMode) {
            inputRef?.current?.focus()
        }
    }, [editMode])

    const onCancel = () => {
        setEditMode(false)
        setEditFieldValue(value) 
        setValid(true)  
    }

    const onSave = () => {
        if (!validate())
            return

        setEditMode(false)
        onEdited(editFieldValue ?? '')
        setValid(true)
    }

    const onEdit = () => {
        setEditMode(true)
    }

    const validate = () => {
        if (validationRegex) {
            if (editFieldValue?.match(validationRegex)) {
                setValid(true)
                return true
            }
            setValid(false)
            return false
        }
        return true
    }

    return (
        <div className={styles.container}>
            {!editMode && 
            <div className={styles.readonlyContainer}>
                <div>
                    {(value == null || value.length < 1) ? placeholder : value}
                </div>
                <div>
                    <FontAwesomeIcon
                        className={styles.button}
                        icon={faPen}
                        color={Colors.gray1}
                        onClick={onEdit}
                        title={t('edit')}
                    />
                </div>
            </div>
            }
            {editMode && 
            <div className={styles.editContainer}>
                <InputField
                    id='field'
                    inputRef={inputRef}
                    value={editFieldValue}
                    valid={valid}
                    onChange={value => setEditFieldValue(value)}
                    label=''
                    placeholder=''
                />
                <FontAwesomeIcon
                    className={styles.button}
                    icon={faBan}
                    color={Colors.gray1}
                    onClick={() => onCancel()}
                    title={t('cancel')}
                />
                <FontAwesomeIcon
                    className={styles.button}
                    icon={faSave}
                    color={Colors.gray1}
                    onClick={() => onSave()}
                    title={t('save')}
                />
            </div>
            }
        </div>
    )
}

export default EditableField

const useStyles = createUseStyles({
    container: {
        width: 400,
    },
    readonlyContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 15
    },
    editContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: 15
    },
    button: {
        cursor: 'pointer'
    }
})