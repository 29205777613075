import { useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { Colors } from '../constants/colors'
import DateTimePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv';
import "react-datepicker/dist/react-datepicker.css"
import InfoIcon from './InfoIcon'
import { useTranslation } from 'react-i18next';

registerLocale('sv', sv)

interface Props {
    id?: string
    includeTime?: boolean
    label?: string
    value?: string
    valid?: boolean
    disabled?: boolean
    mandatory?: boolean
    onChange?: (value: string | undefined | null) => void 
    tooltipText?: string
}

const DatePicker = ({ 
    id = "select",
    includeTime,
    label,
    value,
    valid = true,
    disabled,
    mandatory,
    onChange,
    tooltipText,
} : Props) => {
    const styles = useStyles()
    const { t, i18n } = useTranslation('common');

    const [dateValue, setDateValue] = useState<Date | null>(value ? new Date(value) : null)

    useEffect(() => {
        const valueAsDate = value ? new Date(value) : null;

        if (valueAsDate !== dateValue)
            setDateValue(valueAsDate)             
    }, [value])

    const handleOnChange = (date: Date | null) => {
        setDateValue(date)
        if (date && onChange) {
            if (includeTime) {
                onChange(new Date(date.getTime()).toISOString())
            } else {
                const offset = date.getTimezoneOffset()
                const utcDateString = new Date(date.getTime() - (offset * 60 * 1000)).toISOString()
                onChange(utcDateString.split('T')[0])
            }
            
        } else if (onChange) {
            onChange(null)
        }
    }

    const renderLabel = () => {
        return label && (
            <div>
                <label className={styles.label}>{label}</label>
                {mandatory && <label className={styles.mandatoryIndicator}>*</label>}
                {tooltipText && <InfoIcon text={tooltipText} />}
            </div>
        )
    }  

    return (
        <div className={styles.container}>
            {renderLabel()}
            <div className={`${styles.inputContainer}`} style={{borderColor: valid ? Colors.euro_600 : Colors.rubel_700, backgroundColor: disabled ? Colors.grayDisabled : Colors.grayCard}}>
            <DateTimePicker
                className={styles.input}
                disabled={disabled}
                selected={dateValue}
                onChange={(date) => handleOnChange(date as Date | null)}
                timeInputLabel={`${t('time')}:`}
                dateFormat={includeTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'}
                calendarStartDay={1}
                showTimeInput={includeTime}
                locale={(i18n.language?.startsWith('sv') ? sv : undefined) ?? undefined}
            />
            </div>
        </div>
    )
}

export default DatePicker

const useStyles = createUseStyles({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    inputContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        width: '100%',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 3,
        borderRadius: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: Colors.euro_600,
        backgroundColor: Colors.grayCard,
    },
    input: {
        width: '100%',
        height: 40,
        border: 'none',
        backgroundColor: 'transparent',
        borderWidth: 0,
        '&:focus': {
            border: 'none',
            outline: 'none',
        },
        '&:active': {
            border: 'none',
            outline: 'none',
        },
        '&:disabled': {
            backgroundColor: 'transparent',    
        }
    },
    label: {
        marginBottom: 3,
        marginRight: 4
    },
    mandatoryIndicator: {
        color: Colors.rubel_700,
        marginRight: 4
    },
  })
  