import {Money} from "../redux/types";

export const formatMoney = (money: Money): string => {

  if (money === null || money === undefined) {
    return '-'
  }

  return `${money.amount.toLocaleString('sv-SE')} ${money.currencyCode.toUpperCase()}`
}
