import { createUseStyles } from "react-jss"
import Card from "../components/Card"
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator"
import { useTranslation } from "react-i18next"
import { apiConfig } from "../apiConfig"
import BackButton from "../components/BackButton"
import useExternalScript, { ExternalScriptState } from "../hooks/useExternalScript"

const ForaAdjustment2023 = () => {
    const styles = useStyles()
    const { t } = useTranslation('foraAdjustment');
    const wasmScriptState = useExternalScript('_framework/blazor.webassembly.js?version=' + apiConfig.blazorAppVersion);

    return (
        <>
        <BackButton />
        <Card className={styles.card}>
            <h2>
                {t('title2023')}
            </h2>
        </Card>
        <Card>
            {wasmScriptState == ExternalScriptState.ready &&
            <fora-yearly-adjuster api-base-url={apiConfig.baseUrl} auth-token={localStorage.getItem('authToken')}/>
            }
            <FullScreenLoadingIndicator visible={wasmScriptState === ExternalScriptState.loading} />
        </Card>
        </>
    )
}

export default ForaAdjustment2023

const useStyles = createUseStyles({
    card: {
        marginBottom: 20
    },
    info: {
        marginTop: 20,
    },
    button: {
        alignSelf: 'flex-end'
    }
})
